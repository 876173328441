import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Navigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import GatewayTable from './GatewayTable';

export default function GatewaysManagement() {
    const [state, setState] = useState({
        optionsBalance:{
            chart:{
                foreColor:'#60628b',
                toolbar:{
                    show:false
                },
                dropShadow: {
                    enabled: true,
                    top: 7,
                    left: 5,
                    blur: 2,
                    color: '#df972d',
                    opacity: 0.35
                }
            },
            dataLabels: {
                enabled: false
            },
            colors:['#df972d'],
            series:[{
                data:[10000,7200,4100,12000,8000,3000,150,15000,12000,9500,6350,3750],
                name:"Balance"},
            ],
            stroke: {
                curve: 'smooth'
            },
            grid:{
                xaxis:{
                    lines:{
                        show:false
                    }
                },
                yaxis:{
                    lines:{
                        show:false
                    }
                },
                padding:{
                    top: 0,
                    right: 10,
                    bottom: 0,
                    left: 25
                }
            },
            markers:{
                discrete: [{
                    seriesIndex: 0,
                    dataPointIndex: 11,
                    fillColor: '#fff',
                    strokeColor: '#df972d',
                    size: 7,
                    shape: "circle"
                }]
            },
            xaxis: {
                categories: ["January","February","March","April","May","June","July","August","September","October","November","December"],
                axisBorder:{
                    show: false
                },
                axisTicks:{
                    show:false
                }
            },
            yaxis:{
                show:false
            },
            
        }
        })

    const renderData = () => {
        return <GatewayTable/>
    }
    
    useEffect(()=>{
        

    },[])
    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(role !== 1){
        return <Navigate to="/onlinecalls" replace={true}/>
    }
    else
    return (
    <div className='container-xxl flex-grow-1 container-p-y'>
        {
            JSON.parse(secureLocalStorage.getItem('user')).role_id===1
            ?
            <div className="card flex-grow-1" style={{width:'100%', padding:10, marginTop:10}}>
            <div className="card-body" style={{position: 'relative'}}>
                {
                   renderData()
                }
            </div>
            </div>
            :null
        }
    </div>
)
}
