import React, { useEffect, useState } from 'react'
import styles from './GatewayPrefixes.module.scss'
// import { useDemoData } from '@mui/x-data-grid-generator';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Countries from '../../resources/countries.json';
import Box from '@mui/material/Box';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Backdrop, Button, Fade, LinearProgress, Modal, Stack } from '@mui/material';
import axios from 'axios';
import { WaveSurferPlayer } from './AudioWaveForm.jsx';
import secureLocalStorage from 'react-secure-storage';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import dayjs from 'dayjs';
import Select from 'react-select';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));
function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Empty set</Box>
    </StyledGridOverlay>
  );
}
function customCheckbox(theme) {
  return {
    '& .MuiCheckbox-root svg': {
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
        }`,
      borderRadius: 2,
    },
    '& .MuiCheckbox-root svg path': {
      display: 'none',
    },
    '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
      backgroundColor: '#1890ff',
      borderColor: '#1890ff',
      transition: 'background 3s !important',
      transition: 'color 3s !important',
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
      position: 'absolute',
      display: 'table',
      border: '2px solid #fff',
      borderTop: 0,
      borderLeft: 0,
      transform: 'rotate(45deg) translate(-50%,-50%)',
      opacity: 1,
      transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
      content: '""',
      top: '50%',
      left: '39%',
      width: 5.71428571,
      height: 9.14285714,
    },
    '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
      width: 8,
      height: 8,
      backgroundColor: '#1890ff',
      transform: 'none',
      transition: 'background 3s !important',
      transition: 'color 3s !important',
      top: '39%',
      border: 0,
    },
  };
}
const styleDark = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '15px',
  backgroundColor: '#252424',
  width: "1200px",
  height: "600px"
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '15px',
  backgroundColor: '#fff',
  width: "1200px",
  height: "600px"
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    transition: 'background 3s !important',
    transition: 'color 3s !important',
  },
  '& .MuiDataGrid-rowsContainer': {
    minHeight:500
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {

  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
  },
  '& .MuiDataGrid-cell': {
    color:
      theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  ...customCheckbox(theme),
}));

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}


const PAGE_SIZE = 12;

export default function GatewayRelations({  setIsDarkMode,handleClick,setSnackType,setSnackMessage }) {
  const [search, setSearch] = useState('')
  const [currentRecords, setCurrentRecords] = useState([]);
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [companies,SetCompanies] = useState([]);
  const isDarkMode = true;
  const StyledDatePickerInput = styled(DatePicker)({
    width:'100%',
    background: 'none',
    borderRadius: '10px',
    height:'38px',
    '& .MuiInputBase-input': {
        border: 'none',
        color: isDarkMode?'rgb(255, 193, 172)':'rgb(255, 48, 48)',
        fontFamily: "'Public Sans', sans-serif",

    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: isDarkMode?'rgb(255, 193, 172)':'rgb(255, 48, 48)',
    },
    '& .MuiSvgIcon-root': {
        color: isDarkMode?'rgb(255, 193, 172)':'rgb(255, 48, 48)',
    },
  });

const columns = [
  {
      field: 'id',
      headerName: 'Id',
      width: 100,
      editable: false,
      display:'none'
  },
  // {
  //     field: 'Title',
  //     headerName: 'Company',
  //     width: 100,
  //     editable: false,
  // },
  
    {
      field: 'prefix',
      headerName: 'Префикс',
      width: 300,
      editable: false,
      headerAlign: 'start',
      type:'text',
    },
    {
      field: 'prefix_title',
      headerName: 'Название префикса',
      width: 300,
      editable: false,
      headerAlign: 'start',
      type:'text',
    },
    {
      field: 'titlemask',
      headerName: 'Транк',
      width: 300,
      editable: false,
      headerAlign: 'start',
      type:'text',
    },
    {
      field: 'additional_prefix',
      headerName: 'Доп. префикс',
      width: 300,
      editable: false,
      headerAlign: 'start',
      type:'text',
    },
    
  ];
  const [audioId, setAudioId] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const handleOpenModal = (id) => {
    setOpenModal(true)
    axios.post('https://www.pbxdiamondcall.com:8081/getrecord', { filename: id },
      { responseType: 'blob' })
      .then((res) => {
        if (res.status === 400) {
          console.log("audio not found");
        }
        else {
          var binaryData = [];
          binaryData.push(res.data)
          const audioUrl = URL.createObjectURL(new Blob(binaryData, { type: "audio/mp3" }));
          console.log(audioUrl);
          setAudioId(audioUrl);
          if (document.getElementById("record") != null)
            document.getElementById("record").load();
          setIsLoading(false)
        }
      })
      .catch((err) => { console.log(err) });
  };
  const [downloaded, SetDownloaded] = useState(false);
  const download = (id) => {
    setSnackMessage("Audio is loading. Please wait. It may take a few seconds.");
    setSnackType("success");
    handleClick();
    axios.post('https://www.pbxdiamondcall.com:8081/getrecord/', { filename: id },
      { responseType: 'blob' })
      .then((res) => {
        if (res.status === 400) {
          console.log("audio not found");
        }
        else {
          var binaryData = [];
          binaryData.push(res.data)
          const audioUrl = URL.createObjectURL(new Blob(binaryData, { type: "audio/mp3" }));
          console.log(audioUrl);
          console.log(downloaded);
          if (downloaded == false) {
            const link = document.createElement('a');
            link.href = audioUrl;
            link.setAttribute(
              'download'
              , `${id}`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            SetDownloaded(true);
          }
          if (document.getElementById("record") != null)
            document.getElementById("record").load();
          SetDownloaded(false);
        }
      })
      .catch((err) => { console.log(err) });
  }
  const handleCloseModal = () => {
    setOpenModal(false);
    setIsLoading(true)
  };
  const [recordsB, SetRecordsB] = useState([]);
  const [records, SetRecords] = useState([]);
  const ChangeRecords = () =>{
    
    axios.post(`https://www.pbxdiamondcall.com:8081/records/new`,
      {
        company_id: companyChoose.value,
        username: JSON.parse(secureLocalStorage.getItem('user')).login
      }).then((res) => {
        SetRecords(res.data.data);
        setIsLoadingPage(false);
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    if (search === '') {
      setCurrentRecords(recordsB);
    } else {
      const filtered = recordsB.filter((record) => 
        record.prefix.toString().toLowerCase().includes(search.toLowerCase()) ||
        record.prefix_title.toLowerCase().includes(search.toLowerCase()) ||
        record.titlemask.toLowerCase().includes(search.toLowerCase())
      );
      setCurrentRecords(filtered);
    }
  }, [recordsB, search]);
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });
  
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });
  useEffect(() => {
    axios.get(`https://www.pbxdiamondcall.com:8081/gateway/prefixes`,).then((res) => {
        SetRecords(res.data);
        SetRecordsB(res.data);
        setIsLoadingPage(false);
      })
      .catch((err) => console.log(err))
      // axios.get('https://www.pbxdiamondcall.com:8081/companies')
      //  .then((res)=>{
      //   var arr = [];
      //   res.data.forEach((el)=>{
      //       console.log(el);
      //       arr.push({
      //                   value:el.Id,
      //                   label:el.Title
      //               })
            
      //   })
      //   SetCompanies(arr);
        
      //   console.log(res.data);
      //  })
      //  .catch((err)=>console.log(err))
  }, [])
  useEffect(()=>{
    console.log(currentRecords)
  },[currentRecords])
  const handleChangeStartDate = (newValue) => {
    // Устанавливаем время на полночь
    const midnightStartDate = dayjs(newValue).startOf('day');
    setStartDate(midnightStartDate);
};
// useEffect(()=>{
//   if(recordsB){
//     if(startDate && !endDate){
//       const filtered = recordsB.filter(el=> dayjs(el.time) > dayjs(startDate))
//       setCurrentRecords(filtered)
//     }
//     if(!startDate && endDate){
//       const filtered = recordsB.filter(el=> dayjs(el.time) < dayjs(endDate))
//       setCurrentRecords(filtered)
//     }
//     if(startDate && endDate){
//       const filtered = recordsB.filter(el=> dayjs(el.time).isBetween(startDate,endDate))
//       setCurrentRecords(filtered)      
//     }
//   }else{
//     setCurrentRecords(recordsB)
//   }
// },[recordsB,startDate , endDate])
const [companyChoose,SetCompanyChoose] = useState(false);
const handleChangeEndDate = (newValue) => {
    // Устанавливаем время на полночь
    const midnightEndDate = dayjs(newValue).endOf('day');
    setEndDate(midnightEndDate);
};
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <div className={isDarkMode ? styles.bodyDark : styles.body}>
       
        <div className={styles.page}>
          {
            isLoadingPage ?
              <div className={styles.loader}>
                <LinearProgress sx={{ width: '50%' }} color='inherit' />
              </div>
              :
              <div className={styles.stats}>
                <div className={styles.topBar}>
                  <div className={styles.search}>
                    <label htmlFor="input">Search</label>
                    <input onChange={(e) => setSearch(e.target.value)} placeholder='Search...' />
                  </div>
                  
                </div>

                <StyledDataGrid
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                  getRowId={(row) => row.id}
                  disableCellSelectionOnClick
                  autoHeight={true}
                  sx={{
                    border: isDarkMode ? 'none' : '1px solid lightgray',
                    transition: 'background color 3s !important',
                    width: "60vw",
                    maxHeight:"65vh",
                    backgroundColor: isDarkMode ? '#2A2A31' : "#fff",
                    padding: "10px",
                    minHeight:500,
                    color:"#d3d3d2",
                    overflowY:"auto",
                    fontFamily: "'Ubuntu', sans-serif",
                    '--DataGrid-overlayHeight': '400px',
                  }}
                  slots={{
                    pagination: CustomPagination,
                    noRowsOverlay: CustomNoRowsOverlay
                  }}
                  rows={currentRecords}
                  columns={columns}
                />
              </div>
          }
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}

        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={isDarkMode ? styleDark : style}>
            <div style={{ width: '100%', display: 'flex' }}>
              {
                isLoading ?
                  <div style={{ width: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <LinearProgress sx={{ width: '50%' }} color='inherit' />
                  </div>
                  :
                  <div >

                    {/* <h3 style={{color: isDarkMode?'white':'black', width: '100%', textAlign: 'center', fontFamily: "'Public Sans',sans-serif", margin: '0px' }}>
                          Record
                        </h3> */}
                    <WaveSurferPlayer
                      height={240}
                      waveColor={isDarkMode ? '#D1D6DA' : "black"}
                      progressColor={isDarkMode ? 'rgb(255, 193, 172)' : "rgb(119, 237, 139)"}
                      url={audioId}
                      isDarkMode={isDarkMode}
                    />
                  </div>
              }
              <CancelRoundedIcon
                onClick={() => handleCloseModal()}
                sx={{
                  position: 'absolute',

                  top: '-16px',
                  width: '40px',
                  height: '40px',
                  right: '-16px',
                  fill: isDarkMode ? 'rgb(255, 172, 130)' : 'rgb(119, 237, 139)',
                  cursor: 'pointer'
                }}
              />
            </div>

          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>

  )
}
