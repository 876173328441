/* eslint-disable eqeqeq */
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage';

export default function PrivateRoute({children}) {
    const params = useParams();
    const user = JSON.parse(secureLocalStorage.getItem('user'));
    const parameters = {
      id:params.id!==undefined?params.id:null,
      officeid:params.officeid!==undefined?params.officeid:null,
      depid:params.depid!==undefined?params.depid:null,
      teamid:params.teamid!==undefined?params.teamid:null
    }
    try{
      if(!secureLocalStorage.getItem("logged_user")) return <Navigate to="/login" replace={true} />
      else if(user.role_id === 6){
        return <Navigate to="/denied" replace={true} />
      }
      if(user.role_id !== 1 && user.role_id !== 7){
        if(user.company_id!=parameters.id && parameters.id!==null) return <Navigate to="dashboard" replace={true} />
        else if(user.office_id!=parameters.officeid && parameters.officeid!==null) return <Navigate to="dashboard" replace={true} />
        else if(user.department_id!=parameters.depid && parameters.depid!==null) return <Navigate to="dashboard" replace={true} />
        else if(user.team_id!=parameters.teamid && parameters.teamid!==null) return <Navigate to="dashboard" replace={true} />
      }
    }
    catch(err){
      console.log(err);
      return <Navigate to="/login" replace={true} />
    }
    
    
    return children;
}
