import React, {useState,useEffect, useRef} from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { Navigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import OnlineTable from './OnlineTable';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import { ReadyState } from 'react-use-websocket';
import axios from 'axios';
import ReportIcon from '@mui/icons-material/Report';
export default function OnlineCalls() {
    const didUnmount = useRef(false);
    const {t,i18n} = useTranslation();
    const [socketUrl] = useState('wss://www.pbxdiamondcall.com:8081/echo');
    const [data,SetData] = useState([]);
    const [isPageActive,setActive] = useState(true);
    const [table,SetTable] = useState();
    const [spamResult , setSpamResult] = useState("");
    const [checkSpam , setCheckSpam] = useState("");
    const {sendMessage, lastMessage, readyState} = useWebSocket(socketUrl, {
        onMessage: (event)=>{
            try {
                SetData(event.data);
                SetTable(renderData(JSON.parse(event.data).tp,JSON.parse(event.data).tp1))
            } catch (error) {
                console.log(error)
            }
        },
        shouldReconnect: (closeEvent) => true,
        onClose:(event)=>{
        },
        onReconnectStop:(event)=>{
            // sendMessage("Give me calls");
        },
        onError:(event)=>{
            // sendMessage("Give me calls");
        },
        reconnectAttempts:10,
        reconnectInterval:10000,
    });
    const renderData = (message, message1) => {
        return (message.length === 0 && message1.length === 0)
        ?
        <div className='container-xxl flex-grow-1 container-p-y'>
                <div className="card" style={{width:1400, height:500, justifyContent:'center',alignContent:'center', display:'inline-flex'}}>
                    <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>{t("onlineCallsNoCalls")}</h1>
                    <ScaleLoader
                        color="#696cff"
                        height={270}
                        margin={10}
                        radius={2}
                        speedMultiplier={0.8}
                        width={80}
                        cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
            </div>
        :
        <OnlineTable t={t} names={message} names1={message1}/>
    }    
    const SpamNumber =async ()=>{
        // const num = checkSpam
        const {data} =  await axios.post('https://www.pbxdiamondcall.com:8081/spamNumberCheck', {
            num:checkSpam,
            user_info_id: JSON.parse(secureLocalStorage.getItem("user")).user_info_id,
            billingaccount_id: JSON.parse(secureLocalStorage.getItem("user")).billingaccount_id,
            company_id: JSON.parse(secureLocalStorage.getItem("user")).company_id,
            description:`Номер [${checkSpam}] был проверен на спам.`,
            transactiontype:"SpamCheck",
            price:0.01
        })
        // axios.post("https://www.pbxdiamondcall.com:8081/brand/transactions",{company_id:JSON.parse(secureLocalStorage.getItem("user")).company_id}).then((resp)=>{
        //     console.log(resp);
        //     setTransactions(resp.data);
        // }).catch((err)=>console.log(err));
        setSpamResult(data)
        console.log(data);
    }
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];
    useEffect(()=>{
        sendMessage(`${JSON.parse(secureLocalStorage.getItem('user')).id},Give me calls`);
        window.addEventListener('focus', function () {
            console.log('focused')
            sendMessage(`${JSON.parse(secureLocalStorage.getItem('user')).id},Give me calls`)
            setActive(true);
        });
    },[])
    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(role === 6 ){
        return <Navigate to="/onlinecalls" replace={true}/>
    }
    else if(lastMessage === null) {
        return <div className='container-xxl flex-grow-1 container-p-y'>
                <div className="card" style={{width:1400, height:500, justifyContent:'center',alignContent:'center', display:'inline-flex'}}>
                    <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>{t("loadingData")}...</h1>
                    <ScaleLoader
                        color="#696cff"
                        height={270}
                        margin={10}
                        radius={2}
                        speedMultiplier={0.8}
                        width={80}
                        cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
            </div>
    }
    else if(lastMessage.data!== undefined && JSON.parse(lastMessage?.data)?.tp.length === 0){
        return <div className='container-xxl flex-grow-1 container-p-y'>
                <div className="card" style={{width:1400, height:500, justifyContent:'center',alignContent:'center', display:'inline-flex'}}>
                    <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>{t("onlineCallsNoCalls")}</h1>
                    <ScaleLoader
                        color="#696cff"
                        height={270}
                        margin={10}
                        radius={2}
                        speedMultiplier={0.8}
                        width={80}
                        cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
            </div>
    }
    else{ 
    return (
    <div className='container-xxl flex-grow-1 container-p-y'>
        <div className="card" style={{width:'100%'}}>
            <div className="card-header">
                <div className="row" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', position:"relative",marginBottom:"5vh"}}>
                    <h5 style={{
                        position:"absolute",top:"1vh",left:"1vw",
                        fontSize:"25px", fontWeight:"bold"
                    }}>{t("onlinecalls")}</h5>
                    <h5 style={{
                        position:"absolute",top:"1vh",right:"2vw",
                        fontSize:"18px", fontWeight:"bold", border:"1px solid #696CFF", 
                        width:"150px",padding:"5px", borderRadius:"5px", cursor:"pointer", display:"flex",
                        justifyContent:"center", alignItems:"center",textAlign:"center"
                    }} 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasEnd" 
                        aria-controls="offcanvasEnd">
                            <ReportIcon sx={{width:"1.5vw"}}/>
                            Spam check</h5>
                </div>
            </div>
            {
                        table
            }  
        </div>
        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasEnd" aria-labelledby="offcanvasEndLabel">
                <div className="offcanvas-header">
                <h5 id="offcanvasStartLabel" className="offcanvas-title">Check for spam</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className='card-body' style={{width:"400px", display:"flex",alignItems:"start",justifyContent:"start", flexDirection:"column", padding:"20px"}}>
                    <div class="mb-3">
                        <label for="defaultFormControlInput" className="form-label">Phone number</label>
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <input type="text" className="form-control" id="defaultFormControlInput"
                            placeholder="33312345678901" onChange={e=> setCheckSpam(e.target.value)} aria-describedby="defaultFormControlHelp"  />
                            <button className='btn btn-label-primary' onClick={()=>SpamNumber()} style={{marginLeft:"10px"}}>Check</button>
                        </div>
                    </div>
                    <textarea rows="15" value={spamResult?`[RU]\n${spamResult[0]?.TagRU.trim()}\n[EU]\n${spamResult[0]?.TagEU}`:""} className="form-control" 
                    style={{width:"350px", height:"40vh",resize:"none"}}
                    readOnly/>
                </div>

                <button type="button" className="btn btn-label-secondary d-grid" data-bs-dismiss="offcanvas" style={{width:250, alignSelf:'center', margin:10}}>{t("cancelbtn")}</button>
        </div>
    </div>
)
        }

}
