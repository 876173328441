import React, { useEffect, useMemo, useState } from 'react';
import styles from './History.module.scss';
import secureLocalStorage from 'react-secure-storage';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import dayjs from 'dayjs';
import moment from 'moment';
import axios from 'axios';
import { blue } from '@mui/material/colors';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import MicOffIcon from '@mui/icons-material/MicOff';
import { WaveSurferPlayer } from '../../Components/AudioWaveFormRebrending';
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js'
import { LinearProgress, Tooltip } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Swal from 'sweetalert2';
import Select from 'react-select';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
export default function History() {
    const [data,setData] = useState([]);
    const [companies,SetCompanies] = useState([]);
    const [isDownloaded,setIsDownloaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [companyId,setCompanyId] = useState(JSON.parse(secureLocalStorage.getItem('user')).company_id);

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([{"id":"time","desc":true}]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });
    useEffect(()=>{
        if(JSON.parse(secureLocalStorage.getItem('user')).role_id===1){
            axios.get('https://www.pbxdiamondcall.com:8081/companies')
            .then((res)=>{
                var arr = [];
                res.data.forEach((el)=>{
                    console.log(el);
                    arr.push({
                                value:el.Id,
                                label:el.Title
                            })
                    
                })
                SetCompanies(arr);
                
                console.log(res.data);
            })
            .catch((err)=>console.log(err))
        }
    },[])
    useEffect(() => {       
        const fetchData = async () => {
          if (!data.length) {
            setIsLoading(true);
          } else {
            setIsRefetching(true);
          }
          console.log(columnFilters);
          const url = new URL(
            '/records/new/serverside', 'https://www.pbxdiamondcall.com:8081'
          );
          url.searchParams.set(
            'start',
            `${pagination.pageIndex * pagination.pageSize}`,
          );
          url.searchParams.set('company_id', `${companyId}`);
          url.searchParams.set('username',`${JSON.parse(secureLocalStorage.getItem('user')).login}`);
          url.searchParams.set('size', `${pagination.pageSize}`);
          url.searchParams.set('filters',JSON.stringify(columnFilters ?? []));
          url.searchParams.set('globalFilter', globalFilter ?? '');
          url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
    
          try {
            const response = await fetch(url.href);
            const json = await response.json();
            setData(json.data);
            console.log(json.meta)
            setRowCount(json.meta.totalRowCount[0]["COUNT(calls.uuid)"]);
          } catch (error) {
            setIsError(true);
            console.error(error);
            return;
          }
          setIsError(false);
          setIsLoading(false);
          setIsRefetching(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        companyId
    ]);
    const downloadAudio = (e, uuid) => {
        Swal.fire({
            title:"Preparing audio",
            text:"Please wait until the audio is ready to download",
            icon:'info',
            showCancelButton:false,
            showConfirmButton:false
        })
        e.preventDefault();
        
        axios.get('https://www.pbxdiamondcall.com:8081/record', {
            params: { filename: uuid },
            responseType: 'blob',
        })
        .then((res)=>{
            var binaryData = [];
            binaryData.push(res.data)
            const audioUrl = URL.createObjectURL(new Blob(binaryData, {type:"audio/mp3"}));
            
            if(isDownloaded == false){
            Swal.close();
            const link = document.createElement('a');
            link.href = audioUrl;
            link.setAttribute(
                'download'
                ,`${uuid}`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            }
            if(document.getElementById("record")!=null)
            document.getElementById("record").load();
            setIsDownloaded(true);
            
        })
        .catch((err)=>{console.log(err)});
    }
    const [audio,setAudio] = useState('');
    const [audioLoading,setAudioLoading] = useState(true);
    const [audioFound,setAudioFound] = useState(false);
    // useEffect(()=>{
    //     axios.post('https://www.pbxdiamondcall.com:8081/records/new',{
    //     username:`${JSON.parse(secureLocalStorage.getItem('user')).login}`,
    //     company_id:28}).then((res)=>{
    //         setData(res.data.data);
    //     }).catch((err)=>{

    //     })
    // },[])
    useEffect(()=>{console.log(audioLoading);},[audioLoading])
    // useEffect(()=>setAudioLoading(false),[audio])
    // id: '2c6ea0b2-52a1-4172-b991-700be65125b4',
    //     Title: 'Heaven',
    //     sip_user: 'Test1',
    //     src: '74993499636',
    //     dst: '80179381570637',
    //     duration: 1,
    //     conversation: 0,
    //     status: 'Initiated',
    //     time: '2024-05-10T10:46:48.000Z',
    //     conversation_start: null,
    //     call_end: '2024-05-10T10:46:49.000Z',
    //     cost: 0.1,
    //     billingaccount_id: 13,
    //     company_id: 1,
    //     country_id: 1,
    //     team_id: 1,
    //     price: 0,
    //     country: 'Russia'
    const columns = useMemo(
        () => [
            {
                accessorKey: 'time',
                header: 'Time',
                grow: 1, 
                filterVariant: 'date-range',
                sortingFn: 'datetime',
                Cell: ({ renderedCellValue, row }) => {
                    console.log()
                    return (
                        <span style={{ paddingLeft:"1vw"}}>{row.original.time?moment(row.original.time).format("YYYY-MM-DD HH:mm:ss"):"Отсутствует"}</span>
                    )
                },
                filterFn: (row, columnId, filterValue, addMeta) => {
                    let date1 = dayjs(filterValue[0]).startOf("day");
                    let date2 = dayjs(filterValue[1]).endOf("day");
                    let rowdate = dayjs(row.original.time);
                    if(date1.isValid() && date2.isValid()){
                        console.log(filterValue);
                        if((filterValue[0] == undefined || filterValue[0] == "") || (filterValue[1] == undefined || filterValue[1] == "")) return true;
                        else return (rowdate.isAfter(date1) && rowdate.isBefore(date2))?true:false 
                    }
                    else if(!date1.isValid() && date2.isValid()){
                        console.log(filterValue);
                        if((filterValue[1] == undefined || filterValue[1] == "")) return true;
                        else return rowdate.isBefore(date2)?true:false ;
                    }
                    else if(date1.isValid() && !date2.isValid()) {
                        console.log(filterValue);
                        if((filterValue[0] == undefined || filterValue[0] == "")) return true;
                        else return rowdate.isAfter(date1)?true:false ;
                    }
                    else {
                        console.log(filterValue);
                        return true;
                    }
                },
            },
            // {
            // accessorKey: 'company',
            // header: 'Brand',
            // grow: 1, 
            // Cell: ({ renderedCellValue, row }) => {
            //     console.log()
            //     return (
            //         <span>{row.original.company}</span>
            //     )
            // },
            // },
            {
            accessorKey: 'sip_user',
            header: 'SIP',
            grow: 1, 
            maxSize:250,
            Cell: ({ renderedCellValue, row }) => {
                console.log()
                return (
                    <span>{row.original.sip_user}</span>
                )
            },
            },
            {
            accessorKey: 'dst',
            header: 'Destination',
            grow: 1, 
            size:200,
            Cell: ({ renderedCellValue, row }) => {
                console.log()
                return (
                    <span>{row.original.dst}</span>
                )
            },
            },
            {
            accessorKey: 'duration',
            header: 'Dur.',
            enableResizing:false,
            grow: 1, 
            size:130,
            Cell: ({ renderedCellValue, row }) => {
                console.log()
                return (
                    <span style={{width:"100%",display:"flex",justifyContent:"center"}}>
                        {row.original.duration<3600
                        ?
                        new Date(row.original.duration * 1000).toISOString().substring(14, 19)
                        :
                        new Date(row.original.duration * 1000).toISOString().substring(11, 19)}
                    </span>
                )
            },
            },
            {
            accessorKey: 'conversation',
            header: 'Conv.',
            enableResizing:false,
            grow: 1, 
            size:130,
            Cell: ({ renderedCellValue, row }) => {
                console.log()
                return (
                    <span style={{width:"100%",display:"flex",justifyContent:"center"}}>
                        {row.original.conversation<3600
                        ?
                        new Date(row.original.conversation * 1000).toISOString().substring(14, 19)
                        :
                        new Date(row.original.conversation * 1000).toISOString().substring(11, 19)}
                    </span>
                )
            },
            },
            {
            accessorKey: 'status',
            header: 'Status',
            enableResizing:false,
            grow: 1, 
            size:140,
            Cell: ({ renderedCellValue, row }) => {
                console.log()
                return (
                    <span style={{width:"100%",display:"flex",justifyContent:"center"}}>
                        {row.original.status == "ANSWER"
                        ?
                        <span style={{
                            width:"5vw",padding:"0.3vw 0.6vh",fontWeight:"600", fontFamily:"'Ubuntu',sans-serif",color:"#24D6A3",
                            border:"1px solid #24D6A3", borderRadius:"0.4vh", textAlign:"center",userSelect:"none", background:"#232329"
                        }}>
                            Answer
                        </span>
                        :
                        <span style={{
                            width:"5vw",padding:"0.3vw 0.6vh",fontWeight:"600", fontFamily:"'Ubuntu',sans-serif",color:"#FF4861",
                            border:"1px solid #FF4861", borderRadius:"0.4vh", textAlign:"center",userSelect:"none", background:"#232329"
                        }}>
                            No answer
                        </span>
                        }
                    </span>
                )
            },
            sortingFn:'text'
            },
            {
            accessorKey: 'hangup_code',
            header: 'HangupCause',
            grow: 1, 
            size:200,
            Cell: ({ renderedCellValue, row }) => {
                console.log()
                return (
                    <span>{row.original.hangup_code?row.original.hangup_code:"Обработка.."}</span>
                )
            },
            },
            {
            accessorKey: 'Record',
            header: '',
            enableResizing:false,
            enableSorting:false,
            enableColumnDragging:false,
            enableColumnActions: false,
            enableColumnFilter:false,
            grow: 1, 
            size:90,
            Cell: ({ renderedCellValue, row }) => {
                console.log()
                return (
                    <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center", gap:"0.2vw"}}>
                        
                        <Tooltip title={"Download audio"}>
                            <span onClick={(e)=>downloadAudio(e,row.original.uuid)} 
                            style={{
                                cursor:"pointer",
                                width:"3vw",fontSize:"2vh",height:"3.5vh",padding:"0.3vw 0.6vh",fontWeight:"600", fontFamily:"'Ubuntu',sans-serif",color:"#b47fe9",
                                border:"1px solid #b47fe9", borderRadius:"0.4vh", textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center",background:"#232329"
                            }}>
                                <DownloadForOfflineRoundedIcon sx={{width:"auto",height:"2.5vh"}} />
                            </span>
                        </Tooltip>
                        <Tooltip title={row.original.hangup_cause!==null
                                    ?
                                        row.original.hangup_cause==='agent'?"Agent"
                                        :
                                            row.original.hangup_cause==='client'?"Client"
                                            :"Server"
                                    :"Processing..."
                                    }>
                            <span style={{
                                width:"3vw",fontSize:"0.1vh", height:"3.5vh",padding:"0.3vw 0.6vh", fontFamily:"'Ubuntu',sans-serif",color:"#b47fe9",
                                border:"1px solid #b47fe9", borderRadius:"0.4vh", textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center",background:"#232329"
                            }}>
                                {
                                    row.original.hangup_cause!==null
                                    ?
                                        row.original.hangup_cause==='agent'?<i class="material-icons">support_agent</i>
                                        :
                                            row.original.hangup_cause==='client'?<i class="material-icons">face</i>
                                            :<i class="material-icons">report_problem</i>
                                    :<i class="material-icons">content_paste_off</i>
                                }
                            </span>
                        </Tooltip>
                    </div>
                )
            },
            },
            
        ],
        [],
    );
    const table = useMaterialReactTable({
        columns,
        data,
        enableStickyHeader:true,
        enableStickyFooter:true,
        enableColumnOrdering: true,
        enableExpandAll: false,
        // enableRowVirtualization:true,
        
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        muiToolbarAlertBannerProps: isError
        ? {
            color: 'error',
            children: 'Error loading data',
        }
        : undefined,
        
        rowCount:rowCount,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        state: {
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        },
        renderDetailPanel: ({ row }) => <div style={{width:"75vw",height:"15vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
            {
                audioLoading?
                    <LinearProgress  sx={{width:"900px"}}/>
                :
                    audioFound?
                    <WaveSurferPlayer
                        height={120}
                        waveColor="#D1D6DA"
                        progressColor="#EC407A"
                        url={audio}
                        plugins={[Timeline.create() , Hover.create({labelColor: 'white' , labelTop:'30px',position:'absolute' , top:'50px' , labelBackground: 'black',labelSize: '11px' ,lineColor: 'black'  })]}
                    />
                    :
                    <span style={{color:"#d3d3d2",fontFamily:"'Ubuntu',sans-serif",fontSize:"1.6vh"}}>
                        Error while loading audio.
                    </span>
            }
            </div>,
        muiDetailPanelProps: () => ({
        sx: (theme) => ({
            backgroundColor:'#2A2A31'
        }),
        }),
        
        renderTopToolbarCustomActions: ({ table }) => {
            if(JSON.parse(secureLocalStorage.getItem('user')).role_id == 1)
            return (
                <div className={styles.select}>
                    <Select placeholder='Select company'
                        onChange={(e)=>setCompanyId(e.value)}
                        id='operatorLb' 
                        isDisabled={false} 
                        options={companies} 
                        menuPosition="fixed"
                        menuPlacement="bottom"
                        menuPortalTarget={document.body}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2",
                                borderColor:"#232329"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#949fae",
                            }),
                        }}
                    />
                </div>
            )
        },
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => {
                table.setExpanded({ [row.id]: !row.getIsExpanded() });
                setAudioLoading(true);
                setAudioFound(true);
                axios.get('https://www.pbxdiamondcall.com:8081/record', {
                    params: { filename: row.original.uuid },
                    responseType: 'blob',
                })
                .then((res)=>{
                    if(res.status===400){
                        console.log("audio not found");
                        // self.setState({audioNotFound:true});
                    }
                    else{
                    setAudioFound(true);
                    var binaryData = [];
                    binaryData.push(res.data)
                    const audioUrl = URL.createObjectURL(new Blob(binaryData, {type:"audio/mp3"}));
                    console.log(audioUrl);
                    setAudio(audioUrl);
                    setAudioLoading(false);
                    // if(document.getElementById("record")!=null)
                    // document.getElementById("record").load();
                    // self.setState({downloaded:true});
                    }
                })
                .catch((err)=>{
                    setAudioLoading(false);setAudioFound(false);
                    console.log(err);
                });

            }, 
            children:  row.getIsExpanded() 
            ? 
                <MicOffIcon sx={{width:"3vw", marginLeft:"1.5vw",height:"3.5vh",border:"1px solid #b47fe9",background:"#232329", color:"#b47fe9", padding:"0.2vw 0.4vh", borderRadius:"0.4vh"}} />
            :
                <KeyboardVoiceIcon sx={{width:"3vw", marginLeft:"1.5vw",height:"3.5vh",border:"1px solid #b47fe9",background:"#232329", color:"#b47fe9", padding:"0.2vw 0.4vh", borderRadius:"0.4vh"}} />,
            sx: {
                color:"#fff",
                transition: 'transform 0.2s',
                // transform: row.getIsExpanded() ? 'rotate(90deg)' : 'rotate(0deg)',
                // transition: 'transform 0.2s',
            },
        }),
        onColumnOrderChange: (order)=>console.log(order),
        // onColumnVisibilityChange: (visibility)=>console.log(visibility),
        initialState: { 
          density: 'compact',
          pagination: {pageSize:50},
          columnOrder:[ 'time', 'company', 'sip_user', 'dst', 'duration', 'conversation',
            'status', 'hangup_code',
            'mrt-row-expand','Record', 'mrt-row-spacer'
          ]
        },
        muiSelectCheckboxProps: { 
          color: 'primary', 
          sx:(theme)=>({
            color: blue[50],
              '&.Mui-checked': {
                color: blue[500],
              },
          })
        },
        positionToolbarAlertBanner: 'none', 
        getRowId: (row) => row.Id,
        defaultColumn: {
            maxSize: 800,
            minSize: 80,
            size: 160,
        }, 
        muiTableBodyProps: {
          sx: (theme) => ({
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
              {
                backgroundColor:'#2A2A31',
                borderBottom:'1px dashed rgb(46, 50, 54)',
    
              },
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
              {
                backgroundColor: '#2A2A31',
                borderBottom:'1px dashed rgb(46, 50, 54)',
    
              },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
              {
                backgroundColor: '#2A2A31',
                
                borderBottom:'1px dashed rgb(46, 50, 54)',
              },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
              {
                backgroundColor: '#2A2A31',
                borderBottom:'1px dashed rgb(46, 50, 54)',
              },
          }),
        },
        
        muiTableContainerProps: {
          sx: (theme) => ({
            height:"68vh",
            maxWidth:"90vw"
          })
        },
        mrtTheme: (theme) => ({
          baseBackgroundColor: '#2A2A31',
          draggingBorderColor: '#000',
        }),
        
        muiTableBodyCellProps: ({ column }) => ({
            sx: {
              backgroundColor:'#2A2A31',
              fontFamily:"'Open Sans', sans-serif",
              fontSize: '14px',
              color:'#fff',
              borderBottom:'1px dashed rgb(46, 50, 54)',
              '& .MuiTableCell-root':{
                borderBottom:'1px dashed rgb(46, 50, 54)'
              }
            },
        }),
        muiTableProps: ({table})=>({
        sx:{
            backgroundColor:'grey',
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '14px',
            border:"1px solid rgb(46, 50, 54)"
        }
        }),
        muiTopToolbarProps: ({table})=>({
        sx:{
            backgroundColor:"#2A2A31",
            borderTop:"1px solid rgb(46, 50, 54)",
            borderLeft:"1px solid rgb(46, 50, 54)",
            borderRight:"1px solid rgb(46, 50, 54)",
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '14px',
            borderTopLeftRadius:'5px',
            borderTopRightRadius:'5px',
            '& .MuiButtonBase-root':{
            color:'#fff'
            },
            '& .MuiSvgIcon-root':{
            color:'#fff'

            },
            '& .MuiInputBase-root':{
            borderColor:'#fff'

            },
            '& .MuiInputBase-input':{
            color:'#fff',
            fontFamily:"'Open Sans', sans-serif",
            }
        }
        }),
        muiColumnActionsButtonProps: ({row})=>({
        sx:{
            color:'#fff'
        }
        }),
        muiTableHeadCellProps: {
        sx: {
            fontWeight: 'bold',
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '14px',
            backgroundColor:'#2A2A31',
            color:'#fff',
            
            borderBottom:'1px dashed rgb(46, 50, 54)',
            '& .MuiSvgIcon-root':{
            fill:'#fff'
            },
            '& .MuiDivider-root':{
            borderColor:'lightgrey'
            },
            '& .MuiInputBase-input':{
            color:'#fff',
            fontFamily:"'Open Sans', sans-serif",
            }
        },
        },
        muiTableHeadProps:{
        sx:{
            zIndex:1,
            '& .MuiTableCell-root':{
            border:"1px solid rgb(46, 50, 54)",
            },
        }
        },
        muiPaginationProps: {
        showRowsPerPage: false,
        shape: 'rounded',
        sx:{
            '& .MuiButtonBase-root':{
                color:'#fff'
            },
            '& .Mui-selected':{
                backgroundColor:"rgba(62, 163, 252, 0.213) !important"
            }
        }
        },
        muiBottomToolbarProps:{
        sx:{
            backgroundColor:'#2A2A31',
            borderBottom:"1px solid rgb(46, 50, 54)",
            borderLeft:"1px solid rgb(46, 50, 54)",
            borderRight:"1px solid rgb(46, 50, 54)",
            color:'#fff'

        },
        },
        muiFilterTextFieldProps:{
        sx:{
            color:'#fff'
        }
        },
        paginationDisplayMode: 'pages',
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        localization:{
        //   actions: 'Действия',
        //   and: 'и',
        //   cancel: 'Отменить',
        //   changeFilterMode: 'Изменить режим фильтра',
        //   changeSearchMode: 'Изменить режим поиска',
        //   clearFilter: 'Очистить фильтр',
        //   clearSearch: 'Очистить поиск',
        //   clearSelection: 'Очистить выбор',
        //   clearSort: 'Очистить сортировку',
        //   clickToCopy: 'Нажмите, чтобы скопировать',
        //   copy: 'Копировать',
        //   columnActions: 'Действие колонки',
        //   copiedToClipboard: 'Скопировано в буфер обмена',
        //   collapse: 'Свернуть',
        //   collapseAll: 'Свернуть все',
        //   dropToGroupBy: 'Добавить в группу к {column}',
        //   edit: 'Редактировать',
          expand: 'Audio',
        //   expandAll: 'Раскрыть все',
        //   filterArrIncludes: 'Включить',
        //   filterArrIncludesAll: 'Влючить все',
        //   filterArrIncludesSome: 'Включить',
        //   filterBetween: 'Между',
        //   filterBetweenInclusive: 'Между включительно',
        //   filterByColumn: 'Отфильтровать по {column}',
        //   filterContains: 'Содержит',
        //   filterEmpty: 'Пустой',
        //   filterEndsWith: 'Оканчивается',
        //   filterEquals: 'Равны',
        //   filterEqualsString: 'Равны',
        //   filterFuzzy: 'Физзи',
        //   filterGreaterThan: 'Больше чем',
        //   filterGreaterThanOrEqualTo: 'Больше или равно',
        //   filterInNumberRange: 'Между',
        //   filterIncludesString: 'Содержит',
        //   filterIncludesStringSensitive: 'Содержит (регистрозависимый)',
        //   filterLessThan: 'Меньше чем',
        //   filterLessThanOrEqualTo: 'Меньше или равно',
        //   filterMode: 'Режим фильтра: {filterType}',
        //   filterNotEmpty: 'Не пустое',
        //   filterNotEquals: 'Не равны',
        //   filterStartsWith: 'Начинается с',
        //   filterWeakEquals: 'Равны',
        //   filteringByColumn: 'Фильтр по {column} - {filterType} {filterValue}',
        //   goToFirstPage: 'Перейти на первую страницу',
        //   goToLastPage: 'Перейти на последнюю страницу',
        //   goToNextPage: 'Перейти на следующую страницу',
        //   goToPreviousPage: 'Перейти на предыдущую страницу',
        //   grab: 'Grab',
        //   groupByColumn: 'Сгруппировать по {column}',
        //   groupedBy: 'Сгруппировать',
        //   hideAll: 'Скрыть всё',
        //   hideColumn: 'Скрыть {column} колонку',
        //   max: 'Макс',
        //   min: 'Мин',
        //   move: 'Двигать',
        //   noRecordsToDisplay: <span style={{color:'#fff'}}>Нет записей для показа</span>,
        //   noResultsFound: 'Результатов не найдено',
        //   of: 'из',
        //   or: 'или',
        //   pin: 'Прикрепить',
        //   pinToLeft: 'Прикрепить слева',
        //   pinToRight: 'Прикрепить справа',
        //   resetColumnSize: 'Сбросить размер колонок',
        //   resetOrder: 'Сбросить порядок',
        //   rowActions: 'Действия строки',
        //   rowNumber: '#',
        //   rowNumbers: 'Количество строк',
        //   rowsPerPage: 'Строк на странице',
        //   save: 'Сохранить',
        //   search: 'Найти',
        //   selectedCountOfRowCountRowsSelected: '{selectedCount} из {rowCount} строк выбрано',
        //   select: 'Выбрать',
        //   showAll: 'Показать все',
        //   showAllColumns: 'Показать все колонки',
        //   showHideColumns: 'Показать/скрыть колонки',
        //   showHideFilters: 'Показать/скрыть фильтры',
        //   showHideSearch: 'Показать/скрыть поиск',
        //   sortByColumnAsc: 'Сортировать {column} по возрастанию',
        //   sortByColumnDesc: 'Сортировать {column} по убыванию',
        //   sortedByColumnAsc: 'Отсортирована по {column} возрастанию',
        //   sortedByColumnDesc: 'Отсортирована по {column} убыванию',
        //   thenBy: ', затем ',
        //   toggleDensity: 'Изменить плотность',
        //   toggleFullScreen: 'Включить полноэкранный режим',
        //   toggleSelectAll: 'Выбрать всё',
        //   toggleSelectRow: 'Переключить выбор строки',
        //   toggleVisibility: 'Изменить видимость',
        //   ungroupByColumn: 'Разгруппировать по {column}',
        //   unpin: 'Открепить',
        //   unpinAll: 'Открепить всё',
        }
    },[rowCount]);

    return (
        <div className={styles.page}>
            <div className={styles.history}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MaterialReactTable table={table} />
                </LocalizationProvider>
            </div>
        </div>
    )
}
