import './App.css';
import Dashboard from './Components/Dashboard';
import Menu from './layout/SideMenu';
import { Routes, Route, Router, Navigate, BrowserRouter, useLocation } from "react-router-dom";
import OnlineCalls from './Components/OnlineCalls';
import Login from './Components/Login';
import Layout from './layout/Layout';
import Error from './Components/Error';
import Journal from './Components/Journal';
import { useEffect, useState } from 'react';
import PrivateRoute from './Components/PrivateRoute';
import Companies from './Components/Companies';
import Offices from './Components/Offices';
import Departments from './Components/Departments';
import Teams from './Components/Teams';
import Agents from './Components/Agents';
import Billing from './Components/Billing';
import NoAccess from './Components/NoAccess';
import Users from './Components/Users';
import BillingManagement from './Components/BillingManagement';
import FreeswitchPanel from './Components/FreeswitchPanel';
import Administration from './Components/Administration';
import Analytics from './Components/Analytics';
import Individual from './Components/Individual';
import GatewaysManagement from './Components/GatewaysManagement';
import BrandRouting from './Components/BrandRouting';
import NumberVerification from './Components/NumberVerification';
import Numbers from './Components/Numbers';

import "./css/numpad.css";
import Record from './Components/Record';
import RecordsChoose from './Components/RecordsChoose';
import BillingChoose from './Components/BillingChoose';
import Suspects from './Components/Suspects';
import ItNumbers from './Components/ItNumbers';
import Reports from './Components/Reports/Reports'
import PrefixReport from './Components/PrefixReport/PrefixReport';
import GatewayPrefixes from './Components/GatewayPrefixes/GatewayPrefixes';
import LayoutRebrending from './layout/Layout/LayoutRebrending';
import DashboardNew from './Pages/Dashboard/Dashboard';
import History from './Pages/History/History';
import ManageBrand from './Pages/ManageBrand/ManageBrand';
import OnlineCallsRebrending from './Pages/OnlineCalls/OnlineCallsRebrending';
import UsersRebrending from './Pages/Users/UsersRebrending';
import ItNumbersRebrending from './Pages/Numbers/ItNumbersRebrending';
import BillingRebrending from './Pages/Billing/BillingRebrending';
import AnalyticsRebrending from './Pages/Analytics/AnalyticsRebrending';
import LayoutBrand from './layout/Layout/LayoutBrand';
import BrandInfo from './Pages/BrandInfo/BrandInfo';
import LayoutAdmin from './layout/Layout/LayoutAdmin';
import AdministrationRebrending from './Pages/Administartion/AdministrationRebrending';
import Gateways from './Pages/Gateways/Gateways';
import Freeswitch from './Pages/Freeswitch/Freeswitch';
import NumbersAdmin from './Pages/NumbersAdmin/NumbersAdmin';
import SuspectedNumbers from './Pages/SuspectedNumbers/SuspectedNumbers';
import GatewayRelations from './Pages/GatewayPrefixes/GatewayRelations';
import PrefixReportRebrending from './Pages/PrefixReport/PrefixReportRebrending';
import ReportsRebrending from './Pages/ReportsRebrending/ReportsRebrending';
import BillingManage from './Pages/BillingManage/BillingManage';
import BillingTarifs from './Pages/BillingTarifs/BillingTarifs';
import IndividualStats from './Pages/IndividualStats/IndividualStats';
import Transactions from './Pages/NumberVerification/Transactions';
import TransactionsBilling from './Pages/Transactions/TransactionsBilling';
function App() {
  //require('dotenv').config();
  const location = useLocation();
  return (
      <Routes>
        <Route element={<LayoutRebrending/>} >
          <Route path='history' element={<PrivateRoute><History/></PrivateRoute>}/>
          <Route path='onlinecalls' element={<PrivateRoute><OnlineCallsRebrending/></PrivateRoute>}/>
          <Route path='analytics' element={<PrivateRoute><AnalyticsRebrending/></PrivateRoute>}/>
          <Route path='transactions' element={<PrivateRoute><Transactions/></PrivateRoute>}/>
          <Route exact path="/" element={<PrivateRoute><OnlineCallsRebrending/></PrivateRoute>}/>
          <Route path='/' element={<PrivateRoute><Navigate to={location.pathname} replace={true}/></PrivateRoute>} />

        </Route>
        <Route path='admin' element={<LayoutAdmin/>} >
          <Route path='administration' element={<PrivateRoute><AdministrationRebrending/></PrivateRoute>}/>
          <Route path='billing' element={<PrivateRoute><BillingRebrending/></PrivateRoute>}/>
          <Route path='gateways' element={<PrivateRoute><Gateways/></PrivateRoute>}/>
          <Route path='freeswitch' element={<PrivateRoute><Freeswitch/></PrivateRoute>}/>
          <Route path='numbers' element={<PrivateRoute><NumbersAdmin/></PrivateRoute>}/>
          <Route path='suspects' element={<PrivateRoute><SuspectedNumbers/></PrivateRoute>}/>
          <Route path='gateway/relations' element={<PrivateRoute><GatewayRelations/></PrivateRoute>}/>
          <Route path='report/prefix' element={<PrivateRoute><PrefixReportRebrending/></PrivateRoute>}/>
          <Route path='report/daily' element={<PrivateRoute><ReportsRebrending/></PrivateRoute>}/>
          <Route path='billing/manage' element={<PrivateRoute><BillingManage/></PrivateRoute>}/>
          <Route path='billing/manage/:id/:region' element={<PrivateRoute><BillingTarifs/></PrivateRoute>}/>
          <Route path='billing/manage/:id' element={<PrivateRoute><BillingTarifs/></PrivateRoute>}/>

        </Route>
        <Route path='company' element={<LayoutBrand/>}>
          <Route path='manage' element={<PrivateRoute><ManageBrand/></PrivateRoute>}/>
          <Route path='dashboard/:timeframe' element={<PrivateRoute><DashboardNew/></PrivateRoute>}/>
          <Route path='users' element={<PrivateRoute><UsersRebrending/></PrivateRoute>}/>
          <Route path='numbers' element={<PrivateRoute><ItNumbersRebrending/></PrivateRoute>}/>
          <Route path='billing' element={<PrivateRoute><BillingRebrending/></PrivateRoute>}/>
          <Route path='transactions' element={<PrivateRoute><TransactionsBilling/></PrivateRoute>}/>
          <Route path='info' element={<PrivateRoute><BrandInfo/></PrivateRoute>} />
          <Route path='individual/:id/:sip' element={<PrivateRoute><IndividualStats/></PrivateRoute>}/>
        </Route>
        
        <Route path='old' element={<Layout/>} >
          <Route path='dashboard' element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
          <Route path='dashboard/:id/' element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
          <Route path='users' element={<PrivateRoute><Users/></PrivateRoute>}/>
          <Route path='suspects' element={<PrivateRoute><Suspects/></PrivateRoute>}/>
          <Route path='reports' element={<PrivateRoute><Reports/></PrivateRoute>}/>
          <Route path='gateway/prefixes' element={<PrivateRoute><GatewayPrefixes /></PrivateRoute>} />
          <Route path='reports/prefix' element={<PrivateRoute><PrefixReport/></PrivateRoute>}/>
          <Route path='denied' element={<NoAccess/>}/>
          <Route path='individual/:id/:sip' element={<PrivateRoute><Individual/></PrivateRoute>}/>
          <Route path='record/:uuid/:time/:dst/:conversation/:duration/:sip/:status' element={<PrivateRoute><Record/></PrivateRoute>}/>
          <Route path='journal' element={<PrivateRoute><Journal/></PrivateRoute>}/>
          <Route path='journal/:company/' element={<PrivateRoute><Journal/></PrivateRoute>}/>
          <Route path='records/:company/' element={<PrivateRoute><Journal/></PrivateRoute>}/>
          <Route path='records/:company/:team' element={<PrivateRoute><Journal/></PrivateRoute>}/>
          <Route path='records/:company/:team/:agent' element={<PrivateRoute><Journal/></PrivateRoute>}/>
          <Route path='onlinecalls' element={<PrivateRoute><OnlineCalls/></PrivateRoute>}/>
          <Route path='routing' element={<PrivateRoute><BrandRouting/></PrivateRoute>}/>
          <Route path='billing' element={<PrivateRoute><Billing/></PrivateRoute>}/>
          <Route path='billing/:id' element={<PrivateRoute><Billing/></PrivateRoute>}/>
          <Route path='freeswitchpanel' element={<PrivateRoute><FreeswitchPanel/></PrivateRoute>}/>
          <Route path='administration' element={<PrivateRoute><Administration/></PrivateRoute>}/>
          <Route path='gateways' element={<PrivateRoute><GatewaysManagement/></PrivateRoute>}/>
          <Route path='numbers' element={<PrivateRoute><Numbers/></PrivateRoute>}/>
          <Route path='analytics' element={<PrivateRoute><Analytics/></PrivateRoute>}/>
          <Route path='records/choose' element={<PrivateRoute><RecordsChoose/></PrivateRoute>}/>
          <Route path='number/verification' element={<PrivateRoute><NumberVerification/></PrivateRoute>}/>
          <Route path='numbers/it' element={<PrivateRoute><ItNumbers/></PrivateRoute>}/>
          <Route path='billing/management/:id/:region' element={<PrivateRoute><BillingManagement/></PrivateRoute>}/>
          <Route path='billing/management/:id' element={<PrivateRoute><BillingManagement/></PrivateRoute>}/>
          <Route path='billing/choose' element={<PrivateRoute><BillingChoose/></PrivateRoute>}/>
          <Route path='companies' element={<PrivateRoute><Companies/></PrivateRoute>}/>
          <Route path='companies/:id/offices' element={<PrivateRoute><Offices/></PrivateRoute>}/>
          <Route path='companies/:id/offices/:officeid/departments/' element={<PrivateRoute><Departments/></PrivateRoute>}/>
          <Route path='companies/:id/offices/:officeid/departments/:depid/teams' element={<PrivateRoute><Teams/></PrivateRoute>}/>
          <Route path='companies/:id/offices/:officeid/departments/:depid/teams/:teamid/agents' element={<PrivateRoute><Agents/></PrivateRoute>}/>
          {/* <Route exact path="/" element={<PrivateRoute><OnlineCalls/></PrivateRoute>}/> */}
          {/* <Route path='/' element={<PrivateRoute><Navigate to={location.pathname} replace={true}/></PrivateRoute>} /> */}
        </Route>

        <Route exact path="/" element={<Navigate to="/login" replace={true}/>}/>
        <Route path='/login' element={<Login/>} />
        <Route path='*' element={<Error/>}/>
      </Routes>
  );
}


export default App;
