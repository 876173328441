import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import ReactDropdown from 'react-dropdown';
import { Link, Navigate, useParams } from 'react-router-dom'
import 'react-dropdown/style.css';
import secureLocalStorage from 'react-secure-storage';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import Individual from './Individual';

export default function Agents() {
    const params = useParams(); 
    const companyid = params.id;
    const officeid = params.officeid;
    const departmentid = params.depid;
    const teamid = params.teamid;
    const title = useRef(null);
    const password = useRef(null);
    const role = useRef(null);
    const server = useRef(null);
    const name = useRef(null);
    const currentTeam = useRef("")
    const currentAgent = useRef()
    const [Entities, SetEntities] = useState([]);
    const [Teams, SetTeams] = useState([]);
    const [moveTeams,SetMoveTeams] = useState([]);
    const [moveAgentId,SetMoveAgentId] = useState(0);
    const [moveTeamId,SetMoveTeamId] = useState(0);
    const [agentid, setagentid] = useState(0);
    const MySwal = withReactContent(Swal);
    const {t,i18n} = useTranslation();
    var options = [
        { value: 2, label: 'Company admin'},
        { value: 3, label: 'Office admin'},
        { value: 4, label: 'Department admin'},
        { value: 5, label: 'Team admin'},
        { value: 6, label: 'Agent'},
        { value: 7, label: 'BrandManager'},
        { value: 8, label: 'Coach'},
        { value: 9, label: 'It'},
    ]
    var options1 = [
        { value: 1, label: 'Diamondcall'},
        { value: 2, label: 'Diamondcall_Just'}
    ]
    function handleSubmit(){
        console.log({
            login:title.current.value,
            password:password.current.value,
            role:role.current.state.selected.value,
            name:name.current.value,
            server:server.current.state.selected.value,
            Teams
        });
        var arr = [];
        Teams.forEach(element => {
          arr.push({
            id:element.id,
            access:document.getElementById(element.id).checked
          });
        });
        console.log(arr);
        if(title.current.value !== "" && password.current.value !== "" && name.current.value !== "" && role.current.state.selected.value !== ''){
        axios.post("https://www.pbxdiamondcall.com:8081/agents/add",
        {   
            login:title.current.value,
            password:password.current.value,
            role:role.current.state.selected.value,
            name:name.current.value,
            office_id:officeid,
            company_id:companyid,
            department_id:departmentid,
            team_id:teamid,
            server:server.current.state.selected.value,
            teams_access:arr
        }
        )
        .then((res)=>{
          console.log(res);
          Swal.fire({
            icon:'success',
            title: 'Result',
            timer:2000,
            text: res.statusText,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
      })
      .catch((err)=>{
        Swal.fire({
          icon: 'warning',
          title: 'Result',
          timer:2000,
          text: err.statusText,
          customClass: {
          confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
      })
      connect()
        console.log(err)
      });
    }
    else {
      Swal.fire({
        icon: 'warning',
        title: 'Result',
        timer:2000,
        text: "Fill the fields",
        customClass: {
        confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
      connect()
    }
        
    }
    function handleUpdate(){
            var arr = [];
            Teams.forEach(element => {
              arr.push({
                id:element.id,
                access:document.getElementById(element.id).checked
              });
            });
            console.log(arr);
            axios.post("https://www.pbxdiamondcall.com:8081/agents/update",{
                login:title.current.value,
                password:password.current.value,
                role:role.current.state.selected.value,
                name:name.current.value,
                id:agentid,
                teams_access: arr
            })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                  icon: 'success',
                  timer:2000,
                  title: 'Result',
                  text: res.statusText,
                  customClass: {
                  confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
              })
      connect()
            })
            .catch((err)=>{
              Swal.fire({
                icon: 'warning',
                title: 'Result',
                timer:2000,
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
      connect()
              console.log(err)
            });
    }
    async function handleDelete () {
      let isdel = await SwalDel();
      console.log(isdel);
      if(isdel){
        axios.delete("https://www.pbxdiamondcall.com:8081/agents/delete",{data:{id:currentAgent.current} })
        .then((res)=>{
          console.log(res);
          MySwal.fire({
            icon:'success',
            timer:2000,
            title: 'Result',
            text: res.statusText,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
      connect()
      })
        .catch((err)=>{
          console.log(err);
          MySwal.fire({
            icon: 'error',
            timer:2000,
            title: 'Authentification error.',
            text: err.statusText,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
      connect()
        })
      }
    }
    const SwalDel =()=>{
        return Swal.fire({ // <-- return Promise
          title: 'Delete record?',
          text: 'This action cannot be undone.',
          icon: 'question',
          confirmButtonText: 'Delete',
          confirmButtonColor: '#DC004E',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: false,
          scrollbarPadding: false
        }).then((result) => {
          return result.isConfirmed;
        });
    }
    function onUpdate(ind){
      currentAgent.current = Entities[ind].Id;
      axios.post("https://www.pbxdiamondcall.com:8081/agents/access",
        {user_info_id:Entities[ind].user_info_id,company_id:Entities[ind].company_id})
        .then((res)=>{
          console.log(res);
          console.log(Teams);
          Teams.forEach(element => {
                document.getElementById(element.id).checked = false;
          });
          Teams.forEach(element => {
            res.data.forEach(el=>{
              if(el.id === element.id && el.has_access===1){
                document.getElementById(element.id).checked = true;
              }
            })
          });
        })
        .catch((err)=>console.log(err));
        setagentid(Entities[ind].Id);
        title.current.value = Entities[ind].Login;
        password.current.value =  Entities[ind].Password;
        role.current.state.selected.label = Entities[ind].RoleTitle;
        role.current.state.selected.value = Entities[ind].Role;
        SetMoveAgentId(Entities[ind].Id);
        name.current.value = Entities[ind].Name;
        const el = document.getElementById('submitbtn');
        el.disabled = true;
        const el1 = document.getElementById('updatebtn');
        el1.disabled = false;
    }
    function onAdd(){
      Teams.forEach(element => {
            document.getElementById(element.id).checked = false;
      });
        const element = document.getElementById('updatebtn');
        element.disabled = true; 
        const el1 = document.getElementById('submitbtn');
        el1.disabled = false;
    }
    function onIndiv(ind){
      return <Navigate to="individual" state={{sip:Entities[ind].Login,company_id:Entities[ind].company_id}} replace={true}/>
    }
    function connect(){
      axios.get("https://www.pbxdiamondcall.com:8081/agents/"+teamid)
      .then((res)=>{
        console.log(res.data);
          SetEntities(res.data);
      })
      .catch((err)=>{
          console.log(err);
      })
      axios.post("https://www.pbxdiamondcall.com:8081/teams/company",{
        company_id:companyid
      })
      .then((res)=>{
        console.log(res);
        SetTeams(res.data);
        var arr = [];
        res.data.forEach((val,ind)=>{
          arr.push({value:val.id,label:val.title})
          if(val.id==teamid) currentTeam.current = val.title;
        })
        SetMoveTeams(arr);
      })
    }
    useEffect(()=>{
      connect()
        
    },[])
    let roled = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    
    const handleClose=()=>{
      // this.setState(prev=>{
      //     return {
      //         ...prev,
      //         transferto:""
      //     }
      // })
  }
  const handleMove=()=>{
    console.log(moveAgentId);
    console.log(moveTeamId);
    axios.post('https://www.pbxdiamondcall.com:8081/agents/move',{
      id:moveAgentId,
      team_id:moveTeamId
    })
    .then((res)=>{
      console.log(res);
    })
    .catch((err)=>console.log(err));
  }
    if(roled===3){
      options = [
        { value: 4, label: 'Department admin'},
        { value: 5, label: 'Team admin'},
        { value: 6, label: 'Agent'},
        { value: 8, label: 'Coach'},
    ]
    }
    else if(roled===4){
      options = [
        { value: 5, label: 'Team admin'},
        { value: 6, label: 'Agent'},
        { value: 8, label: 'Coach'},
    ]
    }
    else if(roled===5){
      options = [
        { value: 6, label: 'Agent'},
        { value: 8, label: 'Coach'},
    ]
    }

    if(roled === 6  || role === 8){
        return <Navigate to="/onlinecalls" replace={true}/>
    }
    else
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
    <div className="card">
      <div className="card-header border-bottom">
        <h5 className="card-title" style={{fontSize:"25px", fontWeight:"bold"}}>{t("agents")} [{currentTeam.current}] </h5>
        {/* Add new company button */}
        <div className="col-md-10">
            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-start flex-md-row flex-column mb-3 mb-md-0">
                <div className="dt-buttons btn-group flex-wrap"> 
                    <button className="btn btn-secondary add-new btn-primary"
                     tabIndex={0} aria-controls="DataTables_Table_0" 
                     type="button" data-bs-toggle="offcanvas" 
                     data-bs-target="#offcanvasAddUser"
                     onClick={onAdd}
                     >
                        <span><i className="bx bx-plus me-0 me-sm-1" />
                        <span className="d-none d-sm-inline-block">{t("agentsAdd")}</span>
                        </span>
                    </button> 
                </div>
            </div>
        </div>
      </div>
      <div className="card-datatable table-responsive">
        <table className="datatables-users table border-top">
          <thead>
            <tr>
              <th style={{fontSize:"18px"}}>{t("agentsThLogin")}</th>
              <th style={{fontSize:"18px"}}>{t("agentsThName")}</th>
              <th style={{fontSize:"18px"}}>Individual</th>
              <th style={{fontSize:"18px"}}>{t("companiesThUpdate")}</th>
            </tr>
          </thead>
          <tbody>
            {Entities.length !== 0 ? 
            Entities.map((cmp,ind)=>{
               return <tr key={ind}  style={{fontWeight:"bold"}}>
                        <td><b>{cmp.Login}</b></td>
                        <td><b>{cmp.Name}</b></td>
                        <td><Link to={`/old/individual/${Entities[ind].company_id}/${Entities[ind].Login}`} >
                        <button type="button" className='btn btn-info' 
                        >Individual</button>
                        </Link></td>
                        <td><button type="button" className='btn btn-warning' 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasAddUser"
                        onClick={()=>onUpdate(ind)}
                        disabled={cmp.Login==="TestingGod"?true:false}
                        >{t("updatebtn")}</button></td>
                    </tr>
            })
            :null}
          </tbody>
        </table>
      </div>
      {/* Offcanvas to add new instance */}
      <div className="offcanvas offcanvas-end" tabIndex={-1} 
      id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel" style={{width:450}}>
        <div className="offcanvas-header">
          <h5 id="offcanvasAddUserLabel" className="offcanvas-title">{t("agent")}</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body mx-0 flex-grow-0" >
          <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
            <div className="mb-3">
              <label className="form-label" htmlFor="add-agent-login">{t("agentsThLogin")}</label>
              <input type="text" 
                className="form-control" 
                id="add-agent-login" 
                placeholder={t("agentsPlaceholder")} 
                name="agentLogin" 
                aria-label="Agent login"
                ref={title} />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-agent-psw">{t("agentsThPassword")}</label>
              <input type="text" 
                className="form-control" 
                id="add-agent-psw" 
                placeholder={t("agentsPswPlaceholder")} 
                name="agentPassword" 
                aria-label="Agent psw"
                ref={password} />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="add-agent-fullname">{t("agentsThName")}</label>
              <input type="text" 
                className="form-control" 
                id="add-agent-fullname" 
                placeholder={t("agentsFNPlaceholder")}
                name="agentFullName" 
                aria-label="Agent FullName"
                ref={name} />   
            </div>
            <br />
            <label className="form-label" htmlFor="add-agent-fullname">{t("agentsRole")}</label>
            <ReactDropdown options={options} placeholder={t("agentsSelectRole")} ref={role} />
            <br />
            <label className="form-label" htmlFor="add-agent-fullname">Server</label>
            <ReactDropdown options={options1} placeholder='Select server' ref={server} />
            <br />
            <label className="form-label" htmlFor="teams-list">{t("agentsTeams")}</label>
            <div className="list-group" style={{maxHeight:400, overflow:"scroll", WebkitOverflowScrolling:'touch'}}>
                {
                    Teams.map((val,ind)=>{
                        return <label className="list-group-item" key={ind}>
                            <label class="switch">
                            <input type="checkbox" class="switch-input" id={val.id}  />
                            <span class="switch-toggle-slider">
                                <span class="switch-on">
                                <i class="bx bx-check"></i>
                                </span>
                                <span class="switch-off">
                                <i class="bx bx-x"></i>
                                </span>
                            </span>
                            <span class="switch-label">{val.title}</span>
                            </label>
                        </label>
                    })
                }
                
            </div>
            <br />
            <button 
                id='submitbtn'
                type="button" 
                className="btn btn-success me-sm-3 me-1 data-submit"
                onClick={handleSubmit}
                >{t("submitbtn")}</button>
            <button 
                id='updatebtn'
                type="button" 
                className="btn btn-warning me-sm-3 me-1 data-submit"
                onClick={handleUpdate}
                >{t("updatebtn")}</button>
            <button type="button" className='btn btn-danger me-sm-3 me-1' onClick={()=>handleDelete()}>{t("deletebtn") }
            </button>
            <button type="button" className='btn btn-info me-sm-3 me-1' 
                        data-bs-toggle="modal" data-bs-target="#modalTop"
                        style={{marginRight:5}}
                        >Move Agent</button>
            <button 
                type="button" 
                className="btn btn-label-secondary me-sm-3 me-1" 
                data-bs-dismiss="offcanvas"
                style={{marginTop:5}}>
                {t("cancelbtn")}</button>
          </form>
        </div>
      </div>
      
      <div className="modal fade" data-bs-backdrop="static" id="modalTop" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <form className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTopTitle">Move agent</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>handleClose()}/>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col mb-3">
                                <Select options={moveTeams}
                                    onChange={(e)=>SetMoveTeamId(e.value)}
                                    placeholder='Select team' id='prefixupdLb'
                                    styles={{
                                        control:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd",
                                            borderColor:"#444663"
                                        }),
                                        option: (styles, { isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                            };
                                        },
                                        singleValue:(baseStyles,state)=>({
                                            ...baseStyles,
                                            color:"#b8b8cd"
                                        }),
                                        placeholder:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd"
                                        }),
                                        menuList:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd",
                                        }),
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" onClick={()=>handleClose()}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={()=>handleMove()}>Move</button>
                        </div>
                        </form>
                    </div>
        </div>
    </div>
    
    </div>
  )
}
