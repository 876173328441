import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';

export default function Suspects() {
const [suspects,setSuspects] = useState([]);
const [updateNumber,setNumberUpdate]=useState('');
const [updateId,setUpdateId] = useState(0);
useEffect(()=>{
    axios.get('https://www.pbxdiamondcall.com:8081/suspects').then((res)=>{
        console.log(res);
        setSuspects(res.data);
    })
},[])
const SwalDel =()=>{
    return Swal.fire({ // <-- return Promise
        title: 'Delete number?',
        text: 'This action cannot be undone.',
        icon: 'question',
        confirmButtonText: 'Delete',
        confirmButtonColor: '#DC004E',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false
    }).then((result) => {
        return result.isConfirmed;
    });
}
const handleDelete=async(id)=>{
    let isdel = await SwalDel();
    if(isdel){
        axios.post('https://www.pbxdiamondcall.com:8081/number/delete',{id:id})
        .then((res)=>{
            console.log(res);
            Swal.fire({
                icon:'success',
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            axios.get('https://www.pbxdiamondcall.com:8081/suspects').then((res)=>{
                setSuspects(res.data);
            })
        })
        .catch((err)=>{
            Swal.fire({
            icon: 'warning',
            title: 'Result',
            text: err,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
            console.log(err)
        });
    }   
}
const handleUpdate = () =>{
    axios.post('https://www.pbxdiamondcall.com:8081/number/replace',{
        id:updateId,
        number:updateNumber
    })
    .then((res)=>{
        console.log(res);
        Swal.fire({
            icon:'success',
            title: 'Result',
            text: res.statusText,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
    })
    .catch((err)=>{
        Swal.fire({
        icon: 'warning',
        title: 'Result',
        text: err,
        customClass: {
        confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
    console.log(err)
    });
}
return (
<div className='container-xxl flex-grow-1 container-p-y' style={{display:"flex", flexDirection:"row",justifyContent:"center"}}>
    <div className='card' style={{width:"1400px", height:"700px", margin:"10px", display:"flex", }}>
        <div className='card-header' style={{fontWeight:"bold", fontSize:"25px"}}>Suspected numbers</div>
        <div className='card-body' style={{maxHeight:600,overflowY:'scroll'}}>
        <table className="datatables-users table border-top" style={{width:"100%"}}>
        <thead>
        <tr>
            <th style={{fontSize:"18px"}}></th>
            <th style={{fontSize:"18px"}}>Prefix</th>
            <th style={{fontSize:"18px"}}>Number</th>
            <th style={{fontSize:"18px"}}>Actions</th>
        </tr>
        </thead>
        <tbody >
            {suspects.length !== 0 ? 
            suspects.map((cmp,ind)=>{
            return <tr key={ind} >
                <td><b></b></td>
                <td><b>{cmp.prefix}</b></td>
                <td><b>{cmp.number}</b></td>
                <td>
                    <button type="button" className='btn btn-warning' 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasEdit"
                        onClick={()=>{setUpdateId(cmp.id);console.log(updateId)}}
                    >Replace</button>
                    <button type="button" className='btn btn-danger' 
                        style={{marginLeft:10}}
                        onClick={()=>handleDelete(cmp.id)}
                    >Delete</button>
                </td>
            </tr>
            })
            :null}
        </tbody>
        </table>
        </div>
    </div>
    
    <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasEdit" aria-labelledby="offcanvasEditLabel">
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEditLabel" className="offcanvas-title">Phone number update</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname">Number</label>
                            <input type="text"
                                className="form-control" 
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                value={updateNumber}
                                onChange={(e)=>setNumberUpdate(e.target.value)}
                                />   
                        </div>
                        <br />
                        <button 
                            id='updatebtn'
                            type="button" 
                            className="btn btn-warning me-sm-3 me-1 data-submit"
                            onClick={()=>handleUpdate()}
                            >Update</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary" 
                            data-bs-dismiss="offcanvas">
                            Cancel</button>
                        </form>
                    </div>
    </div>
</div>
)
}
