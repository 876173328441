import React from 'react'
import WaveSurfer from 'wavesurfer.js'
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
const useWavesurfer = (containerRef, options) => {
    const [wavesurfer, setWavesurfer] = React.useState(null)
    
    // Initialize wavesurfer when the container mounts
    // or any of the props change
    React.useEffect(() => {
      if (!containerRef.current) return
  
      const ws = WaveSurfer.create({
        ...options,
        container: containerRef.current,
        preversePitch: true,
        barGap: 1,
      })

      setWavesurfer(ws)
  
      return () => {
        ws.destroy()
      }
    }, [])
  
    return wavesurfer
  }
export const WaveSurferPlayer = (props) => {
    const containerRef = React.useRef()
    const [isPlaying, setIsPlaying] = React.useState(false)
    const [currentTime, setCurrentTime] = React.useState(0)
    const [playBackRate,setPlaybackRate] = React.useState(1);
    const wavesurfer = useWavesurfer(containerRef, props)
  
    // On play button click
    const onPlayClick = React.useCallback(() => {
      wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play()
    }, [wavesurfer])
    const onRateChange2 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(2)
        setPlaybackRate(2);
      }, [wavesurfer])
      const onRateChange15 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(1.5)
        setPlaybackRate(1.5);
      }, [wavesurfer])
      const onRateChange12 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(1.2)
        setPlaybackRate(1.2);
      }, [wavesurfer])
      const onRateChange1 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(1)
        setPlaybackRate(1);
      }, [wavesurfer])
      const onRateChange05 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(0.5)
        setPlaybackRate(0.5);
      }, [wavesurfer])
    // Initialize wavesurfer when the container mounts
    // or any of the props change
    React.useEffect(() => {
      if (!wavesurfer) return
  
      setCurrentTime(0)
      setIsPlaying(false)
  
      const subscriptions = [
        wavesurfer.on('play', () => setIsPlaying(true)),
        wavesurfer.on('pause', () => setIsPlaying(false)),
        wavesurfer.on('hover', () => console.log(currentTime)),
        wavesurfer.on('timeupdate', (currentTime) => setCurrentTime(currentTime)),
        wavesurfer.once('decode', () => {
          }),
          wavesurfer.on('mouseenter', (e) => {
            wavesurfer.params.progressColor = 'red';
            wavesurfer.drawBuffer();
          })
      ]

      return () => {
        subscriptions.forEach((unsub) => unsub())
      }
    }, [wavesurfer])
    const changeSpeed = (type) => {
      var arr = [0.5,1,1.2,1.5,2];
      if(type=="up"){
        if(playBackRate != 2){
          let index=arr.indexOf(playBackRate);
          setPlaybackRate(arr[index+1])
          wavesurfer.setPlaybackRate(arr[index+1]);
        }
      }
      else{
        if(playBackRate != 0.5){
          let index=arr.indexOf(playBackRate);
          setPlaybackRate(arr[index-1])
          wavesurfer.setPlaybackRate(arr[index-1]);
        }
      }
    }
    return (
      <div style={{display:'flex' , alignItems:'center',justifyContent:'end' , flexDirection:'row',gap:"1vw", heigth:"11vh"}}>
        <div style={{display:"flex",flexDirection:"column-reverse", alignItems:"center", justifyContent:"end", height:"100%",fontWeight:"bold" , gap:"0.2vw",marginTop:"2.5vh"}}>
                            <div style={{display:"flex",flexDirection:"row",  fontWeight:"bold", marginTop:10}}>
                            <button onClick={onRateChange05} style={{ width:"3vw", border:"1px solid #b47fe9",borderRadius:"0.4vh",background:playBackRate==0.5?"rgba(180, 127, 233, 0.282)":"transparent", height:"3vh",margin:3 ,zIndex:100,
                                }}>0.5x
                            </button>
                            <button onClick={onRateChange1} style={{ width:"3vw", border:"1px solid #b47fe9",borderRadius:"0.4vh",background:playBackRate==1?"rgba(180, 127, 233, 0.282)":"transparent", height:"3vh", margin:3,zIndex:100
                                }}> 1x
                            </button>
                            <button onClick={onRateChange12} style={{ width:"3vw", border:"1px solid #b47fe9",borderRadius:"0.4vh",background:playBackRate==1.2?"rgba(180, 127, 233, 0.282)":"transparent", height:"3vh", margin:3,zIndex:100
                                }}>1.2x
                            </button>
                            <button onClick={onRateChange15} style={{ width:"3vw", border:"1px solid #b47fe9",borderRadius:"0.4vh",background:playBackRate==1.5?"rgba(180, 127, 233, 0.282)":"transparent", height:"3vh", margin:3,zIndex:100
                                }}> 1.5x
                            </button>
                            <button onClick={onRateChange2} style={{ width:"3vw", border:"1px solid #b47fe9",borderRadius:"0.4vh",background:playBackRate==2?"rgba(180, 127, 233, 0.282)":"transparent", height:"3vh", margin:3,zIndex:100
                                }}> 2x
                            </button>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",  fontWeight:"bold", gap:"0.2vw", marginTop:10}}>
                              <FastRewindIcon sx={{cursor:"pointer",color:"#fff",width:"3vw", border:"1px solid #b47fe9",borderRadius:"0.4vh",background:"transparent", height:"3vh", zIndex:100}} onClick={()=>{changeSpeed("down")}}/>
                              <button onClick={onPlayClick} style={{ width:"3vw", border:"1px solid #b47fe9",borderRadius:"0.4vh",background:"transparent", height:"3vh", zIndex:100
                                  }}>
                                      {
                                      !isPlaying?
                                      <i className="fa-solid fa-circle-play fa-lg"></i>
                                      :
                                      <i class="fa-solid fa-circle-pause fa-lg"></i>
                                      }
                              </button>
                              <FastForwardIcon sx={{cursor:"pointer",color:"#fff",width:"3vw", border:"1px solid #b47fe9",borderRadius:"0.4vh",background:"transparent", height:"3vh", zIndex:100}} onClick={()=>{changeSpeed("up")}}/>
                            </div>
                            
                            
                        </div>
        <div id='waveform' ref={containerRef} style={{ minHeight: '11vh' , width:'50vw' }} />
        
                        <div id="waveform-timeline"></div>
      </div>
    )
  }