import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom';
import NavbarRebrending from '../Navbar/NavbarRebrending'
import SideMenuRebrending from '../Menu/SideMenuRebrending'
import styles from './LayoutRebrending.module.scss';
import SideMenuBrand from '../Menu/SideMenuBrand';
import SideMenuAdmin from '../Menu/SideMenuAdmin';
import { Snackbar } from '@material-ui/core';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Alert from '@mui/material/Alert';

export default function LayoutAdmin() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [suspects, setSuspects] = useState(0);
  useEffect(()=>{
    let countdownworker;
    if(JSON.parse(secureLocalStorage.getItem('user')).role_id == 1)
    {
    if(typeof Worker !== undefined){
    const workerCode = `self.onmessage=function(event){
      setInterval(async ()=>{
        self.postMessage('go')
      },60000*60*2)
  }`
    const blob = new Blob([workerCode], {type:'application/javascript'});
    countdownworker = new Worker(URL.createObjectURL(blob));
    }
    countdownworker.onmessage = function (event) {
      axios.get('https://www.pbxdiamondcall.com:8081/suspectsCount')
      .then((res)=>{
          setSuspects(res.data[0].count);
          setOpen(true)
      })
      .catch((err)=>console.log(err));
    };
    countdownworker.postMessage('start')
    return () => {
      if(countdownworker)
        countdownworker.terminate();
    }
  }
  },[])
    return (
        // container
        <div className={styles.container}> 
            <div className={styles.header}>
                <div className={styles.header_navbar}>
                    <NavbarRebrending/>
                </div>
                <div className={styles.header_menu}>
                    <SideMenuRebrending/>
                </div>
                <div className={styles.header_menu}>
                    <SideMenuAdmin/>
                </div>
            </div>
            <div className={styles.content}>
                <Outlet/>
            <Snackbar
              open={open}
              onClose={()=>setOpen(false)}
            >
              <Link to="/admin/suspects">
                <Alert
                  onClose={()=>setOpen(false)}
                  severity="error"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {`Numbers in spam: ${suspects}`}
                </Alert>
              </Link>
            </Snackbar>
            </div>
            
          <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasStart" aria-labelledby="offcanvasStartLabel"style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
                    <div className="offcanvas-header">
                    <h5 id="offcanvasStartLabel" className="offcanvas-title">Admin panel</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <Link to="/old/onlinecalls" style={{width:250, alignSelf:'center', margin:5}}>
                      <button type='button' className='btn ' style={{width:250, alignSelf:'center',
                    border:"0.1vh solid #ffab00",backgroundColor:"transparent", color:"#ffab00"}}>Old</button>
                    </Link>
                    <Link to="/admin/administration" style={{width:250, alignSelf:'center', margin:5}}>
                      <button type='button' className='btn ' style={{width:250, alignSelf:'center',
                    border:"0.1vh solid #ffab00",backgroundColor:"transparent", color:"#ffab00"}}>Administration</button>
                    </Link>
                    <Link to="/admin/gateways" style={{width:250, alignSelf:'center', margin:5}}>
                      <button type='button' className='btn ' style={{width:250, alignSelf:'center',
                    border:"0.1vh solid #d887e1",backgroundColor:"transparent", color:"#d887e1"}}>Gateways</button>
                    </Link>
                    <Link to="/admin/billing/manage" style={{width:250, alignSelf:'center', margin:5}}>
                      <button type='button' className='btn ' style={{width:250, alignSelf:'center',
                    border:"0.1vh solid #24D6A3",backgroundColor:"transparent", color:"#24D6A3"}}>Billing</button>
                    </Link>
                    <Link to="/admin/freeswitchpanel" style={{width:250, alignSelf:'center', margin:5}}>
                      <button type='button' className='btn ' style={{width:250, alignSelf:'center',
                    border:"0.1vh solid #3EA3FC",backgroundColor:"transparent", color:"#3EA3FC"}}>Freeswitch admin panel</button>
                    </Link>
                    <Link to="/admin/numbers" style={{width:250, alignSelf:'center', margin:5}}>
                      <button type='button' className='btn '  style={{width:250, alignSelf:'center',
                    border:"0.1vh solid #FF4861",backgroundColor:"transparent", color:"#FF4861"}}>Numbers</button>
                    </Link>
                    <Link to="/admin/suspects" style={{width:250, alignSelf:'center', margin:5}}>
                      <button type='button' className='btn '  style={{width:250, alignSelf:'center',
                    border:"0.1vh solid #FF4861",backgroundColor:"transparent", color:"#FF4861"}}>Suspected numbers</button>
                    </Link>
                    <Link to="/admin/report/daily" style={{width:250, alignSelf:'center', margin:5}}>
                      <button type='button' className='btn ' style={{width:250, alignSelf:'center',
                    border:"0.1vh solid #3EA3FC",backgroundColor:"transparent", color:"#3EA3FC"}}>Daily reports</button>
                    </Link>
                    <Link to="/admin/report/prefix" style={{width:250, alignSelf:'center', margin:5}}>
                      <button type='button' className='btn ' style={{width:250, alignSelf:'center',
                    border:"0.1vh solid #24D6A3",backgroundColor:"transparent", color:"#24D6A3"}}>Prefix report</button>
                    </Link>
                    <Link to="/admin/gateway/relations" style={{width:250, alignSelf:'center', margin:5}}>
                      <button type='button' className='btn ' style={{width:250, alignSelf:'center',
                    border:"0.1vh solid #24D6A3",backgroundColor:"transparent", color:"#24D6A3"}}>GatewayPrefixes</button>
                    </Link>

                    <button type="button" className="btn " data-bs-dismiss="offcanvas" style={{width:250, alignSelf:'center', margin:10,
                    border:"0.1vh solid #8B8B8D",backgroundColor:"transparent", color:"#8B8B8D"}}>Cancel</button>
          </div>
        </div>
    )
}
