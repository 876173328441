import React, { useState, useRef, useEffect } from 'react';
import styles from './SideMenuBrand.module.scss';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { Link, useLocation } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import secureLocalStorage from 'react-secure-storage';
export default function SideMenuBrand() {
  let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
  //#region dashboard menu
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const options = ['Today', 'Week', 'Month'];
  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const [selectedOption, setSelectedOption] = useState(options[0]);
  useEffect(() => {
    const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
    }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
    document.removeEventListener('mousedown', handleClickOutside);
    };
}, [ref]);
  //#endregion
  const [activeMenu,setActiveMenu] = useState('online');
  const location = useLocation();
  useEffect(()=>{

  },[]);
  return (
    <div className={styles.menu}>
      <div onClick={()=>setActiveMenu("dashboard")} 
        onMouseEnter={()=>setIsOpen(true)}
        className={location.pathname.includes("dashboard")?styles.menu_item_active:styles.menu_item}>
        <DataUsageOutlinedIcon className={styles.icon} />
        <span className={styles.label}>
          <span>
            dashboard
          </span>
          <ul ref={ref} className={styles.tablist}>
            {options.map((option, index) => (
              <li key={index} onClick={onOptionClicked(option)} className={location.pathname.includes(`${option.toLowerCase()}`)?styles.active:styles.tabitem} 
                style={{
                  borderBottom:
                        index!=2
                        ?"0.1vh solid #8b8b8d30"
                        :"0px",
                      }}>
                      <Link to={`/company/dashboard/${option.toLowerCase()}`} 
                      style={{textDecoration:"none",color:"inherit", width:"100%",height:"100%", display:'flex',alignItems:"center",justifyContent:"center"}}>
                        {option}
                      </Link>
                </li>
            ))}
          </ul>
        </span>
      </div>
      {
        (role==1||role==7||role==9) &&
      <div onClick={()=>setActiveMenu("brand")} className={location.pathname.includes("info")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/company/info`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
          <InfoOutlinedIcon className={styles.icon} />
          <span className={styles.label}>Info</span>
        </Link>
      </div>
      }
      {
        (role==1||role==9) &&
      <div onClick={()=>setActiveMenu("brand")} className={location.pathname.includes("manage")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/company/manage`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
          <TuneIcon className={styles.icon} />
          <span className={styles.label}>Management</span>
        </Link>
      </div>
      }
      {
        (role==1) &&
        <div onClick={()=>setActiveMenu("billing")} className={location.pathname.includes("billing")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/company/billing`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
          <RequestQuoteOutlinedIcon className={styles.icon} />
          <span className={styles.label}>billing</span>
        </Link>
      </div>
      }
      {
        (role==1||role==7) &&
        <div onClick={()=>setActiveMenu("transactions")} className={location.pathname.includes("transactions")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/company/transactions`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
          <MonetizationOnRoundedIcon className={styles.icon} />
          <span className={styles.label}>transactions</span>
        </Link>
      </div>
      }
      
      <div onClick={()=>setActiveMenu("users")} className={location.pathname.includes("users")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/company/users`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
        <RecentActorsOutlinedIcon className={styles.icon} />
        <span className={styles.label}>users</span>
        </Link>
      </div>
      {
        (role==1||role==9) &&
      <div onClick={()=>setActiveMenu("numbers")} className={location.pathname.includes("numbers")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/company/numbers`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
        <FormatListNumberedOutlinedIcon className={styles.icon} />
        <span className={styles.label}>numbers</span>
        </Link>
      </div>
      }
    </div>
  )
}
