import React from 'react'
import { useTranslation } from 'react-i18next';
import HashLoader from 'react-spinners/HashLoader';

export default function NoAccess() {
  const {t,i18n} = useTranslation();
  return (
    <div className="container-xxl flex-grow-1 container-p-y"
        style={{justifyContent:"start", display:"flex"}}>
            <div className='container-xxl flex-grow-1 container-p-y' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center'}}>
                <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto', 
                fontWeight:"bold", color:'red', textAlign:'center'}}>
                  {t("deniedAccess")}<br></br>{t("deniedContact")}
                </h1>
                <div className="card" style={{width:500, height:500, justifyContent:'center',alignContent:'center',marginLeft:'auto',marginRight:'auto'}}>
                <HashLoader
                    color="gray"
                    size="400"
                    speedMultiplier={0.8}
                    cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
            </div>
    </div>
  )
}
