import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select'
import styles from './BillingManage.module.scss'

export default function BillingManage() {

    let navigate = useNavigate();
    const [teams,SetTeams] = useState([]);
    const [companies,SetCompanies] = useState([]);
    const [agents,SetAgents] = useState([]);
    const [companyChoose,SetCompanyChoose] = useState(false);
    const [groupChoose,SetGroupChoose] = useState(false);
    const [selectedCompany,SetSelectedCompany] = useState();
    const [selectedGroup,SetSelectedGroup] = useState();

    useEffect(()=>{
        if(JSON.parse(secureLocalStorage.getItem('user')).role_id===1){
            axios.get('https://www.pbxdiamondcall.com:8081/companies')
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            arr.push({
                        value:el.Id,
                        label:el.Title
                    })
            
        })
        SetCompanies(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
        }
        else{
        axios.post('https://www.pbxdiamondcall.com:8081/agents/access', {
            user_info_id:JSON.parse(secureLocalStorage.getItem('user')).user_info_id,
            company_id:JSON.parse(secureLocalStorage.getItem('user')).company_id
       })
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            if(el.has_access===1){
            arr.push({
                        value:el.id,
                        label:el.title
                    })
            }
            
        })
        SetTeams(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
    }
    },[])
    const HandleCompanyChoose=(el)=>{
        SetCompanyChoose(true);
        SetSelectedCompany(el.value);
        axios.post('https://www.pbxdiamondcall.com:8081/teams/company', {
            company_id:el.value
       })
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            arr.push({
                        value:el.id,
                        label:el.title
                    })
        })
        SetTeams(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
    }
    const HandleGroupChoose=(el)=>{
        SetGroupChoose(true);
        SetSelectedGroup(el.value);
        axios.post('https://www.pbxdiamondcall.com:8081/agents/getFromTeam', {
            team_id:el.value
       })
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            arr.push({
                        value:el.Id,
                        label:el.Login
                    })
        })
        SetAgents(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
    }
    const HandleCompanyTeamRecords=()=>{
        navigate(`/admin/billing/manage/${selectedCompany}/${selectedGroup}`);
    }
    const HandleCompanyRecords=()=>{
        navigate(`/admin/billing/manage/${selectedCompany}`);
        
    }
    return (
        <div>
            <div className='card' style={{height:"30vh", borderRadius:"1.5vh", width:"100%", padding:10, paddingTop:0, backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
                <div className="card-header ">
                    <h5 className="card-title" style={{fontSize:"2.5vh", fontWeight:"bold", color:"#d3d3d2",fontFamily:"'Ubuntu',sans-serif"}}>
                        Select billing to show
                    </h5>
                </div>
                <div style={{display:'flex', flexDirection:"row", marginLeft:"1vw"}}>
                    <div style={{display:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?'flex':'none',flexDirection:'column'}}>
                        <div className="mb-3" style={{width:'12.5vw', margin:10,marginTop:"25px"}}>
                            <Select placeholder='Select company'
                                            onChange={(e)=>HandleCompanyChoose(e)}
                                            id='operatorLb' 
                                            isDisabled={false} 
                                                options={companies} 
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                styles={{
                                                    control:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"#232329",
                                                        color:"#d3d3d2",
                                                        borderColor:"#232329"
                                                    }),
                                                    option: (styles, { isFocused, isSelected }) => {
                                                        return {
                                                            ...styles,
                                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                        };
                                                    },
                                                    singleValue:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"#232329",
                                                        color:"#d3d3d2"
                                                    }),
                                                    placeholder:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"#232329",
                                                        color:"#d3d3d2"
                                                    }),
                                                    menuList:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"#232329",
                                                        color:"#949fae",
                                                    }),
                                                }}/>
                        </div>
                    </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <div className="mb-3" style={{width:'12.5vw', margin:10,marginTop:"25px"}}>
                        <Select placeholder='Select region'      
                                        onChange={(e)=>HandleGroupChoose(e)}
                                        id='operatorLb' 
                                        isDisabled={false} 
                                        options={[
                                            {value:'ru',label:'ru'},
                                            {value:'eu',label:'eu'},
                                            {value:'full',label:'full'},
                                        ]} 
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        styles={{
                                            control:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#232329",
                                                color:"#d3d3d2",
                                                borderColor:"#232329"
                                            }),
                                            option: (styles, { isFocused, isSelected }) => {
                                                return {
                                                    ...styles,
                                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                };
                                            },
                                            singleValue:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#232329",
                                                color:"#d3d3d2"
                                            }),
                                            placeholder:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#232329",
                                                color:"#d3d3d2"
                                            }),
                                            menuList:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#232329",
                                                color:"#949fae",
                                            }),
                                        }}/>
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'row', marginTop:"-5px"}}>
                    <button className='btn' 
                    style={{width:"10vw", height:"5vh", margin:10, alignSelf:"center", marginTop:20, fontWeight:"bold",border:"0.1vh solid #d887e1",backgroundColor:"transparent"}}
                    disabled={!groupChoose} onClick={()=>HandleCompanyTeamRecords()}>Region billing</button>
                    <button className='btn' 
                    style={{display:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?'flex':'none',width:"10vw", height:"5vh", margin:10, alignSelf:"center", marginTop:20, fontWeight:"bold",border:"0.1vh solid #d887e1",backgroundColor:"transparent"}}
                    disabled={!companyChoose} onClick={()=>HandleCompanyRecords()}>Company billing</button>
                </div>
                </div>
            </div>
        </div>
    )
}
