import React from 'react'
import WaveSurfer from 'wavesurfer.js'

const useWavesurfer = (containerRef, options) => {
    const [wavesurfer, setWavesurfer] = React.useState(null)
    
    // Initialize wavesurfer when the container mounts
    // or any of the props change
    React.useEffect(() => {
      if (!containerRef.current) return
  
      const ws = WaveSurfer.create({
        ...options,
        container: containerRef.current,
        preversePitch: true,
        barGap: 1,
      })

      setWavesurfer(ws)
  
      return () => {
        ws.destroy()
      }
    }, [])
  
    return wavesurfer
  }
export const WaveSurferPlayer = (props) => {
    const containerRef = React.useRef()
    const [isPlaying, setIsPlaying] = React.useState(false)
    const [currentTime, setCurrentTime] = React.useState(0)
    const wavesurfer = useWavesurfer(containerRef, props)
  
    // On play button click
    const onPlayClick = React.useCallback(() => {
      wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play()
    }, [wavesurfer])
    const onRateChange2 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(2)
      }, [wavesurfer])
      const onRateChange15 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(1.5)
      }, [wavesurfer])
      const onRateChange12 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(1.2)
      }, [wavesurfer])
      const onRateChange1 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(1)
      }, [wavesurfer])
      const onRateChange05 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(0.5)
      }, [wavesurfer])
    // Initialize wavesurfer when the container mounts
    // or any of the props change
    React.useEffect(() => {
      if (!wavesurfer) return
  
      setCurrentTime(0)
      setIsPlaying(false)
  
      const subscriptions = [
        wavesurfer.on('play', () => setIsPlaying(true)),
        wavesurfer.on('pause', () => setIsPlaying(false)),
        wavesurfer.on('hover', () => console.log(currentTime)),
        wavesurfer.on('timeupdate', (currentTime) => setCurrentTime(currentTime)),
        wavesurfer.once('decode', () => {
          }),
          wavesurfer.on('mouseenter', (e) => {
            wavesurfer.params.progressColor = 'red';
            wavesurfer.drawBuffer();
          })
      ]

      return () => {
        subscriptions.forEach((unsub) => unsub())
      }
    }, [wavesurfer])
    return (
      <div style={{display:'flex' , alignItems:'center',justifyContent:'center' , flexDirection:'column'}}>
        <div id='waveform' ref={containerRef} style={{ minHeight: '110px' , width:'1100px' }} />
        <div style={{display:"flex",flexDirection:"column", alignSelf:"center", justifySelf:"center", fontWeight:"bold" , marginTop:'20px'}}>
                            <button onClick={onPlayClick} className='btn btn-label-primary' style={{ width:50, height:50, alignSelf:"center", justifySelf:"center",zIndex:100
                                }}>
                                    {
                                    !isPlaying?
                                    <i className="fa-solid fa-circle-play fa-lg"></i>
                                    :
                                    <i class="fa-solid fa-circle-pause fa-lg"></i>
                                    }
                            </button>
                            <div style={{display:"flex",flexDirection:"row", alignSelf:"center", justifySelf:"center", fontWeight:"bold", marginTop:10}}>
                            <button onClick={onRateChange05} className='btn btn-label-primary' style={{ width:50, height:50,margin:3 ,zIndex:100
                                }}>0.5x
                            </button>
                            <button onClick={onRateChange1} className='btn btn-label-primary' style={{ width:50, height:50, margin:3,zIndex:100
                                }}> 1x
                            </button>
                            <button onClick={onRateChange12} className='btn btn-label-primary' style={{ width:50, height:50, margin:3,zIndex:100
                                }}>1.2x
                            </button>
                            <button onClick={onRateChange15} className='btn btn-label-primary' style={{ width:50, height:50, margin:3,zIndex:100
                                }}> 1.5x
                            </button>
                            <button onClick={onRateChange2} className='btn btn-label-primary' style={{ width:50, height:50, margin:3,zIndex:100
                                }}> 2x
                            </button>
                            </div>
                            
                            
                        </div>
                        <div id="waveform-timeline"></div>
      </div>
    )
  }