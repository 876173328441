import axios from 'axios';
import { changeLanguage } from 'i18next';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage';
import i18n from '../../i18n/config';
import Snackbar from '@mui/material/Snackbar';
import styles from './NavbarRebrending.module.scss';
import Countries from '../../resources/countries.json';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
export default function NavbarRebrending() {
    const { t,i18n } = useTranslation();
    const [lang,setLang] = useState(secureLocalStorage.getItem("lang")?"en":secureLocalStorage.getItem("lang"))
    const [userinfo] = useState({
        company_id:0,
        role_id:0,
        fullname:""
    });
    const [incall,setInCall] = useState(0);
    function changeLanguage(e) {
        setLang(e);
        i18n.changeLanguage(e);
        secureLocalStorage.setItem("lang",e)
    }
    const nav = useNavigate();
    function logoutHandle(){
        secureLocalStorage.setItem("token",null);
        secureLocalStorage.setItem("logged_user",false);
        //secureLocalStorage.setItem("user",null);
        nav("/login");
    }
useEffect(()=>{
if(JSON.parse(secureLocalStorage.getItem('user')!==null)){
setInterval(async ()=>{
    axios.post('https://www.pbxdiamondcall.com:8081/agents/onlinecounter', {
        ui_id:JSON.parse(secureLocalStorage.getItem('user'))!==null?JSON.parse(secureLocalStorage.getItem('user')).user_info_id:0,
        company_id:JSON.parse(secureLocalStorage.getItem('user'))!==null?JSON.parse(secureLocalStorage.getItem('user')).company_id:0
    })
    .then((res)=>{
        setInCall(res.data.incall);
    })
    .catch((err)=>console.log(err))
},3000)

let userid = JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem("user")).id:0;
axios.post('https://www.pbxdiamondcall.com:8081/getuserinfo', {id:userid})
.then(res=>{
    userinfo.company_id = res.data.agentinfo_id;
    userinfo.fullname = res.data.fullname;
    userinfo.role_id = res.data.role_id;
    // console.log(userinfo);
})
.catch((error)=>{
    console.log(error);
});
}

},[]);

function renderSwitch() {
let value = JSON.parse(secureLocalStorage.getItem('user'))!==null
?JSON.parse(secureLocalStorage.getItem('user'))?.role_id!==null
?JSON.parse(secureLocalStorage.getItem('user'))?.role_id
:1
:1
;
switch (value) {
    case 1: return "God"; 
    case 2: return "BrandAdmin"; 
    case 3: return "OfficeAdmin"; 
    case 4: return "DepartmentAdmin"; 
    case 5: return "TeamLeader"; 
    case 6: return "Agent";  
    case 8: return "Coach";  
    case 7: return "BrandManager"; 
    default: return "default";
}
}
return (
    <div className={styles.navbar}>
        <div className={styles.logo}>
            <img src={process.env.PUBLIC_URL+'/logo.png'}
                alt="logo" className={styles.icon}
            />
            {/* <DiamondOutlinedIcon sx={{width:"auto",height:"5vh",color:"#46C2CD"}}/> */}
            {
                JSON.parse(secureLocalStorage.getItem('user'))?.role_id === 1 ?
                <span className={styles.name} style={{cursor:"pointer"}} data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasStart" 
                            aria-controls="offcanvasStart">
                    DIAMONDCALL
                </span>
                :
                <span className={styles.name} >
                    DIAMONDCALL
                </span>
            }
        </div>
        <div className={styles.actions}>
            <div className={styles.user}>
                <span className={styles.logout_span}>
                    {/* <b>{t("navLogin")}</b> */}
                    {JSON.parse(secureLocalStorage.getItem('user'))!==null?JSON.parse(secureLocalStorage.getItem('user')).login:null}
                </span>
                <PermIdentityRoundedIcon style={{width:"auto",height:"4vh"}} />
            </div>
            <span data-bs-toggle="dropdown" className={styles.settings_country} style={{cursor:"pointer"}}>
                <img src={Countries.find((obj)=>obj.cca2.toLowerCase()==(lang=="en"?"us":lang))?.flags?.png} alt="flag" className={styles.country_img} />
                {/* <span className={styles.logout_span} >{Countries.find((obj)=>obj.cca2.toLowerCase()==(lang=="en"?"us":lang))?.cca2}</span> */}
            </span>
            <ul className="dropdown-menu dropdown-menu-end"
            style={{backgroundColor:"#232329", width:"2.5vw",minWidth:"2.5vw", maxWidth:"2.5vw",marginTop:"1vh",
            paddingLeft:0,paddingRight:0, paddingTop:'1vh',paddingBottom:'1vh',
            flexDirection:"column",gap:'0.2vh', alignItems:"center",justifyContent:"center", borderColor:'#8b8b8d30',
            }}
            >
                <li style={{display:'flex',alignItems:"center",justifyContent:"center", padding:0,margin:0}}>
                    <button className={styles.language} value='en' onClick={(e)=>changeLanguage("en")}>
                    <img src={Countries.find((obj)=>obj.cca2.toLowerCase()=="us")?.flags?.png} alt="flag" className={styles.country_img}/>
                    </button>
                </li>
            <li>
                <div className="dropdown-divider" style={{color:"#8b8b8d30"}} />
            </li>
                <li style={{display:'flex',alignItems:"center",justifyContent:"center", padding:0,margin:0}}>
                    <button className={styles.language} value='fr' onClick={(e)=>changeLanguage("fr")} >                        
                        <img src={Countries.find((obj)=>obj.cca2.toLowerCase()=="fr")?.flags?.png} alt="flag" className={styles.country_img}/>
                    </button>
                </li>
            <li>
                <div className="dropdown-divider" style={{color:"#8b8b8d30"}} />
            </li>
                <li style={{display:'flex',alignItems:"center",justifyContent:"center", padding:0,margin:0}}>
                    <button className={styles.language} value='es' onClick={(e)=>changeLanguage("es")}>      
                        <img src={Countries.find((obj)=>obj.cca2.toLowerCase()=="es")?.flags?.png} alt="flag" className={styles.country_img}/>
                    </button>
                </li>
            <li>
                <div className="dropdown-divider" style={{color:"#8b8b8d30"}} />
            </li>
                <li style={{display:'flex',alignItems:"center",justifyContent:"center", padding:0,margin:0}}>
                    <button className={styles.language} value='it' onClick={(e)=>changeLanguage("it")}>      
                        <img src={Countries.find((obj)=>obj.cca2.toLowerCase()=="it")?.flags?.png} alt="flag" className={styles.country_img}/>
                    </button>
                </li>
            <li>
                <div className="dropdown-divider" style={{color:"#8b8b8d30"}} />
            </li>
                <li style={{display:'flex',alignItems:"center",justifyContent:"center", padding:0,margin:0}}>
                    <button className={styles.language} value='de' onClick={(e)=>changeLanguage("de")}>      
                        <img src={Countries.find((obj)=>obj.cca2.toLowerCase()=="de")?.flags?.png} alt="flag" className={styles.country_img}/>
                    </button>
                </li>
            </ul>
            <div className={styles.settings} onClick={logoutHandle}>
                <LogoutRoundedIcon style={{width:"auto",height:"4vh"}} />
            </div>
        </div>
    </div>
)
}
