import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Navigate, useParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import BillingTable from './BillingTable';
import axios from 'axios';
import BillingRecords from './BillingRecords';
import { useTranslation } from 'react-i18next';
import "../css/billing.css";
import Swal from 'sweetalert2';
import apexchart from 'apexcharts'
import Flatpickr from 'react-flatpickr';
import Select from 'react-select'
import ScaleLoader from 'react-spinners/ScaleLoader';
import '../../node_modules/flatpickr/dist/flatpickr.min.css'
import '../../node_modules/flatpickr/dist/themes/dark.css'
import '../css/administration.css'
import moment from 'moment';
export default function Billing() {
    const {t,i18n} = useTranslation();
    const [manual,setManual] = useState([]);
    const [description,setDescription] = useState('');
    const {id} = useParams()
    const[billingData, setBillingData ] = useState()
    const [price,setPrice] = useState(0);
    const [countries,setCountries] = useState([]);
    const [selectedCountry,setSelectedCountry] = useState();
    const [countryActive,setCountryActive] = useState(false);
    const [loading,setLoading] = useState(false);
    const [tdata,setTData] = useState({
        brand:"",
        country:"",
        conversation:0,
        count:0,
        price:0
    });
    const [tGroup, setTgroup] = useState([]);
    const [daterange,SetDateRange] = useState({
        from:"",
        to:""
    });
    const [state] = useState({
        optionsBalance:{
            chart:{
                foreColor:'#60628b',
                toolbar:{
                    show:false
                },
                dropShadow: {
                    enabled: true,
                    top: 7,
                    left: 5,
                    blur: 2,
                    color: '#df972d',
                    opacity: 0.35
                },
                id:"graph"
            },
            dataLabels: {
                enabled: false
            },
            colors:['#df972d'],
            series:[{
                data:secureLocalStorage.getItem('balanceGraph')? secureLocalStorage.getItem('balanceGraph') : [0,0,0,0,0,0,0,0,0,0,0,0] ,
                name:"Spended $"},
            ],
            stroke: {
                curve: 'smooth'
            },
            grid:{
                xaxis:{
                    lines:{
                        show:false
                    }
                },
                yaxis:{
                    lines:{
                        show:false
                    }
                },
                padding:{
                    top: 0,
                    right: 15,
                    bottom: 0,
                    left: 35
                }
            },
            markers:{
                discrete: [{
                    seriesIndex: 0,
                    dataPointIndex: 11,
                    fillColor: '#fff',
                    strokeColor: '#df972d',
                    size: 7,
                    shape: "circle"
                }]
            },
            xaxis: {
                categories: [t("january"),t("february"),t("march"),t("april"),t("may"),t("june"),t("july"),t("august"),t("september"),t("october"),t("november"),t("december")],
                axisBorder:{
                    show: false
                },
                axisTicks:{
                    show:false
                }
            },
            yaxis:{
                show:false
            },
            
        }
        })
    function addOneDay(date = new Date()) {
        date.setDate(date.getDate() + 1);
        
        return date;
    }
    function subOneDay(date = new Date()) {
        date.setDate(date.getDate() - 1);
        
        return date;
    }
    const renderRecords = () =>{
        return <BillingRecords t={t} billingData={billingData}/>
    }
    useEffect(()=>{
        var flatpickrRange = document.querySelector("#flatpickr-range");
        console.log(flatpickrRange);
            flatpickrRange.flatpickr({
                mode: "range",
                onChange: function (selectedDates, dateStr, instance){
                    console.log(selectedDates,dateStr);
                    if(dateStr.includes("to")){
                        SetDateRange({
                            from:dateStr.split(' ')[0],
                            to:dateStr.split(' ')[2]
                        })
                        setCountryActive(true);
                        axios.get("https://www.pbxdiamondcall.com:8081/countries")
                        .then((res)=>{
                            var arr = [];
                            console.log(res);
                            if(res.data.length>0){

                                if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
                                res.data.forEach((item) => {
                                    arr.push({
                                        value:item.id,
                                        label:item.title
                                    });
                                })
                                setCountries(arr);
                                console.log(arr);
                                console.log(countries);
                                }
                                else{
                                    res.data.forEach((item) => {JSON.parse(secureLocalStorage.getItem("user")).company_id !== item.Id
                                        ?console.log(item)
                                        :arr.push({
                                            value:item.id,
                                            label:item.title
                                        })
                                    })
                                    setCountries(arr);
                                    console.log(countries);
                                }
                            }
                        })
                        .catch((err)=>console.log(err))
                    }
                    else if(!dateStr.includes("to")){
                        console.log(selectedDates,dateStr);
                            SetDateRange({
                                from:selectedDates[0],
                                to:addOneDay(selectedDates[1])
                            })
                            setCountries(true);
                            axios.get("https://www.pbxdiamondcall.com:8081/countries")
                            .then((res)=>{
                                var arr = [];
                                if(res.data.length>0){
    
                                    if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
                                    res.data.forEach((item) => {
                                        arr.push({
                                            value:item.id,
                                            label:item.title
                                        });
                                    })
                                    setCountries(arr);
                                    console.log(countries);
                                    }
                                    else{
                                        res.data.forEach((item) => {JSON.parse(secureLocalStorage.getItem("user")).company_id !== item.Id
                                            ?console.log(item)
                                            :arr.push({
                                                value:item.id,
                                                label:item.title
                                            })
                                        })
                                        setCountries(arr);
                                        console.log(countries);
                                    }
                                }
                            })
                            .catch((err)=>console.log(err))
                    }
                    console.log(countries);
                    }
            });
        let company_id = Number(id)
        console.log(company_id);
        console.log(billingData);
        console.log(JSON.parse(secureLocalStorage.getItem('user')).billingaccount_id);
        if(id !== undefined){
            axios.post('https://www.pbxdiamondcall.com:8081/billing/account', {company_id})
            .then(res => {
                axios.post('https://www.pbxdiamondcall.com:8081/billing/balance', {
                    id: res.data[0].id
                })
                .then((result)=>{
                    setBillingData(res.data);
                    console.log(result);
                    secureLocalStorage.setItem("balance", result.data[0].balance);
                    secureLocalStorage.setItem("agentsCount", result.data[0].count);
                    secureLocalStorage.setItem("prefixCount", result.data[0].prefix_count);
                    secureLocalStorage.setItem("billingCurrentData", res.data);
                    axios.post('https://www.pbxdiamondcall.com:8081/billing/graph', {
                        id: res.data[0].id
                    })
                    .then((response)=>{
                        console.log(response);
                        secureLocalStorage.setItem("balanceGraph", response.data);
                        apexchart.exec("graph",'updateSeries', [{
                            data:response.data!==undefined? response.data : [0,0,0,0,0,0,0,0,0,0,0,0]
                        }], true)
                        apexchart.exec("graph","render");
                        
                        axios.post('https://www.pbxdiamondcall.com:8081/billing/history', {
                            id:res.data[0].id
                        })
                        .then((resl)=>{
                            console.log(resl);
                            setManual(resl.data);
                        })
                        .catch((err)=>console.log(err));
                    })
                    .catch((err)=>console.log(err));
                })
                .catch((err)=>console.log(err));
            }).catch(e => console.log(e))
        }
        else{
            console.log("123");
            axios.post('https://www.pbxdiamondcall.com:8081/billing/balance', {
                id: JSON.parse(secureLocalStorage.getItem('user')).billingaccount_id
            })
            .then((result)=>{
                console.log(result);
                secureLocalStorage.setItem("balance", result.data[0].balance);
                secureLocalStorage.setItem("agentsCount", result.data[0].count);
                secureLocalStorage.setItem("prefixCount", result.data[0].prefix_count);
                axios.post('https://www.pbxdiamondcall.com:8081/billing/graph', {
                    id: JSON.parse(secureLocalStorage.getItem('user')).billingaccount_id
                })
                .then((response)=>{
                    console.log(response);
                    secureLocalStorage.setItem("balanceGraph", response.data);
                    
                    axios.post('https://www.pbxdiamondcall.com:8081/billing/history', {
                        id:JSON.parse(secureLocalStorage.getItem('user')).billingaccount_id
                    })
                    .then((resl)=>{
                        console.log(resl);
                        setManual(resl.data);
                    })
                    .catch((err)=>console.log(err));
                })
                .catch((err)=>console.log(err));
            })
            .catch((err)=>console.log(err));
        }
        
            SetDateRange({
                from:"",
                to:""
            })
    },[])
    const handleCountryChoose=(e)=>{
        console.log(daterange)
        setSelectedCountry(e.value);
        console.log(selectedCountry);
    }
    const handleSingleSubmit=(e)=>{
        setLoading(true);
        setTgroup([]);
        axios.post("https://www.pbxdiamondcall.com:8081/billing/report/single", {
            company_id:Number(id),
            from:daterange.from,
            to:daterange.to,
            country_id:selectedCountry
        })
        .then((res)=>{
            console.log(res);
            setTgroup(res.data);
            setLoading(false);
        })
        .catch((err)=>console.log(err));
    }
    const handleAllSubmit=(e)=>{
        setLoading(true);
        setTgroup([]);
        axios.post("https://www.pbxdiamondcall.com:8081/billing/report/all", {
            company_id:Number(id),
            from:daterange.from,
            to:daterange.to
        })
        .then((res)=>{
            console.log(res);
            setTgroup(res.data);
            setLoading(false);
        })
        .catch((err)=>console.log(err));
    }

    const Withdrawal=()=>{
        console.log(billingData);
        if(description!= "" && price!=0){
            axios.post('https://www.pbxdiamondcall.com:8081/billing/manual',{
                id:billingData!==undefined?billingData[0].id:JSON.parse(secureLocalStorage.getItem('user')).billingaccount_id,
                price:price*-1,
                description:description
            })
            .then((res)=>{
                Swal.fire({
                    title:"Success",
                    icon:"success",
                    timer:1500
                })
            })
            .catch((err)=>{
                Swal.fire({
                    title:"Error",
                    icon:"error",
                    timer:1500
                })
            })
        }
    }
    const Replenish=()=>{
        if(description!= "" && price!=0){
            axios.post('https://www.pbxdiamondcall.com:8081/billing/manual',{
                id:billingData!==undefined?billingData[0].id:JSON.parse(secureLocalStorage.getItem('user')).billingaccount_id,
                price:price,
                description:description
            })
            .then((res)=>{
                console.log(res)
                Swal.fire({
                    title:"Success",
                    icon:"success",
                    timer:1500
                })
            })
            .catch((err)=>{
                console.log(err)
                Swal.fire({
                    title:"Error",
                    icon:"error",
                    timer:1500
                })
            })
        }
    }
    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(role !== 1 && role !== 7){
        return <Navigate to="/onlinecalls" replace={true}/>
    }
    else
    return (
    <div className="nav-align-top">
    <ul className="nav nav-pills mb-3" role="tablist" style={{width:'80%' , position:'absolute' , left:'13%'}}>
        <li className="nav-item">
            <button type="button" className="nav-link active" 
            role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-top-home" aria-controls="navs-pills-top-home" aria-selected="true">Billing</button>
        </li>
        {
            JSON.parse(secureLocalStorage.getItem("user")).role_id === 1 
            ? 
            <li className="nav-item">
                <button type="button" className="nav-link" 
                role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-top-report" aria-controls="navs-pills-top-profile" aria-selected="false">Report</button>
            </li>
            :null
        }
        
    </ul>
    <div>
        <div className="tab-pane fade show active" id="navs-pills-top-home" role="tabpanel">
            <div className='container-xxl container-p-y'>
                <div className="row" style={{width:'100%',display:"flex",flexDirection:"row" , marginTop:'40px' , justifyContent:'space-between'}}>
                        <div className="card " style={{width:'46%',zIndex:2}}>
                            <div className="card-header d-flex align-items-center justify-content-between pb-0">
                                <div className="card-title mb-0">
                                    <div className="d-flex justify-content-start">
                                    <div className="d-flex pe-4">
                                    <div className="me-3">
                                        <span className="badge bg-label-warning p-2" style={{width:45,height:45}}>
                                            <i className="bx bx-wallet text-warning bx-sm" style={{marginTop:"3px"}}/>
                                        </span>
                                    </div>
                                    <div>
                                        <h4 className="mb-0" style={{fontWeight:"bold", color:"#a3a5ca", fontSize:"25px"}}>${secureLocalStorage.getItem("balance")!==undefined
                                    ?secureLocalStorage.getItem("balance")!=null?JSON.parse(secureLocalStorage.getItem("balance")):0:0}</h4>
                                        <small style={{fontSize:"15px"}}>{t("billingBalance")}</small>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="card-body" style={{position: 'relative'}}>
                                    <ReactApexChart 
                                options={state.optionsBalance}
                                series={state.optionsBalance.series}
                                labels={state.optionsBalance.labels}
                                type='line'
                                height={350}
                                width={'100%'}
                                /> 
                            </div>
                        </div>
                        <div  style={{width:350, display:"flex", flexDirection:"column", justifyContent:"space-between" , padding:'0px'}}>
                            <div className="card flex-grow-1" style={{width:350, marginBottom:5}}>
                                <div className="card-header" style={{height:65}}>
                                    <h5 style={{fontWeight:'bold', fontSize:"30px", color:"#a3a5ca"}}>Active agents</h5>
                                </div>   
                                <div className="card-body">
                                    <div style={{display:'flex',flexDirection:'row'}}>
                                    <button className="btn users" style={{width:140,height:130}} disabled={true}>
                                    </button>
                                    <button className='btn btn-label-warning' disabled={true} style={{marginTop:"2%",
                                    marginLeft:"10%", 
                                    fontWeight:"bold", fontSize:"85px", 
                                    height:140,width:140}}>{secureLocalStorage.getItem("agentsCount")!==undefined
                                    ?JSON.parse(secureLocalStorage.getItem("agentsCount")):null}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card flex-grow-1" style={{width:350, marginTop:5}}>
                            <div className="card-header">
                                    <h5 style={{fontWeight:'bold', fontSize:"30px", color:"#a3a5ca"}}>Active prefixes</h5>
                                </div>
                                <div className="card-body">
                                    <div style={{display:'flex',flexDirection:'row'}}>
                                    <button className="btn prefixes" style={{width:140,height:140, marginTop:"-4%"}} disabled={true}>
                                    </button>
                                    <button className='btn btn-label-warning' disabled={true} style={{marginTop:"-6%",
                                    marginLeft:"10%", 
                                    fontWeight:"bold", fontSize:"85px", 
                                    height:140,width:140}}>{secureLocalStorage.getItem("prefixCount")!==undefined
                                    ?JSON.parse(secureLocalStorage.getItem("prefixCount")):null}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card" style={{marginLeft:0,width:320 , display:'flex' , alignItems:'center' , justifyContent:'center' , flexDirection:'column'}}>
                            <div className="card-header" style={{width:'100%' , padding:'10px' , paddingTop:'20px' }}>
                                <h5 style={{width:'100%' ,fontWeight:'bold', fontSize:"30px", color:"#a3a5ca", textAlign:'left'}}>Balance history</h5>
                            </div>
                            <div className="card-body" style={{width:320, height:310}}>
                                <ul className="list-group overflow-auto" style={{position: 'relative',maxHeight:300, zIndex:1}}>
                                    {
                                        manual.map((val,ind)=>{
                                            return <li className="list-group-item list-group-item" key={ind}>[{moment.parseZone(new Date(val.date)).local().format('YYYY-MM-DD HH:mm:ss')}]  
                                            {' '}{val.description}:<br></br> {val.cost}$</li>
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="card-footer" style={{width:320,visibility:role===1?"visible":"hidden",display:'flex',alignItems:"center", flexDirection:'column'}} >
                                <button className='btn btn-label-success' style={{margin:4, width:150}} data-bs-toggle="modal" data-bs-target="#modalTop">
                                    Replenishment
                                </button>
                                <button className='btn btn-label-danger' style={{margin:4, width:150}} data-bs-toggle="modal" data-bs-target="#modalDown">
                                    Withdrawal
                                </button>
                            </div>
                        </div>
                        
                </div>
                    
                <div className="card flex-grow-1" style={{width:'calc(100% - 20px)', padding:10, marginTop:10}}>
                    <div className="card-body" style={{position: 'relative'}}>
                        {
                            renderRecords()
                        }
                    </div>
                </div>


                <div className="modal fade" data-bs-backdrop="static" id="modalTop" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <form className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTopTitle">Replenishment</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col mb-3">
                                    <label htmlFor="nameSlideTop" className="form-label">Description</label>
                                    <input type="text" id="nameSlideTop" className="form-control" 
                                    value={description} onChange={(e)=>setDescription(e.target.value)}/>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col mb-0">
                                    <label htmlFor="emailSlideTop" className="form-label">Cost</label>
                                    <input type="number" id="emailSlideTop" className="form-control"
                                    value={price} onChange={(e)=>setPrice(e.target.value)} min={0}/>
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={()=>Replenish()}>Replenish</button>
                        </div>
                        </form>
                    </div>
                </div>
                <div className="modal fade" data-bs-backdrop="static" id="modalDown" tabIndex={-1}>
                            <div className="modal-dialog modal-dialog-centered">
                                <form className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modalTopTitle">Withdrawal</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col mb-3">
                                            <label htmlFor="nameSlideTop" className="form-label">Description</label>
                                            <input type="text" id="nameSlideTop" className="form-control" 
                                            value={description} onChange={(e)=>setDescription(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="row g-2">
                                        <div className="col mb-0">
                                            <label htmlFor="emailSlideTop" className="form-label">Cost</label>
                                            <input type="number" id="emailSlideTop" className="form-control"
                                            value={price} onChange={(e)=>setPrice(e.target.value)} max={0}/>
                                        </div>
                                    </div><br />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary" onClick={()=>Withdrawal()}>Withdrawal</button>
                                </div>
                                </form>
                            </div>
                </div>
            </div>
        </div>
        <div className="tab-pane fade" id="navs-pills-top-report" role="tabpanel" style={{
            position:"absolute",
            top:170,
            left: 0,
            right: 0
        }}>
            <div className="container-xxl container-p-y" style={{height:"100%", width:"100%"}}>
                <div className="card" style={{height:"100%", width:"100%"}}>
                    <div className="card-header ">
                        <h5 className="card-title" style={{fontSize:"25px", fontWeight:"bold"}}>Report</h5>
                    </div>
                    <div style={{display:'flex', flexDirection:"row"}}>
                        <div className="mb-3" style={{width:400, margin:10}}>
                        <label htmlFor="flatpickr-range" className="form-label">Select range</label>
                        <input type="text" className="form-control" placeholder="YYYY-MM-DD to YYYY-MM-DD" 
                        id="flatpickr-range"/>
                    </div>
                    <div className="mb-3" style={{width:250, margin:10}}>
                        <label className="form-label">Select country</label>
                        <Select placeholder='Select country'
                                        onChange={(e)=>handleCountryChoose(e)}
                                        id='operatorLb' 
                                        isDisabled={!countryActive}
                                        options={countries}
                                        styles={{
                                            control:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#444663",
                                                color:"#b8b8cd",
                                                borderColor:"#444663"
                                            }),
                                            option: (styles, { isFocused, isSelected }) => {
                                                return {
                                                    ...styles,
                                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                };
                                            },
                                            singleValue:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#444663",
                                                color:"#b8b8cd"
                                            }),
                                            placeholder:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#444663",
                                                color:"#b8b8cd"
                                            }),
                                            menuList:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#444663",
                                                color:"#b8b8cd"
                                            }),
                                        }}/>
                    </div>

                    <button className='btn btn-primary' 
                    style={{width:70, height:50, margin:10, alignSelf:"center", marginTop:25, fontWeight:"bold"}}
                    disabled={!(selectedCountry)} onClick={()=>handleSingleSubmit()}
                    >Single</button>
                    <button className='btn btn-primary' 
                    style={{width:70, height:50, margin:10, alignSelf:"center", marginTop:25, fontWeight:"bold"}}
                    disabled={!(countryActive)} onClick={()=>handleAllSubmit()}
                    >All</button>
                    </div>
                    <div className="card-body" style={{height:"100%"}}>
                        {
                            loading===true
                            ?
                            <div className="card" style={{width:1200, height:500, justifyContent:'center',alignContent:'center', marginLeft:"70px"}}>
                                <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>{t("loadingData")}...</h1>
                            <ScaleLoader
                                color="#696cff"
                                height={270}
                                margin={10}
                                radius={2}
                                speedMultiplier={0.8}
                                width={120}
                                cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                            </div>
                            :
                            <div className="card-datatable table-responsive">
                            <table className="datatables-users table border-top">
                                <thead>
                                <tr>
                                    <th>Brand</th>
                                    <th>Country</th>
                                    <th>Conversation time</th>
                                    <th>Price</th>
                                    <th>Count</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                    tGroup.length>0?tGroup.map((val,ind)=>(
                                    <tr key={ind}>
                                    <td style={{fontWeight:"bold"}}>{val.Brand}</td>
                                    <td style={{fontWeight:"bold"}}>{val.Country}</td>
                                    <td style={{fontWeight:"bold"}}>{val.Duration<3600 * 24?new Date(val.Duration * 1000).toISOString().substring(11, 19)
                                    :new Date(val.Duration * 1000).toISOString().substring(8, 19)} </td>
                                    <td style={{fontWeight:"bold"}}>{Number(val.TotalCost).toFixed()} $</td>
                                    <td style={{fontWeight:"bold"}}>{val.TotalCount} calls</td>
                                    </tr>
                                    ))
                                    :
                                    <tr>
                                    <td style={{fontWeight:"bold"}}></td>
                                    <td style={{fontWeight:"bold"}}></td>
                                    <td style={{fontWeight:"bold"}}>Information not found.</td>
                                    <td style={{fontWeight:"bold"}}></td>
                                    <td style={{fontWeight:"bold"}}></td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

)
}
