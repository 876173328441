import axios from 'axios';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import styles from './Freeswitch.module.scss';

export default function Freeswitch() {
    const { t,i18n } = useTranslation();
    const [apicom1,setApi1] = useState("");
    const [apicom2,setApi2] = useState("");
    const [bgapicom1,setBgApi1] = useState("");
    const [bgapicom2,setBgApi2] = useState("");
    const [output,setOutput] = useState("");
    function handleReload(){
        axios.post("https://www.pbxdiamondcall.com:8081/reloadxml")
        .then((res)=>console.log(res))
        .catch((err)=>console.log(err))
    }
    function handleReloadAcl(){
        axios.post("https://www.pbxdiamondcall.com:8081/reloadacl")
        .then((res)=>console.log(res))
        .catch((err)=>console.log(err))
    }
    function handleReloadProfile1(){
        axios.post("https://www.pbxdiamondcall.com:8081/reloadserverone")
        .then((res)=>console.log(res))
        .catch((err)=>console.log(err))
    }
    function handleReloadProfile2(){
        axios.post("https://www.pbxdiamondcall.com:8081/reloadservertwo")
        .then((res)=>console.log(res))
        .catch((err)=>console.log(err))
    }
    const sendApi1=()=>{
        console.log(apicom1);
        axios.post("https://www.pbxdiamondcall.com:8081/freeswitch/sendApi1",{
        command:apicom1
        })
        .then((res)=>setOutput("Server #1\nApi:"+res.data))
        .catch((err)=>setOutput(err))
    }
    const sendApi2=()=>{
        axios.post("https://www.pbxdiamondcall.com:8081/freeswitch/sendApi2",{
        command:apicom2
        })
        .then((res)=>setOutput("Server #2\nApi:"+res.data))
        .catch((err)=>setOutput(err))
    }
    const sendBgApi1=()=>{
        axios.post("https://www.pbxdiamondcall.com:8081/freeswitch/sendBgApi1",{
        command:bgapicom1
        })
        .then((res)=>setOutput("Server #2\nBgApi:"+res.data))
        .catch((err)=>setOutput(err))
    }
    const sendBgApi2=()=>{
        axios.post("https://www.pbxdiamondcall.com:8081/freeswitch/sendBgApi2",{
        command:bgapicom2
        })
        .then((res)=>setOutput("Server #2\nBgApi:"+res.data))
        .catch((err)=>setOutput(err))
    }
    return (
        <div className={styles.page}>
            <div className="card" style={{backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
                <div className="card-header">
                    <label className="form-label" style={{fontWeight:"bold",fontSize:"25px"}}>Freeswitch commands</label>
                    <br /><br />
                    <div style={{width:"60vw", display:'flex',alignContent:"center",justifyContent:"center"}}>
                        <div style={{width:"45vw"}}>
                            <button className='btn' 
                                style={{height:"4vh", margin:10, width:"10vw", marginTop:"2.8vh",border:"0.1vh solid #b47fe9",color:"#d3d3d2",backgroundColor:"transparent"}}
                                onClick={()=>handleReload()}>{t("adminxml")}</button>
                            <button className='btn' 
                                style={{height:"4vh", margin:10, width:"10vw", marginTop:"2.8vh",border:"0.1vh solid #b47fe9",color:"#d3d3d2",backgroundColor:"transparent"}}
                                onClick={()=>handleReloadAcl()}>{t("adminacl")}</button>
                            <button className='btn' 
                                style={{height:"4vh", margin:10, width:"10vw", marginTop:"2.8vh",border:"0.1vh solid #b47fe9",color:"#d3d3d2",backgroundColor:"transparent"}}
                                onClick={()=>handleReloadProfile1()}>{t("adminprofilesone")}</button>
                            <button className='btn' 
                                style={{height:"4vh", margin:10, width:"10vw", marginTop:"2.8vh",border:"0.1vh solid #b47fe9",color:"#d3d3d2",backgroundColor:"transparent"}}
                                onClick={()=>handleReloadProfile2()}>{t("adminprofilestwo")}</button>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div style={{display:"flex",flexDirection:'column'}}>
                        <div>
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Command output</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows={15} defaultValue={""} readOnly={true} 
                                style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            value={output} />
                        </div>
                        <br />
                        <div style={{display:"flex",flexDirection:'row', justifyContent:"space-between"}}>
                            <div style={{display:"flex",flexDirection:'row'}}>
                                <div class="mb-3" >
                                    <label for="defaultFormControlInput" class="form-label">Command</label>
                                    <input type="text" class="form-control" id="defaultFormControlInput" placeholder="sofia status" 
                                    aria-describedby="defaultFormControlHelp" style={{width:"12vw",color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                    onChange={(e)=>setApi1(e.target.value)}/>
                                </div>
                                <button className='btn' 
                                style={{height:"4vh", margin:10, width:"13vw", marginTop:"2.8vh",border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}
                                disabled={apicom1===""?true:false} onClick={()=>sendApi1()}>Submit to api server #1</button>
                            </div>
                            <div style={{display:"flex",flexDirection:'row'}}>
                                <div class="mb-3" >
                                    <label for="defaultFormControlInput" class="form-label">Command</label>
                                    <input type="text" class="form-control" id="defaultFormControlInput" placeholder="show calls" 
                                    aria-describedby="defaultFormControlHelp" style={{width:"12vw",color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                    onChange={(e)=>setApi2(e.target.value)}/>
                                </div>
                                <button className='btn' 
                                style={{height:"4vh", margin:10, width:"13vw", marginTop:"2.8vh",border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}
                                disabled={apicom2===""?true:false} onClick={()=>sendApi2()}>Submit to api server #2</button>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:'row', justifyContent:"space-between"}}>
                            <div style={{display:"flex",flexDirection:'row'}}>
                                <div class="mb-3" >
                                    <label for="defaultFormControlInput" class="form-label">Command</label>
                                    <input type="text" class="form-control" id="defaultFormControlInput" placeholder="sofia gateway_restart <gateway_name>" 
                                    aria-describedby="defaultFormControlHelp" style={{width:"12vw",color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                    onChange={(e)=>setBgApi1(e.target.value)}/>
                                    </div>
                                    <button className='btn' 
                                    style={{height:"4vh", margin:10, width:"13vw", marginTop:"2.8vh",border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}
                                    disabled={bgapicom1===""?true:false} onClick={()=>sendBgApi1()}>Submit to bgapi server #1</button>
                                </div>
                            <div style={{display:"flex",flexDirection:'row'}}>
                                <div class="mb-3" >
                                    <label for="defaultFormControlInput" class="form-label">Command</label>
                                    <input type="text" class="form-control" id="defaultFormControlInput" placeholder="sofia gateway_shutdown <gateway_name>" 
                                    aria-describedby="defaultFormControlHelp" style={{width:"12vw",color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                    onChange={(e)=>setBgApi2(e.target.value)} />
                                </div>
                                <button className='btn' 
                                style={{height:"4vh", margin:10, width:"13vw", marginTop:"2.8vh",border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}
                                disabled={bgapicom2===""?true:false} onClick={()=>sendBgApi2()}>Submit to bgapi server #2</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
