import React, { useState, useRef, useEffect, useMemo } from 'react';
import styles from './Dashboard.module.scss'
import LinearProgress from '@mui/material/LinearProgress';
import { useLocation, useParams } from 'react-router-dom';
import { LineChart, LinePlot } from '@mui/x-charts/LineChart';
import { Box, CssBaseline, ThemeProvider, Tooltip, Typography, createTheme, linearProgressClasses } from '@mui/material';
import { PieChart, PiePlot } from '@mui/x-charts/PieChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { ChartsTooltip, ChartsXAxis, ChartsYAxis, SparkLineChart } from '@mui/x-charts';
import PropTypes from 'prop-types';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import {
    GaugeContainer,
    GaugeValueArc,
    GaugeReferenceArc,
    useGaugeState, gaugeClasses, GaugeValueText, Gauge 
} from '@mui/x-charts/Gauge';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Select from 'react-select';
function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:"start",gap:"0.5vw", height:"5vh", width:"19vw" }}>
            <LinearProgress variant="determinate" {...props} sx={{
                width:"95%",
                height:"1.2vh",
                borderRadius:"0.2vh",
                [`&.${linearProgressClasses.colorPrimary}`]:{
                    backgroundColor:"#FF4861",
                    border:"0.2vh solid #232329"
                },
                [`& .${linearProgressClasses.bar}`]:{
                    backgroundColor:"#24D6A3",
                    borderRight:"0.2vh solid #232329"
                }
            }}/>
            <Typography variant="body2" color="#24D6A3" sx={{fontSize:"1.5vh", fontFamily:"'Ubuntu',sans-serif"}}>{`${Math.round(
            props?.value,
            )}%`}</Typography>
      </Box>
    );
}
LinearProgressWithLabel.propTypes = {
/**
 * The value of the progress indicator for the determinate and buffer variants.
 * Value between 0 and 100.
 */
value: PropTypes.number.isRequired,
};
export default function DashboardNew() {
    const [options,setOptions] = useState([]);
    const [data,setData] = useState([]);
    const [companyId, setCompanyId] = useState(JSON.parse(secureLocalStorage.getItem('user')).company_id);
    const [graphData,setGraphData] = useState({
        activityAnswer:[],
        activityNoAnswer:[],
        activityAnswerWeek:[],
        activityNoAnswerWeek:[],
        activityAnswerMonth:[],
        activityNoAnswerMonth:[],
        acd: 0,
        hangupStats:{
            manager:0,
            client:0,
            server:0,
            processing:0
        },
        ar:0,
        weeklyAR: [60,55,69,58,70,0,0],
        monthlyAR: [60,58,67,71],
        last30: []
    })
    const {timeframe} = useParams();
    const [activeTab,setActiveTab] = useState(timeframe);
    const [isLoading,setIsLoading] = useState(true);
    const location = useLocation();
    const [dimensions, setDimensions] = useState({ width: 300, height: 300 })
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });
    console.log(JSON.parse(secureLocalStorage.getItem('user')))
    const update = async () => {
        setGraphData({
            activityAnswer:[],
            activityNoAnswer:[],
            acd: 0,
            hangupStats:{
                manager:0,
                client:0,
                server:0,
                processing:0
            },
            ar:0,
            weeklyAR: [0,0,0,0,0,0],
            monthlyAR: [0,0,0,0],
            last30: []
        })
        setIsLoading(true);
        await axios.get(`https://www.pbxdiamondcall.com:8081/dashboard/${companyId}/${timeframe}`).then((resp)=>{
            console.log(resp.data);
        if(timeframe == "today"){
            setGraphData({
                activityAnswer:resp.data.activiy_answer.map((val)=>val.CallCount),
                activityNoAnswer:resp.data.activiy_noanswer.map((val)=>val.CallCount),
                acd: resp.data.acd[0].acd,
                hangupStats:{
                    manager:resp.data.hangup_stats[0].manager,
                    client:resp.data.hangup_stats[0].client,
                    server:resp.data.hangup_stats[0].server,
                    processing:resp.data.hangup_stats[0].processing
                },
                ar:resp.data.asr[0].asr
            })
            setData(resp.data.best_agents);
            setIsLoading(false);
        }
        else if(timeframe == "week"){
            setGraphData({
                
                
                
                activityAnswerWeek:resp.data.activiy_answer.map((val)=>val.CallCount),
                activityNoAnswerWeek:resp.data.activiy_noanswer.map((val)=>val.CallCount),
                acd: resp.data.acd[0].acd,
                hangupStats:{
                    manager:resp.data.hangup_stats[0].manager,
                    client:resp.data.hangup_stats[0].client,
                    server:resp.data.hangup_stats[0].server,
                    processing:resp.data.hangup_stats[0].processing
                },
                ar:resp.data.asr[0].asr,
                weeklyAR: resp.data.asrweek.slice(0,-1)
            })
            setData(resp.data.best_agents);
            setIsLoading(false);
        }
        else if(timeframe == "month"){
            setGraphData({
                activityAnswerMonth:resp.data.activiy_answer.map((val)=>val.CallCount),
                activityNoAnswerMonth:resp.data.activiy_noanswer.map((val)=>val.CallCount),
                acd: resp.data.acd[0].acd,
                hangupStats:{
                    manager:resp.data.hangup_stats[0].manager,
                    client:resp.data.hangup_stats[0].client,
                    server:resp.data.hangup_stats[0].server,
                    processing:resp.data.hangup_stats[0].processing
                },
                ar:resp.data.asr[0].asr,
                last30:resp.data.last30DaysDates,
                monthlyAR:resp.data.asrweeks
            })
            setData(resp.data.best_agents);
            setIsLoading(false);
        }
        }).catch((err)=>console.log(err));

    }
    useEffect(()=>{
        if(companyId != JSON.parse(secureLocalStorage.getItem('user')).company_id)
        update();
    },[companyId])
    useEffect(()=>{
        setGraphData({
            activityAnswer:[],
            activityNoAnswer:[],
            acd: 0,
            hangupStats:{
                manager:0,
                client:0,
                server:0,
                processing:0
            },
            ar:0,
            weeklyAR: [0,0,0,0,0,0],
            monthlyAR: [0,0,0,0],
            last30: []
        })
        update();
    },[timeframe])
    const updateDimensions = () => {
        const width = window.innerWidth * 0.2; // Example: 30% of the viewport width
        const height = window.innerHeight * 0.3; // Example: 30% of the viewport height
        setDimensions({ width, height });
    };
    useEffect(() => {
        var arr = [];
        secureLocalStorage.getItem("brands")?.forEach((val)=>{
            arr.push({value:val.Id,label:val.Title});
        })
        setOptions(arr);
        window.addEventListener('resize', updateDimensions);
        updateDimensions(); // Initial setting
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    const columns = useMemo(
        () => [
            {
            accessorKey: 'agent',
            header: 'Agent',
            enableResizing:false,
            grow:false, 
            size:350,
            Cell: ({ renderedCellValue, row }) => {
                console.log()
                return (
                    <span>{row.original.agent}</span>
                )
            },
            },
            {
            accessorKey: 'team',
            header: 'Team',
            enableResizing:false,
            grow:0.5, 
            Cell: ({ renderedCellValue, row }) => {
                const status = secureLocalStorage.getItem("brand_users")?.find((val)=>val.Id == row.original.ResponsibleId)
                return (
                <Tooltip title={status?.Login}>
                    <span>
                        {row.original.team}
                    </span>
                </Tooltip>
                )
            },
            size:150
            },
            {
                accessorKey: 'calls',
                header: 'Calls',
                enableResizing:false,
                grow:0.25, 
                size:100
            },
            {
                accessorKey: 'acd',
                header: 'ACD',
                enableResizing:false,
                grow:0.25, 
                size:120,
                Cell: ({ renderedCellValue, row }) => {
                    console.log()
                    return (
                        <span>{parseFloat(row.original.acd).toFixed(0)} sec.</span>
                    )
                },
            },
            {
                accessorKey: 'asr',
                header: 'AR',
                enableResizing:false,
                grow:0.25, 
                size:120,
                Cell: ({ renderedCellValue, row }) => {
                    console.log()
                    return (
                        <span>{parseFloat(row.original.asr*100).toFixed(0)}%</span>
                    )
                },
            },
        ],
        [],
    );
    const table = useMaterialReactTable({
        columns,
        data,
        muiTableContainerProps: { sx: { maxHeight: '35vh', height:"35vh",maxWidth:"64.5vw" } },
        getRowId: (row)=>row.agent,
    initialState: { density: 'compact' },
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 160,
        }, 
        muiTableBodyProps: {
            sx: (theme) => ({
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
                {
                backgroundColor:'#2A2A31',
                // borderBottom:'1px dashed rgb(46, 50, 54)',

                },
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                {
                backgroundColor: '#2A2A31',
                // borderBottom:'1px dashed rgb(46, 50, 54)',

                },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
                {
                backgroundColor: '#2A2A31',
                
                // borderBottom:'1px dashed rgb(46, 50, 54)',
                },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                {
                backgroundColor: '#2A2A31',
                // borderBottom:'1px dashed rgb(46, 50, 54)',
                },
                height:'32vh',
                maxHeight:'32vh',
            }),
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: '#2A2A31',
            draggingBorderColor: '#000',
        }),
        muiExpandButtonProps:({row}) =>({
        sx:{
            color:'#fff'

        }
        }),
        muiTableBodyCellProps: ({ column }) => ({
            sx: {
                backgroundColor:'#2A2A31',
                fontFamily:"'Ubuntu', sans-serif",
                fontSize: '1.4vh',
                fontWeight:"500",
                color:'#fff',
                borderBottom:'1px dashed rgb(46, 50, 54)',
                '& .MuiTableCell-root':{
                borderBottom:'1px dashed rgb(46, 50, 54)'
                }
            },
            }),
            muiTableProps: ({table})=>({
            sx:{
                backgroundColor:'grey',
                fontFamily:"'Ubuntu', sans-serif",
                fontSize: '1.4vh',
                border:"1px solid rgb(46, 50, 54)",
                height:'35vh',
                maxHeight:'35vh',
            }
            }),
            muiTopToolbarProps: ({table})=>({
            sx:{
                display:"none",
                backgroundColor:"#2A2A31",
                borderTop:"1px solid rgb(46, 50, 54)",
                borderLeft:"1px solid rgb(46, 50, 54)",
                borderRight:"1px solid rgb(46, 50, 54)",
                fontFamily:"'Ubuntu', sans-serif",
                fontSize: '14px',
                borderTopLeftRadius:'1vh',
                borderTopRightRadius:'1vh',
                '& .MuiButtonBase-root':{
                color:'#fff'
                },
                '& .MuiSvgIcon-root':{
                color:'#fff'

                },
                '& .MuiInputBase-root':{
                borderColor:'#fff'

                },
                '& .MuiInputBase-input':{
                color:'#fff',
                fontFamily:"'Ubuntu', sans-serif",
                }
            }
            }),
            muiColumnActionsButtonProps: ({row})=>({
            sx:{
                color:'#fff'
            }
            }),
            muiTableHeadCellProps: {
            sx: {
                fontWeight: 'bold',
                fontFamily:"'Ubuntu', sans-serif",
                fontSize: '1.4vh',
                backgroundColor:'#2A2A31',
                color:'#fff',
                
                borderBottom:'1px dashed rgb(46, 50, 54)',
                '& .MuiSvgIcon-root':{
                fill:'#fff'
                },
                '& .MuiDivider-root':{
                borderColor:'lightgrey'
                },
                '& .MuiInputBase-input':{
                color:'#fff',
                fontFamily:"'Ubuntu', sans-serif",
                }
            },
            },
            muiTableHeadProps:{
            sx:{
                '& .MuiTableCell-root':{
                border:"1px solid rgb(46, 50, 54)",
                },
            }
            },
            muiPaginationProps: {
            showRowsPerPage: false,
            shape: 'rounded',
            sx:{
                display:"none",
                '& .MuiButtonBase-root':{
                color:'#fff'
                }
            }
            },
            muiBottomToolbarProps:{
            sx:{
                display:"none",
                backgroundColor:'#2A2A31',
                borderBottom:"1px solid rgb(46, 50, 54)",
                borderLeft:"1px solid rgb(46, 50, 54)",
                borderRight:"1px solid rgb(46, 50, 54)",
                color:'#fff'

            },
            },
            muiFilterTextFieldProps:{
            sx:{
                color:'#fff'
            }
            },
            paginationDisplayMode: 'pages',
        columnResizeMode: 'onChange',
        localization:{
            actions: 'Действия',
            and: 'и',
            cancel: 'Отменить',
            changeFilterMode: 'Изменить режим фильтра',
            changeSearchMode: 'Изменить режим поиска',
            clearFilter: 'Очистить фильтр',
            clearSearch: 'Очистить поиск',
            clearSelection: 'Очистить выбор',
            clearSort: 'Очистить сортировку',
            clickToCopy: 'Нажмите, чтобы скопировать',
            copy: 'Копировать',
            columnActions: 'Действие колонки',
            copiedToClipboard: 'Скопировано в буфер обмена',
            collapse: 'Свернуть',
            collapseAll: 'Свернуть все',
            dropToGroupBy: 'Добавить в группу к {column}',
            edit: 'Редактировать',
            expand: 'Раскрыть',
            expandAll: 'Раскрыть все',
            filterArrIncludes: 'Включить',
            filterArrIncludesAll: 'Влючить все',
            filterArrIncludesSome: 'Включить',
            filterBetween: 'Между',
            filterBetweenInclusive: 'Между включительно',
            filterByColumn: 'Отфильтровать по {column}',
            filterContains: 'Содержит',
            filterEmpty: 'Пустой',
            filterEndsWith: 'Оканчивается',
            filterEquals: 'Равны',
            filterEqualsString: 'Равны',
            filterFuzzy: 'Физзи',
            filterGreaterThan: 'Больше чем',
            filterGreaterThanOrEqualTo: 'Больше или равно',
            filterInNumberRange: 'Между',
            filterIncludesString: 'Содержит',
            filterIncludesStringSensitive: 'Содержит (регистрозависимый)',
            filterLessThan: 'Меньше чем',
            filterLessThanOrEqualTo: 'Меньше или равно',
            filterMode: 'Режим фильтра: {filterType}',
            filterNotEmpty: 'Не пустое',
            filterNotEquals: 'Не равны',
            filterStartsWith: 'Начинается с',
            filterWeakEquals: 'Равны',
            filteringByColumn: 'Фильтр по {column} - {filterType} {filterValue}',
            goToFirstPage: 'Перейти на первую страницу',
            goToLastPage: 'Перейти на последнюю страницу',
            goToNextPage: 'Перейти на следующую страницу',
            goToPreviousPage: 'Перейти на предыдущую страницу',
            grab: 'Grab',
            groupByColumn: 'Сгруппировать по {column}',
            groupedBy: 'Сгруппировать',
            hideAll: 'Скрыть всё',
            hideColumn: 'Скрыть {column} колонку',
            max: 'Макс',
            min: 'Мин',
            move: 'Двигать',
            noRecordsToDisplay: 'Нет записей для показа',
            noResultsFound: 'Результатов не найдено',
            of: 'из',
            or: 'или',
            pin: 'Прикрепить',
            pinToLeft: 'Прикрепить слева',
            pinToRight: 'Прикрепить справа',
            resetColumnSize: 'Сбросить размер колонок',
            resetOrder: 'Сбросить порядок',
            rowActions: 'Действия строки',
            rowNumber: '#',
            rowNumbers: 'Количество строк',
            rowsPerPage: 'Строк на странице',
            save: 'Сохранить',
            search: 'Найти',
            selectedCountOfRowCountRowsSelected: '{selectedCount} из {rowCount} строк выбрано',
            select: 'Выбрать',
            showAll: 'Показать все',
            showAllColumns: 'Показать все колонки',
            showHideColumns: 'Показать/скрыть колонки',
            showHideFilters: 'Показать/скрыть фильтры',
            showHideSearch: 'Показать/скрыть поиск',
            sortByColumnAsc: 'Сортировать {column} по возрастанию',
            sortByColumnDesc: 'Сортировать {column} по убыванию',
            sortedByColumnAsc: 'Отсортирована по {column} возрастанию',
            sortedByColumnDesc: 'Отсортирована по {column} убыванию',
            thenBy: ', затем ',
            toggleDensity: 'Изменить плотность',
            toggleFullScreen: 'Включить полноэкранный режим',
            toggleSelectAll: 'Выбрать всё',
            toggleSelectRow: 'Переключить выбор строки',
            toggleVisibility: 'Изменить видимость',
            ungroupByColumn: 'Разгруппировать по {column}',
            unpin: 'Открепить',
            unpinAll: 'Открепить всё',
        }
    });
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <div className={styles.page}>
                <div className={styles.dashboard}>
                {
                    isLoading
                    ?
                    <LinearProgress sx={{width:"50%",color:"#3EA3FC"}}/>
                    :
                        timeframe == "today"
                        ?
                        <div className={styles.page_outlet}>
                            
                            <div className={styles.row1}>
                                    <div className={styles.graph}>
                                        <span className={styles.title}>Agents activity</span>
                                        {
                                            JSON.parse(secureLocalStorage.getItem('user')).login === "TestingGod" ?
                                            <div style={{width:"10vw", position:"absolute", top:'1vh',right:"0.5vw",zIndex:10}}>
                                                <Select 
                                                    options={options}
                                                    value={{
                                                        label:options?.find((val)=>val.value==companyId)?.label,
                                                        value:options?.find((val)=>val.value==companyId)?.value
                                                    }}
                                                    onChange={(e)=>setCompanyId(e.value)}
                                                    styles={{
                                                        input: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            minHeight:"1.8vh !important;",
                                                            height:"1.8vh",
                                                            width:"100%",
                                                            padding:"0px",
                                                            color:"#fff",
                                                        }),
                                                        container: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            minHeight:"1.8vh !important;",
                                                            height:"2.5vh",
                                                            width:"100%",
                                                            borderColor: state.isFocused ? '#3EA3FC' : "#3EA3FC;",
                                                            borderRadius: "0.5vh",
                                                            padding:"0px",
                                                        }),
                                                        valueContainer: (baseStyles,state) =>({
                                                            ...baseStyles,
                                                            minHeight:"1.8vh !important;",
                                                            height:"2.5vh",
                                                            fontSize:"1.4vh",
                                                            marginBottom:"20%",
                                                            paddingBottom:"0.4vh"
                                                        }),
                                                        indicatorsContainer: (baseStyles,state) =>({
                                                            ...baseStyles,
                                                            minHeight:"1.8vh !important;",
                                                            height:"2.5vh",
                                                            paddingTop:"0.2vh"
                                                        }),
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            minHeight:"1.8vh !important;",
                                                            height:"3vh",
                                                            background:"#2A2A31",
                                                            fontSize:"1.4vh",
                                                            color:"#fff",
                                                            borderRadius: "0.5vh",
                                                            borderColor: state.isFocused ? '#3EA3FC' : "#3EA3FC",
                                                            "&:hover": {
                                                                borderColor: 'rgba(62, 163, 252, 0.212)'
                                                            },
                                                            boxShadow:"0px",
                                                            padding:"0px",
                                                            paddingLeft:"0.4vw",
                                                        }),
                                                        indicatorSeparator: (baseStyles, state) => ({
                                                            dislay:"none"
                                                        }),
                                                        placeholder:(baseStyles, state) => ({
                                                            ...baseStyles,
                                                            color:"#fff",
                                                        }),
                                                        dropdownIndicator:(baseStyles, state) => ({
                                                            ...baseStyles,
                                                            color:"#fff",
                                                            "svg":{
                                                                height:"2vh",
                                                                width:"auto"
                                                            },
                                                            paddingRight:"0.8vw"
                                                        }),
                                                        menuList:(baseStyles,state)=>({
                                                            ...baseStyles,
                                                            fontSize:"1.4vh",
                                                            color:"#000"
                                                        }),
                                                        singleValue:(baseStyles,state)=>({
                                                            ...baseStyles,
                                                            color:"#fff",
                                                            alignSelf:"center",
                                                        })
                                                    }}
                                                />
                                            </div>
                                            :
                                            null
                                        }
                                        <ResponsiveChartContainer
                                        disableAxisListener={false}
                                        xAxis={[
                                            { 
                                                data: ['0:00','1:00','2:00','3:00','4:00','5:00','6:00','7:00','8:00','9:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'],
                                                scaleType: "band",
                                                id: 'x-axis-id',
                                                valueFormatter: (value) => value.toString()
                                            }
                                        ]}
                                        series={[
                                            {
                                                type: "line",
                                                data: graphData.activityAnswer?graphData.activityAnswer:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
                                                showMark: false,
                                                label: "Answer"
                                            },
                                            {
                                                type: "line",
                                                data: graphData.activityNoAnswer?graphData.activityNoAnswer:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
                                                showMark: false,
                                                label: "No Answer"
                                            },
                                        ]}
                                        colors={[
                                            '#24D6A3',
                                            '#FF4861'
                                        ]}
                                        sx={{
                                            "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                                                strokeWidth: 0,
                                                fill: "#fff",
                                                fontFamily: '"Ubuntu",sans-serif',
                                            },
                                            "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                                                fontFamily: "'Ubuntu',sans-serif",
                                            },
                                            "& .MuiChartsTooltip-root": {
                                                fontFamily: "'Ubuntu',sans-serif",
                                                fill:"#000",
                                                color:"#fff"
                                            },
                                            "& .MuiChartsAxis-tick ": {
                                                stroke: "#fff",
                                                strokeWidth:0
                                            },
                                            '& .MuiLineElement-root':{
                                                strokeWidth:2
                                            },
                                            "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                                                strokeWidth: 1,
                                                fill: "#fff",
                                                fontFamily: "'Ubuntu',sans-serif",
                                                transform:'scale(85%)', 
                                            },
                                            "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                                                stroke: "#fff",
                                                strokeWidth: 1
                                            },
                                            "& .MuiLineHighlightElement-root": {
                                                stroke: "#fff",
                                                strokeWidth: 1
                                            },
                                            // leftAxis Line Styles
                                            "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                                                stroke: "#fff",
                                                strokeWidth: 1
                                            },
                                            "& .MuiChartsAxis-left": {
                                                stroke: "#fff",
                                                display:"none"
                                            }
                                        }}>
                                            <ChartsTooltip />
                                            <LinePlot />
                                            <ChartsYAxis />
                                            <ChartsXAxis position="bottom" axisId="x-axis-id" />
                                        </ResponsiveChartContainer>
                                    </div>
                                    <div className={styles.pie_labels}>
                                        <div className={styles.labels}>
                                            {/* <span className={styles.timeframe}>{timeframe}</span>
                                            <span className={styles.incall}>InCall: {0}</span> */}
                                            
                                            <span className={styles.answer}>Answer: {graphData.activityAnswer?.reduce((a,b)=>a+b,0)}</span>
                                            <span className={styles.noanswer}>NoAnswer: {graphData.activityNoAnswer?.reduce((a,b)=>a+b,0)}</span>
                                            <span className={styles.acd}>ACD: {parseFloat(graphData.acd).toFixed(0)} sec.</span>
                                        </div>
                                        <div className={styles.pie}>
                                        <span className={styles.title}>Hangup statistics</span>
                                        <PieChart
                                            series={[
                                                {
                                                    type: "pie",
                                                    data: [
                                                        { id: 0, value: graphData.hangupStats.server, label: "Server" },
                                                        { id: 2, value: graphData.hangupStats.manager, label: "Manager" },
                                                        { id: 3, value: graphData.hangupStats.client, label: "Client" },
                                                        { id: 4, value: graphData.hangupStats.processing, label: "Processing" },
                                                    ],
                                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                                    innerRadius: "60%", // 25% от меньшей стороны контейнера
                                                    outerRadius: "90%", // 40% от меньшей стороны контейнера
                                                    paddingAngle: 5,
                                                    cornerRadius: 5,
                                                    startAngle: 60,
                                                    endAngle: -220,
                                                    cx: "50%", // Центр в 50% от ширины контейнера
                                                    cy: "52%",  // Центр в 50% от высоты контейнера,
                                                }
                                            ]}
                                            colors={["#FF4861", "#3EA3FC", "#24D6A3","#999999"]}
                                            sx={{
                                                width: '20vw',
                                                height: '100%',
                                                fontSize: "1.2vh",
                                                fontFamily: "'Ubuntu', sans-serif",
                                                '& .MuiChartsLegend-root':{
                                                    padding: 0,
                                                    margin: 0
                                                },
                                                '& .MuiChartsLegend-series': {
                                                    'text':{
                                                        fontSize: '1.5vh !important', // Уменьшите размер шрифта легенды
                                                        padding: '0px !important', // Уменьшите внутренние отступы
                                                        margin: '0', // Уберите внешние отступы
                                                        color:"#d3d3d2 !imporant"
                                                    },
                                                    lineHeight: '1.2 !important', // Сократите высоту строки
                                                },
                                                '.MuiChartsLegend-mark':{
                                                    width:"1vw",
                                                    height:"2vh"
                                                },
                                                // '.MuiChartsLegend-series': {
                                                //     margin: '0 2vw 0 0' // Отступ сверху
                                                // }
                                                
                                            }}
                                        />
                                        </div>
                                    </div>
                            </div>
                            <div className={styles.row2}>
                                <div className={styles.table}>
                                    <MaterialReactTable table={table} layoutMode={"grid"} />
                                </div>
                                <div className={styles.pie}>
                                    <span className={styles.title}>Answer Rate</span>
                                    <div style={{width:"20vw",height:"inherit"}}>
                                    <Gauge
                                        value={parseFloat(graphData.ar*100).toFixed(0)}
                                        startAngle={-210}
                                        endAngle={150}
                                        cornerRadius={"45%"}
                                        cx={"50%"}
                                        cy={"55%"}
                                        innerRadius= "75%"
                                        outerRadius= "85%"
                                        sx={{
                                            'text':{
                                                fill:"#b47fe9"
                                            },
                                            [`& .${gaugeClasses.valueText}`]: {
                                                fontSize: "3vh",
                                                transform: 'translate(0px, 0px)',
                                                color:"#b47fe9",
                                            },
                                            [`& .${gaugeClasses.valueArc}`]:{
                                                fill:"#b47fe9",
                                                borderRadius:"5%"
                                            },
                                            [`& .${gaugeClasses.referenceArc}`]:{
                                                fill:"#fff"
                                            }
                                        }}
                                        text={
                                            ({ value, valueMax }) => `${value}%`
                                        }
                                    />
                                    </div>
                                    </div>
                            </div>
                        </div>
                        :
                        <div className={styles.page_outlet}>
                            <div className={styles.row1}>
                                    <div className={styles.graph}>
                                        <span className={styles.title}>Agents activity</span>
                                        {
                                            JSON.parse(secureLocalStorage.getItem('user')).login === "TestingGod" ?
                                            <div style={{width:"10vw", position:"absolute", top:'1vh',right:"0.5vw",zIndex:10}}>
                                                <Select 
                                                    options={options}
                                                    value={{
                                                        label:options?.find((val)=>val.value==companyId).label,
                                                        value:options?.find((val)=>val.value==companyId).value
                                                    }}
                                                    onChange={(e)=>setCompanyId(e.value)}
                                                    styles={{
                                                        input: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            minHeight:"1.8vh !important;",
                                                            height:"1.8vh",
                                                            width:"100%",
                                                            padding:"0px",
                                                            color:"#fff",
                                                        }),
                                                        container: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            minHeight:"1.8vh !important;",
                                                            height:"2.5vh",
                                                            width:"100%",
                                                            borderColor: state.isFocused ? '#3EA3FC' : "#3EA3FC;",
                                                            borderRadius: "0.5vh",
                                                            padding:"0px",
                                                        }),
                                                        valueContainer: (baseStyles,state) =>({
                                                            ...baseStyles,
                                                            minHeight:"1.8vh !important;",
                                                            height:"2.5vh",
                                                            fontSize:"1.4vh",
                                                            marginBottom:"20%",
                                                            paddingBottom:"0.4vh"
                                                        }),
                                                        indicatorsContainer: (baseStyles,state) =>({
                                                            ...baseStyles,
                                                            minHeight:"1.8vh !important;",
                                                            height:"2.5vh",
                                                            paddingTop:"0.2vh"
                                                        }),
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            minHeight:"1.8vh !important;",
                                                            height:"3vh",
                                                            background:"#2A2A31",
                                                            fontSize:"1.4vh",
                                                            color:"#fff",
                                                            borderRadius: "0.5vh",
                                                            borderColor: state.isFocused ? '#3EA3FC' : "#3EA3FC",
                                                            "&:hover": {
                                                                borderColor: 'rgba(62, 163, 252, 0.212)'
                                                            },
                                                            boxShadow:"0px",
                                                            padding:"0px",
                                                            paddingLeft:"0.4vw",
                                                        }),
                                                        indicatorSeparator: (baseStyles, state) => ({
                                                            dislay:"none"
                                                        }),
                                                        placeholder:(baseStyles, state) => ({
                                                            ...baseStyles,
                                                            color:"#fff",
                                                        }),
                                                        dropdownIndicator:(baseStyles, state) => ({
                                                            ...baseStyles,
                                                            color:"#fff",
                                                            "svg":{
                                                                height:"2vh",
                                                                width:"auto"
                                                            },
                                                            paddingRight:"0.8vw"
                                                        }),
                                                        menuList:(baseStyles,state)=>({
                                                            ...baseStyles,
                                                            fontSize:"1.4vh",
                                                            color:"#000"
                                                        }),
                                                        singleValue:(baseStyles,state)=>({
                                                            ...baseStyles,
                                                            color:"#fff",
                                                            alignSelf:"center",
                                                        })
                                                    }}
                                                />
                                            </div>
                                            :
                                            null
                                        }
                                        <ResponsiveChartContainer
                                        disableAxisListener={false}
                                        xAxis={[
                                            { 
                                                data: timeframe=="week"?
                                                ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]
                                                : graphData.last30?graphData.last30:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
                                                scaleType: "band",
                                                id: 'x-axis-id',
                                                valueFormatter: (value) => value.toString()
                                            }
                                        ]}
                                        series={[
                                            {
                                                type: "line",
                                                data: timeframe=="week"
                                                ?
                                                graphData?.activityAnswerWeek?graphData?.activityAnswerWeek:[0,0,0,0,0,0,0]
                                                :
                                                graphData?.activityAnswerMonth?graphData?.activityAnswerMonth:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
                                                showMark: false,
                                                label: "Answer"
                                            },
                                            {
                                                type: "line",
                                                data: timeframe=="week"
                                                ?
                                                graphData?.activityNoAnswerWeek?graphData?.activityNoAnswerWeek:[0,0,0,0,0,0,0]
                                                :
                                                graphData?.activityNoAnswerMonth?graphData?.activityNoAnswerMonth:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
                                                showMark: false,
                                                label: "No Answer"
                                            },
                                        ]}
                                        colors={[
                                            '#24D6A3',
                                            '#FF4861'
                                        ]}
                                        sx={{
                                            "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                                                strokeWidth: 0,
                                                fill: "#fff",
                                                fontFamily: '"Ubuntu",sans-serif',
                                            },
                                            "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                                                fontFamily: "'Ubuntu',sans-serif",
                                            },
                                            "& .MuiChartsTooltip-root": {
                                                fontFamily: "'Ubuntu',sans-serif",
                                                fill:"#000",
                                                color:"#fff"
                                            },
                                            "& .MuiChartsAxis-tick ": {
                                                stroke: "#fff",
                                                strokeWidth:0
                                            },
                                            '& .MuiLineElement-root':{
                                                strokeWidth:2
                                            },
                                            "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                                                strokeWidth: 1,
                                                fill: "#fff",
                                                fontFamily: "'Ubuntu',sans-serif",
                                                transform:'scale(85%)', 
                                            },
                                            "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                                                stroke: "#fff",
                                                strokeWidth: 1
                                            },
                                            "& .MuiLineHighlightElement-root": {
                                                stroke: "#fff",
                                                strokeWidth: 1
                                            },
                                            // leftAxis Line Styles
                                            "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                                                stroke: "#fff",
                                                strokeWidth: 1
                                            },
                                            "& .MuiChartsAxis-left": {
                                                stroke: "#fff",
                                                display:"none"
                                            }
                                        }}>
                                            <ChartsTooltip />
                                            <LinePlot />
                                            <ChartsYAxis />
                                            <ChartsXAxis position="bottom" axisId="x-axis-id" />
                                        </ResponsiveChartContainer>
                                    </div>
                                    <div className={styles.pie_labels}>
                                        <div className={styles.labels_week}>
                                            <span className={styles.answer}>{
                                                timeframe == "week"
                                                ?
                                            graphData.activityAnswerWeek?.reduce((a,b)=>a+b,0) + graphData.activityNoAnswerWeek?.reduce((a,b)=>a+b,0)
                                            :
                                            graphData.activityAnswerMonth?.reduce((a,b)=>a+b,0) + graphData.activityNoAnswerMonth?.reduce((a,b)=>a+b,0)
                                            } calls</span>
                                            <span className={styles.acd}>ACD: {parseFloat(graphData.acd).toFixed(0)} sec.</span>
                                            <span className={styles.timeframe}>AR: {parseFloat(graphData.ar*100).toFixed(0)}%</span>
                                            {/* <LinearProgressWithLabel value={parseFloat(graphData.ar * 100).toFixed(0)} /> */}
                                        </div>
                                        <div className={styles.sparkline}>
                                        <span className={styles.title}>{timeframe=="week"?'Weekly AR':'Monthly AR'}</span>
                                        <div className={styles.spark}>
                                            <SparkLineChart
                                                plotType='bar'
                                                data= {timeframe=="week"
                                                ?
                                                graphData?.weeklyAR?graphData?.weeklyAR?.reverse()?.map(val=>val?.asr):[0,0,0,0,0,0]
                                                :
                                                graphData?.monthlyAR?graphData?.monthlyAR?.reverse()?.map(val=>val.asr):[0,0,0,0]
                                                }
                                                colors={["#24D6A3"]}
                                                showHighlight={true}
                                                showTooltip={true}
                                                disableAxisListener={false}
                                                valueFormatter= {(value) => value?.toString()}
                                                xAxis={
                                                    { 
                                                        data: timeframe=="week"
                                                        ?
                                                        ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
                                                        :
                                                        ["Week 1", "Week 2", "Week 3", "Week 4"],
                                                        scaleType:'band',
                                                    }
                                                }
                                                sx={{
                                                    width: '20vw',
                                                    height: '30vh',
                                                    fontSize: "1.2vh",
                                                    fontFamily: "'Ubuntu', sans-serif",
                                                
                                                    '& .MuiChartsLegend-root':{
                                                        padding: 0,
                                                        margin: 0
                                                    },
                                                    '& .MuiChartsLegend-series': {
                                                        'text':{
                                                            fontSize: '1.5vh !important', // Уменьшите размер шрифта легенды
                                                            padding: '0px !important', // Уменьшите внутренние отступы
                                                            margin: '0', // Уберите внешние отступы
                                                            color:"#d3d3d2 !imporant"
                                                        },
                                                        lineHeight: '1.2 !important', // Сократите высоту строки
                                                    },
                                                    '.MuiChartsLegend-mark':{
                                                        width:"1vw",
                                                        height:"2vh"
                                                    },
                                                    // '.MuiChartsLegend-series': {
                                                    //     margin: '0 2vw 0 0' // Отступ сверху
                                                    // }
                                                    
                                                }}
                                            />
                                        </div>
                                        </div>
                                    </div>
                            </div>
                            <div className={styles.row2}>
                                <div className={styles.table}>
                                    <MaterialReactTable table={table} layoutMode={"grid"} />
                                </div>
                                <div className={styles.pie}>
                                <span className={styles.title}>Hangup statistics</span>
                                <PieChart
                                    series={[
                                        {
                                            type: "pie",
                                            data: [
                                                { id: 0, value: graphData.hangupStats.server, label: "Server" },
                                                { id: 2, value: graphData.hangupStats.manager, label: "Manager" },
                                                { id: 3, value: graphData.hangupStats.client, label: "Client" },
                                                { id: 4, value: graphData.hangupStats.processing, label: "Processing" },
                                            ],
                                            highlightScope: { faded: 'global', highlighted: 'item' },
                                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                            innerRadius: "60%", // 25% от меньшей стороны контейнера
                                            outerRadius: "90%", // 40% от меньшей стороны контейнера
                                            paddingAngle: 5,
                                            cornerRadius: 5,
                                            startAngle: 60,
                                            endAngle: -220,
                                            cx: "50%", // Центр в 50% от ширины контейнера
                                            cy: "52%",  // Центр в 50% от высоты контейнера,
                                        }
                                    ]}
                                    colors={["#FF4861", "#3EA3FC", "#24D6A3","#999999"]}
                                    sx={{
                                        width: '20vw',
                                        height: '100%',
                                        fontSize: "1.2vh",
                                        fontFamily: "'Ubuntu', sans-serif",
                                        '& .MuiChartsLegend-root':{
                                            padding: 0,
                                            margin: 0
                                        },
                                        '& .MuiChartsLegend-series': {
                                            'text':{
                                                fontSize: '1.5vh !important', // Уменьшите размер шрифта легенды
                                                padding: '0px !important', // Уменьшите внутренние отступы
                                                margin: '0', // Уберите внешние отступы
                                                color:"#d3d3d2 !imporant"
                                            },
                                            lineHeight: '1.2 !important', // Сократите высоту строки
                                        },
                                        '.MuiChartsLegend-mark':{
                                            width:"1vw",
                                            height:"2vh"
                                        },
                                        // '.MuiChartsLegend-series': {
                                        //     margin: '0 2vw 0 0' // Отступ сверху
                                        // }
                                        
                                    }}
                                />
                                </div>
                            </div>
                        </div>
                }
                </div>
            </div>
        </ThemeProvider>
    )
}
