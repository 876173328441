import React, { useState, useRef, useEffect } from 'react';
import styles from './SideMenuRebrening.module.scss';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import { Link, useLocation } from 'react-router-dom';
import DeviceUnknownRoundedIcon from '@mui/icons-material/DeviceUnknownRounded';
export default function SideMenuRebrending() {
  //#region dashboard menu
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const options = ['Today', 'Week', 'Month'];
  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const [selectedOption, setSelectedOption] = useState(options[0]);
  useEffect(() => {
    const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
    }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
    document.removeEventListener('mousedown', handleClickOutside);
    };
}, [ref]);
  //#endregion
  const [activeMenu,setActiveMenu] = useState('online');
  const location = useLocation();
  useEffect(()=>{

  },[]);
  return (
    <div className={styles.menu}>
      <div onClick={()=>setActiveMenu("history")} className={location.pathname.includes("history")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/history`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
          <WorkHistoryOutlinedIcon className={styles.icon} />
          <span className={styles.label}>history</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("brand")} className={location.pathname.includes("company")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/company/users`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
          <AccountBalanceOutlinedIcon className={styles.icon} />
          <span className={styles.label}>brand</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("online")} className={location.pathname.includes("online")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/onlinecalls`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
        <HeadsetMicOutlinedIcon className={styles.icon} />
        <span className={styles.label}>online calls</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("analytics")} className={location.pathname.includes("analytics")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/analytics`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
            <QueryStatsOutlinedIcon className={styles.icon} />
            <span className={styles.label}>analytics</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("transactions")} className={location.pathname.includes("transactions")?styles.menu_item_active:styles.menu_item} style={{width:"fit-content"}}>
        <Link to={`/transactions`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
            <DeviceUnknownRoundedIcon className={styles.icon} />
            <span className={styles.label}>Check Numbers</span>
        </Link>
      </div>
    </div>
  )
}
