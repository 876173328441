import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useScript from '../Hooks/UseScript';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import "../css/routing.css"


export default function BrandRouting() {
    const { t } = useTranslation();
    const {pathname} = useLocation()
const [brands, setBrands] = useState([]);
const [items, setItems] = useState({
    companies: [],
    teams: [],
    departments: [],
    offices: []
});
// const active = useRef(document.getElementById('dashboard'));
// const activeBrand = useRef(document.getElementById('dashboard'));
const active = useRef(document.getElementById('dashboard'));
const active1 = useRef(document.getElementById('dashboard'));
const activeBrand = useRef(document.getElementById('dashboard'));
const companies = useRef('menu-item');
const offices = useRef('menu-item');
const openCompanies =event=> {
    active.current = event.currentTarget;
    event.currentTarget.classList.toggle('open');
    if(event.currentTarget.id === "companies"){
        if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
        if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
        if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
        if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
        if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
        if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
        if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
    }
    else if(event.currentTarget.id === "offices"){
        if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
        if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
        if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
        if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
        if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
        if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
        if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
    }
    else if(event.currentTarget.id === "departments"){
        if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
        if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
        if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
        if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
        if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
        if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
        if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
    }
    else if(event.currentTarget.id === "teams"){
        if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
        if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
        if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
        if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
        if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
        if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
        if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
    }
    else if(event.currentTarget.id === "journal"){
        if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
        if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
        if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
        if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
        if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
        if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
        if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
    }
    else if(event.currentTarget.id === "billing"){
        if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
        if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
        if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
        if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
        if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
        if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
        if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
    }
    else if(event.currentTarget.id === "dashboard"){
        if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
        if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
        if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
        if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
        if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
        if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
        if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
    }
    // else if(event.currentTarget.id === "brandmanage"){
    //     if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
    //     if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
    //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
    //     if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
    //     if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
    //     if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
    //     if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
    // }
    
}
const toggleActive =event=> {
    active.current = event.currentTarget;
    event.currentTarget.classList.toggle('active');
}
const toggleActive1 =event=> {
    active1.current = event.currentTarget;
    event.currentTarget.classList.toggle('active');
}
const toggleActiveBrand =event=> {
    activeBrand.current = event.currentTarget;
    event.currentTarget.classList.toggle('active');
}
useEffect(()=>{
    axios.get("https://www.pbxdiamondcall.com:8081/companies")
        .then((res)=>{
            //console.log(res);
            setBrands(res.data);
            secureLocalStorage.setItem('brands', res.data)
            res.data.forEach((val,ind)=>{
                if(val.Id == JSON.parse(secureLocalStorage.getItem("user")).company_id) secureLocalStorage.setItem("company_title",val.Title);
            });
        })
        .catch((err)=>{
            console.log(err);
        })
    axios.get("https://www.pbxdiamondcall.com:8081/items")
    .then((res)=>{
        console.log(res);
        setItems(res.data);
        //var options = [];
        // res.data.companies.forEach(element => {
        //     options.push({
        //         value:element.Id,
        //         label:element.Title
        //     })
        // });
        //secureLocalStorage.setItem("companiesarray",options);
    })
    .catch((err)=>console.log(err));
        //console.log();
    //console.log(JSON.parse(secureLocalStorage.getItem('user')).role_id);
    $(document.body).on('click', (e)=>{
        if(e.target.textContent !== t("offices")
        && e.target.textContent !== t("departments")
        && e.target.textContent !== t("teams")
        && e.target.textContent !== t("agents") 
        && e.target.textContent !== t("journal")
        && e.target.textContent !== t("billing")
        && e.target.textContent !== t("dashboard")
        && e.target.textContent !== t("brandmanage")
        ){
            if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
            if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
            if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
            if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
            if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
            if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
            if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
            if(document.getElementById("brandmanage")!=null)document.getElementById("brandmanage").classList.remove('open');
        }
        //console.log(e.target.textContent)
    });
    return()=>{
        $('body').off('click')
    }
    

}, [])

const nav = useNavigate();
function logoutHandle(){
    secureLocalStorage.setItem("token",null);
    secureLocalStorage.setItem("logged_user",false);
    //secureLocalStorage.setItem("user",null);
    nav("/login");
}

const renderMenuItems = () =>{
    let isrender = [
        false,
        false,
        false,
        false,
        false,
    ];
    let role = JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem('user')).role_id:6;
    if(role === 1) isrender = [true,true,true,true,true,true,true]
    else if(role===2) isrender = [true,true,true,false,true,true,true]
    else if(role===3) isrender = [true,true,false,false,true,false,true] 
    else if(role===4) isrender = [true,true,false,false,true,false,true] 
    else if(role===5) isrender = [false,true,false,false,true,false,true]
    else if(role===6) isrender = [false,false,false,false,false,false,false]
    else if(role===8) isrender = [false,true,false,false,true,false,true]
    else if(role===7) isrender = [true,true,true,true,true,true,true]
    
    //console.log(role+"render:"+isrender);
  return (
    <div className='container-xxl flex-grow-1 container-p-y' >

        <div className="nav-align-top">
        <ul className="nav nav-pills mb-3" role="tablist">
            <li className="nav-item">
            <button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" 
            data-bs-target="#navs-pills-top-offices" 
            aria-controls="navs-pills-top-offices" aria-selected="true"><b>{t("offices")}</b></button>
            </li>
            <li className="nav-item">
            <button type="button" className="nav-link" role="tab" 
            data-bs-toggle="tab" data-bs-target="#navs-pills-top-departments" 
            aria-controls="navs-pills-top-departments" aria-selected="false"><b>{t("departments")}</b></button>
            </li>
            <li className="nav-item">
            <button type="button" className="nav-link" role="tab" 
            data-bs-toggle="tab" data-bs-target="#navs-pills-top-teams" 
            aria-controls="navs-pills-top-teams" aria-selected="false"><b>{t("teams")}</b></button>
            </li>
            <li className="nav-item">
            <button type="button" className="nav-link" role="tab" 
            data-bs-toggle="tab" data-bs-target="#navs-pills-top-agents" 
            aria-controls="navs-pills-top-agents" aria-selected="false"><b>{t("agents")}</b></button>
            </li>
        </ul>
        <div className="tab-content">
            <div className="tab-pane fade show active" id="navs-pills-top-offices" role="tabpanel">
            <ul className="list-group overflow-auto"  
            style={{
                width:"1350px" , height:'550px', 
                display:"flex", flexWrap:"wrap", overflow:"scroll"
                }}  >
            {
            JSON.parse(secureLocalStorage.getItem("user"))!==null?
            JSON.parse(secureLocalStorage.getItem("user")).role_id===1
            ?  <li className={'menu-item'} 
                id="all" onClick={toggleActiveBrand} style={{listStyleType:"none"}} >
                    <Link to="/old/companies" className="menu-link" style={{fontSize:"24px", margin:5}}>
                <i className='bx bxs-briefcase menu-icon' style={{listStyle:"none"}}></i>
                    <div data-i18n="All" style={{color:"#9b9cc3"}}><b>Companies:{t("companiesAll")}</b></div>
                    </Link>
                </li>
            :null
            :null
            }
            {
            brands.length > 0 
            ? 
            brands.map((val,ind)=>{
                if(val.Id == JSON.parse(secureLocalStorage.getItem("user")).company_id) secureLocalStorage.setItem("company_title",val.Title);
                if(JSON.parse(secureLocalStorage.getItem("user"))!==null?
                JSON.parse(secureLocalStorage.getItem("user")).role_id===1:false)
                return  <li className={'menu-item'} key={ind}>
                <Link to={`/old/companies/${val.Id}/offices/`} className="menu-link" style={{fontSize:"24px", margin:5}}>
                    <i className='bx bxs-briefcase menu-icon'></i>
                    <div data-i18n={val.Title}  style={{color:"#9b9cc3"}}><b>{val.Title}</b></div>
                </Link>
                </li>
                else 
                return JSON.parse(secureLocalStorage.getItem("user")).company_id !== val.Id
                ? null
                : <li className={'menu-item'}>
                <Link to={`/old/companies/${val.Id}/offices/`}  key={ind} className="menu-link" style={{fontSize:"24px", margin:5}}>
                <i className='bx bxs-briefcase menu-icon'></i>
                <div data-i18n={val.Title}  style={{color:"#9b9cc3"}}><b>{val.Title}</b></div>
                </Link>
            </li>
            })
            :
            null
            }
            
        </ul>
            </div>
            <div className="tab-pane fade" id="navs-pills-top-departments" role="tabpanel">
            <ul className="list-group overflow-auto"  
            style={{
                width:"1350px" , height:'550px', 
                display:"flex", flexWrap:"wrap", overflow:"scroll"
                }}  >
            {
            items.offices.length > 0 
            ? 
            items.offices.map((val,ind)=>{
                if(JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem("user")).role_id===1:false)
                return <li className={activeBrand.current!==null?activeBrand.current.id===val.Title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.title} onClick={toggleActiveBrand} >
                <Link to={`/old/companies/${val.company_id}/offices/${val.id}/departments`} className="menu-link" style={{fontSize:"24px", margin:5}}>
                    <i className='bx bxs-briefcase menu-icon'></i>
                    <div data-i18n={val.title} style={{color:"#9b9cc3"}}><b>[{val.Title}]{val.title}</b></div>
                </Link>
                </li>
                else 
                return JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem("user")).company_id !== val.company_id
                ? null
                : <li className={activeBrand.current!==null?activeBrand.current.id===val.title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.title} onClick={toggleActiveBrand}>
                <Link to={`/old/companies/${val.company_id}/offices/${val.id}/departments`} className="menu-link" style={{fontSize:"24px", margin:5}}>
                <i className='bx bxs-briefcase menu-icon'></i>
                <div data-i18n={val.title} style={{color:"#9b9cc3"}}><b>[{val.Title}]{val.title}</b></div>
                </Link>
            </li>
            :null
            })
            :
            null
            }
            
        </ul>
            </div>
            <div className="tab-pane fade" id="navs-pills-top-teams" role="tabpanel">
            <ul className="list-group overflow-auto"  
            style={{
                width:"1350px" , height:'550px', 
                display:"flex", flexWrap:"wrap", overflow:"scroll"
                }}  >
                {
                items.departments.length > 0 
                ? 
                items.departments.map((val,ind)=>{
                    if(JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem("user")).role_id===1:false)
                    return <li className={activeBrand.current!==null?activeBrand.current.id===val.title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.title} onClick={toggleActiveBrand} >
                    <Link to={`/old/companies/${val.company_id}/offices/${val.office_id}/departments/${val.id}/teams`} className="menu-link"  style={{fontSize:"24px", margin:5}}>
                        <i className='bx bxs-briefcase menu-icon'></i>
                        <div data-i18n={val.title} style={{color:"#9b9cc3"}}><b>[{val.Title}]{val.title}</b></div>
                    </Link>
                    </li>
                    else 
                    return JSON.parse(secureLocalStorage.getItem("user")).company_id !== val.company_id
                    ? null
                    : <li className={activeBrand.current!==null?activeBrand.current.id===val.title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.title} onClick={toggleActiveBrand}>
                    <Link to={`/old/companies/${val.company_id}/offices/${val.office_id}/departments/${val.id}/teams`} className="menu-link"  style={{fontSize:"24px", margin:5}}>
                    <i className='bx bxs-briefcase menu-icon'></i>
                    <div data-i18n={val.title} style={{color:"#9b9cc3"}}><b>[{val.Title}]{val.title}</b></div>
                    </Link>
                </li>
                })
                :
                null
                }
                
            </ul>
            </div>
            <div className="tab-pane fade" id="navs-pills-top-agents" role="tabpanel">
            <ul className="list-group overflow-auto"  
            style={{
                width:"1350px" , height:'550px', 
                display:"flex", flexWrap:"wrap", overflow:"scroll"
                }}  >
            {
            items.teams.length > 0 
            ? 
            items.teams.map((val,ind)=>{
                if(JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem("user")).role_id===1:false)
                return <li className='menu-item' key={ind} id={val.title} onClick={toggleActiveBrand} >
                <Link  style={{fontSize:"15px"}} to={`/old/companies/${val.company_id}/offices/${val.office_id}/departments/${val.department_id}/teams/${val.id}/agents`} className="menu-link">
                    <i className='bx bxs-briefcase menu-icon'></i>
                    <div data-i18n={val.title} style={{color:"#9b9cc3"}}><b>[{val.Title}]{val.title}</b></div>
                </Link>
                </li>
                else 
                return JSON.parse(secureLocalStorage.getItem("user")).company_id !== val.company_id
                ? null
                : <li className='menu-item' key={ind} id={val.title} onClick={toggleActiveBrand}>
                <Link  style={{fontSize:"15px"}} to={`/old/companies/${val.company_id}/offices/${val.office_id}/departments/${val.department_id}/teams/${val.id}/agents`} className="menu-link">
                <i className='bx bxs-briefcase menu-icon'></i>
                <div data-i18n={val.title} style={{color:"#9b9cc3"}}><b>[{val.Title}]{val.title}</b></div>
                </Link>
            </li>
            })
            :
            null
            }
            
        </ul>
            </div>
        </div>
        </div>
    </div>
  )
}

return(
    <>
    {renderMenuItems()}
    </>
)

}