import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

export default function Error() {
  const {t,i18n} = useTranslation();
  return (
    <div className="container-xxl container-p-y">
        <div className="misc-wrapper">
            <h2 className="mb-2 mx-2">{t("errorNotFound")}</h2>
            <p className="mb-4 mx-2">{t("errorOops")}</p>
            <Link to="/company/dashboard/today" className="btn btn-primary">{t("errorBack")}</Link>
            <div className="mt-3">
            <img src="../../assets/img/illustrations/page-misc-error-light.png" alt="page-misc-error-light" width={500} className="img-fluid" data-app-dark-img="illustrations/page-misc-error-dark.png" data-app-light-img="illustrations/page-misc-error-light.png" />
            </div>
        </div>
    </div>
  )
}
