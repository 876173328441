import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';
import ReactApexChart from 'react-apexcharts';
import ReactSpeedometer from "react-d3-speedometer"
import apexchart from 'apexcharts';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Countries from '../resources/countries.json';
import moment from 'moment/moment';

const YourComponent = () => {
    const { id } = useParams();
    const [prefixes, setPrefixes] = useState([]);
    const [company, setCompany] = useState();
    const { t, i18n } = useTranslation();
    const [number,setNumber]= useState("");
    const [incall, setInCall] = useState(0);
    const [callsState, setCallsState] = useState("Today");
    const [answer, SetAnswer] = useState(0);
    const [noanswer, SetNoAnswer] = useState(0);
    const [answerMonth, SetAnswerMonth] = useState(0);
    const [noanswerMonth, SetNoAnswerMonth] = useState(0);
    const [answerToday, SetAnswerToday] = useState(0);
    const [noanswerToday, SetNoAnswerToday] = useState(0);
    const [answerWeek, SetAnswerWeek] = useState(0);
    const [noanswerWeek, SetNoAnswerWeek] = useState(0);
    const [count, SetCount] = useState(0);
    const [activeOperator,setActiveOperator] = useState(null);
    const testweek = useRef(0);
    const testmonth = useRef(0);
    const testtoday = useRef(0);
    const testall = useRef(0);
    const [countWeek, SetCountWeek] = useState(0);
    const [countMonth, SetCountMonth] = useState(0);
    const [countToday, SetCountToday] = useState(0);
    const totalansw = useRef(0);
    const totalnoansw = useRef(0);
    const total = useRef(0);
    const [isLoading , setIsLoading] = useState(true)
    const [data, SetData] = useState({
        count: 0,
        answer: 0,
        noanswer: 0,
        avg: 0,
        incall: 0,
        activityarr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        noanswarr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        answarr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        aweek: 0,
        noaweek: 0,
        amonth: 0,
        noamonth: 0,
        countries: [],
        answerweek: [0,0,0,0,0,0,0],
        noanswerweek: [0,0,0,0,0,0,0],
        categories: [],
    })
    const [state, setState] = useState({
        series: [
            {
                name: "Success",
                data: data.noanswerweek
            },
            {
                name: "Fail",
                data: data.answerweek
            }
        ],
        options: {
            chart: {
                foreColor: '#9b9dc1',
                type: 'area',
                id:"weekarea",
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false,
                style: {
                    fontFamily: 'Ubuntu, sans-serif',
                    fontSize: '15px'
                },
                formatter: function (val) {
                    return Math.floor(val) + "%"
                }
            },
            
            legend: {
                show: true,
                position: 'bottom',
                height:30
            },
            // fill: {
            //     type: "gradient",
            //     gradient: {
            //     shadeIntensity: 1,
            //     opacityFrom: 0.7,
            //     opacityTo: 0.9,
            //     stops: [0, 90, 100]
            //     }
            // },
            grid: {
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    },
                    min: -100,
                    max: 100
                },
                padding: {
                    top: 0,
                    right: 25,
                    bottom: 0,
                    left: 25
                }
            },
            colors: ['#00e396', '#fe942b'],
            xaxis: {
                type: 'category',
                categories: [1,2,3,4,5,6,7],
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: true
                }
            },
            stroke: {
                curve: 'smooth',
            },
            toolbar: {
                show: false
            },
            dropShadow: {
                enabled: true,
                top: 7,
                left: 5,
                blur: 2,
                color: '#00e396',
                opacity: 0.35
            },
            yaxis: {
                show: false
            },
        },
        seriesCallsWeek: [answerWeek === null ? 0 : answerWeek,
            noanswerWeek ? 0 : noanswerWeek],
        optionsCallsWeek: {
            chart: {
                foreColor: '#9b9dc1',
                id: "callsWeek"
            },
            labels: [t("dashboardAnswer"), t("dashboardNoAnswer")],
            dataLabels: {
                enabled: true,
                style: {
                    fontFamily: 'Ubuntu, sans-serif',
                    fontSize: '12px'
                },
                formatter: function (val) {
                    return Math.floor(val) + "%"
                }
            },
            title:{
                text:"Week",
                align:'center'
            },
            stroke: {
                curve: 'straight',
                lineCap: 'round',
                colors: '#2b2c40',
                width: 1.5,
                dashArray: 0,
            },
            legend: {
                show: false,
                position: 'bottom'
            },
            colors: ['#00e396', '#fe942b'],
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                offsetY: 10,
                            },
                            value:{},
                            total: {
                                show: true,
                                showAlways: true,
                                fontSize: '22px',
                                fontFamily: 'Ubuntu, sans-serif',
                                fontWeight: 600,
                                label: ``,
                                color: 'rgb(163, 165, 202)',                            
                                formatter: function (w) {
                                    return '';
                                }
                            }
                        }
                    }
                }
            }
        },
        
        seriesCallsMonth: [answerMonth === null ? 0 : answerMonth,
            noanswerMonth ? 0 : noanswerMonth],
        optionsCallsMonth: {
            chart: {
                foreColor: '#9b9dc1',
                id: "callsMonth"
            },
            labels: [t("dashboardAnswer"), t("dashboardNoAnswer")],
            dataLabels: {
                enabled: true,
                style: {
                    fontFamily: 'Ubuntu, sans-serif',
                    fontSize: '12px'
                },
                formatter: function (val) {
                    return Math.floor(val) + "%"
                }
            },
            title:{
                text:"Month",
                align:'center'
            },
            stroke: {
                curve: 'straight',
                lineCap: 'round',
                colors: '#2b2c40',
                width: 1.5,
                dashArray: 0,
            },
            legend: {
                show: false,
                position: 'bottom'
            },
            colors: ['#00e396', '#fe942b'],
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                offsetY: 10,
                            },
                            value:{},
                            total: {
                                show: true,
                                showAlways: true,
                                fontSize: '22px',
                                fontFamily: 'Ubuntu, sans-serif',
                                fontWeight: 600,
                                label: ``,
                                color: 'rgb(163, 165, 202)',                            
                                formatter: function (w) {
                                    return '';
                                }
                            }
                        }
                    }
                }
            }
        },
        optionsASR:{
          
            series: [count > 0 ? Math.floor((answer / count) * 100) : 0, countMonth > 0 ? Math.floor((answerMonth / countMonth) * 100) : 0, countWeek > 0 ? Math.floor((answerWeek / countWeek) * 100) : 0, countToday > 0 ? Math.floor((answerToday / countToday) * 100) : 0],
            options: {
              chart: {
                height: 350,
                type: 'radialBar',
                id:'asr'
              },
              colors:['#7575ff','#7575ff','#7575ff','#7575ff'],
              labels: ['Total', 'Monthly', 'Weekly', 'Today'],
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      fontSize: '22px',
                    },
                    value: {
                      color:'rgb(163, 165, 202)',
                      fontSize: '16px',
                      fontWeight:700
                    },
                    total: {
                      show: true,
                      color:'rgb(163, 165, 202)',
                      label: 'Total',
                      formatter: function (w) {
                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                        return `${count > 0 ? Math.floor((answer / count) * 100) : 0}%`
                      }
                    }
                  }
                }
              },
            }
        },
        seriesCallsToday: [answerToday === null ? 0 : answerToday==0?1:answerToday,
            noanswerToday ? 0 : noanswerToday==0?1:noanswerToday],
        optionsCallsToday: {
            chart: {
                foreColor: '#9b9dc1',
                id: "callsToday"
            },
            noData:{
                text:"No calls today",
                align:"center",
                verticalAlign:"middle",
                style:{
                    fontSize:'20px',
                    fontFamily:"'Ubuntu', sans-serif"
                }
            },
            title:{
                text:"Today",
                align:'center'
            },
            labels: [t("dashboardAnswer"), t("dashboardNoAnswer")],
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px',
                    fontFamily: 'Ubuntu, sans-serif',
                },
                formatter: function (val) {
                    return Math.floor(val) + "%"
                }
            },
            stroke: {
                curve: 'straight',
                lineCap: 'round',
                colors: '#2b2c40',
                width: 1.5,
                dashArray: 0,
            },
            legend: {
                show: false,
                position: 'bottom'
            },
            colors: ['#00e396', '#fe942b'],
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                offsetY: 10,
                            },
                            value:{},
                            total: {
                                show: true,
                                showAlways: true,
                                fontSize: '22px',
                                fontFamily: 'Ubuntu, sans-serif',
                                fontWeight: 600,
                                label: ``,
                                color: 'rgb(163, 165, 202)',                            
                                formatter: function (w) {
                                    return '';
                                }
                            }
                        }
                    }
                }
            }
        },
        optionsSuccessCalls: {
            series: [total.current > 0 ? Math.floor((totalansw.current / totalnoansw.current) * 100) : 0],
            colors: ["#a3a5ca"],
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    hollow: {
                        margin: 0,
                        size: "70%",
                        background: "#2b2c3f"
                    },
                    track: {
                        startAngle: -90,
                        endAngle: 90,
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            blur: 4,
                            opacity: 0.15
                        }
                    },
                    dataLabels: {
                        name: {
                            offsetY: -10,
                            color: "#fff",
                            fontSize: "13px"
                        },
                        value: {
                            color: "#fff",
                            fontSize: "30px",
                            fontWeight: "bold",
                            show: true
                        }
                    }
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: "light",
                    type: "horizontal",
                    gradientToColors: ["#09B109"],
                    stops: [0, 100]
                }
            },
            stroke: {
                lineCap: "round"
            },
            labels: [""]
        },
        optionsSuccessCallsMin: {
            series: [data.avg],
            colors: ["#B1094f"],
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    hollow: {
                        margin: 0,
                        size: "70%",
                        background: "#2b2c3f"
                    },
                    track: {
                        startAngle: -90,
                        endAngle: 90,
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            blur: 4,
                            opacity: 0.15
                        }
                    },
                    dataLabels: {
                        name: {
                            offsetY: -10,
                            color: "#fff",
                            fontSize: "13px"
                        },
                        value: {
                            color: "#fff",
                            fontSize: "30px",
                            fontWeight: "bold",
                            show: true
                        }
                    }
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: "light",
                    type: "horizontal",
                    gradientToColors: ["#6D09B1"],
                    stops: [0, 100]
                }
            },
            stroke: {
                lineCap: "round"
            },
            labels: [""]
        },
        optionsActivity: {
            chart: {
                foreColor: '#60628b',
                toolbar: {
                    show: false
                },
                dropShadow: {
                    enabled: true,
                    top: 7,
                    left: 5,
                    blur: 2,
                    color: '#00e396',
                    opacity: 0.35
                },
                id: "activity"
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#00e396'],
            series: [{
                name: t("dashboardAgentsActivity"),
                data: data.activityarr !== null
                    ? data.activityarr.length === 0
                        ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                        : data.activityarr
                    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
            }
            ],
            stroke: {
                curve: 'smooth'
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    },
                    min: -100,
                    max: 100
                },
                padding: {
                    top: 0,
                    right: 10,
                    bottom: 0,
                    left: 25
                }
            },
            xaxis: {
                // categories: ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                categories: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: true
                }
            },
            yaxis: {
                show: false
            },

        },
        optionsSuccess: {
            series: [
                {
                    name: t("dashboardSuccess"),
                    data: data.answarr !== null ?
                        data.answarr.length === 0
                            ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                            : data.answarr
                        : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                },
                {
                    name: t("dashboardFail"),
                    data: data.noanswarr !== null ?
                        data.noanswarr.length === 0
                            ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                            : data.noanswarr
                        : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                }
            ],
            legend: {
                show: false,
                position: 'bottom'
            },
            plotOptions: {
                bar: {
                    barHeight: '100%',
                    borderRadius: 8,
                    borderRadiusWhenStacked: 'all',
                    columnWidth: 20,
                    horizontal: false
                }
            },
            colors: ['#00e396', '#C73327'],
            chart: {
                foreColor: '#9b9dc1',
                stacked: true,
                toolbar: {
                    show: false
                },
                stackType: 'normal',
                id: "success"
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                colors: ["#2b2c3f"]
            },

            grid: {
                borderColor: "#444662",
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                }
            },
            title: {
                text: t("dashboardSuccessTimeline"),
                style: {
                    fontSize: '25px',
                    fontWeight: 'bold',
                    color: "#a3a5ca"
                }
            },
            xaxis: {
                categories: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: true,

                }
            },
        }
    })
    useEffect(() => {
        SetAnswer(
            data.answer === null
                ? 1
                : data.answer
        );
        SetNoAnswer(
            data.noanswer === null
                ? 1
                : data.noanswer
        );
        SetCount(
            data.count !== null
                ? data.count
                : 1
        );
        testall.current = data.count !== null
        ? data.count
        : 0;
        setState(prevState => {
            return {
                ...prevState, seriesCalls:
                    [
                        data.answer == null
                            ? 0
                            : data.answer,
                        data.noanswer == null
                            ? 0
                            : data.noanswer
                    ]
            }
        });
        SetAnswerWeek(
            data.aweek == null
                ? 0
                : data.aweek
        );
        SetNoAnswerWeek(
            data.noaweek == null
                ? 0
                : data.noaweek
        );
        testweek.current = (data.aweek !== null && data.noaweek !== null)
        ? data.aweek + data.noaweek
        : 0;

        SetCountWeek(
            (data.aweek !== null && data.noaweek !== null)
                ? data.aweek + data.noaweek
                : 0
        );
        setState(prevState => {
            return {
                ...prevState, seriesCallsWeek:
                    [
                        data.aweek == null
                            ? 0
                            : data.aweek,
                        data.noaweek == null
                            ? 0
                            : data.noaweek
                    ]
            }
        });
        SetAnswerMonth(
            data.amonth == null
                ? 0
                : data.amonth
        );
        SetNoAnswerMonth(
            data.noamonth == null
                ? 0
                : data.noamonth
        );
        testmonth.current = (data.amonth !== null && data.noamonth !== null)
        ? data.amonth + data.noamonth
        : 0;
        SetCountMonth(
            (data.amonth !== null && data.noamonth !== null)
                ? data.amonth + data.noamonth
                : 0
        );
        setState(prevState => {
            return {
                ...prevState, seriesCallsMonth:
                    [
                        data.amonth == null
                            ? 0
                            : data.amonth,
                        data.noamonth == null
                            ? 0
                            : data.noamonth
                    ]
            }
        });
        if (data.answarr.length !== 0 && data.noanswarr.length !== 0) {
            totalansw.current = 0;
            totalnoansw.current = 0;
            total.current = 0;
            for (let i = 0; i < data.answarr.length; i++) {
                totalansw.current += data.answarr[i];
                total.current += data.answarr[i];
            }
            for (let i = 0; i < data.noanswarr.length; i++) {
                total.current -= data.noanswarr[i];
                totalnoansw.current -= data.noanswarr[i];
            }
            SetAnswerToday(totalansw.current)
            SetNoAnswerToday(totalnoansw.current)
            SetCountToday(total.current);
            testtoday.current = total.current;
            setState(prevState => {
                return {
                    ...prevState, seriesCallsToday:
                        [
                            totalansw.current == null
                                ? 0
                                : totalansw.current==0?1:totalansw.current,
                            totalnoansw.current == null
                                ? 0
                                : totalnoansw.current
                        ]
                }
            });
        }
        
        apexchart.exec('asr','updateSeries',
        [
            count > 0 ? Math.floor((answer / count) * 100) : 0,
            countMonth > 0 ? Math.floor((answerMonth / countMonth) * 100) : 0,
            countWeek > 0 ? Math.floor((answerWeek / countWeek) * 100) : 0,
            countToday > 0 ? Math.floor((answerToday / countToday) * 100) : 0
        ]
        )
        apexchart.exec("asr", "updateOptions",{
            plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      fontSize: '22px',
                    },
                    value: {
                      color:'rgb(163, 165, 202)',
                      fontSize: '16px',
                    },
                    total: {
                      show: true,
                      label: 'Total',
                      formatter: function (w) {
                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                        return `${count > 0 ? Math.floor((answer / count) * 100) : 0}%`
                      }
                    }
                  }
                }
              }
        });
        apexchart.exec("asr", "render");
    }, [data])
    useEffect(() => {
        
        apexchart.exec("callsWeek", "updateOptions",{
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                offsetY: 10,
                            },
                            value:{},
                            total: {
                                show: true,
                                showAlways: true,
                                fontSize: '22px',
                                fontFamily: 'Ubuntu, sans-serif',
                                fontWeight: 600,
                                label: `${testweek.current}`,
                                color: 'rgb(163, 165, 202)',                            
                                formatter: function (w) {
                                    return '';
                                }
                            }
                        }
                    }
                }
            }
        });
        apexchart.exec("callsWeek", "render");
        apexchart.exec("callsToday", "updateOptions",{
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                offsetY: 10,
                            },
                            value:{},
                            total: {
                                show: true,
                                showAlways: true,
                                fontSize: '22px',
                                fontFamily: 'Ubuntu, sans-serif',
                                fontWeight: 600,
                                label: `${testtoday.current}`,
                                color: 'rgb(163, 165, 202)',                            
                                formatter: function (w) {
                                    return '';
                                }
                            }
                        }
                    }
                }
            }
        });
        apexchart.exec("callsToday", "render");
        apexchart.exec("callsMonth", "updateOptions",{
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                offsetY: 10,
                            },
                            value:{},
                            total: {
                                show: true,
                                showAlways: true,
                                fontSize: '22px',
                                fontFamily: 'Ubuntu, sans-serif',
                                fontWeight: 600,
                                label: `${testmonth.current}`,
                                color: 'rgb(163, 165, 202)',                            
                                formatter: function (w) {
                                    return '';
                                }
                            }
                        }
                    }
                }
            }
        });
        apexchart.exec("callsMonth", "render");
        // apexchart.exec("callsAll", "updateOptions",{
        //     plotOptions: {
        //         pie: {
        //             donut: {
        //                 labels: {
        //                     show: true,
        //                     name: {
        //                         offsetY: 10,
        //                     },
        //                     value:{},
        //                     total: {
        //                         show: true,
        //                         showAlways: true,
        //                         fontSize: '28px',
        //                         fontFamily: 'Ubuntu, sans-serif',
        //                         fontWeight: 600,
        //                         label: `${testall.current}\n\ncalls`,
        //                         color: 'rgb(163, 165, 202)',                            
        //                         formatter: function (w) {
        //                             return '';
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // });
        // apexchart.exec("callsAll", "render");
        setInterval(() => {
            axios.post('https://www.pbxdiamondcall.com:8081/agents/onlinecounter', {
                ui_id: JSON.parse(secureLocalStorage.getItem('user')).user_info_id,
                company_id: id!==undefined?id:JSON.parse(secureLocalStorage.getItem('user')).company_id
            })
                .then((res) => {
                    setInCall(res.data.incall);
                    apexchart.exec("callsWeek", "updateOptions",{
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            offsetY: 10,
                                        },
                                        value:{},
                                        total: {
                                            show: true,
                                            showAlways: true,
                                            fontSize: '22px',
                                            fontFamily: 'Ubuntu, sans-serif',
                                            fontWeight: 600,
                                            label: `${testweek.current}`,
                                            color: 'rgb(163, 165, 202)',                            
                                            formatter: function (w) {
                                                return '';
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });
                    apexchart.exec("callsWeek", "render");
                    apexchart.exec("callsToday", "updateOptions",{
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            offsetY: 10,
                                        },
                                        value:{},
                                        total: {
                                            show: true,
                                            showAlways: true,
                                            fontSize: '22px',
                                            fontFamily: 'Ubuntu, sans-serif',
                                            fontWeight: 600,
                                            label: `${testtoday.current}`,
                                            color: 'rgb(163, 165, 202)',                            
                                            formatter: function (w) {
                                                return '';
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });
                    apexchart.exec("callsToday", "render");
                    apexchart.exec("callsMonth", "updateOptions",{
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            offsetY: 10,
                                        },
                                        value:{},
                                        total: {
                                            show: true,
                                            showAlways: true,
                                            fontSize: '22px',
                                            fontFamily: 'Ubuntu, sans-serif',
                                            fontWeight: 600,
                                            label: `${testmonth.current}`,
                                            color: 'rgb(163, 165, 202)',                            
                                            formatter: function (w) {
                                                return '';
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });
                    apexchart.exec("callsMonth", "render");
                    // apexchart.exec("callsAll", "updateOptions",{
                    //     plotOptions: {
                    //         pie: {
                    //             donut: {
                    //                 labels: {
                    //                     show: true,
                    //                     name: {
                    //                         offsetY: 10,
                    //                     },
                    //                     value:{},
                    //                     total: {
                    //                         show: true,
                    //                         showAlways: true,
                    //                         fontSize: '28px',
                    //                         fontFamily: 'Ubuntu, sans-serif',
                    //                         fontWeight: 600,
                    //                         label: `${testall.current}\n\ncalls`,
                    //                         color: 'rgb(163, 165, 202)',                            
                    //                         formatter: function (w) {
                    //                             return '';
                    //                         }
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     }
                    // });
                    // apexchart.exec("callsAll", "render");
                })
                .catch((err) => console.log(err))
        }, 5000)
    }, [])
    useEffect(() => {
        console.log(id);
        const fetchInitialData = async () => {
            try {
                await axios.get(`https://www.pbxdiamondcall.com:8081/companies/${id==undefined?JSON.parse(secureLocalStorage.getItem('user')).company_id:id}` ).then(async(res) => {
                    if (res) {
                        axios.post("https://www.pbxdiamondcall.com:8081/dashboard/prefix", {
                            company: res.data[0].Title
                        }).then((response) => setPrefixes(response.data))
                        setCompany(res.data[0].Title)
                        const response = await axios.post('https://www.pbxdiamondcall.com:8081/dashboardV2', {
                            company: res.data[0].Title
                        });
                        if (response) {
                            SetData({
                                count: response.data.count === null ? 0 : response.data.count,
                                answer: response.data.answer === null ? 0 : response.data.answer,
                                noanswer: response.data.noanswer === null ? 0 : response.data.noanswer,
                                avg: response.data.avg === null ? 0 : response.data.avg,
                                activityarr: response.data.activityarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.activityarr,
                                noanswarr: response.data.noanswarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.noanswarr,
                                answarr: response.data.answarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.answarr,
                                aweek: response.data.lastweekansw === null ? 0 : response.data.lastweekansw,
                                noaweek: response.data.lastweeknoansw === null ? 0 : response.data.lastweeknoansw,
                                amonth: response.data.lastmonthansw === null ? 0 : response.data.lastmonthansw,
                                noamonth: response.data.lastmonthnoansw === null ? 0 : response.data.lastmonthnoansw,
                                countries: response.data.countries === null ? [] : response.data.countries,
                                answerweek:response.data.dashbweek.map((item)=>item.ANSWER),
                                noanswerweek:response.data.dashbweek.map((item)=>item.other),
                                categories:response.data.dashbweek.map((item)=>item.day),
                            })
                            console.log(response.data);
                            
                            setIsLoading(false)
                            apexchart.exec("activity", 'updateSeries', [{
                                name: t("dashboardAgentsActivity"),
                                data: response.data.activityarr !== null
                                    ? response.data.activityarr.length === 0
                                        ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                        : response.data.activityarr
                                    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                            }], true)
                            apexchart.exec("activity", "render");

                            apexchart.exec("weekarea", 'updateSeries', [
                                {
                                    name: "Success",
                                    data: response.data.dashbweek.map((item)=>item.ANSWER)
                                },
                                {
                                    name: "Fail",
                                    data: response.data.dashbweek.map((item)=>item.other)
                                }
                            ], true)
                            
                            apexchart.exec("weekarea", "updateOptions",{
                                xaxis: {
                                    type: 'datetime',
                                    categories: response.data.dashbweek.map((item)=>moment(item.day).format("MMM Do")),
                                    axisBorder: {
                                        show: true
                                    },
                                    axisTicks: {
                                        show: false
                                    },
                                    labels: {
                                        show: true
                                    }
                                },
                            });
                            apexchart.exec("weekarea", "render");
        
                            apexchart.exec("success", 'updateSeries', [
                                {
                                    name: t("dashboardSuccess"),
                                    data: response.data.answarr !== null ?
                                        response.data.answarr.length === 0
                                            ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                            : response.data.answarr
                                        : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                    //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                                },
                                {
                                    name: t("dashboardFail"),
                                    data: response.data.noanswarr !== null ?
                                        response.data.noanswarr.length === 0
                                            ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                            : response.data.noanswarr
                                        : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                    //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                                }
                            ], true)
                            apexchart.exec("success", "render");
                            
                    apexchart.exec('asr','updateSeries',
                    [
                        count > 0 ? Math.floor((answer / count) * 100) : 0,
                        countMonth > 0 ? Math.floor((answerMonth / countMonth) * 100) : 0,
                        countWeek > 0 ? Math.floor((answerWeek / countWeek) * 100) : 0,
                        countToday > 0 ? Math.floor((answerToday / countToday) * 100) : 0
                    ]
                    )
                    
                    apexchart.exec("asr", "updateOptions",{
                        plotOptions: {
                            radialBar: {
                              dataLabels: {
                                name: {
                                  fontSize: '22px',
                                },
                                value: {
                                  color:'rgb(163, 165, 202)',
                                  fontSize: '16px',
                                },
                                total: {
                                  show: true,
                                  label: 'Total',
                                  formatter: function (w) {
                                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                    return `${count > 0 ? Math.floor((answer / count) * 100) : 0}%`
                                  }
                                }
                              }
                            }
                          }
                    });
                    apexchart.exec("asr", "render");
                        }


                    }
                });
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
            }
        };

        setTimeout(async ()=>{
            setIsLoading(true)
        fetchInitialData();
        },100)
        
        setTimeout(async ()=>{
            fetchInitialData();
        },1100)
        const intervalId = setInterval(async () => {
            fetchInitialData();
        }, 30000);

        return () => clearInterval(intervalId);
    }, []);

    // useEffect(() => {
    //     setTimeout(async ()=>{
    //         try {
    //             const response = await axios.post('https://www.pbxdiamondcall.com:8081/dashboardV2', {
    //                 company: company
    //             });
    //             if (response) {
                                        
    //                 SetData({
    //                     count: response.data.count === null ? 0 : response.data.count,
    //                     answer: response.data.answer === null ? 0 : response.data.answer,
    //                     noanswer: response.data.noanswer === null ? 0 : response.data.noanswer,
    //                     avg: response.data.avg === null ? 0 : response.data.avg,
    //                     activityarr: response.data.activityarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.activityarr,
    //                     noanswarr: response.data.noanswarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.noanswarr,
    //                     answarr: response.data.answarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.answarr,
    //                     aweek: response.data.lastweekansw === null ? 0 : response.data.lastweekansw,
    //                     noaweek: response.data.lastweeknoansw === null ? 0 : response.data.lastweeknoansw,
    //                     amonth: response.data.lastmonthansw === null ? 0 : response.data.lastmonthansw,
    //                     noamonth: response.data.lastmonthnoansw === null ? 0 : response.data.lastmonthnoansw,
    //                     countries: response.data.countries === null ? [] : response.data.countries,
    //                     answerweek:response.data.dashbweek.map((item)=>item.ANSWER),
    //                     noanswerweek:response.data.dashbweek.map((item)=>item.other),
    //                     categories:response.data.dashbweek.map((item)=>item.day),
    //                 })
    //                 console.log(data);
                    
    //                 apexchart.exec("weekarea", 'updateSeries', [
    //                     {
    //                         name: "Success",
    //                         data: response.data.dashbweek.map((item)=>item.ANSWER)
    //                     },
    //                     {
    //                         name: "Fail",
    //                         data: response.data.dashbweek.map((item)=>item.other)
    //                     }
    //                 ], true)
    //                 apexchart.exec("weekarea", "updateOptions",{
    //                     xaxis: {
    //                         type: 'datetime',
    //                         categories: response.data.dashbweek.map((item)=>moment(item.day).format("MMM Do")),
    //                         axisBorder: {
    //                             show: true
    //                         },
    //                         axisTicks: {
    //                             show: false
    //                         },
    //                         labels: {
    //                             show: true
    //                         }
    //                     },
    //                 });
    //                 apexchart.exec("weekarea", "render");
    //                 apexchart.exec("activity", 'updateSeries', [{
    //                     name: t("dashboardAgentsActivity"),
    //                     data: response.data.activityarr !== null
    //                         ? response.data.activityarr.length === 0
    //                             ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                             : response.data.activityarr
    //                         : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                     //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    //                 }], true)
    //                 apexchart.exec("activity", "render");
    //                 apexchart.exec('asr','updateSeries',
    //                 [
    //                     count > 0 ? Math.floor((answer / count) * 100) : 0,
    //                     countMonth > 0 ? Math.floor((answerMonth / countMonth) * 100) : 0,
    //                     countWeek > 0 ? Math.floor((answerWeek / countWeek) * 100) : 0,
    //                     countToday > 0 ? Math.floor((answerToday / countToday) * 100) : 0
    //                 ]
    //                 )
    //                 apexchart.exec("asr", "render");

    //                 apexchart.exec("success", 'updateSeries', [
    //                     {
    //                         name: t("dashboardSuccess"),
    //                         data: response.data.answarr !== null ?
    //                             response.data.answarr.length === 0
    //                                 ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                                 : response.data.answarr
    //                             : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                         //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    //                     },
    //                     {
    //                         name: t("dashboardFail"),
    //                         data: response.data.noanswarr !== null ?
    //                             response.data.noanswarr.length === 0
    //                                 ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                                 : response.data.noanswarr
    //                             : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                         //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    //                     }
    //                 ], true)
    //                 apexchart.exec("success", "render");
    //             }
    //         } catch (error) {
    //             console.error('Ошибка при выполнении запроса:', error);
    //         }
    //     },100)
    //     const intervalId = setInterval(async () => {
    //         try {
    //             const response = await axios.post('https://www.pbxdiamondcall.com:8081/dashboardV2', {
    //                 company: company
    //             });
    //             if (response) {
                    
    //                 SetData({
    //                     count: response.data.count === null ? 0 : response.data.count,
    //                     answer: response.data.answer === null ? 0 : response.data.answer,
    //                     noanswer: response.data.noanswer === null ? 0 : response.data.noanswer,
    //                     avg: response.data.avg === null ? 0 : response.data.avg,
    //                     activityarr: response.data.activityarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.activityarr,
    //                     noanswarr: response.data.noanswarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.noanswarr,
    //                     answarr: response.data.answarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.answarr,
    //                     aweek: response.data.lastweekansw === null ? 0 : response.data.lastweekansw,
    //                     noaweek: response.data.lastweeknoansw === null ? 0 : response.data.lastweeknoansw,
    //                     amonth: response.data.lastmonthansw === null ? 0 : response.data.lastmonthansw,
    //                     noamonth: response.data.lastmonthnoansw === null ? 0 : response.data.lastmonthnoansw,
    //                     countries: response.data.countries === null ? [] : response.data.countries,
    //                     answerweek:response.data.dashbweek.map((item)=>item.ANSWER),
    //                     noanswerweek:response.data.dashbweek.map((item)=>item.other),
    //                     categories:response.data.dashbweek.map((item)=>item.day),
    //                 })
    //                 console.log(data);
    //                 apexchart.exec("activity", 'updateSeries', [{
    //                     name: t("dashboardAgentsActivity"),
    //                     data: response.data.activityarr !== null
    //                         ? response.data.activityarr.length === 0
    //                             ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                             : response.data.activityarr
    //                         : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                     //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    //                 }], true)
    //                 apexchart.exec("activity", "render");
                    
    //                 apexchart.exec("weekarea", "updateOptions",{
    //                     xaxis: {
    //                         type: 'datetime',
    //                         categories: response.data.dashbweek.map((item)=>moment(item.day).format("MMM Do")),
    //                         axisBorder: {
    //                             show: true
    //                         },
    //                         axisTicks: {
    //                             show: false
    //                         },
    //                         labels: {
    //                             show: true
    //                         }
    //                     },
    //                 });
    //                 apexchart.exec("weekarea", 'updateSeries', [
    //                     {
    //                         name: "Success",
    //                         data: response.data.dashbweek.map((item)=>item.ANSWER)
    //                     },
    //                     {
    //                         name: "Fail",
    //                         data: response.data.dashbweek.map((item)=>item.other)
    //                     }
    //                 ], true)
    //                 apexchart.exec("weekarea", "render");
    //                 apexchart.exec('asr','updateSeries',
    //                 [
    //                     count > 0 ? Math.floor((answer / count) * 100) : 0,
    //                     countMonth > 0 ? Math.floor((answerMonth / countMonth) * 100) : 0,
    //                     countWeek > 0 ? Math.floor((answerWeek / countWeek) * 100) : 0,
    //                     countToday > 0 ? Math.floor((answerToday / countToday) * 100) : 0
    //                 ]
    //                 )
    //                 apexchart.exec("asr", "render");

    //                 apexchart.exec("success", 'updateSeries', [
    //                     {
    //                         name: t("dashboardSuccess"),
    //                         data: response.data.answarr !== null ?
    //                             response.data.answarr.length === 0
    //                                 ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                                 : response.data.answarr
    //                             : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                         //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    //                     },
    //                     {
    //                         name: t("dashboardFail"),
    //                         data: response.data.noanswarr !== null ?
    //                             response.data.noanswarr.length === 0
    //                                 ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                                 : response.data.noanswarr
    //                             : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //                         //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    //                     }
    //                 ], true)
    //                 apexchart.exec("success", "render");
    //             }
    //         } catch (error) {
    //             console.error('Ошибка при выполнении запроса:', error);
    //         }
    //     }, 15000);

    //     return () => clearInterval(intervalId);
    // }, [company]);
    const setCallsToday = () => {
        setCallsState('Today')
    }
    const setCallsWeek = () => {
        setCallsState('Week')
    }
    const setCallsMonth = () => {
        setCallsState('Month')
    }
    const setCallsAll = () => {
        setCallsState('All')
    }
    if(isLoading){
        return <div className='container-xxl flex-grow-1 container-p-y'>
                <div className="card" style={{width:1400, height:500, justifyContent:'center',alignContent:'center'}}>
                    <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>{t("loadingData")}...</h1>
                <ScaleLoader
                    color="#696cff"
                    height={270}
                    margin={10}
                    radius={2}
                    speedMultiplier={0.8}
                    width={120}
                    cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
            </div>
    }
    else{
        return (
            <div className="container-xxl flex-grow-1 container-p-y"
                style={{ justifyContent: "start", display: "flex", flexDirection: "column", width:'100%' }}>
                <div className="row" style={{ height: 700, width: '100vw' , display:"flex", flexWrap:'wrap'}}>
                    <div className="col-sm" style={{ width: 800, display:'flex', flexDirection: "column"}}>
                        <div className="card h-100">
                            <div className="card-header d-flex align-items-center justify-content-between pb-0">
                                <div className="card-title mb-0">
                                    <h5 className="m-0 me-2" style={{ fontSize: '25px', fontWeight: "bold", color: "#a3a5ca" }}>{t("dashboardCalls")}</h5>
                                </div>
                            </div>
                            <div className="card-body">
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between", 
                                    marginTop:"3%",
                                    border:'solid #444564',
                                    borderWidth:'0px 0px 2px 0px',
                                    height:'280px'
                                }}>
                                    <ul className="p-0 m-0" style={{ display: "flex", flexDirection: "row", justifyContent:'space-around', width:'100%' }}>
                                        <li className="d-flex mb-4 pb-1">
                                        <ReactApexChart
                                            options={state.optionsCallsMonth}
                                            series={state.seriesCallsMonth}
                                            labels={state.optionsCallsMonth.labels}
                                            type='donut'
                                            width={250}
                                            height={200} />
                                        </li>
                                        <li className="d-flex mb-4 pb-1">
                                        <ReactApexChart
                                            options={state.optionsCallsWeek}
                                            series={state.seriesCallsWeek}
                                            labels={state.optionsCallsWeek.labels}
                                            type='donut'
                                            width={250}
                                            height={200} />
                                        </li>
                                        <li className="d-flex mb-4 pb-1">
                                        <ReactApexChart
                                            options={state.optionsCallsToday}
                                            series={state.seriesCallsToday}
                                            labels={state.optionsCallsToday.labels}
                                            type='donut'
                                            width={250}
                                            height={200} />
                                        </li>
                                        { /* 
                                            <li className="d-flex mb-4 pb-1">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <span className="avatar-initial rounded bg-label-danger"><i className="bx bx-user-x" /></span>
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                <h6 className="mb-0">Busy</h6>
                                                </div>
                                                <div className="user-progress">
                                                <small className="fw-semibold">{JSON.parse(secureLocalStorage.getItem('busy'))}</small>
                                                </div>
                                            </div>
                                            </li> 
                                            */ }
                                    </ul>
                                    {/* {role===1?<Select options={secureLocalStorage.getItem("companiesarray")} 
                                        onChange={(e)=>setDashboardData("Give me dashboard,"+e.label)}
                                        placeholder="Select company for dashboard data"
                                        />:null} */}
                                </div>
                                <div className="d-flex justify-content-center align-items-center mb-3" 
                                style={{
                                width:'100%',  display:'flex',
                                flexDirection:'column', justifyContent:"start", paddingTop:'20px'}}>
                                    
                                <div className="card-title mb-0" style={{width:'100%'}}>
                                    <h5 className="m-0 me-2" style={{width:'100%', fontSize: '25px', fontWeight: "bold", color: "#a3a5ca" }}>Weekly statistics</h5>
                                </div>
                                    {/* <div className="d-flex flex-column align-items-center gap-1">
                                        <h2 className="mb-2">{count}</h2>
                                        <span>{t("dashboardCalls")}</span>
                                    </div> */}
                                    <div id="orderStatisticsChart" style={{width:'100%'}}>
                                        <ReactApexChart
                                            options={state.options}
                                            series={state.series}
                                            type='area'
                                            width={'100%'}
                                            height={'400'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div style={window.innerWidth>1733?
                        { display: "flex", flexDirection: "column", flexWrap: "nowrap", marginRight: "30px" }
                        :{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-around", marginTop:15 }
                        } >
                            <div className="card" style={{ width: 530, height: 280, marginBottom: 10 }}>
                                {/* <div className="card-header">
                                                <h2 className="mb-1" style={{color:"#a3a5ca",fontSize:'25px', fontWeight:"bold", marginLeft:"10px"}}>{t("dashboardInCall")}</h2>
                                </div> */}
                                <div className="card-body row g-4">
    
                                    <div className="col-md-8 ps-md-" style={{ marginTop: "3" }}>
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <h5 className="mb-0" style={{ fontSize: "25px", fontWeight: "bold", color: "#a3a5ca" }}>{t("dashboardActivity")}</h5>
                                            <small style={{ position: "absolute", marginLeft: "80%", fontSize: "15px", color: "#a3a5ca", fontWeight: "bold" }}>{t("dashboardToday")}</small>
                                        </div>
                                        <div className="d-flex justify-content-between" style={{ position: 'relative' }}>
    
                                            <div className="d-flex justify-content-between" >
                                                <ReactApexChart
                                                    options={state.optionsActivity}
                                                    series={state.optionsActivity.series}
                                                    labels={state.optionsActivity.xaxis.categories}
                                                    width={500}
                                                    height={230}
                                                    type='area'
                                                />
                                            </div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card" style={{ width: 530, height: 280, marginBottom: 10 }}>
                                <div className="card-body row g-4" style={{ alignContent: "center", justifyContent: "center" }}>
                                    <div className="d-flex justify-content-between" style={{ position: 'relative' }}>
                                        {/* <h1 style={{marginLeft:"auto",marginRight:"auto", color:'darkred'}}>UNDER DEVELOPMENT</h1> */}
                                        <div className="d-flex justify-content-between" >
                                            <ReactApexChart
                                                options={state.optionsSuccess}
                                                series={state.optionsSuccess.series}
                                                labels={state.optionsSuccess.xaxis.categories}
                                                type='bar'
                                                width={500}
                                                height={240}
                                            />
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            <div className="card" style={{ width: 530, height: 280, padding: 5 }}>
                                <div className="card-header" 
                                style={{ fontSize: '25px', fontWeight: "bold", 
                                alignSelf: "center", height: 10, width:'560px',
                                display:'flex',flexDirection:"row",
                                justifyContent:"space-around"}}>
                                    <h2 className="mb-1" style={{ color: "#a3a5ca", fontSize: '25px', fontWeight: "bold", marginLeft: "10px" }}>{t("dashboardInCall")}</h2>
                                    <h2 className="mb-1" style={{ color: "#a3a5ca", fontSize: '25px', fontWeight: "bold", marginLeft: "10px", marginRight:'30px' }}>ACD</h2>
                                </div>
                                <div className='card-body row'>
                                <div className="col-md-6 pe-md-4 card-separator" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 260, height:200 }}>
                                    <div className="d-flex">
                                        <div style={{ textAlign: "center" }}>
                                            <h1 style={{ color: "#7575ff", margin: 5 }}>
                                                <b style={{ marginLeft: "10px",  fontSize: "80px" }}>
                                                    {incall}
                                                </b>
                                                {/* <i class="fa-solid fa-phone fa-2xl" style={{color:"#a3a5ca",top:"100%", position:"absolute",}}></i> */}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pe-md-4" style={{ display: "flex",width: 230, height: 210, 
                                padding: 5, textAlign: "center", alignItems:"center", justifyContent:'center' }}>
                                    <div className="card-body" >
                                        <h1 style={{color: '#a3a5ca', margin: 5 }}>
                                           <b style={{marginLeft: "15px",  fontSize: "35px"}}>{Math.floor(data.avg)} {t("onlineSeconds")}</b> 
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm"
                        style={{
                            display: "flex",
                            marginTop:30,
                            width:'100%'
                        }}>
                        <div style={window.innerWidth>=2000?
                        {display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent:"space-between", marginTop:15, width:'72vw' }
                        :window.innerWidth>=1800?
                        { display: "flex", flexDirection: "row", flexWrap: "nowrap", marginRight: "30px" }
                        :
                        {display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-around", marginTop:15, width:'1300px' }
                        }>
                            
                            
                            <div className='col-sm' style={{ width: 530, height: 570, marginRight:30, textAlign:'left', scrollbarWidth:'none'}}>
                                <div className="card">
                                    {/* <div className="card-header" style={{ fontSize: '25px', fontWeight: "bold", color: "#a3a5ca" }} >Prefixes</div> */}
                                    <div className="card-body"  >
                                        <div className="table-responsive p-0 pb-2" style={{ height: "453px" }}>
                                            <table id="table" className="table table-responsive-sm table-striped table-bordered align-items-center justify-content-center mb-0"
                                            >
                                                <thead style={{ backgroundColor: "#2B2C3F", borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", borderRightWidth: "3px", position: "sticky", top: 0 }}>
                                                    <tr>
                                                    <th  className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2"
                                                            style={{
                                                                fontSize: "18px", textAlign: "center",
                                                                borderRightWidth: "3px", borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", position: "sticky", top: 0
                                                            }}></th>
                                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2"
                                                            style={{
                                                                fontSize: "18px", textAlign: "center",
                                                                borderRightWidth: "3px", borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", position: "sticky", top: 0
                                                            }}>Title</th>
                                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2"
                                                            style={{
                                                                fontSize: "18px", textAlign: "center",
                                                                borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", borderRightWidth: "3px", position: "sticky", top: 0
                                                            }}>Prefix</th>
                                                        {/* <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2"
                                                            style={{
                                                                fontSize: "18px", textAlign: "center"
                                                                , borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", borderRightWidth: "3px", position: "sticky", top: 0
                                                            }}>Description</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody style={{ borderLeftWidth: "3px", borderRightWidth: "3px", borderBottomWidth: "3px" }}>
                                                    {
                                                        // JSON.parse(lastMessage.data)!==null?
                                                        prefixes.length > 0 ?
                                                            prefixes.map((element, idx) => {
                                                                return <tr key={idx}  >
                                                                    <td style={{ textAlign: "center", fontWeight: "bold" }}>
                                                                    <input className="form-check-input me-1" type="radio" style={{alignSelf:"center"}} 
                                                                        id={element.title} name="operators" value={element.prefix} 
                                                                        onClick={()=>{setActiveOperator(element.prefix)}}/>    
                                                                    </td>
                                                                    <td style={{ textAlign: "center", fontWeight: "bold" }}>{element.title}</td>
                                                                    <td style={{ textAlign: "center", fontWeight: "bold" }}>{element.prefix}</td>
                                                                    {/* <td style={{ textAlign: "center", fontWeight: "bold" }}>{element.description}</td> */}
                                                                </tr>
                                                            })
                                                            : null
                                                        // :<tr><td>
                                                        //     <div style={{display:"flex",flexDirection:"row"}}>
                                                        //     Loading...
                                                        //     <BeatLoader color="#36d7b7" />
                                                        // </div>
                                                        //     </td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{display:'flex',flexDirection:'row', marginTop:10}}>
                                            <input type="number" value={number} placeholder='Phone number'
                                            onChange={(e)=>{setNumber(e.target.value);console.log(number)}}
                                            name="" id="" className='form-control' style={{marginRight:10}} />
                                            {
                                            (number?.toString().length === 0 || activeOperator == null)
                                            ?
                                                <button className='btn btn-primary' 
                                                disabled={true}>
                                                    Call
                                                </button>
                                            :
                                            <Link to={`zoiper:${activeOperator}${number}`}>
                                                <button className='btn btn-primary' 
                                                disabled={false}>
                                                    Call
                                                </button>
                                            </Link>
                                            }         
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card" style={{ width: '550px', height: 550, marginRight:30, display:'flex', flexDirection:'row' }}>
                            <div className='card-body'>
                                <div style={{display:'flex',alignContent:'center', flexDirection:"column"}}>
                                        <div className="table-responsive p-0 pb-2" style={{ maxHeight: "500px", width:'100%', overflowX:'hidden' }}>
                                            <table id="table" className="table table-responsive-sm table-striped table-bordered align-items-center justify-content-center mb-0"
                                            style={{fontFamily:'Ubuntu, sans-serif', height:"500px"}}>
                                                <thead style={{ backgroundColor: "#2B2C3F", borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", borderRightWidth: "3px", position: "sticky", top: 0 }}>
                                                    <tr>
                                                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2"
                                                                                style={{
                                                                                    fontSize: "18px", textAlign: "center",
                                                                                    borderRightWidth: "3px", borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", position: "sticky", top: 0
                                                                                }}>Country</th>
                                                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2"
                                                                                style={{
                                                                                    fontSize: "18px", textAlign: "center",
                                                                                    borderRightWidth: "3px", borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", position: "sticky", top: 0
                                                                                }}>Calls</th>
                                                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2"
                                                                                style={{
                                                                                    fontSize: "18px", textAlign: "center",
                                                                                    borderRightWidth: "3px", borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", position: "sticky", top: 0
                                                                                }}>Success</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ borderLeftWidth: "3px", borderRightWidth: "3px", borderBottomWidth: "3px" }}>
                                                    { 
                                                    data.countries !== undefined ? data.countries.map((val,idx)=>{
                                                        
                                                        return <tr key={idx} style={{height:"50px"}}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                <img src={Countries.find((obj)=>obj.name.common==val.Country)?.flags?.png} alt={Countries.find((obj)=>obj.name.common==val.Country|| obj.altSpellings.includes(val.Country))?.flags?.alt} height={24} className="me-2" width={24} style={{borderRadius:'50%'}} />
                                                                <span>{val.Country}</span>
                                                                </div>
                                                            </td>
                                                            <td>{val.TotalCount}</td>
                                                            <td>
                                                                <div className="d-flex justify-content-between align-items-center gap-3">
                                                                <div className="progress w-100" style={{height: 10}}>
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{width: `${val.avg*100}%`}} aria-valuenow={val.avg*100}aria-valuemin={0} aria-valuemax={100} />
                                                                </div>
                                                                <small className="fw-semibold">{Math.round(val.avg*100)}%</small>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })
                                                    :console.log(data)
                                                    }
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                </div>
                            </div>
                            </div>
                            <div className="card" style={{ width: '350px', height: 550, padding: 10, display:'flex', flexDirection:'row' }}>
                                <div style={{display:'flex',alignContent:'center',flexDirection:"column", width:500}}>
                                    
                                    <div className="card-header" style={{ fontSize: '25px', fontWeight: "bold", alignSelf: "center",flexDirection:'column', height: 10 }}>
                                        ASR
                                    </div>
                                    <div style={{ marginTop: 0, alignItems:"center", justifyContent:'center', display:'flex', height:500, flexDirection:"column" }}>
                                    <ReactApexChart options={state.optionsASR.options} series={state.optionsASR.series} labels={state.optionsASR.options.labels} type="radialBar" height={350} width={300} />
        
                                        {/* <ReactSpeedometer
                                            ringWidth={30}
                                            customSegmentStops={[0,
                                                count > 0 ? Math.floor((answer / count) * 100) : 0,
                                                100
                                            ]
                                            }
                                            forceRender={true}
                                            needleTransitionDuration={0}
                                            valueTextFontSize="25px"
                                            needleColor="#ddd9c7"
                                            textColor='#a3a5ca'
                                            segmentColors={['#7575ff', '#AAA']}
                                            width={280}
                                            height={170}
                                            paddingVertical={10}
                                            currentValueText={'${value}%'}
                                            value={count > 0 ? Math.floor((answer / count) * 100) : 0}
                                            minValue={0}
                                            maxValue={100}
                                            maxSegmentLabels={0}
                                        /> */}
                                        {/* <h3 style={{color:'rgb(163, 165, 202)', fontFamily:'Helvetica, Arial, sans-serif', fontSize:'14px', fontWeight:'900'}}>Total</h3> */}
        
                                        {/* <ul className="p-0 m-0" style={{ display: "flex", flexDirection: "row" }}>
                                            <li className="d-flex mb-4 pb-1" style={{display:'flex', flexDirection:"column", justifyContent:"center", textAlign:'center'}}>
                                                <ReactSpeedometer
                                                ringWidth={30}
                                                customSegmentStops={[0,
                                                    countMonth > 0 ? Math.floor((answerMonth / countMonth) * 100) : 0,
                                                    100
                                                ]
                                                }
                                                forceRender={true}
                                                needleTransitionDuration={0}
                                                valueTextFontSize="25px"
                                                needleColor="#ddd9c7"
                                                textColor='#a3a5ca'
                                                segmentColors={['#7575ff', '#AAA']}
                                                width={250}
                                                height={160}
                                                paddingVertical={10}
                                                currentValueText={'${value}%'}
                                                value={countMonth > 0 ? Math.floor((answerMonth / countMonth) * 100) : 0}
                                                minValue={0}
                                                maxValue={100}
                                                maxSegmentLabels={0}
                                                />
                                                <h3 style={{color:'rgb(163, 165, 202)', fontFamily:'Helvetica, Arial, sans-serif', fontSize:'14px', fontWeight:'900'}}>Month</h3>
                                            </li>
                                            <li className="d-flex mb-4 pb-1" style={{display:'flex', flexDirection:"column", justifyContent:"center", textAlign:'center'}}>
                                                <ReactSpeedometer
                                                ringWidth={30}
                                                customSegmentStops={[0,
                                                    countWeek > 0 ? Math.floor((answerWeek / countWeek) * 100) : 0,
                                                    100
                                                ]
                                                }
                                                forceRender={true}
                                                needleTransitionDuration={0}
                                                valueTextFontSize="25px"
                                                needleColor="#ddd9c7"
                                                textColor='#a3a5ca'
                                                segmentColors={['#7575ff', '#AAA']}
                                                width={250}
                                                height={160}
                                                paddingVertical={10}
                                                currentValueText={'${value}%'}
                                                value={countWeek > 0 ? Math.floor((answerWeek / countWeek) * 100) : 0}
                                                minValue={0}
                                                maxValue={100}
                                                maxSegmentLabels={0}
                                                />
                                                <h3 style={{color:'rgb(163, 165, 202)', fontFamily:'Helvetica, Arial, sans-serif', fontSize:'14px', fontWeight:'900'}}>Week</h3>
                                            </li>
                                            <li className="d-flex mb-4 pb-1" style={{display:'flex', flexDirection:"column", justifyContent:"center", textAlign:'center'}}>
                                                <ReactSpeedometer
                                                ringWidth={30}
                                                customSegmentStops={[0,
                                                    countToday > 0 ? Math.floor((answerToday / countToday) * 100) : 0,
                                                    100
                                                ]
                                                }
                                                forceRender={true}
                                                needleTransitionDuration={0}
                                                valueTextFontSize="25px"
                                                needleColor="#ddd9c7"
                                                textColor='#a3a5ca'
                                                segmentColors={['#7575ff', '#AAA']}
                                                width={250}
                                                height={160}
                                                paddingVertical={10}
                                                currentValueText={'${value}%'}
                                                value={countToday > 0 ? Math.floor((answerToday / countToday) * 100) : 0}
                                                minValue={0}
                                                maxValue={100}
                                                maxSegmentLabels={0}
                                                />
                                                <h3 style={{color:'rgb(163, 165, 202)', fontFamily:'Helvetica, Arial, sans-serif', fontSize:'14px', fontWeight:'900'}}>Today</h3>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                                

                            </div>
                            {/* <div className="card" style={{ width: 300, height: 280, padding: 5 }}>
                                <div className="card-header" style={{ fontSize: '25px', fontWeight: "bold", alignSelf: "center", height: 10 }}>
                                    ACD
                                </div>
                                <div className="card-body" style={{ display: "flex", alignContent: 'center', justifyContent: 'center' }}>
                                    <h1 style={{ fontWeight: "bold", alignSelf: "center", justifySelf: 'center', color: '#a3a5ca' }}>
                                        {Math.floor(data.avg)} {t("onlineSeconds")}
                                    </h1>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default YourComponent;
