import React, { useEffect, useRef, useState } from 'react'
import styles from './ManageBrand.module.scss';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { Link, Navigate } from 'react-router-dom';
import { t } from 'i18next';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ReactDropdown from 'react-dropdown';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Select from 'react-select';
import { CircularProgress, LinearProgress } from '@mui/material';
import { DatePicker } from 'rsuite';
import moment from 'moment/moment';
import dayjs from 'dayjs';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function ManageBrand() {
    const [brands,setBrands] = useState([]);
    const [items,setItems] = useState([]);
    const [itemsF,setItemsF] = useState([]);
    const [activeItem,setActiveItem] = useState(JSON.parse(secureLocalStorage.getItem("user"))?.role_id ===1?"Brands":"Departments");
    const [values,setValues] = useState([]);
    const [agents,setAgents] = useState();
    const [teams,setTeams] = useState();
    const [isLoading,setIsLoading] = useState(false);
    const itemsTitles = {
        "Companies":"Companies",
        "Brands":"Offices",
        "Offices":"Departments",
        "Departments":"Teams",
        "Teams":"Agents"
    }
    const [activeSubItem,setActiveSubItem] = useState();
    const [activeValue,setActiveValue] = useState();
    const [search,setSearch] = useState('');
    const [prefixes,setPrefixes] = useState([]);

    const [moveTeams,SetMoveTeams] = useState([]);
    const [moveTeamId,SetMoveTeamId] = useState(0);
    const title = useRef(null);
    const titleOffice = useRef(null);
    const titleDepartment = useRef(null);
    const titleTeam = useRef(null);
    const titleAgent = useRef(null);
    const password = useRef(null);
    const role = useRef(null);
    const server = useRef(null);
    const name = useRef(null);
    const banTime = useRef(null);
    const MySwal = withReactContent(Swal);
    var options = [
        { value: 2, label: 'Company admin'},
        { value: 3, label: 'Office admin'},
        { value: 4, label: 'Department admin'},
        { value: 5, label: 'Team admin'},
        { value: 6, label: 'Agent'},
        { value: 7, label: 'BrandManager'},
        { value: 8, label: 'Coach'},
        { value: 9, label: 'It'},
    ]
    var options1 = [
        { value: 1, label: 'Diamondcall'},
        { value: 2, label: 'Diamondcall_Just'}
    ]

    function handleSubmit(){
        // console.log({
        //     login:title.current.value,
        //     password:password.current.value,
        //     role:role.current.state.selected.value,
        //     name:name.current.value,
        //     server:server.current.state.selected.value,
        //     Teams
        // });
        var arr = [];
        teams.forEach(element => {
            arr.push({
                id:element.id,
                access:document.getElementById(element.id).checked
            });
        });
        console.log(arr);
        if(titleAgent.current.value !== "" && password.current.value !== "" && name.current.value !== "" && role.current.state.selected.value !== ''){
        axios.post("https://www.pbxdiamondcall.com:8081/agents/add",
        {   
            login:titleAgent.current.value,
            password:password.current.value,
            role:role.current.state.selected.value,
            name:name.current.value,
            office_id:items.teams.find((val)=>val.id == activeSubItem).office_id,
            company_id:items.teams.find((val)=>val.id == activeSubItem).company_id,
            department_id:items.teams.find((val)=>val.id == activeSubItem).department_id,
            team_id:items.teams.find((val)=>val.id == activeSubItem).id,
            server:server.current.state.selected.value,
            teams_access:arr
        }
        )
        .then((res)=>{
            connectAgents()
        console.log(res);
        Swal.fire({
            icon:'success',
            title: 'Result',
            timer:2000,
            text: res.statusText,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        })
        .catch((err)=>{
        Swal.fire({
            icon: 'warning',
            title: 'Result',
            timer:2000,
            text: err.statusText,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        connectAgents()
        console.log(err)
        });
    }
    else {
    Swal.fire({
        icon: 'warning',
        title: 'Result',
        timer:2000,
        text: "Fill the fields",
        customClass: {
        confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
        connectAgents()
    }
        
    }
    function handleUpdate(){
            var arr = [];
            teams.forEach(element => {
                arr.push({
                    id:element.id,
                    access:document.getElementById(element.id).checked
                });
            });
            console.log(arr);
            axios.post("https://www.pbxdiamondcall.com:8081/agents/update",{
                login:titleAgent.current.value,
                password:password.current.value,
                role:role.current.state.selected.value,
                name:name.current.value,
                id:activeValue,
                banTime:banTime.current.value!==null?dayjs().add(banTime.current.value,'minutes').format("YYYY-MM-DD HH:mm:ss"):null,
                teams_access: arr
            })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                    icon: 'success',
                    timer:2000,
                    title: 'Result',
                    text: res.statusText,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                connectAgents()
            })
            .catch((err)=>{
                Swal.fire({
                    icon: 'warning',
                    title: 'Result',
                    timer:2000,
                    text: err.statusText,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                connectAgents()
                console.log(err)
            });
    }
    const connectAgents = () => {        
        setIsLoading(true);
        axios.get("https://www.pbxdiamondcall.com:8081/agents/"+activeSubItem)
            .then((res)=>{
                let company_id = res.data[0]?.company_id;
                
            axios.post("https://www.pbxdiamondcall.com:8081/teams/company",{
                company_id:company_id
            })
            .then((res)=>{
                console.log(res);
                setTeams(res.data);
                var arr = [];
                res.data.forEach((val,ind)=>{
                    arr.push({value:val.id,label:val.title})
                })
                SetMoveTeams(arr);
            })
                console.log(res.data);
                setAgents(res.data);                
                setIsLoading(false);
                setValues(res.data.map((val)=>{
                    if(val.Login!=="TestingGod")
                    return (
                        <span className={styles.item}>
                            <span className={styles.label}>
                                {val.Login}
                            </span>
                            <span className={styles.label} style={{textAlign:"center"}}>
                                {val.RoleTitle}
                            </span>
                            <span className={styles.button} 
                            onClick={()=>{setActiveValue(val.Id)
                            const currentAgent = res.data?.find((value)=>value.Id == val.Id);
                            console.log(currentAgent);
                            console.log(dayjs(Date.now()).toDate());
                            if(currentAgent){
                                axios.post("https://www.pbxdiamondcall.com:8081/agents/access",
                                {user_info_id:currentAgent?.user_info_id,company_id:currentAgent?.company_id})
                                .then((res)=>{
                                    teams.forEach(element => {
                                        document.getElementById(element.id).checked = false;
                                    });
                                    teams.forEach(element => {
                                    res.data.forEach(el=>{
                                        if(el.id === element.id && el.has_access===1){
                                        document.getElementById(element.id).checked = true;
                                        }
                                    })
                                    });
                                })
                                .catch((err)=>console.log(err));
                                // setagentid(Entities[ind].Id);
                                titleAgent.current.value = currentAgent.Login;
                                password.current.value =  currentAgent.Password;
                                role.current.state.selected.label = currentAgent.RoleTitle;
                                role.current.state.selected.value = currentAgent.Role;
                                const banEnd = dayjs(currentAgent.Ban);
                                const now = dayjs();
                                if (now.isBefore(banEnd)) {
                                    const minutesLeft = banEnd.diff(now, 'minute');
                                    banTime.current.value = minutesLeft;
                                } else {
                                    banTime.current.value = 0;
                                }
                                // SetMoveAgentId(currentAgent.Id);
                                name.current.value = currentAgent.Name;
                                const el = document.getElementById('submitbtn');
                                el.disabled = true;
                                const el1 = document.getElementById('updatebtn');
                                el1.disabled = false;
                            }
                            
                            }}
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasAddUser">
                                Update
                            </span>
                            <span className={styles.button_delete} 
                            onClick={()=>{setActiveValue(val.Id);handleDelete(val.Id)}}
                            >
                                Delete
                            </span>
                        </span>
                    )
                })
                )
            })
            .catch((err)=>{
                console.log(err);
            })
    } 
    const connectAll = () => {
        setIsLoading(true);
        axios.get("https://www.pbxdiamondcall.com:8081/companies")
        .then((res)=>{
            setBrands(res.data);
            secureLocalStorage.setItem('brands', res.data)
            axios.get("https://www.pbxdiamondcall.com:8081/items")
            .then((res)=>{
                if(roled !== 1){
                    const obj = {
                        companies:res.data?.companies?.filter((val)=>val.Id == JSON.parse(secureLocalStorage.getItem("user"))?.company_id),
                        departments:res.data?.departments?.filter((val)=>val.company_id == JSON.parse(secureLocalStorage.getItem("user"))?.company_id),
                        offices:res.data?.offices?.filter((val)=>val.company_id == JSON.parse(secureLocalStorage.getItem("user"))?.company_id),
                        teams:res.data?.teams?.filter((val)=>val.company_id == JSON.parse(secureLocalStorage.getItem("user"))?.company_id),
                    }
                    setItemsF(obj);
                    setItems(obj);
                }
                else{
                    setItemsF(res.data);
                    setItems(res.data);
                }
                setIsLoading(false);
            })
            .catch((err)=>console.log(err));
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    async function handleDelete (id) {
      let isdel = await SwalDel();
      console.log(isdel);
      if(isdel){
        axios.delete("https://www.pbxdiamondcall.com:8081/agents/delete",{data:{id:activeValue?activeValue:id} })
        .then((res)=>{
          console.log(res);
          MySwal.fire({
            icon:'success',
            timer:2000,
            title: 'Result',
            text: res.statusText,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
      connectAgents()
      })
        .catch((err)=>{
          console.log(err);
          MySwal.fire({
            icon: 'error',
            timer:2000,
            title: 'Authentification error.',
            text: err.statusText,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
      connectAgents()
        })
      }
    }
    const SwalDel =()=>{
        return Swal.fire({ // <-- return Promise
          title: 'Delete record?',
          text: 'This action cannot be undone.',
          icon: 'question',
          confirmButtonText: 'Delete',
          confirmButtonColor: '#DC004E',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: false,
          scrollbarPadding: false
        }).then((result) => {
          return result.isConfirmed;
        });
    }
    useEffect(()=>connectAll(), []);
    const renderCategories = () => {
        switch (activeItem) {
            case "Brands":
                if(search!==""){
                    return itemsF?.companies?.filter((val)=>val.Title.toLowerCase().includes(search.toLowerCase())).map((val)=>{
                        return (
                            <span className={activeSubItem==val.Id?styles.category_item_active:styles.category_item} onClick={()=>setActiveSubItem(val.Id)}>
                                {val.Title}
                            </span>
                        )
                    })   
                }else {
                    return itemsF?.companies?.map((val)=>{
                        return (
                            <span className={activeSubItem==val.Id?styles.category_item_active:styles.category_item} onClick={()=>setActiveSubItem(val.Id)}>
                                {val.Title}
                            </span>
                        )
                    })
                }
            case "Offices":
                if(search!==""){
                    return itemsF?.offices?.filter((val)=>val.title.toLowerCase().includes(search.toLowerCase())||val.Title.toLowerCase().includes(search.toLowerCase())).map((val)=>{
                        return (
                            <span className={activeSubItem==val.id?styles.category_item_active:styles.category_item} onClick={()=>setActiveSubItem(val.id)}>
                                [{val.Title}] {val.title}
                            </span>
                        )
                    })     
                }  else {
                    return itemsF?.offices?.map((val)=>{
                        return (
                            <span className={activeSubItem==val.id?styles.category_item_active:styles.category_item} onClick={()=>setActiveSubItem(val.id)}>
                                [{val.Title}] {val.title}
                            </span>
                        )
                    })  
                }
            case "Departments":
                if(search!==""){
                    return itemsF?.departments?.filter((val)=>val.title.toLowerCase().includes(search.toLowerCase())||val.Title.toLowerCase().includes(search.toLowerCase())).map((val)=>{
                        return (
                            <span className={activeSubItem==val.id?styles.category_item_active:styles.category_item} onClick={()=>setActiveSubItem(val.id)}>
                                [{val.Title}] {val.title}
                            </span>
                        )
                    })     
                }  else {
                    return itemsF?.departments?.map((val)=>{
                        return (
                            <span className={activeSubItem==val.id?styles.category_item_active:styles.category_item} onClick={()=>setActiveSubItem(val.id)}>
                                [{val.Title}] {val.title}
                            </span>
                        )
                    })  
                }
            case "Teams":
                if(search!==""){
                    return itemsF?.teams?.filter((val)=>val.title.toLowerCase().includes(search.toLowerCase())||val.Title.toLowerCase().includes(search.toLowerCase())).map((val)=>{
                        return (
                            <span className={activeSubItem==val.id?styles.category_item_active:styles.category_item} onClick={()=>setActiveSubItem(val.id)}>
                                [{val.Title}] {val.title}
                            </span>
                        )
                    })     
                }  else {
                    return itemsF?.teams?.map((val)=>{
                        return (
                            <span className={activeSubItem==val.id?styles.category_item_active:styles.category_item} onClick={()=>setActiveSubItem(val.id)}>
                                [{val.Title}] {val.title}
                            </span>
                        )
                    })  
                }
            default: return "Loading..."
        } 
    }
    const renderValues = () => {
        if(activeSubItem)
        switch (activeItem) {
            case "Companies":
                return items.companies.map((val)=>{
                    return (                    
                        <span className={styles.item}>
                            <span className={styles.label}>
                                {val?.Title}
                            </span>
                            <span className={styles.button} 
                            onClick={()=>{setActiveValue(val?.Id)
                                const element = document.getElementById('updatebtn_offices');
                                element.disabled = false; 
                                const el1 = document.getElementById('submitbtn_offices');
                                el1.disabled = true;
                            }}
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasAddOffice">
                                Update
                            </span>
                            <span className={styles.button_delete} 
                            onClick={()=>{setActiveValue(val?.Id);handleDeleteOffices(val?.Id)}}
                            >
                                Delete
                            </span>
                        </span>
                    )
                })    
            case "Brands":
                return items.offices.filter((val)=>val.company_id == activeSubItem).map((val)=>{
                    return (                    
                        <span className={styles.item}>
                            <span className={styles.label}>
                                {val?.title}
                            </span>
                            <span className={styles.label} style={{textAlign:"center"}}>
                                [{val?.Title}]
                            </span>
                            <span className={styles.button} 
                            onClick={()=>{setActiveValue(val?.id)
                                const element = document.getElementById('updatebtn_offices');
                                element.disabled = false; 
                                const el1 = document.getElementById('submitbtn_offices');
                                el1.disabled = true;
                            }}
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasAddOffice">
                                Update
                            </span>
                            <span className={styles.button_delete} 
                            onClick={()=>{setActiveValue(val?.id);handleDeleteOffices(val?.id)}}
                            >
                                Delete
                            </span>
                        </span>
                    )
                })    
            case "Offices":
                return items.departments.filter((val)=>val.office_id == activeSubItem).map((val)=>{
                    return (              
                        <span className={styles.item}>
                            <span className={styles.label}>
                                {val?.title}
                            </span>
                            <span className={styles.label} style={{textAlign:"center"}}>
                                [{val?.Title}]
                            </span>
                            <span className={styles.button} 
                            onClick={()=>{setActiveValue(val?.id)
                                const element = document.getElementById('updatebtn_departments');
                                element.disabled = false; 
                                const el1 = document.getElementById('submitbtn_departments');
                                el1.disabled = true;
                            }}
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasAddDepartment">
                                Update
                            </span>
                            <span className={styles.button_delete} 
                            onClick={()=>{setActiveValue(val?.id);handleDeleteDepartments(val?.id)}}
                            >
                                Delete
                            </span>
                        </span>
                    )
                })     
            case "Departments":
                return items.teams.filter((val)=>val.department_id == activeSubItem).map((val)=>{
                    return (              
                        <span className={styles.item}>
                            <span className={styles.label}>
                                {val?.title}
                            </span>
                            <span className={styles.label} style={{textAlign:"center"}}>
                                [{val?.Title}]
                            </span>
                            <span className={styles.button} 
                            onClick={()=>{setActiveValue(val?.id);
                                const element = document.getElementById('updatebtn_teams');
                                element.disabled = false; 
                                const el1 = document.getElementById('submitbtn_teams');
                                el1.disabled = true;
                            }}
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasAddTeam">
                                Update
                            </span>
                            <span className={styles.button_delete} 
                            onClick={()=>{setActiveValue(val?.id);handleDeleteTeams(val?.id)}}
                            >
                                Delete
                            </span>
                        </span>
                    )
                })
            case "Teams": 
                
            break;
            default: return "Loading..."
        } 
        else if (activeItem == "Companies") {
            return items.companies.map((val)=>{
                return (                    
                    <span className={styles.item}>
                        <span className={styles.label}>
                            {val?.Title}
                        </span>
                        <span className={styles.button} 
                        onClick={()=>{setActiveValue(val?.Id)
                            const element = document.getElementById('updatebtn_companies');
                            element.disabled = false; 
                            const el1 = document.getElementById('submitbtn_companies');
                            el1.disabled = true;
                            onUpdate(val?.Id);
                        }}
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasAddCompany">
                            Update
                        </span>
                        <span className={styles.button_delete} 
                        onClick={()=>{setActiveValue(val?.Id);handleDeleteCompanies(val?.Id)}}
                        >
                            Delete
                        </span>
                    </span>
                )
            })   
        }
    }
    const handleClose=()=>{
    }
    const handleMove=()=>{
        axios.post('https://www.pbxdiamondcall.com:8081/agents/move',{
            id:activeValue,
            team_id:moveTeamId
        })
        .then((res)=>{
            console.log(res);
        })
        .catch((err)=>console.log(err));
    }
    useEffect(()=>{
        if(activeItem == "Teams"){
            const currentAgent = agents?.find((val)=>val.Id == activeValue);
            console.log(currentAgent);
            if(currentAgent){
                axios.post("https://www.pbxdiamondcall.com:8081/agents/access",
                {user_info_id:currentAgent?.user_info_id,company_id:currentAgent?.company_id})
                .then((res)=>{
                    teams.forEach(element => {
                        document.getElementById(element.id).checked = false;
                    });
                    teams.forEach(element => {
                    res.data.forEach(el=>{
                        if(el.id === element.id && el.has_access===1){
                        document.getElementById(element.id).checked = true;
                        }
                    })
                    });
                })
                .catch((err)=>console.log(err));
                // setagentid(Entities[ind].Id);
                titleAgent.current.value = currentAgent.Login;
                password.current.value =  currentAgent.Password;
                role.current.state.selected.label = currentAgent.RoleTitle;
                role.current.state.selected.value = currentAgent.Role;
                // SetMoveAgentId(currentAgent.Id);
                name.current.value = currentAgent.Name;
                const el = document.getElementById('submitbtn');
                el.disabled = true;
                const el1 = document.getElementById('updatebtn');
                el1.disabled = false;
            }
        }
        if(activeItem=="Departments"){
            const currentTeam = items.teams?.find((val)=>val.id == activeValue);
            console.log(currentTeam);
            titleTeam.current.value = currentTeam?.title;
        }
        if(activeItem=="Offices"){
            const currentTeam = items.departments?.find((val)=>val.id == activeValue);
            console.log(currentTeam);
            titleDepartment.current.value = currentTeam?.title;
            console.log(titleDepartment.current.value);
        }
        if(activeItem=="Brands"){
            const currentTeam = items.offices?.find((val)=>val.id == activeValue);
            console.log(currentTeam);
            titleOffice.current.value = currentTeam?.title;
        }
        if(activeItem == "Companies"){
            const currentTeam = items.companies?.find((val)=>val.Id == activeValue);
            console.log(currentTeam);
            axios.post("https://www.pbxdiamondcall.com:8081/prefixes/company",
                {id:activeValue})
            .then((res)=>{
                console.log(res);
                prefixes.forEach(element => {
                        document.getElementById(element.id).checked = false;
                });
                prefixes.forEach(element => {
                    res.data.forEach(el=>{
                    if(el.prefix_id === element.id){
                        document.getElementById(element.id).checked = true;
                    }
                    })
                });
            })
            .catch((err)=>console.log(err));
            const element = title.current;
            element.value = currentTeam?.Title;
            const el = document.getElementById('submitbtn_companies');
            el.disabled = true;
            const el1 = document.getElementById('updatebtn_companies');
            el1.disabled = false;
        }
    },[activeValue])
    useEffect(()=>{
        setActiveSubItem(null);
        setActiveValue(null);
        if(activeItem=="Companies"){
            axios.get("https://www.pbxdiamondcall.com:8081/prefixes")
            .then((res)=>{
              console.log(res.data);
                setPrefixes(res.data);
                connectAll()
            })
            .catch((err)=>{
                console.log(err);
            })
        }
    },[activeItem])
    useEffect(()=>{
        if(activeItem=="Teams"){
            connectAgents()
        }
        else{
            connectAll()
        }
    },[activeSubItem])
    useEffect(()=>{
        setValues(renderValues());
    },[items]) 

    useEffect(()=>{

    },[search])

    function handleSubmitTeams(){
        const element = titleTeam.current;
        if(element.value !== ""){
            axios.post("https://www.pbxdiamondcall.com:8081/teams/add",{title:element.value, department_id:activeSubItem})
            .then((res)=>{
                Swal.fire({
                    icon: 'success',
                    title: 'Result',
                    text: res.statusText,
                    timer:2000,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                connectAll()
            })
            .catch((err)=>{
                Swal.fire({
                    icon: 'warning',
                    title: 'Result',
                    text: err.statusText,
                    timer:2000,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                connectAll()
            });
        }
        else Swal.fire("enter title");
    }
    function handleUpdateTeams(){
        const element = titleTeam.current;
        if(element.value !== ""){
            axios.post("https://www.pbxdiamondcall.com:8081/teams/update",{title:element.value, id:activeValue})
            .then((res)=>{
                Swal.fire({
                    icon:'success',
                    title: 'Result',
                    text: res.statusText,
                    timer:2000,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                connectAll()
            })
            .catch((err)=>{
                Swal.fire({
                    title: 'Result',
                    text: err.statusText,
                    timer:2000,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                connectAll()
            });
        }
        else Swal.fire("enter title");
    }
    async function handleDeleteTeams (id) {
        let isdel = await SwalDel();
        if(isdel){
            axios.delete("https://www.pbxdiamondcall.com:8081/teams/delete",{data:{id:activeValue?activeValue:id} })
            .then((res)=>{
                Swal.fire({
                    icon:'success',
                    title: 'Result',
                    timer:2000,
                    text: res.statusText,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                connectAll()
            }).catch((err)=>{
                Swal.fire({
                    icon: 'error',
                    timer:2000,
                    title: 'Authentification error.',
                    text: err,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                connectAll()
            })
        }
    }

    
    function handleSubmitDepartments(){
        const element = titleDepartment.current;
        if(element.value !== ""){
            axios.post("https://www.pbxdiamondcall.com:8081/departments/add",{title:element.value, office_id:activeSubItem})
            .then((res)=>{
                console.log(res);
                Swal.fire({
                  icon: 'success',
                  title: 'Result',
                  text: res.statusText,
                  timer:2000,
                  customClass: {
                  confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
              })
      connectAll()
            })
            .catch((err)=>{
              Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err.statusText,
                timer:2000,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
      connectAll()
              console.log(err)
            });
        }
        else alert("enter title");
    }
    function handleUpdateDepartments(){
        const element = titleDepartment.current;
        if(element.value !== ""){
            axios.post("https://www.pbxdiamondcall.com:8081/departments/update",{title:element.value, id:activeValue})
            .then((res)=>{
                console.log(res);
                Swal.fire({
                  title: 'Result',
                  text: res.statusText,
                  timer:2000,
                  customClass: {
                  confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
              })
      connectAll()
            })
            .catch((err)=>{
              Swal.fire({
                title: 'Result',
                text: err.statusText,
                timer:2000,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
      connectAll()
              console.log(err)
            });
        }
        else alert("enter title");
    }
    async function handleDeleteDepartments (id) {
      let isdel = await SwalDel();
      console.log(isdel);
      if(isdel){
        axios.delete("https://www.pbxdiamondcall.com:8081/departments/delete",{data:{id:activeValue?activeValue:id} })
        .then((res)=>{
          console.log(res);
          Swal.fire({
            icon:'success',
            title: 'Result',
            text: res.statusText,
            timer:2000,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
      connectAll()
      })
        .catch((err)=>{
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Authentification error.',
            text: err.statusText,
            timer:2000,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
      connectAll()
        })
      }
    }

    
    function handleSubmitOffices (){
        const element = titleOffice.current;
        if(element.value !== ""){
            axios.post("https://www.pbxdiamondcall.com:8081/offices/add",{title:element.value, company_id:activeSubItem})
            .then((res)=>{
                console.log(res);
                Swal.fire({
                  icon: 'success',
                  title: 'Result',
                  text: res.statusText,
                  timer:2000,
                  customClass: {
                  confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
              });
              connectAll();
            })
            .catch((err)=>{
              Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err.statusText,
                timer:2000,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            });
            connectAll();
              console.log(err)
            });
        }
        else alert("enter title");
    }
    function handleUpdateOffices (){
        const element = titleOffice.current;
        if(element.value !== ""){
            axios.post("https://www.pbxdiamondcall.com:8081/offices/update",{title:element.value, id:activeValue})
            .then((res)=>{
                console.log(res);
                Swal.fire({
                  title: 'Result',
                  text: res.statusText,
                  timer:2000,
                  customClass: {
                  confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
              })
              connectAll();
            })
            .catch((err)=>{
              Swal.fire({
                title: 'Result',
                text: err.statusText,
                timer:2000,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            connectAll();
              console.log(err)
            });
        }
        else alert("enter title");
    }
    async function handleDeleteOffices (id) {
      let isdel = await SwalDel();
      console.log(isdel);
      if(isdel){
        axios.delete("https://www.pbxdiamondcall.com:8081/offices/delete",{data:{id:activeValue?activeValue:id} })
        .then((res)=>{
          console.log(res);
          Swal.fire({
            icon:'success',
            title: 'Result',
            text: res.statusText,
            timer:2000,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        connectAll()
      })
        .catch((err)=>{
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Authentification error.',
            text: err.statusText,
            timer:2000,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        connectAll()
        })
      }
    }
    
    function handleSubmitCompanies(){
        var arr = [];
        prefixes.forEach(element => {
            arr.push({
            id:element.id,
            access:document.getElementById(element.id).checked
            });
        });
        const element = title.current;
        if(element.value !== ""){
            axios.post("https://www.pbxdiamondcall.com:8081/companies/add",{title:element.value, prefixes: arr})
            .then((res)=>{
                console.log(res);
                Swal.fire({
                    title: 'Result',
                    text: res.statusText,
                    timer:2000,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                connectAll();
            })
            .catch((err)=>{
                Swal.fire({
                title: 'Result',
                text: err.statusText,
                timer:2000,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            connectAll();
                console.log(err)
            });
        }
        else alert("enter title");
    }
    function handleUpdateCompanies(){
        var arr = [];
        prefixes.forEach(element => {
            arr.push({
            id:element.id,
            access:document.getElementById(element.id).checked
            });
        });
        console.log(arr);
        const element = title.current;
        let id = activeValue;
        if(element.value !== ""){
            console.log(id);
            axios.post("https://www.pbxdiamondcall.com:8081/companies/update",{title:element.value, id, prefixes:arr})
            .then((res)=>{
                console.log(res);
                Swal.fire({
                    icon: 'success',
                    title: 'Result',
                    text: res.statusText,
                    timer:2000,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                connectAll();
            })
            .catch((err)=>{
                Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err.statusText,
                timer:2000,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            connectAll();
                console.log(err)
            });
        }
        else alert("enter title");
    }
    function onUpdate(id){
        const currentTeam = items.companies?.find((val)=>val.Id == id);
            axios.post("https://www.pbxdiamondcall.com:8081/prefixes/company",
                {id:id})
            .then((res)=>{
                console.log(res);
                prefixes.forEach(element => {
                        document.getElementById(element.id).checked = false;
                });
                prefixes.forEach(element => {
                    res.data.forEach(el=>{
                    if(el.prefix_id === element.id){
                        document.getElementById(element.id).checked = true;
                    }
                    })
                });
            })
            .catch((err)=>console.log(err));
            const element = title.current;
            element.value = currentTeam?.Title;
            const el = document.getElementById('submitbtn_companies');
            el.disabled = true;
            const el1 = document.getElementById('updatebtn_companies');
            el1.disabled = false;
    }
    async function handleDeleteCompanies (id) {
        let isdel = await SwalDel();
        console.log(isdel);
        if(isdel){
        axios.delete("https://www.pbxdiamondcall.com:8081/companies/delete",{data:{id:activeValue?activeValue:id} })
        .then((res)=>{
            console.log(res);
            Swal.fire({
            icon:'success',
            title: 'Result',
            text: res.statusText,
            timer:2000,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        connectAll();
        })
        .catch((err)=>{
            console.log(err);
            Swal.fire({
            icon: 'error',
            title: 'Authentification error.',
            text: err.statusText,
            timer:2000,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        connectAll();
        })
        }
    }

    let roled = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(roled===3){
        options = [
          { value: 4, label: 'Department admin'},
          { value: 5, label: 'Team admin'},
          { value: 6, label: 'Agent'},
          { value: 8, label: 'Coach'},
      ]
    }
    else if(roled===9){
        options = [
            { value: 5, label: 'Team admin'},
            { value: 6, label: 'Agent'},
        ]
    }
    else if(roled===4){
    options = [
        { value: 5, label: 'Team admin'},
        { value: 6, label: 'Agent'},
        { value: 8, label: 'Coach'},
    ]
    }
    else if(roled===5){
    options = [
        { value: 6, label: 'Agent'},
        { value: 8, label: 'Coach'},
    ]
    }
    console.log(JSON.parse(secureLocalStorage.getItem("user")));
    if(roled === 6  || roled === 8){
        return <Navigate to="/onlinecalls" replace={true}/>
    }
    else
    return (
        <div className={styles.page}>
                <div className={styles.header}>
                    {roled===1 && <span className={activeItem!=="Companies"?styles.header_item:styles.header_item_active} onClick={()=>setActiveItem("Companies")}>
                        Companies
                    </span>}
                     {roled===1 &&<span className={activeItem!=="Brands"?styles.header_item:styles.header_item_active} onClick={()=>setActiveItem("Brands")}>
                        Offices
                    </span>}
                     {roled===1 &&<span className={activeItem!=="Offices"?styles.header_item:styles.header_item_active} onClick={()=>setActiveItem("Offices")}>
                        Departments
                    </span>}
                    <span className={activeItem!=="Departments"?styles.header_item:styles.header_item_active} onClick={()=>setActiveItem("Departments")}>
                        Teams
                    </span>
                    <span className={activeItem!=="Teams"?styles.header_item:styles.header_item_active} onClick={()=>setActiveItem("Teams")}>
                        Agents
                    </span>
                </div>
                {
                    activeItem!=="Companies"
                    ?
                    <div className={styles.container}>
                            <div className={styles.categories}>
                                <span className={styles.category_header}>
                                    <input 
                                        type="text" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder={`Search for ${activeItem.toLowerCase()}`}
                                        className={styles.category_search}
                                    />
                                </span>
                                {
                                    renderCategories()
                                }
                            </div>
                            <div className={styles.items}>
                                <span className={styles.item_header}>
                                {itemsTitles[activeItem]}
                                    {
                                    (activeSubItem && activeItem == "Teams" && !isLoading) && 
                                    <span className={styles.button_create} 
                                    data-bs-toggle="offcanvas" 
                                    data-bs-target="#offcanvasAddUser" onClick={()=>{
                                        teams?.forEach(element => {
                                            document.getElementById(element.id).checked = false;
                                        });
                                        const element = document.getElementById('updatebtn');
                                        element.disabled = true; 
                                        const el1 = document.getElementById('submitbtn');
                                        el1.disabled = false;
                                    }}>
                                        Create
                                    </span>
                                    }
                                    {
                                    (activeSubItem && activeItem == "Departments" && !isLoading) && 
                                    <span className={styles.button_create} 
                                    data-bs-toggle="offcanvas" 
                                    data-bs-target="#offcanvasAddTeam" onClick={()=>{
                                        const element = document.getElementById('updatebtn_teams');
                                        element.disabled = true; 
                                        const el1 = document.getElementById('submitbtn_teams');
                                        el1.disabled = false;
                                    }}>
                                        Create
                                    </span>
                                    }
                                    {
                                    (activeSubItem && activeItem == "Offices" && !isLoading) && 
                                    <span className={styles.button_create} 
                                    data-bs-toggle="offcanvas" 
                                    data-bs-target="#offcanvasAddDepartment" onClick={()=>{
                                        const element = document.getElementById('updatebtn_departments');
                                        element.disabled = true; 
                                        const el1 = document.getElementById('submitbtn_departments');
                                        el1.disabled = false;
                                    }}>
                                        Create
                                    </span>
                                    }
                                    {
                                    (activeSubItem && activeItem == "Brands" && !isLoading) && 
                                    <span className={styles.button_create} 
                                    data-bs-toggle="offcanvas" 
                                    data-bs-target="#offcanvasAddOffice" onClick={()=>{
                                        const element = document.getElementById('updatebtn_offices');
                                        element.disabled = true; 
                                        const el1 = document.getElementById('submitbtn_offices');
                                        el1.disabled = false;
                                    }}>
                                        Create
                                    </span>
                                    }
                                </span>
                                {
                                    isLoading ? 
                                    <div style={{width:"100%",height:"100%",display:'flex',alignItems:"center",justifyContent:"center"}}>
                                        <CircularProgress size={100} sx={{color:"#3EA3FC"}}/>
                                    </div>
                                    :
                                    values?.length>0?
                                    activeSubItem && values
                                    :
                                    <div style={{width:"100%",height:"100%",display:'flex',fontSize:"2vh",alignItems:"center",justifyContent:"center"}}>
                                        <span>
                                            No records...
                                        </span>
                                    </div>
                                }
                                
                            </div>
                    </div>
                    :
                    <div className={styles.container}>
                        <div className={styles.items}>
                            <span className={styles.item_header}>
                                {itemsTitles[activeItem]}
                                {
                                (activeSubItem && activeItem == "Teams" && !isLoading) && 
                                <span className={styles.button_create} 
                                data-bs-toggle="offcanvas" 
                                data-bs-target="#offcanvasAddUser" onClick={()=>{
                                    teams?.forEach(element => {
                                        document.getElementById(element.id).checked = false;
                                    });
                                    const element = document.getElementById('updatebtn');
                                    element.disabled = true; 
                                    const el1 = document.getElementById('submitbtn');
                                    el1.disabled = false;
                                }}>
                                    Create
                                </span>
                                }
                                {
                                (activeSubItem && activeItem == "Departments" && !isLoading) && 
                                <span className={styles.button_create} 
                                data-bs-toggle="offcanvas" 
                                data-bs-target="#offcanvasAddTeam" onClick={()=>{
                                    const element = document.getElementById('updatebtn_teams');
                                    element.disabled = true; 
                                    const el1 = document.getElementById('submitbtn_teams');
                                    el1.disabled = false;
                                }}>
                                    Create
                                </span>
                                }
                                {
                                (activeSubItem && activeItem == "Offices" && !isLoading) && 
                                <span className={styles.button_create} 
                                data-bs-toggle="offcanvas" 
                                data-bs-target="#offcanvasAddDepartment" onClick={()=>{
                                    const element = document.getElementById('updatebtn_departments');
                                    element.disabled = true; 
                                    const el1 = document.getElementById('submitbtn_departments');
                                    el1.disabled = false;
                                }}>
                                    Create
                                </span>
                                }
                                {
                                (activeSubItem && activeItem == "Brands" && !isLoading) && 
                                <span className={styles.button_create} 
                                data-bs-toggle="offcanvas" 
                                data-bs-target="#offcanvasAddOffice" onClick={()=>{
                                    const element = document.getElementById('updatebtn_offices');
                                    element.disabled = true; 
                                    const el1 = document.getElementById('submitbtn_offices');
                                    el1.disabled = false;
                                }}>
                                    Create
                                </span>
                                }
                                {
                                (activeItem == "Companies" && !isLoading) && 
                                <span className={styles.button_create} 
                                data-bs-toggle="offcanvas" 
                                data-bs-target="#offcanvasAddCompany" onClick={()=>{
                                    const element = document.getElementById('updatebtn_companies');
                                    element.disabled = true; 
                                    const el1 = document.getElementById('submitbtn_companies');
                                    el1.disabled = false;
                                }}>
                                    Create
                                </span>
                                }
                            </span>
                            {
                                isLoading ? 
                                <div style={{width:"100%",height:"100%",display:'flex',alignItems:"center",justifyContent:"center"}}>
                                    <CircularProgress size={100} sx={{color:"#3EA3FC"}}/>
                                </div>
                                :
                                values?.length>0?
                                    values
                                :
                                <div style={{width:"100%",height:"100%",display:'flex',fontSize:"2vh",alignItems:"center",justifyContent:"center"}}>
                                    <span>
                                        No records...
                                    </span>
                                </div>
                            }
                            
                        </div>
                    </div>
                }
                

                <div className="offcanvas offcanvas-end" tabIndex={-1} 
                id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel" style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
                    <div className="offcanvas-header">
                    <h5 id="offcanvasAddUserLabel" className="offcanvas-title">{t("agent")}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0" >
                    <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-login">{t("agentsThLogin")}</label>
                        <input type="text" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            className="form-control" 
                            id="add-agent-login" 
                            placeholder={t("agentsPlaceholder")} 
                            name="agentLogin" 
                            aria-label="Agent login"
                            ref={titleAgent} />
                        </div>
                        <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-psw">{t("agentsThPassword")}</label>
                        <input type="text" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            className="form-control" 
                            id="add-agent-psw" 
                            placeholder={t("agentsPswPlaceholder")} 
                            name="agentPassword" 
                            aria-label="Agent psw"
                            ref={password} />
                        </div>
                        <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">{t("agentsThName")}</label>
                        <input type="text" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            className="form-control" 
                            id="add-agent-fullname" 
                            placeholder={t("agentsFNPlaceholder")}
                            name="agentFullName" 
                            aria-label="Agent FullName"
                            ref={name} />   
                        </div>
                        <br />
                        <label className="form-label" htmlFor="add-agent-fullname">{t("agentsRole")}</label>
                        <ReactDropdown options={options} placeholder={t("agentsSelectRole")} ref={role} />
                        <br />
                        {JSON.parse(secureLocalStorage.getItem("user"))?.role_id === 1 && 
                        <>
                        <label className="form-label" htmlFor="add-agent-fullname">Server</label>
                        <ReactDropdown options={options1} placeholder='Select server' ref={server} />
                        </>
                        }
                        <br />
                        <label className="form-label" htmlFor="teams-list">{t("agentsTeams")}</label>
                        <div className="list-group" style={{maxHeight:400, overflow:"scroll", WebkitOverflowScrolling:'touch'}}>
                            {
                                teams?.map((val,ind)=>{
                                    return <label className="list-group-item" key={ind}>
                                        <label class="switch">
                                        <input type="checkbox" class="switch-input" id={val.id}  />
                                        <span class="switch-toggle-slider">
                                            <span class="switch-on">
                                            <i class="bx bx-check"></i>
                                            </span>
                                            <span class="switch-off">
                                            <i class="bx bx-x"></i>
                                            </span>
                                        </span>
                                        <span class="switch-label">{val?.title}</span>
                                        </label>
                                    </label>
                                })
                            }
                            
                        </div>
                        <br />
                        <div className="mb-3" style={JSON.parse(secureLocalStorage.getItem("user"))?.role_id === 1?{}:{display:"none"}}>
                        <label className="form-label" htmlFor="add-agent-fullname">Ban</label>
                        <input type="text" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            className="form-control" 
                            id="add-agent-fullname" 
                            placeholder={"Ban time"}
                            name="agentFullName" 
                            aria-label="Agent bantime"
                            ref={banTime} />   
                        </div>
                        <br />
                        <button 
                            id='submitbtn'
                            type="button" 
                            style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}
                            className="btn btn-success me-sm-3 me-1 data-submit"
                            onClick={handleSubmit}
                            >{t("submitbtn")}</button>
                        <button 
                            id='updatebtn'
                            type="button" 
                            className="btn btn-warning me-sm-3 me-1 data-submit"
                            style={{border:"0.1vh solid #ffab00",backgroundColor:"transparent"}}
                            onClick={handleUpdate}
                            >{t("updatebtn")}</button>
                        <button type="button" className='btn btn-danger me-sm-3 me-1' 
                        style={{border:"0.1vh solid #FF4861",backgroundColor:"transparent"}}
                        onClick={()=>handleDelete()}>{t("deletebtn") }
                        </button>
                        <br />
                        <br />
                        <button type="button" className='btn btn-info me-sm-3 me-1' 
                                    style={{border:"0.1vh solid #3EA3FC",backgroundColor:"transparent",marginRight:5}}
                                    data-bs-toggle="modal" data-bs-target="#modalTop"
                                    >Move Agent</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary me-sm-3 me-1" 
                            data-bs-dismiss="offcanvas"
                            style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>
                            {t("cancelbtn")}</button>
                    </form>
                    </div>
                </div>
                <div className="offcanvas offcanvas-end" tabIndex={-1}
                id="offcanvasAddTeam" aria-labelledby="offcanvasAddUserLabel"
                style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
                    <div className="offcanvas-header">
                    <h5 id="offcanvasAddUserLabel" className="offcanvas-title">{t("teams")}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                    <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <div className="mb-3">
                        <label className="form-label" htmlFor="add-team-title">{t("companiesThTitle")}</label>
                        <input type="text" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            className="form-control" 
                            id="add-team-title" 
                            placeholder={t("teamsPlaceholder")}
                            name="teamTitle" 
                            aria-label="Team title"
                            ref={titleTeam} />
                        </div>
                        <button 
                            id='submitbtn_teams'
                            type="button" 
                            style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}
                            className="btn btn-success me-sm-3 me-1 data-submit"
                            onClick={handleSubmitTeams}
                            >{t("submitbtn")}</button>
                        <button 
                            id='updatebtn_teams'
                            type="button" 
                            className="btn btn-warning me-sm-3 me-1 data-submit"
                            onClick={handleUpdateTeams}
                            style={{border:"0.1vh solid #ffab00",backgroundColor:"transparent"}}
                            >{t("updatebtn")}</button>
                            <button type="button" className='btn btn-danger me-sm-3 me-1' 
                            style={{border:"0.1vh solid #FF4861",backgroundColor:"transparent"}}
                            onClick={()=>handleDeleteTeams()} disabled={false}>{t("deletebtn")}</button>
                            <br />
                            <br />
                        <button 
                            type="button" 
                            className="btn btn-label-secondary me-sm-3 me-1" 
                            data-bs-dismiss="offcanvas"
                            style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>
                            {t("cancelbtn")}</button>
                    </form>
                    </div>
                </div>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddDepartment"
                aria-labelledby="offcanvasAddUserLabel" 
                style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
                    <div className="offcanvas-header">
                    <h5 id="offcanvasAddUserLabel" className="offcanvas-title">{t("departments")}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                    <form className="add-new-user pt-0" id="addNewDepartment" onsubmit="return false">
                        <div className="mb-3">
                        <label className="form-label" htmlFor="add-department-title">{t("companiesThTitle")}</label>
                        <input type="text" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            className="form-control" 
                            id="add-department-title" 
                            placeholder={t("departmentsPlaceholder")} 
                            name="departmentTitle" 
                            aria-label="Department title"
                            ref={titleDepartment} />
                        </div>
                        <button 
                            id='submitbtn_departments'
                            type="button" 
                            className="btn btn-success me-sm-3 me-1 data-submit"
                            onClick={handleSubmitDepartments}
                            style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}
                            >{t("submitbtn")}</button>
                        <button 
                            id='updatebtn_departments'
                            type="button" 
                            className="btn btn-warning me-sm-3 me-1 data-submit"
                            onClick={handleUpdateDepartments}
                            style={{border:"0.1vh solid #ffab00",backgroundColor:"transparent"}}
                            >{t("updatebtn")}</button>
                            <button type="button" className='btn btn-danger me-sm-3 me-1' 
                            style={{border:"0.1vh solid #FF4861",backgroundColor:"transparent"}} onClick={()=>handleDeleteDepartments()} disabled={false}>{t("deletebtn")}</button>
                            <br /><br />
                        <button 
                            type="button" 
                            className="btn btn-label-secondary" 
                            data-bs-dismiss="offcanvas"
                            style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>
                            {t("cancelbtn")}</button>
                    </form>
                    </div>
                </div>
                
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddOffice" aria-labelledby="offcanvasAddUserLabel" 
                style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
                    <div className="offcanvas-header">
                    <h5 id="offcanvasAddUserLabel" className="offcanvas-title">{t("offices")}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                    <form className="add-new-user pt-0" id="addNewOffice" onsubmit="return false">
                        <div className="mb-3">
                        <label className="form-label" htmlFor="add-office-title">{t("companiesThTitle")}</label>
                        <input type="text" 
                            className="form-control" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            id="add-office-title" 
                            placeholder={t("officesPlaceholder")}
                            name="officeTitle" 
                            aria-label="Office title"
                            ref={titleOffice} />
                        </div>
                        <button 
                            id='submitbtn_offices'
                            type="button" 
                            className="btn btn-success me-sm-3 me-1 data-submit"
                            onClick={handleSubmitOffices}
                            style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}
                            >{t("submitbtn")}</button>
                        <button 
                            id='updatebtn_offices'
                            type="button" 
                            className="btn btn-warning me-sm-3 me-1 data-submit"
                            onClick={handleUpdateOffices}
                            style={{border:"0.1vh solid #ffab00",backgroundColor:"transparent"}}
                            >{t("updatebtn")}</button>
                            <button type="button" className='btn btn-danger me-sm-3 me-1' 
                            style={{border:"0.1vh solid #FF4861",backgroundColor:"transparent"}}onClick={()=>handleDeleteOffices()} disabled={false}>{t("companiesThDelete")}</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary me-sm-3 me-1" 
                            data-bs-dismiss="offcanvas"
                            style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>
                            {t("cancelbtn")}</button>
                    </form>
                    </div>
                </div>
                
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddCompany"  style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}} aria-labelledby="offcanvasAddUserLabel">
                    <div className="offcanvas-header">
                    <h5 id="offcanvasAddUserLabel" className="offcanvas-title">{t("companies")}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                    <form className="add-new-user pt-0" id="addNewCompany" onsubmit="return false">
                        <div className="mb-3">
                        <label className="form-label" htmlFor="add-company-title">{t("companiesThTitle")}</label>
                        <input type="text" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            className="form-control" 
                            id="add-company-title" 
                            placeholder={t("companiesPlaceholder")}
                            name="companyTitle" 
                            aria-label="Company title"
                            ref={title} />
                        </div>
                        <br />
                        <label className="form-label" htmlFor="teams-list">{t("companiesPrefixes")}</label>
                        <div className="list-group" style={{maxHeight:400, overflow:"scroll", WebkitOverflowScrolling:'touch'}}>
                            {
                                prefixes.map((val,ind)=>{
                                    return <label className="list-group-item" key={ind}>
                                        <label class="switch">
                                        <input type="checkbox" class="switch-input" id={val.id}  />
                                        <span class="switch-toggle-slider">
                                            <span class="switch-on">
                                            <i class="bx bx-check"></i>
                                            </span>
                                            <span class="switch-off">
                                            <i class="bx bx-x"></i>
                                            </span>
                                        </span>
                                        <span class="switch-label">{val.prefix}_{val.title}</span>
                                        </label>
                                    </label>
                                })
                            }
                            
                        </div>
                        <br />
                        <button 
                            id='submitbtn_companies'
                            type="button" 
                            className="btn btn-success me-sm-3 me-1 data-submit"
                            style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}
                            onClick={handleSubmitCompanies}
                            >{t("submitbtn")}</button>
                        <button 
                            id='updatebtn_companies'
                            type="button" 
                            className="btn btn-warning me-sm-3 me-1 data-submit"
                            style={{border:"0.1vh solid #ffab00",backgroundColor:"transparent"}}
                            onClick={handleUpdateCompanies}
                            >{t("companiesThUpdate")}</button>
                        <button type="button" className='btn btn-danger me-sm-3 me-1 '
                            style={{border:"0.1vh solid #FF4861",backgroundColor:"transparent"}} onClick={()=>handleDeleteCompanies()} disabled={false}>{t("companiesThDelete")}</button>
                        <br /><br />  
                        <button 
                            type="button" 
                            className="btn btn-label-secondary me-sm-3 me-1 " 
                            data-bs-dismiss="offcanvas"
                            style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>
                            {t("cancelbtn")}</button>
                    </form>
                    </div>
                </div>
                <div className="modal fade" data-bs-backdrop="static" id="modalTop" tabIndex={-1}>
                                <div className="modal-dialog modal-dialog-centered">
                                    <form className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="modalTopTitle">Move agent</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>handleClose()}/>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col mb-3">
                                            <Select options={moveTeams}
                                                onChange={(e)=>SetMoveTeamId(e.value)}
                                                placeholder='Select team' id='prefixupdLb'
                                                styles={{
                                                    control:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"#444663",
                                                        color:"#b8b8cd",
                                                        borderColor:"#444663"
                                                    }),
                                                    option: (styles, { isFocused, isSelected }) => {
                                                        return {
                                                            ...styles,
                                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                        };
                                                    },
                                                    singleValue:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        color:"#b8b8cd"
                                                    }),
                                                    placeholder:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"#444663",
                                                        color:"#b8b8cd"
                                                    }),
                                                    menuList:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"#444663",
                                                        color:"#b8b8cd",
                                                    }),
                                                }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" onClick={()=>handleClose()}>Close</button>
                                        <button type="button" className="btn btn-primary" onClick={()=>handleMove()}>Move</button>
                                    </div>
                                    </form>
                                </div>
                </div>
        </div>
    )
}
