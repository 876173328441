import React, { useEffect, useMemo, useState } from 'react';
import styles from './Suspects.module.scss';
import secureLocalStorage from 'react-secure-storage';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import axios from 'axios';
import { blue } from '@mui/material/colors';
import { Tooltip } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Swal from 'sweetalert2';
import Select from 'react-select';

export default function SuspectedNumbers() {
    const [data,setData] = useState([]);

    const [updateNumber,setNumberUpdate]=useState('');
    const [updateId,setUpdateId] = useState(0);
    
    const SwalDel = () =>{
        return Swal.fire({ // <-- return Promise
            title: 'Delete record?',
            text: 'This action cannot be undone.',
            icon: 'question',
            confirmButtonText: 'Delete',
            confirmButtonColor: '#DC004E',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            focusConfirm: false,
            focusCancel: false,
            scrollbarPadding: false
            }).then((result) => {
            return result.isConfirmed;
            });
    }
    const loadInfo=()=>{
        axios.get('https://www.pbxdiamondcall.com:8081/suspects')
        .then((res)=>{
            setData(res.data);
        })
        .catch((err)=>console.log(err))
    }
    const handleDelete=async(id)=>{
        let isdel = await SwalDel();
        if(isdel){
            axios.post('https://www.pbxdiamondcall.com:8081/number/delete',{id:id})
            .then((res)=>{
                console.log(res);
                Swal.fire({
                    icon:'success',
                    title: 'Result',
                    text: res.statusText,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                loadInfo();
            })
            .catch((err)=>{
                Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
                console.log(err)
            });
        }   
    }
    const handleUpdate = () =>{
        axios.post('https://www.pbxdiamondcall.com:8081/number/replace',{
            id:updateId,
            number:updateNumber
        })
        .then((res)=>{
            console.log(res);
            Swal.fire({
                icon:'success',
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
        })
        .catch((err)=>{
            Swal.fire({
            icon: 'warning',
            title: 'Result',
            text: err,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        console.log(err)
        });
    }
    useEffect(()=>{
        loadInfo()
    },[])
    const columns = useMemo(
        () => [
            {
                accessorKey: 'number',
                header: 'Number',
                size: 700, 
                Cell: ({ renderedCellValue, row }) => {
                    console.log()
                    return (
                        <span style={{ paddingLeft:"1vw"}}>{row.original.number}</span>
                    )
                },
            },
            {
            accessorKey: 'title_prefix',
            header: 'Prefix',
            grow:false, 
            size:500,
            Cell: ({ renderedCellValue, row }) => {
                return (
                    <span>{row.original.prefix}</span>
                )
            },
            },
            {
            accessorKey: 'action',
            header: '',
            enableResizing:false,
            enableSorting:false,
            enableColumnDragging:false,
            enableColumnActions: false,
            enableColumnFilter:false,
            grow:false, 
            size:300,
            Cell: ({ renderedCellValue, row }) => {
                return (
                    <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center", gap:"0.2vw"}}>
                        <Tooltip title={"Replace"}>
                            <span className={styles.button} 
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasEdit"
                            onClick={()=>{setUpdateId(row.original.id);console.log(updateId)}}>
                                Replace
                            </span>
                        </Tooltip>
                        <Tooltip title={"Delete"}>
                            <span className={styles.button_delete} 
                            onClick={()=>{
                                handleDelete(row.original.id)
                            }}
                            >
                                Delete
                            </span>
                        </Tooltip>
                    </div>
                )
            },
            },
            
        ],
        [],
    );
    const table = useMaterialReactTable({
        columns,
        data,
        enableStickyHeader:true,
        enableStickyFooter:true,
        enableColumnOrdering: true,
        muiDetailPanelProps: () => ({
        sx: (theme) => ({
            backgroundColor:'#2A2A31'
        }),
        }),
        onColumnOrderChange: (order)=>console.log(order),
        initialState: { 
            density: 'compact',
            pagination: {pageSize:50},
        },
        muiSelectCheckboxProps: { 
            color: 'primary', 
            sx:(theme)=>({
                color: blue[50],
                '&.Mui-checked': {
                    color: blue[500],
                },
            })
        },
        positionToolbarAlertBanner: 'none', 
        getRowId: (row) => row.Id,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 160,
        }, 
        muiTableBodyProps: {
            sx: (theme) => ({
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
                {
                    backgroundColor:'#2A2A31',
                    borderBottom:'1px dashed rgb(46, 50, 54)',
        
                },
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                {
                    backgroundColor: '#2A2A31',
                    borderBottom:'1px dashed rgb(46, 50, 54)',
        
                },
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
                {
                    backgroundColor: '#2A2A31',
                    
                    borderBottom:'1px dashed rgb(46, 50, 54)',
                },
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                {
                    backgroundColor: '#2A2A31',
                    borderBottom:'1px dashed rgb(46, 50, 54)',
                },
            }),
        },
        muiTableContainerProps: {
            sx: (theme) => ({
                height:"68vh",
                maxWidth:"90vw"
            })
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: '#2A2A31',
            draggingBorderColor: '#000',
        }),
        muiTableBodyCellProps: ({ column }) => ({
            sx: {
                backgroundColor:'#2A2A31',
                fontFamily:"'Open Sans', sans-serif",
                fontSize: '14px',
                color:'#fff',
                borderBottom:'1px dashed rgb(46, 50, 54)',
                '& .MuiTableCell-root':{
                    borderBottom:'1px dashed rgb(46, 50, 54)'
                }
            },
        }),
        muiTableProps: ({table})=>({
            sx:{
                backgroundColor:'grey',
                fontFamily:"'Open Sans', sans-serif",
                fontSize: '14px',
                border:"1px solid rgb(46, 50, 54)"
            }
        }),
        muiTopToolbarProps: ({table})=>({
            sx:{
                backgroundColor:"#2A2A31",
                borderTop:"1px solid rgb(46, 50, 54)",
                borderLeft:"1px solid rgb(46, 50, 54)",
                borderRight:"1px solid rgb(46, 50, 54)",
                fontFamily:"'Open Sans', sans-serif",
                fontSize: '14px',
                borderTopLeftRadius:'5px',
                borderTopRightRadius:'5px',
                '& .MuiButtonBase-root':{
                color:'#fff'
                },
                '& .MuiSvgIcon-root':{
                color:'#fff'

                },
                '& .MuiInputBase-root':{
                borderColor:'#fff'

                },
                '& .MuiInputBase-input':{
                color:'#fff',
                fontFamily:"'Open Sans', sans-serif",
                }
            }
        }),
        muiColumnActionsButtonProps: ({row})=>({
            sx:{
                color:'#fff'
            }
        }),
        muiTableHeadCellProps: {
            sx: {
                fontWeight: 'bold',
                fontFamily:"'Open Sans', sans-serif",
                fontSize: '14px',
                backgroundColor:'#2A2A31',
                color:'#fff',
                
                borderBottom:'1px dashed rgb(46, 50, 54)',
                '& .MuiSvgIcon-root':{
                fill:'#fff'
                },
                '& .MuiDivider-root':{
                borderColor:'lightgrey'
                },
                '& .MuiInputBase-input':{
                color:'#fff',
                fontFamily:"'Open Sans', sans-serif",
                }
            },
        },
        muiTableHeadProps:{
            sx:{
                zIndex:1,
                '& .MuiTableCell-root':{
                border:"1px solid rgb(46, 50, 54)",
                },
            }
        },
        muiPaginationProps: {
            showRowsPerPage: false,
            shape: 'rounded',
            sx:{
                '& .MuiButtonBase-root':{
                    color:'#fff'
                },
                '& .Mui-selected':{
                    backgroundColor:"rgba(62, 163, 252, 0.213) !important"
                }
            }
        },
        muiBottomToolbarProps:{
            sx:{
                backgroundColor:'#2A2A31',
                borderBottom:"1px solid rgb(46, 50, 54)",
                borderLeft:"1px solid rgb(46, 50, 54)",
                borderRight:"1px solid rgb(46, 50, 54)",
                color:'#fff'
            },
        },
        muiFilterTextFieldProps:{
            sx:{
                color:'#fff'
            }
        },
        paginationDisplayMode: 'pages',
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        localization:{
        //   actions: 'Действия',
        //   and: 'и',
        //   cancel: 'Отменить',
        //   changeFilterMode: 'Изменить режим фильтра',
        //   changeSearchMode: 'Изменить режим поиска',
        //   clearFilter: 'Очистить фильтр',
        //   clearSearch: 'Очистить поиск',
        //   clearSelection: 'Очистить выбор',
        //   clearSort: 'Очистить сортировку',
        //   clickToCopy: 'Нажмите, чтобы скопировать',
        //   copy: 'Копировать',
        //   columnActions: 'Действие колонки',
        //   copiedToClipboard: 'Скопировано в буфер обмена',
        //   collapse: 'Свернуть',
        //   collapseAll: 'Свернуть все',
        //   dropToGroupBy: 'Добавить в группу к {column}',
        //   edit: 'Редактировать',
          expand: 'Audio',
        //   expandAll: 'Раскрыть все',
        //   filterArrIncludes: 'Включить',
        //   filterArrIncludesAll: 'Влючить все',
        //   filterArrIncludesSome: 'Включить',
        //   filterBetween: 'Между',
        //   filterBetweenInclusive: 'Между включительно',
        //   filterByColumn: 'Отфильтровать по {column}',
        //   filterContains: 'Содержит',
        //   filterEmpty: 'Пустой',
        //   filterEndsWith: 'Оканчивается',
        //   filterEquals: 'Равны',
        //   filterEqualsString: 'Равны',
        //   filterFuzzy: 'Физзи',
        //   filterGreaterThan: 'Больше чем',
        //   filterGreaterThanOrEqualTo: 'Больше или равно',
        //   filterInNumberRange: 'Между',
        //   filterIncludesString: 'Содержит',
        //   filterIncludesStringSensitive: 'Содержит (регистрозависимый)',
        //   filterLessThan: 'Меньше чем',
        //   filterLessThanOrEqualTo: 'Меньше или равно',
        //   filterMode: 'Режим фильтра: {filterType}',
        //   filterNotEmpty: 'Не пустое',
        //   filterNotEquals: 'Не равны',
        //   filterStartsWith: 'Начинается с',
        //   filterWeakEquals: 'Равны',
        //   filteringByColumn: 'Фильтр по {column} - {filterType} {filterValue}',
        //   goToFirstPage: 'Перейти на первую страницу',
        //   goToLastPage: 'Перейти на последнюю страницу',
        //   goToNextPage: 'Перейти на следующую страницу',
        //   goToPreviousPage: 'Перейти на предыдущую страницу',
        //   grab: 'Grab',
        //   groupByColumn: 'Сгруппировать по {column}',
        //   groupedBy: 'Сгруппировать',
        //   hideAll: 'Скрыть всё',
        //   hideColumn: 'Скрыть {column} колонку',
        //   max: 'Макс',
        //   min: 'Мин',
        //   move: 'Двигать',
        //   noRecordsToDisplay: <span style={{color:'#fff'}}>Нет записей для показа</span>,
        //   noResultsFound: 'Результатов не найдено',
        //   of: 'из',
        //   or: 'или',
        //   pin: 'Прикрепить',
        //   pinToLeft: 'Прикрепить слева',
        //   pinToRight: 'Прикрепить справа',
        //   resetColumnSize: 'Сбросить размер колонок',
        //   resetOrder: 'Сбросить порядок',
        //   rowActions: 'Действия строки',
        //   rowNumber: '#',
        //   rowNumbers: 'Количество строк',
        //   rowsPerPage: 'Строк на странице',
        //   save: 'Сохранить',
        //   search: 'Найти',
        //   selectedCountOfRowCountRowsSelected: '{selectedCount} из {rowCount} строк выбрано',
        //   select: 'Выбрать',
        //   showAll: 'Показать все',
        //   showAllColumns: 'Показать все колонки',
        //   showHideColumns: 'Показать/скрыть колонки',
        //   showHideFilters: 'Показать/скрыть фильтры',
        //   showHideSearch: 'Показать/скрыть поиск',
        //   sortByColumnAsc: 'Сортировать {column} по возрастанию',
        //   sortByColumnDesc: 'Сортировать {column} по убыванию',
        //   sortedByColumnAsc: 'Отсортирована по {column} возрастанию',
        //   sortedByColumnDesc: 'Отсортирована по {column} убыванию',
        //   thenBy: ', затем ',
        //   toggleDensity: 'Изменить плотность',
        //   toggleFullScreen: 'Включить полноэкранный режим',
        //   toggleSelectAll: 'Выбрать всё',
        //   toggleSelectRow: 'Переключить выбор строки',
        //   toggleVisibility: 'Изменить видимость',
        //   ungroupByColumn: 'Разгруппировать по {column}',
        //   unpin: 'Открепить',
        //   unpinAll: 'Открепить всё',
        }
    },[data]);

    return (
        <div className={styles.page}>
            <div className={styles.history}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MaterialReactTable table={table} />
                </LocalizationProvider>
            </div>
            
    <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasEdit" aria-labelledby="offcanvasEditLabel" style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEditLabel" className="offcanvas-title">Phone number update</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname">Number</label>
                            <input type="text"
                                className="form-control" 
                                style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                value={updateNumber}
                                onChange={(e)=>setNumberUpdate(e.target.value)}
                                />   
                        </div>
                        <br />
                        <button 
                            id='updatebtn'
                            type="button" 
                            className="btn btn-warning me-sm-3 me-1 data-submit"
                            onClick={()=>handleUpdate()}
                            >Update</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary" 
                            data-bs-dismiss="offcanvas">
                            Cancel</button>
                        </form>
                    </div>
    </div>
        </div>
    )
}
