import React, { useEffect, useMemo, useState } from 'react';
import styles from './Gateways.module.scss';
import secureLocalStorage from 'react-secure-storage';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import axios from 'axios';
import { blue } from '@mui/material/colors';
import { Tooltip } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Swal from 'sweetalert2';

export default function Gateways() {
    const [data,setData] = useState([]);
    const [prefixes,setPrefixes] = useState([]);
    const [activeGateway,setActiveGateway] = useState(0);
    const [title,setTitle] = useState("");
    const [titleMask,setTitleMask] = useState("");
    const [domain,setDomain] = useState("");
    const [phoneUse,setPhoneUse] = useState(0);
    const [params,setParams] = useState("");
    const [caleeName,setCaleeName] = useState(0);
    const [caleeNum,setCaleeNum] = useState(0);
    const [provider,setProvider] = useState(0);
    const [additionalPrefix,setAdditionalPrefix] = useState("");
    const [useAdditional,setUseAdditional] = useState(1);

    const setGatewayInfo = (val) =>{
        setTitle(val.title);
        setTitleMask(val.titlemask);
        setDomain(val.domain);
        setPhoneUse(val.phone_use);
        setParams(val.params);
        setCaleeName(val.calee_name);
        setCaleeNum(val.calee_num);
        setProvider(val.IsProvider);
        setAdditionalPrefix(val.additional_prefix);
        setUseAdditional(1);
        setActiveGateway(val.id);
        axios.get('https://www.pbxdiamondcall.com:8081/prefixes')
        .then((res)=>{
            axios.post("https://www.pbxdiamondcall.com:8081/gateway/prefixes",
            {gateway:val.id})
            .then((ress)=>{
                res.data.forEach(element => {
                        document.getElementById(`${element.id}_upd`).checked = false;
                });
                res.data.forEach(element => {
                    ress.data.forEach(el=>{
                        if(el.prefix_id === element.id){
                            document.getElementById(`${element.id}_upd`).checked = true;
                        }
                    })
                });
            })
            .catch((err)=>console.log(err));
        })
        .catch((err)=>console.log(err))
    }
    const clearGatewayInfo = (val) =>{
        setTitle("");
        setTitleMask("");
        setDomain("");
        setPhoneUse(0);
        setParams("");
        setCaleeName(0);
        setCaleeNum(0);
        setProvider(0);
        setAdditionalPrefix("");
        setUseAdditional(1);
        prefixes.forEach(element => {
            document.getElementById(`${element.id}_upd`).checked = false;
    });
    }
    const deleteGateway = async (val) => {
        let isdel = await SwalDel();
        if(isdel){
        axios.post('https://www.pbxdiamondcall.com:8081/gateway/delete',{id:val.id})
        .then((res)=>{
            console.log(res);
            Swal.fire({
                icon:'success',
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            loadInfo();
        })
        .catch((err)=>{
            Swal.fire({
            icon: 'warning',
            title: 'Result',
            text: err,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
            console.log(err)
        });
    }
    }
    const SwalDel = () =>{
        return Swal.fire({ // <-- return Promise
            title: 'Delete record?',
            text: 'This action cannot be undone.',
            icon: 'question',
            confirmButtonText: 'Delete',
            confirmButtonColor: '#DC004E',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            focusConfirm: false,
            focusCancel: false,
            scrollbarPadding: false
            }).then((result) => {
            return result.isConfirmed;
            });
    }
    
    const handleSubmit = () =>{
        var arr = [];
        prefixes.forEach(element => {
            arr.push({
                id:element.id,
                access:document.getElementById(element.id).checked
            });
        });
        axios.post('https://www.pbxdiamondcall.com:8081/gateway',{
            prefixes:arr,
            title:title,
            phone_use:phoneUse?1:0,
            domain:domain,
            params:params,
            calee_num:caleeNum?1:0,
            calee_name:caleeName?1:0,
            provider:provider?1:0,
            additional_prefix:additionalPrefix,
            titlemask:titleMask
        })
        .then((res)=>{
            console.log(res);
            Swal.fire({
                icon:'success',
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            loadInfo()
        })
        .catch((err)=>{
            Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            console.log(err)
        });

    }
    const loadInfo=()=>{
        axios.get('https://www.pbxdiamondcall.com:8081/gateways')
        .then((res)=>{
            setData(res.data.data);
        })
        .catch((err)=>console.log(err))
        axios.get('https://www.pbxdiamondcall.com:8081/prefixes')
        .then((res)=>{
            var options2 = [];
            res.data.forEach((element, idx) => {
                options2.push({value:element.id,label:`${element.title|element.prefix}`});
            });
            secureLocalStorage.setItem('prefixes',options2);
            setPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
    }
    const handleUpdate = () =>{
        var arr = [];
        prefixes.forEach(element => {
            arr.push({
                id:element.id,
                access:document.getElementById(`${element.id}_upd`).checked
            });
        });
        axios.post('https://www.pbxdiamondcall.com:8081/gateway/update',{
            id:activeGateway,
            prefixes:arr,
            title:title,
            phone_use:phoneUse?1:0,
            domain:domain,
            params:params,
            calee_num:caleeNum?1:0,
            calee_name:caleeName?1:0,
            provider:provider?1:0,
            additional_prefix:additionalPrefix,
            titlemask:titleMask
        })
        .then((res)=>{
            console.log(res);
            Swal.fire({
                icon:'success',
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            loadInfo()
        })
        .catch((err)=>{
            Swal.fire({
            icon: 'warning',
            title: 'Result',
            text: err,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        console.log(err)
        });
    }
    useEffect(()=>{
        loadInfo()
    },[])
    const columns = useMemo(
        () => [
            {
                accessorKey: 'titlemask',
                header: 'Title',
                size: 200, 
                Cell: ({ renderedCellValue, row }) => {
                    console.log()
                    return (
                        <span style={{ paddingLeft:"1vw"}}>{row.original.titlemask}</span>
                    )
                },
            },
            {
            accessorKey: 'domain',
            header: 'Domain',
            grow:false, 
            size:200,
            Cell: ({ renderedCellValue, row }) => {
                return (
                    <span>{row.original.domain}</span>
                )
            },
            },
            {
            accessorKey: 'phone_use',
            header: 'Phone_use',
            grow:false, 
            size:200,
            Cell: ({ renderedCellValue, row }) => {
                return (
                    <span>{row.original.phone_use?"True":"False"}</span>
                )
            },
            },
            {
            accessorKey: 'params',
            header: 'params',
            grow:false, 
            size:350,
            Cell: ({ renderedCellValue, row }) => {
                return (
                    <textarea class='form-control' rows='5' readonly='true'
                    style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}>
                        {row.original.params}
                    </textarea>
                )
            }
            },
            {
            accessorKey: 'action',
            header: '',
            enableResizing:false,
            enableSorting:false,
            enableColumnDragging:false,
            enableColumnActions: false,
            enableColumnFilter:false,
            grow:false, 
            size:300,
            Cell: ({ renderedCellValue, row }) => {
                return (
                    <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center", gap:"0.2vw"}}>
                        <Tooltip title={"Update"}>
                            <span className={styles.button} 
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasEdit"
                            onClick={()=>{
                                setGatewayInfo(row.original);
                            }}>
                                Update
                            </span>
                        </Tooltip>
                        <Tooltip title={"Delete"}>
                            <span className={styles.button_delete} 
                            onClick={()=>{
                                deleteGateway(row.original)
                            }}
                            >
                                Delete
                            </span>
                        </Tooltip>
                    </div>
                )
            },
            },
            
        ],
        [],
    );
    const table = useMaterialReactTable({
        columns,
        data,
        enableStickyHeader:true,
        enableStickyFooter:true,
        enableColumnOrdering: true,
        // rowCount:rowCount,
        // state: {
        //     columnFilters,
        //     globalFilter,
        //     isLoading,
        //     pagination,
        //     showAlertBanner: isError,
        //     showProgressBars: isRefetching,
        //     sorting,
        // },
        // renderDetailPanel: ({ row }) => <div style={{width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
        //     </div>,
        muiDetailPanelProps: () => ({
        sx: (theme) => ({
            backgroundColor:'#2A2A31'
        }),
        }),
        renderTopToolbarCustomActions: ({ table }) => {
            return (
                <div className={styles.select}>
                    <span className={styles.button_create} 
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasAddUser"
                            onClick={()=>{
                                clearGatewayInfo();
                            }}>
                                Create gateway
                    </span>
                </div>
            )
        },
        // muiExpandButtonProps: ({ row, table }) => ({
        //     onClick: () => {
        //         table.setExpanded({ [row.id]: !row.getIsExpanded() });
        //         setAudioLoading(true);
        //         axios.post('https://www.pbxdiamondcall.com:8081/getrecord', {filename:row.original.uuid},
        //         {responseType:'blob'})
        //         .then((res)=>{
        //             if(res.status===400){
        //                 console.log("audio not found");
        //                 // self.setState({audioNotFound:true});
        //             }
        //             else{
        //             setAudioFound(true);
        //             var binaryData = [];
        //             binaryData.push(res.data)
        //             const audioUrl = URL.createObjectURL(new Blob(binaryData, {type:"audio/mp3"}));
        //             console.log(audioUrl);
        //             setAudio(audioUrl);
        //             setAudioLoading(false);
        //             // if(document.getElementById("record")!=null)
        //             // document.getElementById("record").load();
        //             // self.setState({downloaded:true});
        //             }
        //         })
        //         .catch((err)=>{
        //             setAudioLoading(false);setAudioFound(false)});

        //     }, 
        //     children:  row.getIsExpanded() 
        //     ? 
        //         <MicOffIcon sx={{width:"3vw", marginLeft:"1.5vw",height:"3.5vh",border:"1px solid #b47fe9",background:"#232329", color:"#b47fe9", padding:"0.2vw 0.4vh", borderRadius:"0.4vh"}} />
        //     :
        //         <KeyboardVoiceIcon sx={{width:"3vw", marginLeft:"1.5vw",height:"3.5vh",border:"1px solid #b47fe9",background:"#232329", color:"#b47fe9", padding:"0.2vw 0.4vh", borderRadius:"0.4vh"}} />,
        //     sx: {
        //         color:"#fff",
        //         transition: 'transform 0.2s',
        //         // transform: row.getIsExpanded() ? 'rotate(90deg)' : 'rotate(0deg)',
        //         // transition: 'transform 0.2s',
        //     },
        // }),
        onColumnOrderChange: (order)=>console.log(order),
        // onColumnVisibilityChange: (visibility)=>console.log(visibility),
        initialState: { 
            density: 'compact',
            pagination: {pageSize:50},
        //   columnOrder:[ 'time', 'company', 'sip_user', 'dst', 'duration', 'conversation',
        //     'status', 'Record', 'mrt-row-spacer'
        //   ]
        },
        muiSelectCheckboxProps: { 
          color: 'primary', 
          sx:(theme)=>({
            color: blue[50],
              '&.Mui-checked': {
                color: blue[500],
              },
          })
        },
        positionToolbarAlertBanner: 'none', 
        getRowId: (row) => row.Id,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 160,
        }, 
        muiTableBodyProps: {
          sx: (theme) => ({
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
              {
                backgroundColor:'#2A2A31',
                borderBottom:'1px dashed rgb(46, 50, 54)',
    
              },
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
              {
                backgroundColor: '#2A2A31',
                borderBottom:'1px dashed rgb(46, 50, 54)',
    
              },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
              {
                backgroundColor: '#2A2A31',
                
                borderBottom:'1px dashed rgb(46, 50, 54)',
              },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
              {
                backgroundColor: '#2A2A31',
                borderBottom:'1px dashed rgb(46, 50, 54)',
              },
          }),
        },
        muiTableContainerProps: {
          sx: (theme) => ({
            height:"68vh",
            maxWidth:"90vw"
          })
        },
        mrtTheme: (theme) => ({
          baseBackgroundColor: '#2A2A31',
          draggingBorderColor: '#000',
        }),
        muiTableBodyCellProps: ({ column }) => ({
            sx: {
              backgroundColor:'#2A2A31',
              fontFamily:"'Open Sans', sans-serif",
              fontSize: '14px',
              color:'#fff',
              borderBottom:'1px dashed rgb(46, 50, 54)',
              '& .MuiTableCell-root':{
                borderBottom:'1px dashed rgb(46, 50, 54)'
              }
            },
        }),
        muiTableProps: ({table})=>({
        sx:{
            backgroundColor:'grey',
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '14px',
            border:"1px solid rgb(46, 50, 54)"
        }
        }),
        muiTopToolbarProps: ({table})=>({
        sx:{
            backgroundColor:"#2A2A31",
            borderTop:"1px solid rgb(46, 50, 54)",
            borderLeft:"1px solid rgb(46, 50, 54)",
            borderRight:"1px solid rgb(46, 50, 54)",
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '14px',
            borderTopLeftRadius:'5px',
            borderTopRightRadius:'5px',
            '& .MuiButtonBase-root':{
            color:'#fff'
            },
            '& .MuiSvgIcon-root':{
            color:'#fff'

            },
            '& .MuiInputBase-root':{
            borderColor:'#fff'

            },
            '& .MuiInputBase-input':{
            color:'#fff',
            fontFamily:"'Open Sans', sans-serif",
            }
        }
        }),
        muiColumnActionsButtonProps: ({row})=>({
        sx:{
            color:'#fff'
        }
        }),
        muiTableHeadCellProps: {
        sx: {
            fontWeight: 'bold',
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '14px',
            backgroundColor:'#2A2A31',
            color:'#fff',
            
            borderBottom:'1px dashed rgb(46, 50, 54)',
            '& .MuiSvgIcon-root':{
            fill:'#fff'
            },
            '& .MuiDivider-root':{
            borderColor:'lightgrey'
            },
            '& .MuiInputBase-input':{
            color:'#fff',
            fontFamily:"'Open Sans', sans-serif",
            }
        },
        },
        muiTableHeadProps:{
        sx:{
            zIndex:1,
            '& .MuiTableCell-root':{
            border:"1px solid rgb(46, 50, 54)",
            },
        }
        },
        muiPaginationProps: {
        showRowsPerPage: false,
        shape: 'rounded',
        sx:{
            '& .MuiButtonBase-root':{
                color:'#fff'
            },
            '& .Mui-selected':{
                backgroundColor:"rgba(62, 163, 252, 0.213) !important"
            }
        }
        },
        muiBottomToolbarProps:{
        sx:{
            backgroundColor:'#2A2A31',
            borderBottom:"1px solid rgb(46, 50, 54)",
            borderLeft:"1px solid rgb(46, 50, 54)",
            borderRight:"1px solid rgb(46, 50, 54)",
            color:'#fff'

        },
        },
        muiFilterTextFieldProps:{
        sx:{
            color:'#fff'
        }
        },
        paginationDisplayMode: 'pages',
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        localization:{
        //   actions: 'Действия',
        //   and: 'и',
        //   cancel: 'Отменить',
        //   changeFilterMode: 'Изменить режим фильтра',
        //   changeSearchMode: 'Изменить режим поиска',
        //   clearFilter: 'Очистить фильтр',
        //   clearSearch: 'Очистить поиск',
        //   clearSelection: 'Очистить выбор',
        //   clearSort: 'Очистить сортировку',
        //   clickToCopy: 'Нажмите, чтобы скопировать',
        //   copy: 'Копировать',
        //   columnActions: 'Действие колонки',
        //   copiedToClipboard: 'Скопировано в буфер обмена',
        //   collapse: 'Свернуть',
        //   collapseAll: 'Свернуть все',
        //   dropToGroupBy: 'Добавить в группу к {column}',
        //   edit: 'Редактировать',
          expand: 'Audio',
        //   expandAll: 'Раскрыть все',
        //   filterArrIncludes: 'Включить',
        //   filterArrIncludesAll: 'Влючить все',
        //   filterArrIncludesSome: 'Включить',
        //   filterBetween: 'Между',
        //   filterBetweenInclusive: 'Между включительно',
        //   filterByColumn: 'Отфильтровать по {column}',
        //   filterContains: 'Содержит',
        //   filterEmpty: 'Пустой',
        //   filterEndsWith: 'Оканчивается',
        //   filterEquals: 'Равны',
        //   filterEqualsString: 'Равны',
        //   filterFuzzy: 'Физзи',
        //   filterGreaterThan: 'Больше чем',
        //   filterGreaterThanOrEqualTo: 'Больше или равно',
        //   filterInNumberRange: 'Между',
        //   filterIncludesString: 'Содержит',
        //   filterIncludesStringSensitive: 'Содержит (регистрозависимый)',
        //   filterLessThan: 'Меньше чем',
        //   filterLessThanOrEqualTo: 'Меньше или равно',
        //   filterMode: 'Режим фильтра: {filterType}',
        //   filterNotEmpty: 'Не пустое',
        //   filterNotEquals: 'Не равны',
        //   filterStartsWith: 'Начинается с',
        //   filterWeakEquals: 'Равны',
        //   filteringByColumn: 'Фильтр по {column} - {filterType} {filterValue}',
        //   goToFirstPage: 'Перейти на первую страницу',
        //   goToLastPage: 'Перейти на последнюю страницу',
        //   goToNextPage: 'Перейти на следующую страницу',
        //   goToPreviousPage: 'Перейти на предыдущую страницу',
        //   grab: 'Grab',
        //   groupByColumn: 'Сгруппировать по {column}',
        //   groupedBy: 'Сгруппировать',
        //   hideAll: 'Скрыть всё',
        //   hideColumn: 'Скрыть {column} колонку',
        //   max: 'Макс',
        //   min: 'Мин',
        //   move: 'Двигать',
        //   noRecordsToDisplay: <span style={{color:'#fff'}}>Нет записей для показа</span>,
        //   noResultsFound: 'Результатов не найдено',
        //   of: 'из',
        //   or: 'или',
        //   pin: 'Прикрепить',
        //   pinToLeft: 'Прикрепить слева',
        //   pinToRight: 'Прикрепить справа',
        //   resetColumnSize: 'Сбросить размер колонок',
        //   resetOrder: 'Сбросить порядок',
        //   rowActions: 'Действия строки',
        //   rowNumber: '#',
        //   rowNumbers: 'Количество строк',
        //   rowsPerPage: 'Строк на странице',
        //   save: 'Сохранить',
        //   search: 'Найти',
        //   selectedCountOfRowCountRowsSelected: '{selectedCount} из {rowCount} строк выбрано',
        //   select: 'Выбрать',
        //   showAll: 'Показать все',
        //   showAllColumns: 'Показать все колонки',
        //   showHideColumns: 'Показать/скрыть колонки',
        //   showHideFilters: 'Показать/скрыть фильтры',
        //   showHideSearch: 'Показать/скрыть поиск',
        //   sortByColumnAsc: 'Сортировать {column} по возрастанию',
        //   sortByColumnDesc: 'Сортировать {column} по убыванию',
        //   sortedByColumnAsc: 'Отсортирована по {column} возрастанию',
        //   sortedByColumnDesc: 'Отсортирована по {column} убыванию',
        //   thenBy: ', затем ',
        //   toggleDensity: 'Изменить плотность',
        //   toggleFullScreen: 'Включить полноэкранный режим',
        //   toggleSelectAll: 'Выбрать всё',
        //   toggleSelectRow: 'Переключить выбор строки',
        //   toggleVisibility: 'Изменить видимость',
        //   ungroupByColumn: 'Разгруппировать по {column}',
        //   unpin: 'Открепить',
        //   unpinAll: 'Открепить всё',
        }
    },[data]);

    return (
        <div className={styles.page}>
            <div className={styles.history}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MaterialReactTable table={table} />
                </LocalizationProvider>
            </div>
            
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel"   style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}} >
                <div className="offcanvas-header">
                    <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Gateway</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body mx-0 flex-grow-0" style={{height:"100%"}}>
                    <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                    <br />
                    <label className="form-label" htmlFor="teams-list">Prefixes</label>
                    <div className="list-group" style={{maxHeight:300, overflow:"scroll", WebkitOverflowScrolling:'touch'}}>
                        {
                            prefixes.map((val,ind)=>{
                                return <label className="list-group-item" key={ind}>
                                    <label class="switch">
                                    <input type="checkbox" class="switch-input" id={val.id}  />
                                    <span class="switch-toggle-slider">
                                        <span class="switch-on">
                                        <i class="bx bx-check"></i>
                                        </span>
                                        <span class="switch-off">
                                        <i class="bx bx-x"></i>
                                        </span>
                                    </span>
                                    <span class="switch-label">{val.prefix}_{val.title}</span>
                                    </label>
                                </label>
                            })
                        }
                        
                    </div>
                    <br />
                    <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">Trunk name</label>
                        <input type="text"
                            className="form-control" 
                            id="add-gateway-title" 
                            name="gatewayTitle" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            aria-label="Title"
                            value={title}
                            onChange={(e)=>setTitle(e.target.value)}
                            />   
                    </div>
                    <br />
                    <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">Title</label>
                        <input type="text"
                            className="form-control" 
                            id="add-gateway-title" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            name="gatewayTitle" 
                            aria-label="Title"
                            value={titleMask}
                            onChange={(e)=>setTitleMask(e.target.value)}
                            />   
                    </div>
                    <br />
                    <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">Domain</label>
                        <input type="text"
                            className="form-control" 
                            id="add-gateway-title" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            name="gatewayTitle" 
                            aria-label="Title"
                            value={domain}
                            onChange={(e)=>setDomain(e.target.value)}
                            />   
                    </div>
                    <br />
                    <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">Additional prefix</label>
                        <input type="text"
                            className="form-control" 
                            id="add-gateway-title" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            name="gatewayTitle" 
                            aria-label="Title"
                            disabled={useAdditional}
                            value={additionalPrefix}
                            onChange={(e)=>setAdditionalPrefix(e.target.value)}
                            />  
                            <div> 
                                <label htmlFor="use" className="form-label">Active</label>
                                <br/>
                                <input type="checkbox" id="use" className="form-check-input" checked={useAdditional}
                                onChange={(e)=>{setUseAdditional(e.target.checked)}}/>
                            </div> 
                    </div>
                    <br />
                    <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">Params</label>
                            <textarea 
                            className="form-control" 
                            id="exampleFormControlTextarea1" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            rows={5} defaultValue={""}
                            value={params}
                            onChange={(e)=>setParams(e.target.value)} />
                    </div>
                    <br />
                    <div className="col mb-0" style={{display:'flex',flexDirection:"row", justifyContent:"space-between",flexWrap:'wrap',width:"200px"}}>
                        <div>
                            <label htmlFor="use" className="form-label">Use phone</label>
                            <br/>
                            <input type="checkbox" id="use" className="form-check-input" checked={phoneUse}
                            onChange={(e)=>{setPhoneUse(e.target.checked)}}/>
                        </div>
                        <div> 
                            <label htmlFor="use" className="form-label">Show number</label>
                            <br/>
                            <input type="checkbox" id="use" className="form-check-input" checked={caleeNum}
                            onChange={(e)=>{setCaleeNum(e.target.checked)}}/>
                        </div>
                        <div>
                            <label htmlFor="use" className="form-label">Provider</label>
                            <br/>
                            <input type="checkbox" id="use" className="form-check-input" checked={provider}
                            onChange={(e)=>{setProvider(e.target.checked)}}/>
                            </div>
                        <div> 
                            <label htmlFor="use" className="form-label">Show prefix</label>
                            <br/>
                            <input type="checkbox" id="use" className="form-check-input" checked={caleeName}
                            onChange={(e)=>{setCaleeName(e.target.checked)}}/>
                        </div>
                    </div>
                    <br />
                    <br />
                    <button type="button" className='btn btn-info me-sm-3 me-1' 
                    data-bs-toggle="modal" data-bs-target="#modalTop"
                    style={{border:"0.1vh solid #3EA3FC",backgroundColor:"transparent",marginRight:5}}
                    >Методичка</button>
                    <button 
                        id='submitbtn'
                        type="button" 
                        className="btn btn-success me-sm-3 me-1 data-submit"
                        onClick={()=>handleSubmit()}
                        style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}
                        >Submit</button>
                    <button 
                        type="button" 
                        className="btn btn-label-secondary" 
                        data-bs-dismiss="offcanvas"
                        style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>
                        Cancel</button>
                    </form>
                </div>
            </div>
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasEdit" aria-labelledby="offcanvasEditLabel"  style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}} >
                <div className="offcanvas-header">
                    <h5 id="offcanvasEditLabel" className="offcanvas-title">Gateway update</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body mx-0 flex-grow-0">
                    <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                    <br />
                    <label className="form-label" htmlFor="teams-list">Prefixes</label>
                    <div className="list-group" style={{maxHeight:400, overflow:"scroll", WebkitOverflowScrolling:'touch'}}>
                        {
                            prefixes.map((val,ind)=>{
                                return <label className="list-group-item" key={ind}>
                                    <label class="switch">
                                    <input type="checkbox" class="switch-input" id={`${val.id}_upd`}  />
                                    <span class="switch-toggle-slider">
                                        <span class="switch-on">
                                        <i class="bx bx-check"></i>
                                        </span>
                                        <span class="switch-off">
                                        <i class="bx bx-x"></i>
                                        </span>
                                    </span>
                                    <span class="switch-label">{val.prefix}_{val.title}</span>
                                    </label>
                                </label>
                            })
                        }
                        
                    </div>
                    <br />
                    <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">Trunk name</label>
                        <input type="text"
                            className="form-control" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            id="add-gateway-title" 
                            name="gatewayTitle" 
                            aria-label="Title"
                            value={title}
                            onChange={(e)=>setTitle(e.target.value)}
                            />   
                    </div>
                    <br />
                    <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">Title</label>
                        <input type="text"
                            className="form-control" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            id="add-gateway-title" 
                            name="gatewayTitle" 
                            aria-label="Title"
                            value={titleMask}
                            onChange={(e)=>setTitleMask(e.target.value)}
                            />   
                    </div>
                    <br />
                    <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">Domain</label>
                        <input type="text"
                            className="form-control" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            id="add-gateway-title" 
                            name="gatewayTitle" 
                            aria-label="Title"
                            value={domain}
                            onChange={(e)=>setDomain(e.target.value)}
                            />   
                    </div>
                    <br />
                    <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">Additional prefix</label>
                        <input type="text"
                            className="form-control" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            id="add-gateway-title" 
                            name="gatewayTitle" 
                            aria-label="Title"
                            disabled={useAdditional}
                            value={additionalPrefix}
                            onChange={(e)=>setAdditionalPrefix(e.target.value)}
                            />  
                            <div> 
                                <label htmlFor="use" className="form-label">Active</label>
                                <br/>
                                <input type="checkbox" id="use" className="form-check-input" checked={useAdditional}
                                onChange={(e)=>{setUseAdditional(e.target.checked)}}/>
                            </div> 
                    </div>
                    <br />
                    <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">Params</label>
                            <textarea 
                            className="form-control" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            id="exampleFormControlTextarea1" 
                            rows={5} defaultValue={""}
                            value={params}
                            onChange={(e)=>setParams(e.target.value)} />
                    </div>
                    <br />
                    <div className="col mb-0" style={{display:'flex',flexDirection:"row", justifyContent:"space-between",flexWrap:'wrap',width:"200px"}}>
                        <div>
                            <label htmlFor="use" className="form-label">Use phone</label>
                            <br/>
                            <input type="checkbox" id="use" className="form-check-input" checked={phoneUse}
                            onChange={(e)=>{setPhoneUse(e.target.checked)}}/>
                        </div>
                        <div> 
                            <label htmlFor="use" className="form-label">Show number</label>
                            <br/>
                            <input type="checkbox" id="use" className="form-check-input" checked={caleeNum}
                            onChange={(e)=>{setCaleeNum(e.target.checked)}}/>
                        </div>
                        <div>
                            <label htmlFor="use" className="form-label">Provider</label>
                            <br/>
                            <input type="checkbox" id="use" className="form-check-input" checked={provider}
                            onChange={(e)=>{setProvider(e.target.checked)}}/>
                            </div>
                        <div> 
                            <label htmlFor="use" className="form-label">Show prefix</label>
                            <br/>
                            <input type="checkbox" id="use" className="form-check-input" checked={caleeName}
                            onChange={(e)=>{setCaleeName(e.target.checked)}}/>
                        </div>
                    </div>
                    <br />
                    <button type="button" className='btn btn-info me-sm-3 me-1' 
                    data-bs-toggle="modal" data-bs-target="#modalTop"
                    style={{border:"0.1vh solid #3EA3FC",backgroundColor:"transparent",marginRight:5}}
                    >Методичка</button>
                    <button 
                        id='updatebtn'
                        type="button" 
                        className="btn btn-warning me-sm-3 me-1 data-submit"
                        style={{border:"0.1vh solid #ffab00",backgroundColor:"transparent"}}
                        onClick={()=>handleUpdate()}
                        >Update</button>
                    <button 
                        type="button" 
                        className="btn btn-label-secondary" 
                        style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}
                        data-bs-dismiss="offcanvas">
                        Cancel</button>
                    </form>
                </div>
            </div>
            <div className="modal fade" data-bs-backdrop="static" id="modalTop" tabIndex={-1} >
                <div className="modal-dialog modal-dialog-centered modal-lg"  style={{maxWidth:'700px !important'}}>
                    <form className="modal-content" style={{backgroundColor:"#2A2A31",color:"#d3d3d2"}} >
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalTopTitle">Методичка</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col mb-3">
                            <table id="table1212" className="table table-stripped align-items-center justify-content-center mb-0 cell-border" style={{width:'100%'}}>
                            <thead>
                                <tr>
                                    <th className="text-uppercase text-sm font-weight-bolder opacity-7 ps-2" style={{color:"#d3d3d2"}}>Значение</th>
                                    <th className="text-uppercase text-sm font-weight-bolder opacity-7 ps-2" style={{color:"#d3d3d2"}}>переменная</th>
                                </tr>
                            </thead>
                            <tbody style={{color:"#d3d3d2"}}>
                                <tr>
                                    <td>Название транка</td>
                                    <td>{'${resss[0].title}'}</td>
                                </tr>
                                <tr>
                                    <td>Ююайди нового звонка</td>
                                    <td>{'${uuid_call}'}</td>
                                </tr>
                                <tr>
                                    <td>Номер куда звоним</td>
                                    <td>{"${telephResponse['called_number']}"}</td>
                                </tr>
                                <tr>
                                    <td>Номер куда звоним(без префикса)</td>
                                    <td>{"${telephResponse['called_number'].slice(3)}"}</td>
                                </tr>
                                <tr>
                                    <td>Префикс</td>
                                    <td>{"${telephResponse['called_number'].slice(0,3)}"}</td>
                                </tr>
                                <tr>
                                    <td>Имя того, кто звонит</td>
                                    <td>{"${telephResponse['caller_number']}"}</td>
                                </tr>
                                <tr>
                                    <td>Номер для подставления</td>
                                    <td>{'${item.number}'}</td>
                                </tr>
                            </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal"
                        style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>Close</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
