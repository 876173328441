import React, { Component } from 'react'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import {ic_phone_missed} from 'react-icons-kit/md/ic_phone_missed'
import { withBaseIcon } from 'react-icons-kit';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "../css/table.css";
import DataTable from 'datatables.net-dt';
import DateTime from 'datatables.net-datetime';
import 'datatables.net-plugins/api/sum().mjs';
import moment from 'moment/moment';
import Countries from '../resources/countries.json';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const SideIconContainer = 
    withBaseIcon({ size: 32, style: {color: '#a3a5ca'}})

export const PhoneMissedIcon = () => <SideIconContainer icon={ic_phone_missed}/>
   class BillingRecords extends Component {
        company = '';
        options = [
            { value: 2, label: 'Company admin'},
            { value: 3, label: 'Office admin'},
            { value: 4, label: 'Department admin'},
            { value: 5, label: 'Team admin'},
            { value: 6, label: 'Agent'},
            { value: 7, label: 'BrandManager'},
            { value: 8, label: 'Coach'},
        ]
        options1 = [
            { value: 1, label: 'Russia'},
            { value: 2, label: 'Armenia'},
            { value: 3, label: 'Kazakhstan'},
            { value: 4, label: 'Azerbaijan'},
        ]
        options2 = []
        startTime = '';
        endTime = '';
        prefix = '';
        totalRecords=0;
        currentBatch=1;
        state = { company:1,prefix:1,inbound:"",outbound:"",operator:1,price:0.0,priceUpdate:0.0,prefixUpdate:0,status:0,countries:[],
        prefixDisabled:true, countryDisabled:true,operatorDisabled:true,priceDisabled:true,idUpdate:0, sum:0, pref:"",
        inputs:{
            company: false,
            prefix:false,
            inbound:false,
            outbound:false,
            operator:false,
            price:false
        }, valid:false }
        getCountryData(){
            const { billingData } = this.props;
            let id =billingData!==undefined||null?billingData[0].id:secureLocalStorage.getItem("billingCurrentData")!==undefined||null
            ?secureLocalStorage.getItem("billingCurrentData")[0].id : JSON.parse(secureLocalStorage.getItem('user')).billingaccount_id;
            axios.get('https://www.pbxdiamondcall.com:8081/countriesBillingData/'+id).then((res)=>{
                this.setState({countries:res.data})
            })
        }
        SwalDel(){
            return Swal.fire({ // <-- return Promise
                title: 'Delete record?',
                text: 'This action cannot be undone.',
                icon: 'question',
                confirmButtonText: 'Delete',
                confirmButtonColor: '#DC004E',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                reverseButtons: true,
                focusConfirm: false,
                focusCancel: false,
                scrollbarPadding: false
                }).then((result) => {
                return result.isConfirmed;
                });
        }
        
        componentDidMount(){
            
            const { t , billingData } = this.props;
            const self = this;
            if (!$.fn.DataTable.isDataTable("#myTable")) {
                    $(document).ready(function () {
                        setTimeout(function () {
                        $("#myTable").DataTable({
                            pagingType: "full_numbers",
                            pageLength: 20,
                            processing: true,
                            serverSide: false,
                            dom: "Bfrtip",
                            paging:true,
                            select: {
                                style: "single",
                            },
                            language: {
                                "processing": 'Loading data...'
                            },
                            ajax:{
                                url:'https://www.pbxdiamondcall.com:8081/billing/records',
                                type:'POST',
                                dataType:"json",
                                dataSrc: (json)=>{
                                    // secureLocalStorage.setItem('totalRecords',json.totalRecords);
                                    return json.data;
                                },
                                data: function (d) {
                                    // Добавление дополнительных параметров к запросу
                                    d.id = billingData !== undefined || null 
                                        ? billingData[0].id
                                        : secureLocalStorage.getItem("billingCurrentData") !== undefined || null
                                            ? secureLocalStorage.getItem("billingCurrentData")[0].id
                                            : JSON.parse(secureLocalStorage.getItem('user')).billingaccount_id;
                                    // d.prefix = $("#prefix").val();
                                    // d.startTime = $("#min").val().length>0?moment($("#min").val(), "MMMM Do YYYY").format("YYYY-MM-DD"):null;
                                    // d.endTime =  $("#max").val().length>0?moment($("#max").val(), "MMMM Do YYYY").format("YYYY-MM-DD"):null;
                                },
                                error: function (xhr, textStatus, errorThrown) {
                                    console.log(xhr);
                                }
                            },
                            columns: [
                                { data: null,name: '#'},
                                { data: 'Title',name: 'Company'},
                                { data: 'team',name: 'Team'},
                                { data: 'sip_user',name: 'User'},
                                { data: 'cost',name: 'Cost'},
                                { data: 'price',name: 'Price'},
                                { data: 'conversation',name: 'Conversation',
                                render: function(data,type,row){
                                return data<3600?new Date(data * 1000).toISOString().substring(14, 19)
                                    :new Date(data * 1000).toISOString().substring(11, 19)
                                }},
                                { data: 'dst',name: 'Number'},
                                { data: 'country',name: 'Country'},
                                { data: 'time',name: 'Date',
                                render: function(data){
                                    const date = new Date(data); 
                                    const formattedDate = moment.parseZone(date).local().format('YYYY-MM-DD HH:mm:ss')
                                    return formattedDate
                                }
                            },
                            ],
                            bDestroy:true,
                            buttons: [
                                {
                                    extend: "copy",
                                    text:t("onlineButtonsCopy"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "print",
                                    text:t("onlineButtonsPrint"),
                                    customize: function (win) {
                                    $(win.document.body).css("font-size", "10pt");
                                    $(win.document.body)
                                        .find("table")
                                        .addClass("compact")
                                        .css("font-size", "inherit");
                                    },
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: 'spacer',
                                    style: 'bar',
                                    text: 'Export files:'
                                },
                                {
                                    extend: "csv",
                                    text:t("onlineButtonsCSV"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "excel",
                                    text:"Excel",
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend:'pdf',
                                    text:"PDF",
                                    className: "btn btn-secondary bg-primary",
                                }
        
                                
                                ],
                            fnRowCallback: function (
                            nRow,
                            aData,
                            iDisplayIndex,
                            iDisplayIndexFull
                            ) {
                            var index = iDisplayIndexFull + 1;
                            $("td:first", nRow).html(index);
                            return nRow;
                            },
                            lengthMenu: [
                            [10, 20, 30, 50, -1],
                            [10, 20, 30, 50, "All"],
                            ],
                            columnDefs: [
                            {
                                targets: 0,
                                render: function (data, type, row, meta) {
                                return type === "export" ? meta.row + 1 : data;
                                },
                            },
                            {
                                targets: [1,2,3,4,5,6,7,8,9],
                                className:"bolded"
                            }
                            ],
                        });
                        }, 1000);
                    });
                    let minDate, maxDate;
                    DataTable.ext.search.push(function (settings, data, dataIndex) {
                        let min = minDate.val();
                        let max = maxDate.val();
                        let date = new Date(data[9]);
                        
                        
                        if (
                            (min === null && max === null) ||
                            (min === null && date <= max) ||
                            (min <= date && max === null) ||
                            (min <= date && date <= max)
                        ) {
                            return true;
                        }
                        return false;
                    });
                     
                    // Create date inputs
                    minDate = new DateTime('#min', {
                        format: 'MMMM Do YYYY'
                    });
                    maxDate = new DateTime('#max', {
                        format: 'MMMM Do YYYY'
                    });
                     
            
                    DataTable.ext.search.push(function (settings, data, dataIndex) {
                        let pref = self.state.pref;
                        let dst = data[7];
                        if (
                            (pref === null) ||
                            (pref === "") ||
                            (dst.slice(0,3) === pref)
                        ) {
                            return true;
                        }
                        return false;
                    });
                    document.querySelector('#prefix').addEventListener('change', () => {$('#myTable').DataTable().draw();
                    self.setState({sum:$('#myTable').DataTable().column( 5, {search:'applied'} ).data().sum()});});
                    // Refilter the table
                    document.querySelectorAll('#min, #max').forEach((el) => {
                        el.addEventListener('change', () => {$('#myTable').DataTable().draw();
                        self.setState({sum:$('#myTable').DataTable().column( 5, {search:'applied'} ).data().sum()});
                    
                    });
                    });

                    // var table = ;
                    self.setState({sum:$('#myTable').DataTable().column( 5 ).data().sum()});

            }
            
        }
        render(){
            console.log($("#max").val())
            const { t } = this.props;
            return(
            <div className="table-responsive text-nowrap">
                <div style={{display:"flex", flexDirection:'row', width:"100%"}}>
                    <table border={0} cellSpacing={5} cellPadding={5} style={{marginBottom:10}}>
                    <tbody><tr>
                        <td className="form-label">Minimum date:</td>
                        <td><input type="text" id="min" name="min" className='form-control' onChange={(e)=>{console.log(e)}}/></td>
                        </tr>
                        <tr>
                        <td className="form-label">Maximum date:</td>
                        <td><input type="text" id="max" name="max" className='form-control'/></td>
                        </tr>
                    </tbody></table>
                    <div style={{display:'flex',flexDirection:"column"}}>
                        <div style={{display:"flex", flexDirection:'row', marginTop:2,marginLeft:5}}>
                            <label className="form-label" style={{marginTop:"10px", marginRight:10}}>Spended money:</label>
                            <input type="text" disabled className='form-control' style={{width:"200px", height:40}}
                            value={`${Number(this.state.sum).toFixed()} $`}/>
                        </div>
                        <div style={{display:"flex",flexDirection:"row", textAlign:"center",marginTop:12,marginLeft:5}}>
                        <label className="form-label" htmlFor="add-team-title" style={{marginTop:10, marginRight:70}}>Prefix:</label>
                        <input type="text" 
                            className="form-control" 
                            id="prefix" 
                            placeholder="enter prefix"
                            name="prefix"
                            onChange={(e)=>this.setState({pref:e.target.value})}
                            value={this.state.pref}
                            style={{height:40, width:200}}
                            />  
                        </div>
                    </div>
                    <button className="btn btn-primary" style={{height:"50px", marginLeft:'40%'}} onClick={()=>this.getCountryData()}
                    data-bs-toggle="modal" data-bs-target="#modalCountry">Show stats by country</button>
                </div>
                <table id="myTable" className="table table-stripped align-items-center justify-content-center mb-0 cell-border" style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <th style={{fontSize:"18px"}}></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"18px"}}>{t("recordsThCompany")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"18px"}}>Team</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"18px"}}>{t("recordsThAgent")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"18px"}}>Tariff</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"18px"}}>Price</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"18px"}}>On line</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"18px"}}>{t("recordsThNumber")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"18px"}}>Country</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"18px"}}>{t("recordsThDate")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
                
                <div className="modal fade" data-bs-backdrop="static" id="modalCountry" tabIndex={-1}>
                            <div className="modal-dialog modal-dialog-centered">
                                <form className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modalTopTitle">Country statistics</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                                </div>
                                <div className="modal-body">
                                <div className="table-responsive p-0 pb-2" style={{ maxHeight: "500px", width:'100%', overflowX:'hidden' }}>
                                            <table id="table" className="table table-responsive-sm table-striped table-bordered align-items-center justify-content-center mb-0"
                                            style={{fontFamily:'Ubuntu, sans-serif', height:"500px"}}>
                                                <thead style={{ backgroundColor: "#2B2C3F", borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", borderRightWidth: "3px", position: "sticky", top: 0 }}>
                                                    <tr>
                                                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2"
                                                                                style={{
                                                                                    fontSize: "18px", textAlign: "center",
                                                                                    borderRightWidth: "3px", borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", position: "sticky", top: 0
                                                                                }}>Country</th>
                                                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2"
                                                                                style={{
                                                                                    fontSize: "18px", textAlign: "center",
                                                                                    borderRightWidth: "3px", borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", position: "sticky", top: 0
                                                                                }}>Calls</th>
                                                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2"
                                                                                style={{
                                                                                    fontSize: "18px", textAlign: "center",
                                                                                    borderRightWidth: "3px", borderTopWidth: "3px", borderBottomWidth: "3px", borderLeftWidth: "3px", position: "sticky", top: 0
                                                                                }}>Money spent</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ borderLeftWidth: "3px", borderRightWidth: "3px", borderBottomWidth: "3px" }}>
                                                    { 
                                                    this.state.countries !== undefined ? this.state.countries.map((val,idx)=>{
                                                        
                                                        return <tr key={idx} style={{height:"50px"}}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                <img src={Countries.find((obj)=>obj.name.common==val.Country)?.flags?.png} alt={Countries.find((obj)=>obj.name.common==val.Country)?.flags?.alt} height={24} className="me-2" width={24} style={{borderRadius:'50%'}} />
                                                                <span>{val.Country}</span>
                                                                </div>
                                                            </td>
                                                            <td style={{textAlign:"center"}}>{val.TotalCount}</td>
                                                            <td style={{textAlign:"center"}}>
                                                                <div className="d-flex align-items-center">
                                                                {val.sum}$
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })
                                                    :console.log()
                                                    }
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                                </form>
                            </div>
                </div>
            </div>
        )
        }
    }

export default withTranslation()(BillingRecords)
