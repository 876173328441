import React, { Component, createRef } from 'react'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import Swal from 'sweetalert2';
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import FaceRoundedIcon from '@mui/icons-material/FaceRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';

import ScaleLoader from 'react-spinners/ScaleLoader';
import { WaveSurferPlayer } from './AudioWaveForm';
import $ from "jquery";
import {ic_phone_missed} from 'react-icons-kit/md/ic_phone_missed'
import { withBaseIcon } from 'react-icons-kit';
import { Tooltip } from 'react-tooltip';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import moon from '../Fly Me To The Moon.mp3'
import AudioSpectrum from 'react-audio-spectrum';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "../css/table.css";
import '../css/journal.css';
import ReactWaves from '@dschoon/react-waves';
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js'
import { Link } from 'react-router-dom';
import DataTable from 'datatables.net-dt';
import DateTime from 'datatables.net-datetime';
import moment from 'moment/moment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const SideIconContainer = 
    withBaseIcon({ size: 32, style: {color: '#a3a5ca'}})


export const PhoneMissedIcon = () => <SideIconContainer icon={ic_phone_missed}/>
class Table extends Component {
        
    company = '';
    state = { audiosrc: "",uuid:"", filename: 'song', playing : false, playSpeed:1, brands: [], minDuration: 0, prefix:"", audioLoading:true, audioNotFound:false, downloaded: false }
    audioRef = createRef();
    componentDidMount(){
        const self = this;
        const { t , i18n , pathname, company, team, agent } = this.props; 
        //:id/:teamid/:agentid
        const companies = secureLocalStorage.getItem('brands')
        console.log(company,team,agent);
    function SetCompany (){
        if(company === undefined && JSON.parse(secureLocalStorage.getItem('user')).role_id===1) return "0"
        let str = ``;
        let obj = companies.find(el=> el.Id == company)
        str+=obj.Title;
        console.log(str.split('_').length);
        if(team!== undefined)str+=`_${team}`;
        if(agent!==undefined)str+=`_${agent}`;
        console.log(str.split('_').length);
        return str
    }    
           

        if (!$.fn.DataTable.isDataTable("#table")) {
                $(document).ready(function () {
                    setTimeout(function () {
                    $("#table").DataTable({

                        orderCellsTop: true,
                            fixedHeader: true,
                            initComplete: function () {
                                var api = this.api();
                    
                                // For each column
                                api
                                    .columns()
                                    .eq(0)
                                    .each(function (colIdx) {
                                        // Set the header cell to contain the input element
                                        if(colIdx!==0 && colIdx!==5 && colIdx!==6 && colIdx!==7&& colIdx!==8&& colIdx!==10){
                                        var cell = $('.filters th').eq(
                                            $(api.column(colIdx).header()).index()
                                        );
                                        var title = $(cell).text();
                                        $(cell).html('<input type="text" class="searchT'+colIdx+' form-control" placeholder="' + "..." + '" />');
                    
                                        // On every keypress in this input
                                        $(
                                            'input',
                                            $('.filters th').eq($(api.column(colIdx).header()).index())
                                        )
                                            .off('keyup change')
                                            .on('change', function (e) {
                                                // Get the search value
                                                $(this).attr('title', $(this).val());
                                                var regexr = '({search})'; //$(this).parents('th').find('select').val();
                    
                                                var cursorPosition = this.selectionStart;
                                                // Search the column for that value
                                                api
                                                    .column(colIdx)
                                                    .search(
                                                        this.value != ''
                                                            ? regexr.replace('{search}', '(((' + this.value + ')))')
                                                            : '',
                                                        this.value != '',
                                                        this.value == ''
                                                    )
                                                    .draw();
                                            })
                                            .on('keyup', function (e) {
                                                e.stopPropagation();
                                                var cursorPosition = this.selectionStart;
                    
                                                $(this).trigger('change');
                                                $(this)
                                                    .focus()[0]
                                                    .setSelectionRange(cursorPosition, cursorPosition);
                                            });
                                            
                                        }
                                    });
                            },
                            deferRender:true,
                            pagingType: "full_numbers",
                            pageLength: 20,
                            processing: true,
                            //serverSide:true,
                            dom: "Bflrtip",
                            bPaginate:true,
                            lengthChange:true,
                            lengthMenu: [
                                [10, 20, 30, 50],
                                [10, 20, 30, 50],
                                ],
                            select: {
                            style: "single",
                            },
                            language: {
                                "processing": t("loadingData")+'...'
                            },
                            ajax:
                            company==undefined&&team==undefined&&agent==undefined?{    
                            url:'https://www.pbxdiamondcall.com:8081/records/new',
                            type:'POST',
                            data:{
                                "company_id":JSON.parse(secureLocalStorage.getItem('user')).company_id,
                                "username":JSON.parse(secureLocalStorage.getItem('user')).login
                            },
                            dataType:"json",
                            dataSrc: 'data',
                            error: function (xhr, textStatus, errorThrown) {
                                console.log(xhr);
                            }}
                            :
                            {    
                                url:'https://www.pbxdiamondcall.com:8081/records',
                                type:'POST',
                                data:{
                                    "id":SetCompany()
                                },
                                dataType:"json",
                                dataSrc: 'data',
                                error: function (xhr, textStatus, errorThrown) {
                                    console.log(xhr);
                                }},
                            columns: [
                                { data: null,name: '#'},
                                { data: 'time',name: 'time',
                                render: function(data){
                                    const date = new Date(data); 
                                    const formattedDate = moment.parseZone(date).local().format('YYYY-MM-DD HH:mm:ss')
                                    return formattedDate
                                }},
                                { data: 'company',name: 'company'},
                                { data: 'sip_user',name: 'sip_user'},
                                // { data: 'src',name: 'src'},
                                { data: 'dst',name: 'dst'},
                                { data: 'duration',name: 'duration',
                                render: function(data,type,row){
                                return data<3600?new Date(data * 1000).toISOString().substring(14, 19)
                                    :new Date(data * 1000).toISOString().substring(11, 19)
                                }},
                                { data: 'conversation',name: 'conversation',
                                    render: function(data,type,row){
                                    return data<3600?new Date(data * 1000).toISOString().substring(14, 19)
                                        :new Date(data * 1000).toISOString().substring(11, 19)
                                    }
                                },
                                {
                                    data:null,
                                    name:'record',
                                    className: 'record-show',
                                    orderable: false,
                                    defaultContent: '<button class="btn btn-label-dark" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><i class="fa-solid fa-microphone-lines"></i></button>'
                                },
                                {
                                    data:null,
                                    name:'record',
                                    className: 'record-download',
                                    orderable: false,
                                    defaultContent: '<a download className="btn btn-label-dark" tarket="_blank" ><button class="btn btn-label-dark"><i class="fa-solid fa-circle-down"></i></button></a>'
                                },
                                { data: 'status',name: 'status', render: function(data,type,row){
                                    //console.log(data);
                                    return data==="ANSWER"?
                                    `<button  class='btn btn-label-success'>&nbsp&nbsp&nbsp${t("journalAnswer")}&nbsp&nbsp&nbsp</button>`
                                    :
                                    `<button  class='btn btn-label-warning'>${t("journalNoAnswer")}</button>`
                                }},
                                {
                                    data:'hangup_cause',
                                    name:'hangup',
                                    orderable: false,
                                    defaultContent: `<button class="btn btn-label-dark" style="width:20px"><i class="material-icons">report_problem</i></button>`,
                                    render: function(data,type,row){
                                        //console.log(data);
                                        return data!==null?
                                        data==='agent'?
                                        `<button class="btn btn-label-dark" style="width:20px"><i class="material-icons">support_agent</i></button>`
                                        :
                                        data==='client'?`<button class="btn btn-label-dark" style="width:20px"><i class="material-icons">face</i></button>`
                                        :`<button class="btn btn-label-dark" style="width:20px"><i class="material-icons">report_problem</i></button>`
                                        :`<button class="btn btn-label-dark" style="width:20px"><i class="material-icons">content_paste_off</i></button>`
                                    },
                                }
                            ],
                            bDestroy:true,
                            buttons: [
                                {
                                    extend: "copy",
                                    text:t("onlineButtonsCopy"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "print",
                                    text:t("onlineButtonsPrint"),
                                    customize: function (win) {
                                    $(win.document.body).css("font-size", "10pt");
                                    $(win.document.body)
                                        .find("table")
                                        .addClass("compact")
                                        .css("font-size", "inherit");
                                    },
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: 'spacer',
                                    style: 'bar',
                                    text: 'Export files:'
                                },
                                {
                                    extend: "csv",
                                    text:t("onlineButtonsCSV"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "excel",
                                    text:"Excel",
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend:'pdf',
                                    text:"PDF",
                                    className: "btn btn-secondary bg-primary",
                                }
        
                                
                            ],
                            fnRowCallback: function (
                            nRow,
                            aData,
                            iDisplayIndex,
                            iDisplayIndexFull
                            ) {
                            var index = iDisplayIndexFull + 1;
                            console.log(aData);
                            if(aData.hangup_cause==null)
                            $("td:last", nRow).attr('title','hangup cause: no data');
                            else
                            $("td:last", nRow).attr('title','hangup cause: '+aData.hangup_cause);

                            $("td:first", nRow).html(index);
                            return nRow;
                            },
                            columnDefs: [
                            {
                                targets: 0,
                                render: function (data, type, row, meta) {
                                return type === "export" ? meta.row + 1 : data;
                                },
                            },
                            {
                                targets: [1,2,3,4,5,6,7,8],
                                className: 'bolded',
                            },
                            {
                                targets: [7],
                                createdCell: function (td, cellData, rowData, row, col) {
                                      $(td).find(">:first-child").attr('id',`${row}${col}`)
                                  }
                            }
                            ],
                        });
                        }, 1000);
                    });
                    $('#table').on('click', 'td.record-show', function(e, type, action){
                        self.setState({downloaded:false});
                        self.setState({playing:true});
                        self.setState({audioLoading:true, audioNotFound:false});
                        e.preventDefault();
                        let counter = 0;
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        //window.location.pathname = `/record/${row[0].uuid}/${row[0].time}/${row[0].dst}/${row[0].conversation}/${row[0].duration}/${row[0].sip_user}/${row[0].status}`;
                        // console.log($('#table').DataTable().cell(this).index());
                        // console.log(row[0].uuid);
                        // console.log(row[0]);
                        // console.log(e.target.closest('tr').cells);
                        self.filename = 'song';
                        self.setState(prevState=>{return{...prevState,uuid : row[0].uuid}});
                        console.log(self.state.uuid);
                        axios.post('https://www.pbxdiamondcall.com:8081/getrecord', {filename:row[0].uuid},
                        {responseType:'blob'})
                        .then((res)=>{
                            self.setState({audioNotFound:false});
                            if(res.status===400){
                                console.log("audio not found");
                                self.setState({audioNotFound:true});
                            }
                            else{
                            var binaryData = [];
                            binaryData.push(res.data)
                            const audioUrl = URL.createObjectURL(new Blob(binaryData, {type:"audio/mp3"}));
                            console.log(audioUrl);
                            self.setState({audioLoading:false});
                            self.setState(prevState=>{return{...prevState,audiosrc : audioUrl}});
                            if(document.getElementById("record")!=null)
                            document.getElementById("record").load();
                            self.setState({downloaded:true});
                            }
                        })
                        .catch((err)=>{
                            
                            Swal.fire({
                                title:"Error!",
                                text:"An error occurred while searching or downloading audio.",
                                icon:'error',
                                showConfirmButton:false
                            })
                            self.setState({audioNotFound:true});
                            console.log(err);

                        });
                        
                    });
                    $('#table').on('click', 'td.record-download', function(e, type, action){
                        Swal.fire({
                            title:"Preparing audio",
                            text:"Please wait until the audio is ready to download",
                            icon:'info',
                            showCancelButton:false,
                            showConfirmButton:false
                        })
                        self.setState({downloaded:false});
                        self.setState({audioLoading:true, audioNotFound:false});
                        e.preventDefault();
                        let counter = 0;
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        console.log($('#table').DataTable().cell(this).index());
                        console.log(row[0].uuid);
                        console.log(row[0]);
                        console.log(e.target.closest('tr').cells);
                        self.filename = 'song';
                        axios.post('https://www.pbxdiamondcall.com:8081/getrecord', {filename:row[0].uuid},
                        {responseType:'blob'})
                        .then((res)=>{
                            if(res.status===400){
                                console.log("audio not found");
                                self.setState({audioNotFound:true});
                            }
                            else{
                            var binaryData = [];
                            binaryData.push(res.data)
                            const audioUrl = URL.createObjectURL(new Blob(binaryData, {type:"audio/mp3"}));
                            console.log(audioUrl);
                            self.setState({audioLoading:false});
                            self.setState(prevState=>{return{...prevState,audiosrc : audioUrl}});
                            
                            if(self.state.downloaded == false){
                            Swal.close();
                            const link = document.createElement('a');
                            link.href = audioUrl;
                            link.setAttribute(
                              'download'
                              ,`${row[0].uuid}`,
                            );
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                            }
                            if(document.getElementById("record")!=null)
                            document.getElementById("record").load();
                            self.setState({downloaded:true});
                            }
                        })
                        .catch((err)=>{
                            Swal.fire({
                                title:"Error!",
                                text:"An error occurred while searching or downloading audio.",
                                icon:'error',
                                showConfirmButton:false
                            })
                            console.log(err)
                        });
                        
                    });
 
                    $('#next').on( 'click', function () {
                        $('#table').DataTable().page( 'next' ).draw( 'page' );
                    } );
                     
                    $('#previous').DataTable().on( 'click', function () {
                        $('#table').page( 'previous' ).draw( 'page' );
                    } );

                    axios.get("https://www.pbxdiamondcall.com:8081/companies")
                        .then((res)=>{
                            var arr = [];
                            if(res.data.length>0){

                                if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
                                res.data.forEach((item) => {
                                    arr.push({
                                        value:item.Id,
                                        label:item.Title
                                    });
                                })
                                self.setState({brands:arr});
                                }
                                else{
                                    res.data.forEach((item) => {JSON.parse(secureLocalStorage.getItem("user")).company_id !== item.Id
                                        ?console.log(item)
                                        :arr.push({
                                            value:item.Id,
                                            label:item.Title
                                        })
                                    })
                                    self.setState({brands:arr});
                                }
                            }
                        })
                        .catch((err)=>console.log(err))
            }
            
            let minDate, maxDate;
 
            DataTable.ext.search.push(function (settings, data, dataIndex) {
                let min = self.state.minDuration;
                let duration = parseInt(data[6].split(':')[0])*60+parseInt(data[6].split(':')[1]);
                if (
                    (min === null) ||
                    (min <= duration)
                ) {
                    return true;
                }
                return false;
            });
            document.querySelector('#prefix').addEventListener('change', () => $('#table').DataTable().draw());
            
            DataTable.ext.search.push(function (settings, data, dataIndex) {
                let pref = self.state.prefix;
                let dst = data[4];
                if (
                    (pref === null) ||
                    (pref === "") ||
                    (dst.slice(0,3) === pref)
                ) {
                    return true;
                }
                return false;
            });
            document.querySelector('#duration').addEventListener('change', () => $('#table').DataTable().draw());
            // Custom filtering function which will search data in column four between two values
            DataTable.ext.search.push(function (settings, data, dataIndex) {
                let min = minDate.val();
                let max = maxDate.val();
                let date = new Date(data[1]);
             
                if (
                    (min === null && max === null) ||
                    (min === null && date <= max) ||
                    (min <= date && max === null) ||
                    (min <= date && date <= max)
                ) {
                    return true;
                }
                return false;
            });
             
            // Create date inputs
            minDate = new DateTime('#min', {
                format: 'MMMM Do YYYY'
            });
            maxDate = new DateTime('#max', {
                format: 'MMMM Do YYYY'
            });
             
             
            // Refilter the table
            document.querySelectorAll('#min, #max').forEach((el) => {
                el.addEventListener('change', () => $('#table').DataTable().draw());
            });
            //console.log(JSON.parse(secureLocalStorage.getItem('user')).company_id);
            axios.get('https://www.pbxdiamondcall.com:8081/companies/'+JSON.parse(secureLocalStorage.getItem('user')).company_id)
            .then((res)=>{
                this.company = res.data[0].Title;
                console.log(this.company);
            })
            
        }  
        
        defaultFormatTimeCallback(seconds, pxPerSec) {
            if (seconds / 60 > 1) {
              // calculate minutes and seconds from seconds count
              const minutes = parseInt(seconds / 60, 10);
              seconds = parseInt(seconds % 60, 10);
              // fill up seconds with zeroes
              seconds = seconds < 10 ? '0' + seconds : seconds;
              return `${minutes}:${seconds}`;
            }
            return Math.round(seconds * 1000) / 1000;
          }
        
          defaultTimeInterval(pxPerSec) {
            if (pxPerSec >= 25) {
              return 1;
            } else if (pxPerSec * 5 >= 25) {
              return 5;
            } else if (pxPerSec * 15 >= 25) {
              return 15;
            }
            return Math.ceil(0.5 / pxPerSec) * 60;
          }
        
          defaultPrimaryLabelInterval(pxPerSec) {
            if (pxPerSec >= 25) {
              return 10;
            } else if (pxPerSec * 5 >= 25) {
              return 6;
            } else if (pxPerSec * 15 >= 25) {
              return 4;
            }
            return 4;
          }
        
          defaultSecondaryLabelInterval(pxPerSec) {
            if (pxPerSec >= 25) {
              return 5;
            } else if (pxPerSec * 5 >= 25) {
              return 2;
            } else if (pxPerSec * 15 >= 25) {
              return 2;
            }
            return 2;
          }
        
            render(){
                const { t, i18n } = this.props;

            return(
            <div className="table-responsive text-nowrap" width="1800px">
                <div>
                    <div className="offcanvas offcanvas-top" tabIndex={-1} id="offcanvasTop" aria-labelledby="offcanvasTopLabel" style={{height:500}}>
                        <div className="offcanvas-header">
                        <h5 id="offcanvasTopLabel" className="offcanvas-title">{t("journalThRecord")} {JSON.parse(secureLocalStorage.getItem('user')).role_id===1?`[${this.state.uuid}]`:''}</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"  onClick={()=>this.setState({playing: false})} />
                        </div>
                        {
                             this.state.audioNotFound
                             ?<div className="offcanvas-body" style={{justifyContent:"center",alignContent:'center', display:'flex', flexDirection:'column'}}> 
                                 <div className="card" style={{width:1400, height:500, justifyContent:'center',alignContent:'center', marginLeft:"10%"}}>
                                     <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>Audio not found. Try again later</h1>
                                 </div>
                            </div>
                            :
                            this.state.audioLoading
                            ?
                            <div className="offcanvas-body" style={{justifyContent:"center",alignContent:'center', display:'flex', flexDirection:'column'}}> 
                              <div className="card" style={{width:1400, height:500, justifyContent:'center',alignContent:'center', marginLeft:"10%"}}>
                                <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>Loading audio...</h1>
                                <ScaleLoader
                                    color="#696cff"
                                    height={270}
                                    margin={10}
                                    radius={2}
                                    speedMultiplier={0.8}
                                    width={120}
                                    cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                                </div>
                            </div>
                            :<div className="offcanvas-body" style={{justifyContent:"center",alignContent:'center', display:'flex', flexDirection:'column'}}>
                        
                            {/* <audio class="w-100" id="record" controls onPlay={()=>this.setState({playing: !this.state.playing})} 
                            onEnded={()=>this.setState({playing: false})}
                            ref={this.audioRef}>
                                <source src='' type="audio/wav" id="audio-src"/>
                            </audio> */}
                        <div style={{display:"flex",flexDirection:'row', margin:10, alignContent:'center', justifyContent:'center'}}>
                        
                        <div style={{width:1000, height:200, marginTop:"-2.2%"}} >
                            {
                                this.state.playing && (
                                    <WaveSurferPlayer
                                    height={120}
                                    waveColor="#D1D6DA"
                                    progressColor="#EC407A"
                                    url={this.state.audiosrc}
                                    plugins={[Timeline.create() , Hover.create({labelColor: 'white' , labelTop:'30px',position:'absolute' , top:'50px' , labelBackground: 'black',labelSize: '11px' ,lineColor: 'black'  })]}
                                />
                                )
                            }

                        
                        </div>
                        </div>
                        <br />
                        <br />
                        
                        <div style={{display:"flex",flexDirection:"row", alignSelf:"center", justifySelf:"center", fontWeight:"bold"}}>
                            {/* <div style={{display:"flex",flexDirection:"column", alignSelf:"center", justifySelf:"center", fontWeight:"bold",marginLeft:"-30%", position:"absolute"}}>
                                <button onClick={()=>this.setState({playing: !this.state.playing})} className='btn btn-label-primary' style={{ width:50, height:50, alignSelf:"center", justifySelf:"center"
                                    }}>
                                        {
                                        !this.state.playing?
                                        <i className="fa-solid fa-circle-play fa-lg"></i>
                                        :
                                        <i class="fa-solid fa-circle-pause fa-lg"></i>
                                        }
                                </button>
                                <div style={{display:"flex",flexDirection:"row", alignSelf:"center", justifySelf:"center", fontWeight:"bold", marginTop:10}}>
                                <button onClick={()=>this.setState({playSpeed: 0.5})} className='btn btn-label-primary' style={{ width:50, height:50,margin:3 
                                    }}>0.5x
                                </button>
                                <button onClick={()=>this.setState({playSpeed: 1})} className='btn btn-label-primary' style={{ width:50, height:50, margin:3
                                    }}> 1x
                                </button>
                                <button onClick={()=>this.setState({playSpeed: 1.2})} className='btn btn-label-primary' style={{ width:50, height:50, margin:3
                                    }}>1.2x
                                </button>
                                <button onClick={()=>this.setState({playSpeed: 1.5})} className='btn btn-label-primary' style={{ width:50, height:50, margin:3
                                    }}> 1.5x
                                </button>
                                <button onClick={()=>this.setState({playSpeed: 2})} className='btn btn-label-primary' style={{ width:50, height:50, margin:3
                                    }}> 2x
                                </button>
                                </div>
                                
                                
                            </div> */}
                        <button type="button" className="btn btn-label-secondary" data-bs-dismiss="offcanvas" 
                        style={{width:150, alignSelf:'center', margin:10,height:40, fontWeight:"bold", marginTop:75}} onClick={()=>this.setState({playing: false})}>{t("cancelbtn")}</button>
        
                        <a href={this.state.audiosrc} download className='btn btn-label-primary' style={{margin:10,width:150, height:40, fontWeight:"bold", marginTop:75}}>
                            Download<i className="fa-solid fa-download" style={{marginLeft:5}}></i>
                        </a>
                        </div>
                                </div>
                        }
                        
                    </div>
                </div>
                <div style={{display:"flex",flexDirection:"row"}}>
                <table border={0} cellSpacing={0} cellPadding={5} style={{marginBottom:10}}>
                <tbody><tr>
                    <td className="form-label">Minimum date:</td>
                    <td><input type="text" id="min" name="min" className='form-control'/></td>
                    </tr>
                    <tr>
                    <td className="form-label">Maximum date:</td>
                    <td><input type="text" id="max" name="max" className='form-control'/></td>
                    </tr>
                </tbody></table>
                <div style={{display:"flex",flexDirection:"row", textAlign:"center",marginLeft:10, marginTop:3}}>
                <label className="form-label" htmlFor="add-team-title" style={{marginTop:10, marginRight:5}}>Minimal duration:</label>
                <input type="number" 
                    className="form-control" 
                    id="duration" 
                    placeholder="min duration"
                    name="teamTitle"
                    min={0} 
                    onChange={(e)=>this.setState({minDuration:e.target.value})}
                    value={this.state.minDuration}
                    style={{height:40}}
                    />  
                </div>
                <div style={{display:"flex",flexDirection:"row", textAlign:"center",marginLeft:10, marginTop:3}}>
                <label className="form-label" htmlFor="add-team-title" style={{marginTop:10, marginRight:5}}>Prefix:</label>
                <input type="text" 
                    className="form-control" 
                    id="prefix" 
                    placeholder="enter prefix"
                    name="prefix"
                    onChange={(e)=>this.setState({prefix:e.target.value})}
                    value={this.state.prefix}
                    style={{height:40}}
                    />  
                </div>
                </div>
                
                    
                <table id="table" className="table table-stripped align-items-center justify-content-center mb-0" style={{width:'100%'}}>
                    <thead  style={{width:'1400px'}}>
                        <tr>
                            <th style={{fontSize:"18px"}}></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={i18n.language !== 'en'?{fontSize:"16px"}:{fontSize:"18px"}}>{t("journalThTime")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={i18n.language !== 'en'?{fontSize:"16px"}:{fontSize:"18px"}}>{t("journalThCompany")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={i18n.language !== 'en'?{fontSize:"16px"}:{fontSize:"18px"}}>{t("journalThSip")}</th>
                            {/* <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={i18n.language !== 'en'?{fontSize:"16px"}:{fontSize:"18px"}}>{t("journalThSrc")}</th> */}
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={i18n.language !== 'en'?{fontSize:"16px"}:{fontSize:"18px"}}>{t("journalThDst")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={i18n.language !== 'en'?{fontSize:"16px"}:{fontSize:"18px", width:80}}>dur.</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={i18n.language !== 'en'?{fontSize:"16px"}:{fontSize:"18px", width:80}}>conv.</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={i18n.language !== 'en'?{fontSize:"16px"}:{fontSize:"18px"}}></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={i18n.language !== 'en'?{fontSize:"16px"}:{fontSize:"18px"}}></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={i18n.language !== 'en'?{fontSize:"16px"}:{fontSize:"18px"}}>{t("journalThStatus")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"18px"}}></th>
                        </tr>
                        <tr className='filters'>
                            <th style={{fontSize:"18px"}} className='filters'></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            {/* <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th> */}
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px", width:80}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px", width:80}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                        </tr>
                    </thead>
        
                    <tbody style={{width:'1400px'}}>
                            {
                            //this.showTable()
                            }
                    </tbody>
                </table>
            </div>
        )
        }
    }
export default withTranslation()(Table)