import axios from 'axios';
import { changeLanguage } from 'i18next';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage';
import i18n from '../i18n/config';
import Snackbar from '@mui/material/Snackbar';

export default function NavbarHorizontal() {

    const { t,i18n } = useTranslation();

  const [userinfo] = useState({
    company_id:0,
    role_id:0,
    fullname:""
  });

const [incall,setInCall] = useState(0);
  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }
  const nav = useNavigate();
  function logoutHandle(){
    secureLocalStorage.setItem("token",null);
    secureLocalStorage.setItem("logged_user",false);
    //secureLocalStorage.setItem("user",null);
    nav("/login");
}
  useEffect(()=>{
    if(JSON.parse(secureLocalStorage.getItem('user')!==null)){
    setInterval(async ()=>{
        axios.post('https://www.pbxdiamondcall.com:8081/agents/onlinecounter', {
            ui_id:JSON.parse(secureLocalStorage.getItem('user'))!==null?JSON.parse(secureLocalStorage.getItem('user')).user_info_id:0,
            company_id:JSON.parse(secureLocalStorage.getItem('user'))!==null?JSON.parse(secureLocalStorage.getItem('user')).company_id:0
        })
        .then((res)=>{
            setInCall(res.data.incall);
        })
        .catch((err)=>console.log(err))
    },3000)
    
    let userid = JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem("user")).id:0;
    axios.post('https://www.pbxdiamondcall.com:8081/getuserinfo', {id:userid})
    .then(res=>{
      userinfo.company_id = res.data.agentinfo_id;
      userinfo.fullname = res.data.fullname;
      userinfo.role_id = res.data.role_id;
      // console.log(userinfo);
    })
    .catch((error)=>{
      console.log(error);
    });
    }
   
  },[]);
  
  function renderSwitch() {
    let value = JSON.parse(secureLocalStorage.getItem('user'))!==null
    ?JSON.parse(secureLocalStorage.getItem('user')).role_id!==null
    ?JSON.parse(secureLocalStorage.getItem('user')).role_id
    :1
    :1
    ;
    switch (value) {
      case 1: return "God"; 
      case 2: return "BrandAdmin"; 
      case 3: return "OfficeAdmin"; 
      case 4: return "DepartmentAdmin"; 
      case 5: return "TeamLeader"; 
      case 6: return "Agent";  
      case 8: return "Coach";  
      case 7: return "BrandManager"; 
      default: return "default";
    }
  }
  
  return (
    <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar" style={{position:"absolute", left:0,top:0}}>
        <div className="container-xxl">
            <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-4">
            <Link to="dashboard" className="app-brand-link">
                        <span className="app-brand-logo demo">
                        <img src={process.env.PUBLIC_URL+'/images/diamond-30.png'}
                                                        alt="Суфлер"
                                                        />
                        </span>
                        <span className="app-brand-text demo menu-text fw-bolder ms-2" style={{textTransform:'uppercase' , fontFamily:'Public Sans ,sans-serif'}}>DiamondCall</span>
                        </Link>
                <Link to="dashboard" className="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
                <i className="bx bx-chevron-left bx-sm align-middle" />
                </Link>
            </div>
            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <ul className="navbar-nav flex-row align-items-center ms-auto d-flex" style={{gap:'7px'}}>
                    {
                        JSON.parse(secureLocalStorage.getItem('user'))==null?
                        <Navigate to="/login" replace={true} />
                        :
                        JSON.parse(secureLocalStorage.getItem('user')).role_id!==null?
                        JSON.parse(secureLocalStorage.getItem('user')).role_id===1?
                        <li className="nav-item me-2 me-xl-0">Incall:{incall}</li>
                        :null
                        :null
                    }
                    {
                        // JSON.parse(secureLocalStorage.getItem('user')).role_id!==null?
                        // JSON.parse(secureLocalStorage.getItem('user')).role_id===1?
                        // <li>
                        //         <button className='btn' style={{
                        //             border:2,
                        //             borderRadius:13,
                        //             width:30,
                        //             height:30,
                        //             marginTop:2,
                        //             marginLeft:5,
                        //             padding:1
                        //             }}
                        //             data-bs-toggle="modal" data-bs-target="#modalTop"
                        //             >
                        //                 <i className="fa-solid fa-phone-volume fa-xl"  
                        //                 id="transfer" data-tooltip-id="tooltip" data-tooltip-content={"Transfer call"}></i>
                        //         </button>
                        // </li>
                        // :null
                        // :null
                    }
                    {/* Language */}
                    <li className="nav-item dropdown-language dropdown me-2 me-xl-0" style={{display:'flex', alignItems:'center' , justifyContent:'center'}}>
                            <span className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                <i className={t("langclass")}/>
                            </span>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li style={{width:"100%",height:"100%"}} onClick={(e)=>changeLanguage(e)}>
                                    <button className="dropdown-item" value='en' style={{width:"100%",height:"100%"}}>
                                        <i className="fi fi-us fis rounded-circle fs-4 " />
                                        <span className="align-middle">{t("langus")}</span>
                                    </button>
                                </li>
                                {/* <li>
                                    <button className="dropdown-item" value='ru' onClick={(e)=>changeLanguage(e)} style={{width:"100%",height:"100%"}}>
                                        <i className="fi fi-ru fis rounded-circle fs-4 me-1" />
                                        <span className="align-middle">{t("langru")}</span>
                                    </button>
                                </li> */}
                                <li style={{width:"100%",height:"100%"}} onClick={(e)=>changeLanguage(e)}>
                                    <button className="dropdown-item" value='fr'  style={{width:"100%",height:"100%"}}>
                                        <i className="fi fi-fr fis rounded-circle fs-4" />
                                        <span className="align-middle">{t("langfr")}</span>
                                    </button>
                                </li>
                                <li style={{width:"100%",height:"100%"}} onClick={(e)=>changeLanguage(e)}>
                                    <button className="dropdown-item" value='es' style={{width:"100%",height:"100%"}}>
                                        <i className="fi fi-es fis rounded-circle fs-4" />
                                        <span className="align-middle">{t("langes")}</span>
                                    </button>
                                </li>
                                <li style={{width:"100%",height:"100%"}} onClick={(e)=>changeLanguage(e)}>
                                    <button className="dropdown-item" value='it' style={{width:"100%",height:"100%"}}>
                                        <i className="fi fi-it fis rounded-circle fs-4" />
                                        <span className="align-middle">{t("langit")}</span>
                                    </button>
                                </li>
                                <li style={{width:"100%",height:"100%"}} onClick={(e)=>changeLanguage(e)}>
                                    <button className="dropdown-item" value='de' style={{width:"100%",height:"100%"}}>
                                        <i className="fi fi-de fis rounded-circle fs-4" />
                                        <span className="align-middle">{t("langde")}</span>
                                    </button>
                                </li>
                            </ul>
                    </li>
                    {/* User */}
                    <li className="nav-item navbar-dropdown dropdown-user dropdown " >
                            <div className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                <i className="fa-solid fa-user-tie fa-xl" />
                            </div>
                            <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <div className="dropdown-item">
                                <div className="d-flex">
                                    <div className="flex-shrink-0 me-2">
                                    <div className='avatar' style={{alignContent:"center",justifyContent:"center"}}>
                                        <i className="fa-solid fa-user-tie fa-2xl" style={{position:"absolute", top:"55%"}} />
                                    </div>
                                    </div>
                                    <div className="flex-grow-1">
                                    <span className="fw-semibold d-block"><b>{t("navLogin")}</b>{JSON.parse(secureLocalStorage.getItem('user'))!==null?JSON.parse(secureLocalStorage.getItem('user')).login:null}</span>
                                    <small className="text-muted">{renderSwitch()}</small>
                                    </div>
                                </div>
                                </div>
                            </li>
                            {/* <li>
                                <div className="dropdown-divider" />
                            </li> */}
                            {/* <li>
                                <div className="dropdown-item" onClick={logoutHandle}>
                                <i className="bx bx-power-off me-2" />
                                <span className="align-middle">Log Out</span>
                                </div>
                            </li> */}
                            </ul>
                    </li>
                    {/*/ User */}
                    {
                        JSON.parse(secureLocalStorage.getItem('user'))!==null?JSON.parse(secureLocalStorage.getItem('user')).role_id===1?<button className="nav-link btn btn-label-dark" type="button"
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasStart" 
                        aria-controls="offcanvasStart" >
                            <i className="fa-solid fa-unlock-keyhole" />
                        </button>:null:null
                    }
                    
                    <li className="nav-item" onClick={()=>logoutHandle()} style={{}}  >
                        <div className="menu-link" >
                            <button className='btn btn-label-secondary rounded-pill'>
                                <i className="nav-icon tf-icons bx bx-log-out-circle" style={{marginLeft:"-2px",marginRight:"2px"}}/>
                                <div data-i18n="Logout"><b>{t("logout")}</b></div>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
            {/* Search Small Screens */}
        </div>
        
    </nav>

)
}
