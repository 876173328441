import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select'

export default function RecordsChoose() {
    let navigate = useNavigate();
    const [teams,SetTeams] = useState([]);
    const [companies,SetCompanies] = useState([]);
    const [agents,SetAgents] = useState([]);
    const [agentChoose,SetAgentChoose] = useState(false);
    const [companyChoose,SetCompanyChoose] = useState(false);
    const [groupChoose,SetGroupChoose] = useState(false);
    const [selectedCompany,SetSelectedCompany] = useState();
    const [selectedAgent,SetSelectedAgent] = useState();
    const [selectedGroup,SetSelectedGroup] = useState();

    useEffect(()=>{
        if(JSON.parse(secureLocalStorage.getItem('user')).role_id===1){
            axios.get('https://www.pbxdiamondcall.com:8081/companies')
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            arr.push({
                        value:el.Id,
                        label:el.Title
                    })
            
        })
        SetCompanies(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
        }
        else{
        axios.post('https://www.pbxdiamondcall.com:8081/agents/access', {
            user_info_id:JSON.parse(secureLocalStorage.getItem('user')).user_info_id,
            company_id:JSON.parse(secureLocalStorage.getItem('user')).company_id
       })
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            if(el.has_access===1){
            arr.push({
                        value:el.id,
                        label:el.title
                    })
            }
            
        })
        SetTeams(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
    }
    },[])
    const HandleCompanyChoose=(el)=>{
        SetCompanyChoose(true);
        SetSelectedCompany(el.value);
        axios.post('https://www.pbxdiamondcall.com:8081/teams/company', {
            company_id:el.value
       })
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            arr.push({
                        value:el.id,
                        label:el.title
                    })
        })
        SetTeams(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
    }
    const HandleGroupChoose=(el)=>{
        SetGroupChoose(true);
        SetSelectedGroup(el.value);
        axios.post('https://www.pbxdiamondcall.com:8081/agents/getFromTeam', {
            team_id:el.value
       })
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            arr.push({
                        value:el.Id,
                        label:el.Login
                    })
        })
        SetAgents(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
    }
    const HandleAgentChoose=(el)=>{
        SetAgentChoose(true);
        SetSelectedAgent(el.label);
    }
    const HandleCompanyTeamRecords=()=>{
        navigate(`/old/records/${selectedCompany}/${selectedGroup}`);
    }
    const HandleCompanyAgentRecords=()=>{
        navigate(`/old/records/${selectedCompany}/${selectedGroup}/${selectedAgent}`);
        
    }
    const HandleCompanyRecords=()=>{
        navigate(`/old/records/${selectedCompany}`);
        
    }
    const HandleTeamRecords=()=>{
        navigate(`/old/records/${JSON.parse(secureLocalStorage.getItem('user')).company_id}/${selectedGroup}`);
    }
    const HandleAgentRecords=()=>{
        navigate(`/old/records/${JSON.parse(secureLocalStorage.getItem('user')).company_id}/${selectedGroup}/${selectedAgent}`);
        
    }
    const HandleAllRecords=()=>{
        navigate(`/old/journal`);
    }
  return (
    <div className="container-xxl flex-grow-1 container-p-y" >
    <div className='card' style={{height:"30.5%", borderRadius:"15px", width:"100%", padding:10, paddingTop:0}}>
        <div className="card-header ">
            <h5 className="card-title" style={{fontSize:"25px", fontWeight:"bold"}}>Select records to show</h5>
        </div>
        <div style={{display:'flex', flexDirection:"row", marginLeft:"-10px"}}>
            <div style={{display:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?'flex':'none',flexDirection:'column'}}>
            <div className="mb-3" style={{width:250, margin:10,marginTop:"40px"}}>
                {/* <label htmlFor="flatpickr-range" className="form-label">Select company</label> */}
                <Select placeholder='Select company'
                                onChange={(e)=>HandleCompanyChoose(e)}
                                id='operatorLb' 
                                isDisabled={false} 
                                    options={companies} 
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={{
                                        control:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd",
                                            borderColor:"#444663"
                                        }),
                                        option: (styles, { isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                            };
                                        },
                                        singleValue:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd"
                                        }),
                                        placeholder:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd"
                                        }),
                                        menuList:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#949fae",
                                        }),
                                    }}/>
            </div>
            </div>
            
        
        <div style={{display:'flex',flexDirection:'column'}}>
            <div className="mb-3" style={{width:250, margin:10}}>
                {/* <label htmlFor="flatpickr-range" className="form-label">Select group</label> */}
                <Select placeholder='Select group'      
                                onChange={(e)=>HandleGroupChoose(e)}
                                id='operatorLb' 
                                isDisabled={false} 
                                options={teams} 
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#fff":"#444663":"#444663",
                                        color:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#949fae":"#b8b8cd":"#b8b8cd",
                                        borderColor:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#949fae":"#444663":"#444663"
                                    }),
                                    option: (styles, { isFocused, isSelected }) => {
                                        return {
                                            ...styles,
                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                        };
                                    },
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#fff":"#444663":"#444663",
                                        color:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#949fae":"#b8b8cd":"#b8b8cd",
                                    }),
                                    placeholder:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#fff":"#444663":"#444663",
                                        color:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#949fae":"#b8b8cd":"#b8b8cd",
                                    }),
                                    menuList:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:!companyChoose?"#fff":"#444663",
                                        color:"#949fae",
                                    }),
                                }}/>
            </div>
            <div className="mb-3" style={{width:250, margin:10}}>
                {/* <label htmlFor="flatpickr-range" className="form-label">Select agent</label> */}
                <Select placeholder='Select agent'
                                onChange={(e)=>HandleAgentChoose(e)}
                                id='operatorLb' 
                                isDisabled={!groupChoose} 
                                options={agents} 
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:!groupChoose?"#fff":"#444663",
                                        color:!groupChoose?"#949fae":"#b8b8cd",
                                        borderColor:!groupChoose?"#949fae":"#444663"
                                    }),
                                    option: (styles, { isFocused, isSelected }) => {
                                        return {
                                            ...styles,
                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                        };
                                    },
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:!groupChoose?"#fff":"#444663",
                                        color:!groupChoose?"#fff":"#b8b8cd"
                                    }),
                                    placeholder:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:!groupChoose?"#fff":"#444663",
                                        color:!groupChoose?"#949fae":"#b8b8cd"
                                    }),
                                    menuList:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:!groupChoose?"#fff":"#444663",
                                        color:"#949fae",
                                    }),
                                }}/>
            </div> 
        </div>
        <div style={{display:'flex',flexDirection:'row', marginTop:"-5px"}}>
            <button className='btn btn-label-primary' 
            style={{width:160, height:50, margin:10, alignSelf:"center", marginTop:20, fontWeight:"bold"}}
            disabled={!groupChoose} onClick={()=>JSON.parse(secureLocalStorage.getItem('user')).role_id!==1?
            HandleTeamRecords()
            :HandleCompanyTeamRecords()}>Team records</button>
            <button className='btn btn-label-primary'
            style={{width:160, height:50, margin:10, alignSelf:"center", marginTop:20, fontWeight:"bold"}}
            disabled={!agentChoose} onClick={()=>JSON.parse(secureLocalStorage.getItem('user')).role_id!==1?
            HandleAgentRecords()
            :HandleCompanyAgentRecords()}>Agent records</button>
            <button className='btn btn-label-primary'
            style={{width:160, height:50, margin:10, alignSelf:"center", marginTop:20, fontWeight:"bold"}}
            onClick={()=>HandleAllRecords()}>All records</button>
            <button className='btn btn-label-primary' 
            style={{display:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?'flex':'none',width:160, height:50, margin:10, alignSelf:"center", marginTop:20, fontWeight:"bold"}}
            disabled={!companyChoose} onClick={()=>HandleCompanyRecords()}>Company records</button>
        </div>
        </div>
        
    </div>
    </div>
  )
}
