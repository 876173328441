import React, { useEffect, useState } from 'react'
import styles from './AdministrationRebrending.module.scss';
import PropTypes from 'prop-types';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Select from 'react-select';
import { CircularProgress } from '@mui/material';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function AdministrationRebrending() {
    const [activeItem,setActiveItem] = useState("Prefixes");
    const [values,setValues] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const itemsTitles = {
        "Prefixes":"Prefix",
        "Countries":"Country",
        "Accounts":"Billing account",
        "OperatorsRU": "Operator",
        "OperatorsEU": "Operator",
        "OperatorsFULL": "Operator",
    }
    const [activeSubItem,setActiveSubItem] = useState();
    const [activeValue,setActiveValue] = useState();
    const [search,setSearch] = useState('');
    const [prefixes,SetPrefixes] = useState([]);
    const [countries,SetCountries] = useState([]);
    const [countriesSelect,setCountriesSelect] = useState([]);
    const [accounts,SetAccounts] = useState([]);
    const [operatorsru,SetOperatorsRU] = useState([]);
    const [operatorseu,SetOperatorsEU] = useState([]);
    const [operatorsfull,SetOperatorsFULL] = useState([]);

    const [prefix,setPrefix] = useState('');
    const [prefixTitle,setPrefixTitle] = useState('');
    const [prefixDescription,setPrefixDescription] = useState('');
    const [prefixType,setPrefixType] = useState('');
    const [prefixRegion,setPrefixRegion] = useState('');

    const [countryTitle,setCountryTitle] = useState('');
    
    const [podmenaChance,setPodmenaChance] = useState({value:50,label:"1:1"});
    const [podmenaId,setPodmenaId] = useState(null);
    const [gateways,setGateways] = useState([]);

    const [description,setDescription] = useState('');
    const [price,setPrice] = useState(0);
    const [access,setAccess] = useState(0);

    const [operatorTitle,setOperatorTitle] = useState('');
    const [operatorNumber,setOperatorNumber] = useState('');
    const [operatorPrice,setOperatorPrice] = useState(0);
    const [operatorCountry,setOperatorCountry] = useState(0);

    const chances = [                       
        { value: 50, label: '1:1' },
        { value: 33, label: '1:2' },
        { value: 25, label: '1:3' },
        { value: 20, label: '1:4' },
        { value: 16, label: '1:5' },
        { value: 14, label: '1:6' },
        { value: 12, label: '1:7' },
        { value: 11, label: '1:8' },
        { value: 10, label: '1:9' },
        { value: 9, label: '1:10' },
    ]

    const MySwal = withReactContent(Swal);

    const connectAll = () => {
        setIsLoading(true);
        axios.get("https://www.pbxdiamondcall.com:8081/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
            axios.get('https://www.pbxdiamondcall.com:8081/gateways')
            .then((res)=>{
                var arr = [];
                res.data.data.forEach((val)=>{
                    arr.push({value:val.id,label:val.title});
                })
                setGateways(arr);
            })
            .catch((err)=>console.log(err))
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.pbxdiamondcall.com:8081/countries")
            .then((res)=>{
                SetCountries(res.data);
                var arr = [];
                res.data.forEach((val)=>{
                    arr.push({value:val.id,label:val.title});
                })
                setCountriesSelect(arr);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.pbxdiamondcall.com:8081/billing/accounts")
            .then((res)=>{
                SetAccounts(res.data);
                axios.get("https://www.pbxdiamondcall.com:8081/alloperators")
                .then((res)=>{
                    SetOperatorsRU(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));
                    setIsLoading(false);
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
    }
    const SwalDel =()=>{
        return Swal.fire({ // <-- return Promise
          title: 'Delete record?',
          text: 'This action cannot be undone.',
          icon: 'question',
          confirmButtonText: 'Delete',
          confirmButtonColor: '#DC004E',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: false,
          scrollbarPadding: false
        }).then((result) => {
          return result.isConfirmed;
        });
    }
    const setPrefixInfo = (val) => {
        setPrefix(val.prefix)
        setPrefixTitle(val.title)
        setPrefixDescription(val.description)
        setPrefixType({value:val.type,label:val.type})
        setPrefixRegion({value:val.region,label:val.region})
        setPodmenaId(val.podmena_id!=null?gateways.find((gat)=>gat.value == val.podmena_id):null);
        setPodmenaChance(val.podmena_chance!=null?chances.find((ch)=>ch.value == val.podmena_chance):null);
    }
    const setOperatorInfo = (val) => {
        setOperatorTitle(val.title);
        setOperatorNumber(val.num);
        setOperatorPrice(val.price);
        setOperatorCountry(countriesSelect.find((country)=>val.country===country.label));
    }

    const HandleAddPrefix = () => {
        if(prefixTitle === "" || prefixDescription === "" || prefix === 0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.pbxdiamondcall.com:8081/prefixes",{
                title: prefixTitle,
                description:prefixDescription,
                prefix:prefix,
                additional: '',
                typeofPrefix: prefixType.value,
                regionofPrefix: prefixRegion.value,
                useAdditional : 0,
                podmenaId:podmenaId!=null?podmenaId.value:null,
                podmenaChance:podmenaChance!=null?podmenaChance.value:null
            })
            .then((res)=>{
                Swal.fire({
                    icon:"Prefix succesfully created",
                    text:"Success!"
                })
                connectAll()
            })
            .catch((err)=>Swal.fire({
                title:"Error while creating prefix.",
                text:err?.data,
                icon:'warning'
            }))
        }
    }
    const HandleUpdatePrefix = () => {
        if(prefixTitle === "" || prefixDescription === "" || prefix === 0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.patch("https://www.pbxdiamondcall.com:8081/prefixes",{
                id:activeSubItem.id,
                title: prefixTitle,
                description: prefixDescription,
                prefix: prefix,
                additional: '',
                typeofPrefix: prefixType.value,
                regionofPrefix: prefixRegion.value,
                useAdditional : 0,
                podmenaId:podmenaId!=null?podmenaId.value:null,
                podmenaChance:podmenaChance!=null?podmenaChance.value:null
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Prefix succesfully updated!"
                })
                connectAll()
            })
            .catch((err)=>Swal.fire({
                title:"Error updating prefix.",
                text:err?.data,
                icon:'warning'
            }))
        }
    }
    const HandleDeletePrefix=async()=>{
        let isdel = await SwalDel();
        console.log(isdel);
        if(isdel){
            axios.post("https://www.pbxdiamondcall.com:8081/prefixes/del",{id:activeSubItem.id })
            .then((res)=>{
                Swal.fire({
                icon:'success',
                timer:2000,
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            connectAll()

            setActiveSubItem(null)
            })
            .catch((err)=>{
                console.log(err);
                Swal.fire({
                icon: 'error',
                timer:2000,
                title: 'Error occured while deleting prefix.',
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            })
        }
    }
    
    const HandleAddC = () => {
        if(countryTitle === ""){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.pbxdiamondcall.com:8081/countries",{
                title:countryTitle
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                connectAll();
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleUpdateC = () => {
        if(countryTitle === ""){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.patch("https://www.pbxdiamondcall.com:8081/countries",{
                id:activeSubItem.id,
                title:countryTitle
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                    connectAll();
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleDeleteC=async()=>{
        let isdel = await SwalDel();
        if(isdel){
            axios.post("https://www.pbxdiamondcall.com:8081/countries/del",{id:activeSubItem.id })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                icon:'success',
                timer:2000,
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            connectAll();
            setActiveSubItem(null)
            })
            .catch((err)=>{
                console.log(err);
                Swal.fire({
                icon: 'error',
                timer:2000,
                title: 'Error occured while deleting country.',
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            })
        }
    }
    
    const Withdrawal=()=>{
        if(description!= "" && price!=0){
            axios.post('https://www.pbxdiamondcall.com:8081/billing/manual',{
                id:activeSubItem.id,
                price:price*-1,
                description:description
            })
            .then((res)=>{
                Swal.fire({
                    title:"Success",
                    icon:"success",
                    timer:1500
                })
                connectAll();
            })
            .catch((err)=>{
                Swal.fire({
                    title:"Error",
                    icon:"error",
                    timer:1500
                })
            })
        }
    }
    const Replenish=()=>{
        if(description!= "" && price!=0){
            axios.post('https://www.pbxdiamondcall.com:8081/billing/manual',{
                id:activeSubItem.id,
                price:price,
                description:description
            })
            .then((res)=>{
                console.log(res)
                Swal.fire({
                    title:"Success",
                    icon:"success",
                    timer:1500
                })
                connectAll();
            })
            .catch((err)=>{
                console.log(err)
                Swal.fire({
                    title:"Error",
                    icon:"error",
                    timer:1500
                })
            })
        }
    }
    const SubmitAccess = () =>{
        axios.post("https://www.pbxdiamondcall.com:8081/billing/account/access", {
            id:activeSubItem.id,
            access:access
        })
        .then((res)=>{
            console.log(res);
            Swal.fire({
                icon:"success",
                text:"Success!"
            })
            connectAll();
            
        })
        .catch((err)=> Swal.fire({
            title:"Error!",
            timer:2000,
            icon:"error",
            text:err
        }))
    }

    
    const HandleAddO = () => {
        if(operatorTitle === "" || operatorCountry?.label === "" || operatorNumber === "" || operatorPrice ==0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
            
        }
        else {
            axios.post("https://www.pbxdiamondcall.com:8081/operators/add",{
                title:operatorTitle,
                num:operatorNumber,
                country:operatorCountry?.label,
                region: 'ru',
                price:operatorPrice
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                connectAll();
                
            })
            .catch((err)=>{Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            })
        console.log(err)})
        }
    }
    const HandleAddOE = () => { 
        if(operatorTitle === "" || operatorCountry?.label === "" || operatorNumber === "" || operatorPrice ==0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.pbxdiamondcall.com:8081/operators/add",{
                title:operatorTitle,
                num:operatorNumber,
                country:operatorCountry?.label,
                region:"eu",
                price:operatorPrice
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                connectAll();
                
            })
            .catch((err)=>{Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            })
        console.log(err)})
        }
    }
    const HandleAddOF = () => {
        if(operatorTitle === "" || operatorCountry?.label === "" || operatorNumber === "" || operatorPrice == 0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.pbxdiamondcall.com:8081/operators/add",{
                title:operatorTitle,
                num:operatorNumber,
                country:operatorCountry?.label,
                region:"full",
                price:operatorPrice
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                connectAll();
                
            })
            .catch((err)=>{Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            })
        console.log(err)})
        }
    }
    const HandleUpdateO = () => {
        if(operatorTitle === "" || operatorCountry?.label === "" || operatorNumber === "" || operatorPrice ==0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.pbxdiamondcall.com:8081/operators/update",{
                id:activeSubItem.id,
                title:operatorTitle,
                num:operatorNumber,
                country:operatorCountry?.label,
                price:operatorPrice
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                connectAll();
                
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleUpdateOF = () => {
        if(operatorTitle === "" || operatorCountry?.label === "" || operatorNumber === "" || operatorPrice == 0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.pbxdiamondcall.com:8081/operators/update",{
                id:activeSubItem.id,
                title:operatorTitle,
                num:operatorNumber,
                country:operatorCountry?.label,
                price:operatorPrice
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                connectAll();
                
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleUpdateOE = () => {
        if(operatorTitle === "" || operatorCountry?.label === "" || operatorNumber === "" || operatorPrice ==0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.pbxdiamondcall.com:8081/operators/update",{
                id:activeSubItem.id,
                title:operatorTitle,
                num:operatorNumber,
                country:operatorCountry?.label,
                price:operatorPrice
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                connectAll();
                
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleDeleteO=async()=>{
        let isdel = await SwalDel();
        console.log(isdel);
        if(isdel){
            axios.post("https://www.pbxdiamondcall.com:8081/operators/del",{id:activeSubItem.id })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                icon:'success',
                timer:2000,
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            connectAll();
            setActiveSubItem(null);
            
            })
            .catch((err)=>{
                console.log(err);
                Swal.fire({
                icon: 'error',
                timer:2000,
                title: 'Error occured while deleting country.',
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            })
        }
    }
    const HandleDeleteOF=async()=>{
        let isdel = await SwalDel();
        console.log(isdel);
        if(isdel){
            axios.post("https://www.pbxdiamondcall.com:8081/operators/del",{id:activeSubItem.id })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                icon:'success',
                timer:2000,
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            connectAll();
            setActiveSubItem(null);
            
            })
            .catch((err)=>{
                console.log(err);
                Swal.fire({
                icon: 'error',
                timer:2000,
                title: 'Error occured while deleting country.',
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            })
        }
    }
    const HandleDeleteOE=async()=>{
        let isdel = await SwalDel();
        console.log(isdel);
        if(isdel){
            axios.post("https://www.pbxdiamondcall.com:8081/operators/del",{id:activeSubItem.id })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                icon:'success',
                timer:2000,
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            connectAll();
            setActiveSubItem(null);
            
            })
            .catch((err)=>{
                console.log(err);
                Swal.fire({
                icon: 'error',
                timer:2000,
                title: 'Error occured while deleting country.',
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            })
        }
    }


    useEffect(()=>connectAll(), []);
    const renderCategories = () => {
        switch (activeItem) {
            case "Prefixes":
                if(search!==""){
                    return prefixes.filter((val)=>val.title.toLowerCase().includes(search.toLowerCase()) || val.prefix.toString().includes(search.toLowerCase())).map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val?.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setPrefixInfo(val)}}>
                                [{val.prefix}] {val.title}
                            </span>
                        )
                    })     
                }  else {
                    return prefixes.map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val?.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setPrefixInfo(val)}}>
                                [{val.prefix}] {val.title}
                            </span>
                        )
                    })     
                }
            case "Countries":
                if(search!==""){
                    return countries.filter((val)=>val.title.toLowerCase().includes(search.toLowerCase())).map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val?.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setCountryTitle(val.title);}}>
                                {val.title}
                            </span>
                        )
                    })     
                }  else {
                    return countries.map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val?.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setCountryTitle(val.title);}}>
                                {val.title}
                            </span>
                        )
                    })     
                }
            case "Accounts":
                if(search!==""){
                    return accounts.filter((val)=>val.Title.toLowerCase().includes(search.toLowerCase())).map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val?.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setAccess(val.negative_access)}}>
                                {val.Title}
                            </span>
                        )
                    })     
                }  else {
                    return accounts.map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val?.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setAccess(val.negative_access)}}>
                                {val.Title}
                            </span>
                        )
                    })     
                }
            case "OperatorsRU":
                if(search!==""){
                    return operatorsru.filter((val)=>val.title.toLowerCase().includes(search.toLowerCase()) || val.num.includes(search.toLowerCase())
                    || val.country.toLowerCase().includes(search.toLowerCase())
                    ).map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setOperatorInfo(val)}}>
                                [{val.num}] {val.title}
                            </span>
                        )
                    })     
                }  else {
                    return operatorsru.map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setOperatorInfo(val)}}>
                            [{val.num}] {val.title}
                            </span>
                        )
                    })     
                }
            case "OperatorsEU":
                if(search!==""){
                    return operatorseu.filter((val)=>val.title.toLowerCase().includes(search.toLowerCase()) || val.num.includes(search.toLowerCase())
                    || val.country.toLowerCase().includes(search.toLowerCase())
                    ).map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setOperatorInfo(val)}}>
                                [{val.num}] {val.title}
                            </span>
                        )
                    })     
                }  else {
                    return operatorseu.map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setOperatorInfo(val)}}>
                            [{val.num}] {val.title}
                            </span>
                        )
                    })     
                }
            case "OperatorsFULL":
                if(search!==""){
                    return operatorsfull.filter((val)=>val.title.toLowerCase().includes(search.toLowerCase()) || val.num.includes(search.toLowerCase())
                    || val.country.toLowerCase().includes(search.toLowerCase())
                    ).map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setOperatorInfo(val)}}>
                                [{val.num}] {val.title}
                            </span>
                        )
                    })     
                }  else {
                    return operatorsfull.map((val)=>{
                        return (
                            <span className={activeSubItem?.id==val.id?styles.category_item_active:styles.category_item} onClick={()=>{setActiveSubItem(val);setOperatorInfo(val)}}>
                            [{val.num}] {val.title}
                            </span>
                        )
                    })     
                } 
            default: return "Loading..."
        } 
    }
    const renderValues = () => {
        if(activeSubItem)
        switch (activeItem) { 
            case "Prefixes":
                    return (                    
                        <span className={styles.prefixItem}>
                            <div style={{width:"45%"}}>
                                <div style={{width:"80%"}}>
                                    <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>Type of prefix</label>
                                    <br/>
                                    <Select 
                                        options={[  
                                            { value: 'spam', label: 'spam' },
                                            { value: 'pool', label: 'pool' },
                                            { value: 'dedicated', label: 'dedicated' }
                                        ]} 
                                        onChange={(e)=>setPrefixType(e)}
                                        placeholder='Select type' id='prefixLb'
                                        value={prefixType}
                                        styles={{
                                            control:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2",
                                                borderColor:"#3EA3FC"
                                            }),
                                            singleValue:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2"
                                            }),
                                            placeholder:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2"
                                            }),
                                            option: (styles, { isFocused, isSelected }) => {
                                                return {
                                                    ...styles,
                                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                };
                                            },
                                            menuList:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#232329",
                                                color:"#d3d3d2",
                                            }),
                                        }}
                                    />
                                </div>
                                <div style={{width:"80%"}}>
                                    <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                        Region of prefix
                                    </label>
                                    <br/>
                                    <Select 
                                        options={[  
                                            { value: 'eu', label: 'eu' },
                                            { value: 'ru', label: 'ru' },
                                            { value: 'full', label: 'full' }
                                        ]} 
                                        onChange={(e)=>setPrefixRegion(e)}
                                        placeholder='Select region' id='prefixLb'
                                        value={prefixRegion}
                                        styles={{
                                            control:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2",
                                                borderColor:"#3EA3FC"
                                            }),
                                            singleValue:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2"
                                            }),
                                            placeholder:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2"
                                            }),
                                            option: (styles, { isFocused, isSelected }) => {
                                                return {
                                                    ...styles,
                                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                };
                                            },
                                            menuList:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#232329",
                                                color:"#d3d3d2",
                                            }),
                                        }} 
                                    />
                                </div>
                                <div className="col mb-0">
                                    <label htmlFor="use" className="form-label">Podmena</label>
                                    <br/>
                                    <input type="checkbox" id="use" className="form-check-input" checked={podmenaId!=null}
                                    onClick={(e)=>{podmenaId!=null?setPodmenaId(null):setPodmenaId(gateways[0]);setPodmenaChance(chances[0]);}}/>
                                </div>
                                {
                                    podmenaId &&
                                    <>
                                        <div style={{width:"80%"}}>
                                            <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>Podmena</label>
                                            <br/>
                                            <Select 
                                                options={gateways} 
                                                onChange={(e)=>setPodmenaId(e)}
                                                placeholder='Select gateway' id='prefixLb'
                                                value={podmenaId}
                                                styles={{
                                                    control:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2",
                                                        borderColor:"#3EA3FC"
                                                    }),
                                                    singleValue:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2"
                                                    }),
                                                    placeholder:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2"
                                                    }),
                                                    option: (styles, { isFocused, isSelected }) => {
                                                        return {
                                                            ...styles,
                                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                        };
                                                    },
                                                    menuList:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"#232329",
                                                        color:"#d3d3d2",
                                                    }),
                                                }}
                                            />
                                        </div>
                                        <div style={{width:"80%"}}>
                                            <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                                Podmena chance
                                            </label>
                                            <br/>
                                            <Select 
                                                options={chances} 
                                                onChange={(e)=>setPodmenaChance(e)}
                                                placeholder='Select chance' id='prefixLb'
                                                value={podmenaChance}
                                                styles={{
                                                    control:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2",
                                                        borderColor:"#3EA3FC"
                                                    }),
                                                    singleValue:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2"
                                                    }),
                                                    placeholder:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2"
                                                    }),
                                                    option: (styles, { isFocused, isSelected }) => {
                                                        return {
                                                            ...styles,
                                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                        };
                                                    },
                                                    menuList:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"#232329",
                                                        color:"#d3d3d2",
                                                    }),
                                                }} 
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                            <div style={{width:"45%"}}>
                                <div style={{width:"80%"}}>
                                    <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                        Title
                                    </label>
                                    <br/>
                                    <input 
                                        type="text" value={prefixTitle} 
                                        onChange={(e)=>setPrefixTitle(e.target.value)} 
                                        className={styles.value_input}
                                    />
                                </div>
                                <div style={{width:"80%"}}>
                                    <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                        Description
                                    </label>
                                    <br/>
                                    <input 
                                        type="text" value={prefixDescription} 
                                        onChange={(e)=>setPrefixDescription(e.target.value)} 
                                        className={styles.value_input}
                                    />
                                </div>
                                <div style={{width:"80%"}}>
                                    <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                        Prefix
                                    </label>
                                    <br/>
                                    <input 
                                        type="text" value={prefix} 
                                        onChange={(e)=>setPrefix(e.target.value)} 
                                        className={styles.value_input}
                                    />
                                </div>
                                <div style={{width:"80%", display:"flex", gap:"1vw",height:"5vh", marginTop:"3vh"}}>
                                    <span className={styles.button} 
                                    onClick={()=>{
                                        HandleUpdatePrefix()
                                    }}>
                                        Update
                                    </span>
                                    <span className={styles.button_delete} 
                                    onClick={()=>{HandleDeletePrefix()}}
                                    >
                                        Delete
                                    </span>
                                </div>
                            </div>
                        </span>
                )
            case "Countries":
                return (                    
                    <span className={styles.prefixItem}>
                        <div style={{width:"45%"}}>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Title
                                </label>
                                <br/>
                                <input 
                                    type="text" value={countryTitle} 
                                    onChange={(e)=>setCountryTitle(e.target.value)} 
                                    className={styles.value_input}
                                />
                            </div>
                        </div>
                        <div style={{width:"45%"}}>
                            <div style={{width:"80%", display:"flex", gap:"1vw",height:"5vh", marginTop:"3vh"}}>
                                <span className={styles.button} 
                                onClick={()=>{
                                    HandleUpdateC()
                                }}>
                                    Update
                                </span>
                                <span className={styles.button_delete} 
                                onClick={()=>{HandleDeleteC()}}
                                >
                                    Delete
                                </span>
                            </div>
                        </div>
                    </span>
                )
            case "Accounts":
                return (                    
                    <span className={styles.prefixItem} style={{flexDirection:"column", justifyContent:"start"}}>
                        <div style={{width:"80%", height:"20vh"}}>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Title
                                </label>
                                <br/>
                                <input 
                                    type="text" value={activeSubItem.Title} 
                                    className={styles.value_input}
                                    disabled={true}
                                    />
                            </div>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Balance
                                </label>
                                <br/>
                                <input 
                                    type="text" value={activeSubItem.balance} 
                                    className={styles.value_input}
                                    disabled={true}
                                    />
                            </div>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Negative access
                                </label>
                                <br/>
                                <input 
                                    type="text" value={activeSubItem.negative_access?"Access granted":"No access"} 
                                    className={styles.value_input}
                                    disabled={true}
                                    />
                            </div>
                        </div>
                        <div style={{width:"75%"}}>
                            <div style={{width:"80%", display:"flex", gap:"1vw",height:"5vh", marginTop:"3vh", justifyContent:"center"}}>
                                <span className={styles.button_replenish} data-bs-toggle="modal" data-bs-target="#modalTop">
                                    Replenish
                                </span>
                                <span className={styles.button_delete} data-bs-toggle="modal" data-bs-target="#modalDown">
                                    Withdrawal
                                </span>
                                <span className={styles.button_access} data-bs-toggle="modal" data-bs-target="#modalAccess">
                                    Set access
                                </span>
                            </div>
                        </div>
                    </span>
                )
            case "OperatorsRU":
                return (                    
                    <span className={styles.prefixItem}>
                        <div style={{width:"45%"}}>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Title
                                </label>
                                <br/>
                                <input 
                                    type="text" value={operatorTitle} 
                                    onChange={(e)=>setOperatorTitle(e.target.value)} 
                                    className={styles.value_input}
                                />
                            </div>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Number
                                </label>
                                <br/>
                                <input 
                                    type="text" value={operatorNumber} 
                                    onChange={(e)=>setOperatorNumber(e.target.value)} 
                                    className={styles.value_input}
                                />
                            </div>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Price
                                </label>
                                <br/>
                                <input 
                                    type="text" value={operatorPrice} 
                                    onChange={(e)=>setOperatorPrice(e.target.value)} 
                                    className={styles.value_input}
                                />
                            </div>
                        </div>
                        <div style={{width:"45%"}}>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>Country</label>
                                <br/>
                                <Select 
                                    options={countriesSelect} 
                                    onChange={(e)=>setOperatorCountry(e)}
                                    placeholder='Select country' id='prefixLb'
                                    value={operatorCountry}
                                    styles={{
                                        control:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"transparent",
                                            color:"#d3d3d2",
                                            borderColor:"#3EA3FC"
                                        }),
                                        singleValue:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"transparent",
                                            color:"#d3d3d2"
                                        }),
                                        placeholder:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"transparent",
                                            color:"#d3d3d2"
                                        }),
                                        option: (styles, { isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                            };
                                        },
                                        menuList:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#232329",
                                            color:"#d3d3d2",
                                        }),
                                        input:(baseStyles,state)=>({
                                            ...baseStyles,
                                            color:"#d3d3d2",
                                        }),
                                    }}
                                />
                            </div>
                            <div style={{width:"80%", display:"flex", gap:"1vw",height:"5vh", marginTop:"3vh"}}>
                                <span className={styles.button} 
                                onClick={()=>{
                                    HandleUpdateO()
                                }}>
                                    Update
                                </span>
                                <span className={styles.button_delete} 
                                onClick={()=>{HandleDeleteO()}}
                                >
                                    Delete
                                </span>
                            </div>
                        </div>
                    </span>
                )
            case "OperatorsEU":
                return (                    
                    <span className={styles.prefixItem}>
                        <div style={{width:"45%"}}>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Title
                                </label>
                                <br/>
                                <input 
                                    type="text" value={operatorTitle} 
                                    onChange={(e)=>setOperatorTitle(e.target.value)} 
                                    className={styles.value_input}
                                />
                            </div>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Number
                                </label>
                                <br/>
                                <input 
                                    type="text" value={operatorNumber} 
                                    onChange={(e)=>setOperatorNumber(e.target.value)} 
                                    className={styles.value_input}
                                />
                            </div>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Price
                                </label>
                                <br/>
                                <input 
                                    type="text" value={operatorPrice} 
                                    onChange={(e)=>setOperatorPrice(e.target.value)} 
                                    className={styles.value_input}
                                />
                            </div>
                        </div>
                        <div style={{width:"45%"}}>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>Country</label>
                                <br/>
                                <Select 
                                    options={countriesSelect} 
                                    onChange={(e)=>setOperatorCountry(e)}
                                    placeholder='Select country' id='prefixLb'
                                    value={operatorCountry}
                                    styles={{
                                        control:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"transparent",
                                            color:"#d3d3d2",
                                            borderColor:"#3EA3FC"
                                        }),
                                        singleValue:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"transparent",
                                            color:"#d3d3d2"
                                        }),
                                        placeholder:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"transparent",
                                            color:"#d3d3d2"
                                        }),
                                        option: (styles, { isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                            };
                                        },
                                        menuList:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#232329",
                                            color:"#d3d3d2",
                                        }),
                                        input:(baseStyles,state)=>({
                                            ...baseStyles,
                                            color:"#d3d3d2",
                                        }),
                                    }}
                                />
                            </div>
                            <div style={{width:"80%", display:"flex", gap:"1vw",height:"5vh", marginTop:"3vh"}}>
                                <span className={styles.button} 
                                onClick={()=>{
                                    HandleUpdateOE()
                                }}>
                                    Update
                                </span>
                                <span className={styles.button_delete} 
                                onClick={()=>{HandleDeleteOE()}}
                                >
                                    Delete
                                </span>
                            </div>
                        </div>
                    </span>
                )
            case "OperatorsFULL":
                return (                    
                    <span className={styles.prefixItem}>
                        <div style={{width:"45%"}}>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Title
                                </label>
                                <br/>
                                <input 
                                    type="text" value={operatorTitle} 
                                    onChange={(e)=>setOperatorTitle(e.target.value)} 
                                    className={styles.value_input}
                                />
                            </div>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Number
                                </label>
                                <br/>
                                <input 
                                    type="text" value={operatorNumber} 
                                    onChange={(e)=>setOperatorNumber(e.target.value)} 
                                    className={styles.value_input}
                                />
                            </div>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                    Price
                                </label>
                                <br/>
                                <input 
                                    type="text" value={operatorPrice} 
                                    onChange={(e)=>setOperatorPrice(e.target.value)} 
                                    className={styles.value_input}
                                />
                            </div>
                        </div>
                        <div style={{width:"45%"}}>
                            <div style={{width:"80%"}}>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>Country</label>
                                <br/>
                                <Select 
                                    options={countriesSelect} 
                                    onChange={(e)=>setOperatorCountry(e)}
                                    placeholder='Select country' id='prefixLb'
                                    value={operatorCountry}
                                    styles={{
                                        control:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"transparent",
                                            color:"#d3d3d2",
                                            borderColor:"#3EA3FC"
                                        }),
                                        singleValue:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"transparent",
                                            color:"#d3d3d2"
                                        }),
                                        placeholder:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"transparent",
                                            color:"#d3d3d2"
                                        }),
                                        option: (styles, { isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                            };
                                        },
                                        menuList:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#232329",
                                            color:"#d3d3d2",
                                        }),
                                        input:(baseStyles,state)=>({
                                            ...baseStyles,
                                            color:"#d3d3d2",
                                        }),
                                    }}
                                />
                            </div>
                            <div style={{width:"80%", display:"flex", gap:"1vw",height:"5vh", marginTop:"3vh"}}>
                                <span className={styles.button} 
                                onClick={()=>{
                                    HandleUpdateOF()
                                }}>
                                    Update
                                </span>
                                <span className={styles.button_delete} 
                                onClick={()=>{HandleDeleteOF()}}
                                >
                                    Delete
                                </span>
                            </div>
                        </div>
                    </span>
                )
            default: return "Loading..."
        } 
    }
    useEffect(()=>{
        setValues(renderValues());
    },[ 
        prefix,
        prefixTitle,
        prefixDescription,
        prefixType,
        prefixRegion,

        countryTitle,

        operatorTitle,
        operatorNumber,
        operatorPrice,
        operatorCountry
    ])
    useEffect(()=>{
        if(activeItem=="Brands"){
        }
    },[activeValue])
    useEffect(()=>{
        setActiveSubItem(null);
        setActiveValue(null);
    },[activeItem])
    useEffect(()=>{
        connectAll()
        setValues(renderValues());
    },[activeSubItem])
    useEffect(()=>{
        setValues(renderValues());
    },[podmenaId,podmenaChance])

    useEffect(()=>{

    },[search])

    let roled = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(roled !== 1){
        return <Navigate to="/onlinecalls" replace={true}/>
    }
    else
    return (
        <div className={styles.page}>
                <div className={styles.header}>
                    <span className={activeItem!=="Prefixes"?styles.header_item:styles.header_item_active} onClick={()=>setActiveItem("Prefixes")}>
                        Prefixes
                    </span>
                    <span className={activeItem!=="Countries"?styles.header_item:styles.header_item_active} onClick={()=>setActiveItem("Countries")}>
                        Countries
                    </span>
                    <span className={activeItem!=="Accounts"?styles.header_item:styles.header_item_active} onClick={()=>setActiveItem("Accounts")}>
                        Accounts
                    </span>
                    <span className={activeItem!=="OperatorsRU"?styles.header_item:styles.header_item_active} onClick={()=>setActiveItem("OperatorsRU")}>
                        Operators RU
                    </span>
                    <span className={activeItem!=="OperatorsEU"?styles.header_item:styles.header_item_active} onClick={()=>setActiveItem("OperatorsEU")}>
                        Operators EU
                    </span>
                    <span className={activeItem!=="OperatorsFULL"?styles.header_item:styles.header_item_active} onClick={()=>setActiveItem("OperatorsFULL")}>
                        Operators FULL
                    </span>
                </div>
                <div className={styles.container}>
                        <div className={styles.categories}>
                            <span className={styles.category_header}>
                                <input 
                                    type="text" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder={`Search for ${activeItem.toLowerCase()}`}
                                    className={styles.category_search}
                                />
                            </span>
                            {
                                renderCategories()
                            }
                        </div>
                        <div className={styles.items}>
                            <span className={styles.item_header}>
                            {itemsTitles[activeItem]}
                                {
                                (activeItem == "Prefixes" && !isLoading) && 
                                <span className={styles.button_create} 
                                data-bs-toggle="modal" 
                                data-bs-target="#basicModalAdd" onClick={()=>{
                                    setPrefix(0)
                                    setPrefixTitle("")
                                    setPrefixDescription("")
                                    setPrefixType({value:"pool",label:"pool"})
                                    setPrefixRegion({value:"ru",label:"ru"})
                                    setActiveSubItem(null);
                                }}>
                                    Create
                                </span>
                                }
                                {
                                (activeItem == "Countries" && !isLoading) && 
                                <span className={styles.button_create} 
                                data-bs-toggle="modal" 
                                data-bs-target="#basicModalAddC" onClick={()=>{
                                    setCountryTitle("");
                                    setActiveSubItem(null);
                                }}>
                                    Create
                                </span>
                                }
                                {
                                (activeItem == "OperatorsRU" && !isLoading) && 
                                <span className={styles.button_create} 
                                data-bs-toggle="modal" 
                                data-bs-target="#basicModalAddO" onClick={()=>{
                                    setOperatorTitle("");
                                    setOperatorNumber("");
                                    setOperatorPrice(0);
                                    setOperatorCountry(countriesSelect.find((country)=>"Russia"===country.label));
                                    setActiveSubItem(null);
                                }}>
                                    Create
                                </span>
                                }
                                {
                                (activeItem == "OperatorsEU" && !isLoading) && 
                                <span className={styles.button_create} 
                                data-bs-toggle="modal" 
                                data-bs-target="#basicModalAddOE" onClick={()=>{
                                    setOperatorTitle("");
                                    setOperatorNumber("");
                                    setOperatorPrice(0);
                                    setOperatorCountry(countriesSelect.find((country)=>"France"===country.label));
                                    setActiveSubItem(null);
                                }}>
                                    Create
                                </span>
                                }
                                {
                                (activeItem == "OperatorsFULL" && !isLoading) && 
                                <span className={styles.button_create} 
                                data-bs-toggle="modal" 
                                data-bs-target="#basicModalAddOF" onClick={()=>{
                                    setOperatorTitle("");
                                    setOperatorNumber("");
                                    setOperatorPrice(0);
                                    setOperatorCountry(countriesSelect.find((country)=>"UK"===country.label));
                                    setActiveSubItem(null);
                                }}>
                                    Create
                                </span>
                                }
                            </span>
                            {
                                isLoading ? 
                                <div style={{width:"100%",height:"100%",display:'flex',alignItems:"center",justifyContent:"center"}}>
                                    <CircularProgress size={100} sx={{color:"#3EA3FC"}}/>
                                </div>
                                :
                                activeSubItem ? values
                                :
                                <div style={{width:"100%",height:"100%",display:'flex',fontSize:"2vh",alignItems:"center",justifyContent:"center"}}>
                                    <span>
                                        No records...
                                    </span>
                                </div>
                            }
                            
                        </div>
                </div>

                <div className="modal fade" id="basicModalAdd" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" style={{background:"#232329",color:"#d3d3d2"}}>
                            <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel1">Prefix</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col mb-3">
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input type="text" id="title" className="form-control" placeholder="Enter title" 
                                    style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                    value={prefixTitle} onChange={(e)=>setPrefixTitle(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="row g-2 mb-3">
                                    <div className="col mb-0">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <input type="text" id="description" className="form-control" placeholder="Description.." 
                                    style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                    value={prefixDescription} onChange={(e)=>setPrefixDescription(e.target.value)}/>
                                    </div>
                                    <div className="col mb-0">
                                    <label htmlFor="prefix" className="form-label">Prefix</label>
                                    <input type="number" id="prefix" className="form-control" max={999} min={0}  
                                    style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                    value={prefix} onChange={(e)=>setPrefix(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="row g-2">
                                <div className="col mb-0">
                                    <label htmlFor="use" className="form-label">Type of prefix</label>
                                    <br/>
                                    <Select     
                                        options={[  
                                            { value: 'spam', label: 'spam' },
                                            { value: 'pool', label: 'pool' },
                                            { value: 'dedicated', label: 'dedicated' }
                                        ]} 
                                        onChange={(e)=>setPrefixType(e)}
                                        placeholder='Select type' id='prefixLb'
                                        value={prefixType}
                                        styles={{
                                            control:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2",
                                                borderColor:"#3EA3FC"
                                            }),
                                            singleValue:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2"
                                            }),
                                            placeholder:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2"
                                            }),
                                            option: (styles, { isFocused, isSelected }) => {
                                                return {
                                                    ...styles,
                                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                };
                                            },
                                            menuList:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#232329",
                                                color:"#d3d3d2",
                                            }),
                                        }}  />
                                </div>
                                <div className="col mb-0">
                                    <label htmlFor="use" className="form-label">Region of prefix</label>
                                    <br/>
                                    <Select     
                                        options={[  
                                            { value: 'eu', label: 'eu' },
                                            { value: 'ru', label: 'ru' },
                                            { value: 'full', label: 'full' }
                                        ]} 
                                        onChange={(e)=>setPrefixRegion(e)}
                                        placeholder='Select region' id='prefixLb'
                                        value={prefixRegion}
                                        styles={{
                                            control:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2",
                                                borderColor:"#3EA3FC"
                                            }),
                                            singleValue:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2"
                                            }),
                                            placeholder:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"transparent",
                                                color:"#d3d3d2"
                                            }),
                                            option: (styles, { isFocused, isSelected }) => {
                                                return {
                                                    ...styles,
                                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                };
                                            },
                                            menuList:(baseStyles,state)=>({
                                                ...baseStyles,
                                                background:"#232329",
                                                color:"#d3d3d2",
                                            }),
                                        }} />
                                </div>
                                
                                </div>
                                <div className="col mb-0">
                                    <label htmlFor="use" className="form-label">Podmena</label>
                                    <br/>
                                    <input type="checkbox" id="use" className="form-check-input" checked={podmenaId!=null}
                                    onClick={(e)=>{podmenaId!=null?setPodmenaId(null):setPodmenaId(gateways[0]);setPodmenaChance(chances[0]);}}/>
                                </div>
                                {
                                    podmenaId &&
                                    <div className="row g-2">
                                        <div style={{width:"80%"}}>
                                            <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>Podmena</label>
                                            <br/>
                                            <Select 
                                                options={gateways} 
                                                onChange={(e)=>setPodmenaId(e)}
                                                placeholder='Select gateway' id='prefixLb'
                                                value={podmenaId}
                                                styles={{
                                                    control:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2",
                                                        borderColor:"#3EA3FC"
                                                    }),
                                                    singleValue:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2"
                                                    }),
                                                    placeholder:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2"
                                                    }),
                                                    option: (styles, { isFocused, isSelected }) => {
                                                        return {
                                                            ...styles,
                                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                        };
                                                    },
                                                    menuList:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"#232329",
                                                        color:"#d3d3d2",
                                                    }),
                                                }}
                                            />
                                        </div>
                                        <div style={{width:"80%"}}>
                                            <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                                Podmena chance
                                            </label>
                                            <br/>
                                            <Select 
                                                options={chances} 
                                                onChange={(e)=>setPodmenaChance(e)}
                                                placeholder='Select chance' id='prefixLb'
                                                value={podmenaChance}
                                                styles={{
                                                    control:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2",
                                                        borderColor:"#3EA3FC"
                                                    }),
                                                    singleValue:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2"
                                                    }),
                                                    placeholder:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"transparent",
                                                        color:"#d3d3d2"
                                                    }),
                                                    option: (styles, { isFocused, isSelected }) => {
                                                        return {
                                                            ...styles,
                                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                                        };
                                                    },
                                                    menuList:(baseStyles,state)=>({
                                                        ...baseStyles,
                                                        background:"#232329",
                                                        color:"#d3d3d2",
                                                    }),
                                                }} 
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="modal-footer">
                                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal"
                                style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>Close</button>
                                <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={()=>HandleAddPrefix()}
                                style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="basicModalAddC" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" style={{background:"#232329",color:"#d3d3d2"}}>
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel1">Country</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input type="text" id="title" className="form-control" placeholder="Enter title" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            value={countryTitle} onChange={(e)=>setCountryTitle(e.target.value)}/>
                            </div>
                        </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal"
                            style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>Close</button>
                        <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={HandleAddC}
                            style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}>Submit</button>
                        </div>
                    </div>
                    </div>
                </div>
                
                <div className="modal fade" data-bs-backdrop="static" id="modalTop" tabIndex={-1}>
                            <div className="modal-dialog modal-dialog-centered">
                                <form className="modal-content"  style={{background:"#232329",color:"#d3d3d2"}}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modalTopTitle">Replenishment</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col mb-3">
                                            <label htmlFor="nameSlideTop" className="form-label">Description</label>
                                            <input type="text" id="nameSlideTop" className="form-control" 
                                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                            value={description} onChange={(e)=>setDescription(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="row g-2">
                                        <div className="col mb-0">
                                            <label htmlFor="emailSlideTop" className="form-label">Cost</label>
                                            <input type="number" id="emailSlideTop" className="form-control"
                                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                            value={price} onChange={(e)=>setPrice(e.target.value)} min={0}/>
                                        </div>
                                    </div><br />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal"
                            style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>Close</button>
                                    <button type="button" className="btn btn-success" onClick={()=>Replenish()}
                            style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}>Replenish</button>
                                </div>
                                </form>
                            </div>
                </div>
                <div className="modal fade" data-bs-backdrop="static" id="modalDown" tabIndex={-1}>
                            <div className="modal-dialog modal-dialog-centered">
                                <form className="modal-content"  style={{background:"#232329",color:"#d3d3d2"}}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modalTopTitle">Withdrawal</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col mb-3">
                                            <label htmlFor="nameSlideTop" className="form-label">Description</label>
                                            <input type="text" id="nameSlideTop" className="form-control" 
                                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                            value={description} onChange={(e)=>setDescription(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="row g-2">
                                        <div className="col mb-0">
                                            <label htmlFor="emailSlideTop" className="form-label">Cost</label>
                                            <input type="number" id="emailSlideTop" className="form-control"
                                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                            value={price} onChange={(e)=>setPrice(e.target.value)} max={0}/>
                                        </div>
                                    </div><br />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal"
                            style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>Close</button>
                                    <button type="button" className="btn btn-success" onClick={()=>Withdrawal()}
                            style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}>Withdrawal</button>
                                </div>
                                </form>
                            </div>
                </div>
                <div className="modal fade" data-bs-backdrop="static" id="modalAccess" tabIndex={-1}>
                            <div className="modal-dialog modal-dialog-centered">
                                <form className="modal-content"  style={{background:"#232329",color:"#d3d3d2"}}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modalTopTitle">Withdrawal</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                                </div>
                                <div className="modal-body">
                                    <div className="row g-2">
                                        <div className="col mb-0">
                                            <label htmlFor="emailSlideTop" className="form-label">Negative balance access:</label>
                                            <input type="checkbox" id="emailSlideTop" style={{marginLeft:"5px",color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                            checked={access} onChange={(e)=>setAccess(e.target.checked)}/>
                                        </div>
                                    </div><br />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal"
                            style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>Close</button>
                                    <button type="button" className="btn btn-success" onClick={()=>SubmitAccess()}
                            style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}>Set</button>
                                </div>
                                </form>
                            </div>
                </div>
                
                <div className="modal fade" id="basicModalAddO" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{background:"#232329",color:"#d3d3d2"}}>
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel1">Operator RU</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input type="text" id="title" className="form-control" placeholder="Enter title" 
                                style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            value={operatorTitle} onChange={(e)=>setOperatorTitle(e.target.value)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="country" className="form-label">Country</label>
                            <Select 
                                options={countriesSelect} 
                                onChange={(e)=>setOperatorCountry(e)}
                                placeholder='Select country' id='prefixLb'
                                value={operatorCountry}
                                styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"transparent",
                                        color:"#d3d3d2",
                                        borderColor:"#3EA3FC"
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"transparent",
                                        color:"#d3d3d2"
                                    }),
                                    placeholder:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"transparent",
                                        color:"#d3d3d2"
                                    }),
                                    option: (styles, { isFocused, isSelected }) => {
                                        return {
                                            ...styles,
                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                        };
                                    },
                                    menuList:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"#232329",
                                        color:"#d3d3d2",
                                    }),
                                    input:(baseStyles,state)=>({
                                        ...baseStyles,
                                        color:"#d3d3d2",
                                    }),
                                }}
                            />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="num" className="form-label">Num</label>
                            <input type="text" id="num" className="form-control" placeholder="Enter num" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            value={operatorNumber} onChange={(e)=>setOperatorNumber(e.target.value)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="num" className="form-label">Price</label>
                            <input type="number" id="num" className="form-control" placeholder="Enter price"
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}} 
                            value={operatorPrice} onChange={(e)=>setOperatorPrice(e.target.value)}/>
                            </div>
                        </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal"
                        style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>Close</button>
                        <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={HandleAddO}
                        style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}>Submit</button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="modal fade" id="basicModalAddOE" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{background:"#232329",color:"#d3d3d2"}}>
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel1">Operator RU</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input type="text" id="title" className="form-control" placeholder="Enter title" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            value={operatorTitle} onChange={(e)=>setOperatorTitle(e.target.value)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="country" className="form-label">Country</label>
                            <Select 
                                options={countriesSelect} 
                                onChange={(e)=>setOperatorCountry(e)}
                                placeholder='Select country' id='prefixLb'
                                value={operatorCountry}
                                styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"transparent",
                                        color:"#d3d3d2",
                                        borderColor:"#3EA3FC"
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"transparent",
                                        color:"#d3d3d2"
                                    }),
                                    placeholder:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"transparent",
                                        color:"#d3d3d2"
                                    }),
                                    option: (styles, { isFocused, isSelected }) => {
                                        return {
                                            ...styles,
                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                        };
                                    },
                                    menuList:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"#232329",
                                        color:"#d3d3d2",
                                    }),
                                    input:(baseStyles,state)=>({
                                        ...baseStyles,
                                        color:"#d3d3d2",
                                    }),
                                }}
                            />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="num" className="form-label">Num</label>
                            <input type="text" id="num" className="form-control" placeholder="Enter num" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            value={operatorNumber} onChange={(e)=>setOperatorNumber(e.target.value)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="num" className="form-label">Price</label>
                            <input type="number" id="num" className="form-control" placeholder="Enter price"
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}} 
                            value={operatorPrice} onChange={(e)=>setOperatorPrice(e.target.value)}/>
                            </div>
                        </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal"
                        style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>Close</button>
                        <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={HandleAddOE}
                        style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}>Submit</button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="modal fade" id="basicModalAddOF" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{background:"#232329",color:"#d3d3d2"}}>
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel1">Operator RU</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input type="text" id="title" className="form-control" placeholder="Enter title" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            value={operatorTitle} onChange={(e)=>setOperatorTitle(e.target.value)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="country" className="form-label">Country</label>
                            <Select 
                                options={countriesSelect} 
                                onChange={(e)=>setOperatorCountry(e)}
                                placeholder='Select country' id='prefixLb'
                                value={operatorCountry}
                                styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"transparent",
                                        color:"#d3d3d2",
                                        borderColor:"#3EA3FC"
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"transparent",
                                        color:"#d3d3d2"
                                    }),
                                    placeholder:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"transparent",
                                        color:"#d3d3d2"
                                    }),
                                    option: (styles, { isFocused, isSelected }) => {
                                        return {
                                            ...styles,
                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                        };
                                    },
                                    menuList:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"#232329",
                                        color:"#d3d3d2",
                                    }),
                                    input:(baseStyles,state)=>({
                                        ...baseStyles,
                                        color:"#d3d3d2",
                                    }),
                                }}
                            />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="num" className="form-label">Num</label>
                            <input type="text" id="num" className="form-control" placeholder="Enter num" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            value={operatorNumber} onChange={(e)=>setOperatorNumber(e.target.value)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                            <label htmlFor="num" className="form-label">Price</label>
                            <input type="number" id="num" className="form-control" placeholder="Enter price" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            value={operatorPrice} onChange={(e)=>setOperatorPrice(e.target.value)}/>
                            </div>
                        </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal"
                        style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>Close</button>
                        <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={HandleAddOF}
                        style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}>Submit</button>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
    )
}
