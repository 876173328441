import React, { useState, useRef, useEffect } from 'react';
import styles from './SideMenuAdmin.module.scss';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import { Link, useLocation } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
export default function SideMenuAdmin() {
  //#region dashboard menu
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const options = ['Today', 'Week', 'Month'];
  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const [selectedOption, setSelectedOption] = useState(options[0]);
  useEffect(() => {
    const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
    }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
    document.removeEventListener('mousedown', handleClickOutside);
    };
}, [ref]);
  //#endregion
  const [activeMenu,setActiveMenu] = useState('online');
  const location = useLocation();
  useEffect(()=>{

  },[]);
  return (
    <div className={styles.menu}>
      <div onClick={()=>setActiveMenu("brand")} className={location.pathname.includes("info")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/admin/administration`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
          <TuneOutlinedIcon className={styles.icon} />
          <span className={styles.label}>Administration</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("brand")} className={location.pathname.includes("gateways")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/admin/gateways`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
          <TuneIcon className={styles.icon} />
          <span className={styles.label}>Gateways</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("billing")} className={location.pathname.includes("billing")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/admin/billing/manage`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
          <RequestQuoteOutlinedIcon className={styles.icon} />
          <span className={styles.label}>Billing</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("freeswitch")} className={location.pathname.includes("freeswitch")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/admin/freeswitch`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
        <RecentActorsOutlinedIcon className={styles.icon} />
        <span className={styles.label}>Freeswitch</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("numbers")} className={location.pathname.includes("numbers")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/admin/numbers`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
        <ContactPhoneOutlinedIcon className={styles.icon} />
        <span className={styles.label}>numbers</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("suspects")} className={location.pathname.includes("suspects")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/admin/suspects`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
        <ErrorOutlineOutlinedIcon className={styles.icon} />
        <span className={styles.label}>Suspects</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("daily")} className={location.pathname.includes("daily")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/admin/report/daily`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
        <AssignmentOutlinedIcon className={styles.icon} />
        <span className={styles.label}>Daily reports</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("prefix")} className={location.pathname.includes("prefix")?styles.menu_item_active:styles.menu_item}>
      <Link to={`/admin/report/prefix`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
        <PinOutlinedIcon className={styles.icon} />
        <span className={styles.label}>Prefix Report</span>
        </Link>
      </div>
      <div onClick={()=>setActiveMenu("relations")} className={location.pathname.includes("relations")?styles.menu_item_active:styles.menu_item}>
        <Link to={`/admin/gateway/relations`} style={{
          textDecoration:"none",color:"inherit", width:"100%",
          height:"100%", display:'flex',alignItems:"center",justifyContent:"center", gap:'0.2vw'
          }}>
        <InfoOutlinedIcon className={styles.icon} />
        <span className={styles.label}>Gateway Prefixes</span>
        </Link>
      </div>
    </div>
  )
}
