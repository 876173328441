import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import '../css/record.css'
import ScaleLoader from 'react-spinners/ScaleLoader';
import { WaveSurferPlayer } from "./AudioWaveForm";
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js'

export default function Record() {
    const { uuid, time, dst, conversation, duration, sip, status } = useParams();
    const [audioSrc, setAudioSrc] = useState("");
    const [isLoading , setIsLoading] = useState(true)
    const [isLoaded , setIsLoaded] = useState(true)
    // Функция для выполнения POST-запроса и получения аудиозаписи
    const fetchAudio = async () => {
        // setIsLoading(true)
        try {
            const response = await fetch("https://www.pbxdiamondcall.com:8081/getrecord", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ filename: uuid }),
            })
            .then(async (res)=>{
                if (res.ok) {
                    // Получаем аудиозапись в формате Blob
                    const audioBlob = await res.blob();
                    // Создаем URL для Blob
                    const audioUrl = URL.createObjectURL(audioBlob);
                    // Устанавливаем URL в состояние
                    setAudioSrc(audioUrl);
                    console.log(audioUrl);
                    setIsLoading(false);
                    setIsLoaded(true);
                } else {
                    console.error("Ошибка при получении аудиозаписи");
                }
            });

            
        } catch (error) {
            console.error("Ошибка при выполнении запроса", error);
        }
    };
    useEffect(() => {
        
        

        // Вызываем функцию при монтировании компонента
        fetchAudio();

        // Очищаем URL при размонтировании компонента
        return () => {
            URL.revokeObjectURL(audioSrc);
            //setAudioSrc('');
        };
    }, [uuid]); // Перезапускаем useEffect при изменении id
    let endTime = new Date() 
    const startTime = new Date(time).getTime()
    endTime= startTime + duration * 1000
    // if(isLoading){
    //     return <div className='container-xxl flex-grow-1 container-p-y'>
    //             <div className="card" style={{width:1400, height:800, justifyContent:'center',alignContent:'center', backgroundColor:"#f5f5f9", boxShadow:'none'}}>
    //                 <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto',}}>Loading...</h1>
    //                 <MoonLoader
    //                     color="#33cc66"
    //                     height={270}
    //                     margin={10}
    //                     radius={2}
    //                     speedMultiplier={0.8}
    //                     width={80}
    //                     cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
    //             </div>
    //         </div>
    // }
    return (
        <div className="container-xxl flex-grow-1 container-p-y" style={{ marginLeft: "80px", marginTop: "50px", fontFamily: 'Poppins' }}>
            <div className="card">
                <div className="card-body">
                <h1 style={{marginLeft:140}}>ID: {uuid}</h1>
            <div style={{ width: '80%', display: 'flex', alignItems: 'center' , gap:'40px' , marginTop:'50px', marginLeft:140 }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p style={{ fontWeight: 'bold' }}>Start Time:</p>
                        <p style={{ fontWeight: 'bold' }}>{new Date(time).toLocaleString()}</p>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p style={{ fontWeight: 'bold' }}>ANI/From:</p>
                        <p style={{ fontWeight: 'bold' }}>{sip}</p>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',height:"54.94px" }} >
                        <p style={{ fontWeight: 'bold' }}>DNIS/To:</p>
                        <p style={{ fontWeight: 'bold'}}>{dst}</p>
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p style={{ fontWeight: 'bold' }}>End Time:</p>
                        <p style={{ fontWeight: 'bold' }}>{new Date(endTime).toLocaleString()}</p>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p style={{ fontWeight: 'bold' }}>Total contact time:</p>
                        <p style={{ fontWeight: 'bold' }}>{parseInt(conversation)>3600?new Date(parseInt(conversation) * 1000).toISOString().substring(11, 19)
                                        :parseInt(conversation) < 60?new Date(parseInt(conversation) * 1000).toISOString().substring(17, 19) + ` sec`
                                        : new Date(parseInt(conversation) * 1000).toISOString().substring(14, 19)}</p>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p style={{ fontWeight: 'bold' }}>Status:</p>
                        <p style={status.includes('NO')?{fontWeight: 'bold', padding:'8px',borderRadius:'10px' , color:'rgb(183, 110, 0)',backgroundColor: 'rgba(255, 171, 0, 0.16)'}:{fontWeight: 'bold', padding:'8px',borderRadius:'10px' , color:'rgb(17, 141, 87)',backgroundColor: 'rgba(34, 197, 94, 0.16)'}}>{status}</p>
                    </div>
                </div>
            </div>
            {
                isLoading ? 
                <div style={{ width: "80%"  , marginTop:'50px', marginLeft:150, display:'flex'  , alignItems:'center' , justifyContent:'center', flexDirection:'column'}}>
                <ScaleLoader
                color="#696cff"
                height={120}
                margin={10}
                radius={2}
                speedMultiplier={0.8}
                width={60}
                cssOverride={{display:'block',marginLeft:'0',marginRight:'0'}}/>
                <p style={{fontWeight:'bold', marginTop:'10px'}}>Loading audio record</p>
                </div>



                :          <WaveSurferPlayer className="audio-wrapper" controls url={audioSrc} 
                style={{ width: "60%"  , marginTop:'50px'}}
                waveColor="#D1D6DA"
                height={120}
                progressColor="#EC407A"
                plugins={[Timeline.create() , Hover.create({labelColor: 'white' , labelTop:'30px',position:'absolute' , top:'50px' , labelBackground: 'black',labelSize: '11px' ,lineColor: 'black'  })]} />
            }
                </div>
            </div>
            
  
        </div>
    );
}
