import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useScript from '../Hooks/UseScript';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';

const SideMenuAdminHorizontal = () => {

    const { t } = useTranslation();
    const {pathname} = useLocation()
const [brands, setBrands] = useState([]);
const [items, setItems] = useState({
    companies: [],
    teams: [],
    departments: [],
    offices: []
});
// const active = useRef(document.getElementById('dashboard'));
// const activeBrand = useRef(document.getElementById('dashboard'));
const active = useRef(document.getElementById('dashboard'));
const active1 = useRef(document.getElementById('dashboard'));
const activeBrand = useRef(document.getElementById('dashboard'));
const companies = useRef('menu-item');
const offices = useRef('menu-item');
const openCompanies =event=> {
    active.current = event.currentTarget;
    event.currentTarget.classList.toggle('open');
    // if(event.currentTarget.id === "companies"){
    //     if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
    //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
    //     if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
    //     if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
    //     if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
    //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
    //     if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
    // }
    // else if(event.currentTarget.id === "offices"){
    //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
    //     if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
    //     if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
    //     if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
    //     if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
    //     if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
    //     if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
    // }
    // else if(event.currentTarget.id === "departments"){
    //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
    //     if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
    //     if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
    //     if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
    //     if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
    //     if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
    //     if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
    // }
    // else if(event.currentTarget.id === "teams"){
    //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
    //     if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
    //     if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
    //     if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
    //     if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
    //     if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
    //     if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
    // }
    // else 
    if(event.currentTarget.id === "journal"){
        if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
        if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
        if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
        if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
        if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
        if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
        if(document.getElementById("brandmanage")!=null)document.getElementById("brandmanage").classList.remove('open');
    }
    else if(event.currentTarget.id === "billing"){
        if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
        if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
        if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
        if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
        if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
        if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
        if(document.getElementById("brandmanage")!=null)document.getElementById("brandmanage").classList.remove('open');
    }
    else if(event.currentTarget.id === "dashboard"){
        if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
        if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
        if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
        if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
        if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
        if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
        if(document.getElementById("brandmanage")!=null)document.getElementById("brandmanage").classList.remove('open');
    }
    // else if(event.currentTarget.id === "brandmanage"){
    //     if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
    //     if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
    //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
    //     if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
    //     if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
    //     if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
    //     if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
    // }
    
}
const toggleActive =event=> {
    active.current = event.currentTarget;
    event.currentTarget.classList.toggle('active');
}
const toggleActive1 =event=> {
    active1.current = event.currentTarget;
    event.currentTarget.classList.toggle('active');
}
const toggleActiveBrand =event=> {
    activeBrand.current = event.currentTarget;
    event.currentTarget.classList.toggle('active');
}
useEffect(()=>{
    axios.get("https://www.pbxdiamondcall.com:8081/companies")
        .then((res)=>{
            //console.log(res);
            setBrands(res.data);
            secureLocalStorage.setItem('brands', res.data)
            res.data.forEach((val,ind)=>{
                if(JSON.parse(secureLocalStorage.getItem("user")!==null))
                if(val.Id == JSON.parse(secureLocalStorage.getItem("user")).company_id) secureLocalStorage.setItem("company_title",val.Title);
            });
        })
        .catch((err)=>{
            console.log(err);
        })
    axios.get("https://www.pbxdiamondcall.com:8081/items")
    .then((res)=>{
        // console.log(res);
        setItems(res.data);
        //var options = [];
        // res.data.companies.forEach(element => {
        //     options.push({
        //         value:element.Id,
        //         label:element.Title
        //     })
        // });
        //secureLocalStorage.setItem("companiesarray",options);
    })
    .catch((err)=>console.log(err));
        //console.log();
    //console.log(JSON.parse(secureLocalStorage.getItem('user')).role_id);
    $(document.body).on('click', (e)=>{if(
        e.target.textContent !== t("journal")
        && e.target.textContent !== t("billing")
        && e.target.textContent !== t("dashboard")
        && e.target.textContent !== t("brandmanage")
        ){
            if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
            if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
            if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
            if(document.getElementById("brandmanage")!=null)document.getElementById("brandmanage").classList.remove('open');
        }
        //console.log(e.target.textContent)
    });
    return()=>{
        $('body').off('click')
    }
    

}, [])

const nav = useNavigate();
function logoutHandle(){
    secureLocalStorage.setItem("token",null);
    secureLocalStorage.setItem("logged_user",false);
    //secureLocalStorage.setItem("user",null);
    nav("/login");
}

const renderMenuItems = () =>{
    let isrender = [
        false,
        false,
        false,
        false,
        false,
    ];
    let role = JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem('user')).role_id:6;
    if(role === 1) isrender = [true,true,true,true,true,true,true]
    else if(role===2) isrender = [true,true,false,false,true,true,true]
    else if(role===3) isrender = [true,true,false,false,true,true,true] 
    else if(role===4) isrender = [true,true,false,false,true,true,true] 
    else if(role===5) isrender = [false,true,false,false,true,true,true]
    else if(role===6) isrender = [false,false,false,false,false,false,false]
    else if(role===8) isrender = [false,true,false,false,true,true,true]
    else if(role===7) isrender = [true,true,true,true,true,true,true]
    else if(role===9) isrender = [true,true,false,false,true,false,true]
    //console.log(role+"render:"+isrender);
    return <ul className="menu-inner"style={{width:1700}}>
        <li className={active.current!==null?active.current.id==='administration' && pathname === '/administration'?'menu-item active':'menu-item':'menu-item'}id='administration' onClick={openCompanies}>
            <Link to="/administration" className="menu-link">
                <i className="menu-icon tf-icons bx bx-cog"></i>
                <div data-i18n="Journal"><b>Administration</b></div>
            </Link> 
        </li>
        <li className={active.current!==null?active.current.id==='gateways' && pathname === '/gateways'?'menu-item active':'menu-item':'menu-item'}id='gateways' onClick={openCompanies}>
            <Link to="/gateways" className="menu-link">
                <i className="menu-icon tf-icons bx bx-phone"></i>
                <div data-i18n="Journal"><b>Gateways</b></div>
            </Link> 
        </li>
        <li className={active.current!==null?active.current.id==='billing' && pathname === '/billing/choose'?'menu-item active':'menu-item':'menu-item'}id='billing' onClick={openCompanies}>
            <Link to="/billing/choose" className="menu-link">
                <i className="menu-icon tf-icons bx bx-dollar-circle"></i>
                <div data-i18n="Journal"><b>Billing</b></div>
            </Link> 
        </li>
    </ul>
}
useScript('../../public/assets/js/menu.js');
return (
    <aside id="layout-menu" className="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0" style={{position:"absolute", left:0,top:110}}>
        <div className="container-xxl d-flex h-100" style={{width:1700, paddingRight:0}} >
            {renderMenuItems()}
        </div>
    </aside>
);
};

export default SideMenuAdminHorizontal;
