import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../css/loginpage.css';
import $ from 'jquery';

export default function Login() {

  const MySwal = withReactContent(Swal);
  const nav = useNavigate();
  const log = useRef('');
  const psw = useRef('');
  const [login, SetLogin] = useState('');
  const [password, SetPassword] = useState('');
 
  useEffect(()=>{
    $(document.body).on('keydown', handleClick);
    return()=>{
      $('body').off('keydown')
    }
  },[])
  const handleSubmit = (event) =>{
    event.preventDefault();
    axios.post('https://www.pbxdiamondcall.com:8081/login', {login:log.current.value,password:psw.current.value})
    .then(res=>{
      if(res.data.result === "success"){
        if(res.data.user.role_id!==6){
        secureLocalStorage.setItem("user",JSON.stringify(res.data.user));
        //secureLocalStorage.setItem("token",res.data.token);
        secureLocalStorage.setItem('logged_user', true);
        secureLocalStorage.setItem("balance",JSON.parse(secureLocalStorage.getItem('user')).billingbalance);
        if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
        axios.get("https://www.pbxdiamondcall.com:8081/companies")
        .then((res)=>{
            var arr = [];
            if(res.data.length>0){
                secureLocalStorage.setItem("brands",res.data);
              }
            })
            .catch((err)=>console.log(err))
        }
        nav("/onlinecalls", {replace:true});
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Result',
            text: "Access denied",
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        }
      }
    })
    .catch((error)=>{
      MySwal.fire({
        icon: 'error',
        title: 'Authentification error.',
        text: error.response.data.result,
        customClass: {
        confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
      //console.log(error.response.data.result);
      //console.log(error);
    });
  }
  function handleShowPsw(){
    const element = document.getElementById('password');
    if(element.type==="text")
    element.type="password";
    else element.type="text";
  }
  const handleClick = (event) => {
    if(event.keyCode == 13){
      event.preventDefault();
      axios.post('https://www.pbxdiamondcall.com:8081/login', {login:log.current.value,password:psw.current.value})
    .then(res=>{
      if(res.data.result === "success"){
        if(res.data.user.role_id!==6){
        secureLocalStorage.setItem("user",JSON.stringify(res.data.user));
        //secureLocalStorage.setItem("token",res.data.token);
        secureLocalStorage.setItem('logged_user', true);
        secureLocalStorage.setItem("balance",JSON.parse(secureLocalStorage.getItem('user')).billingbalance);
        if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
        axios.get("https://www.pbxdiamondcall.com:8081/companies")
        .then((res)=>{
            var arr = [];
            if(res.data.length>0){
                secureLocalStorage.setItem("brands",res.data);
              }
            })
            .catch((err)=>console.log(err))
        }
        nav("/onlinecalls", {replace:true});
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Result',
            text: "Access denied",
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        }
      }
    })
    .catch((error)=>{
      MySwal.fire({
        icon: 'error',
        title: 'Authentification error.',
        text: error.response.data.result,
        customClass: {
        confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
      //console.log(error.response.data.result);
      //console.log(error);
    });
    }
  }
  const renderForm = (
  <div>
    <div className="app-brand mb-5">
          
        </div>
        <div className="login-box" style={{height:450}}>
          <span className="app-brand-link gap-2">
            <span className="app-brand-logo demo">
            <img src={process.env.PUBLIC_URL+'/images/logo.png'}
                                            alt="Логотип"
                                            style={{
                                              width: '2.5vw',
                                              height: '4.4vh'
                                            }}
                                            />
            </span>
            <span className="app-brand-text demo text-body fw-bolder"  style={{textTransform:'uppercase', fontSize:"35px",color:"#d3d3d2",fontFamily:"'Ubuntu', sans-serif"}}>DiamondCall</span>
          </span>
          <br />
      <form id="formAuthentication" className="mb-3" method="POST" onSubmit={handleSubmit}>
              <label htmlFor="email" className="form-label" style={{fontSize:"15px"}}>Username</label>
            <div className="user-box">
              <input 
              type="text" 
              className="form-control" 
              id="username" 
              name="username" 
              placeholder="" autoFocus onChange={(e)=>SetLogin(e.target.value)}
              ref={log}
              required={true} />
            </div>
                <label className="form-label" htmlFor="password" style={{fontSize:"15px"}}>Password</label>
                <div className="user-box"> 
              <div style={{display:"flex",flexDirection:"row"}}>
                <input 
                type="password" 
                id="password" 
                name="password" placeholder="············" 
                aria-describedby="password"
                onChange={(e)=>SetPassword(e.target.value)}
                ref={psw}
                required={true} />
                <i className="bx bx-hide" onClick={handleShowPsw} style={{marginTop:15}}/>
              </div>
            </div>
            <div style={{alignContent:"center",justifyContent:"center", width:320}}>
              <a href="/" onClick={handleSubmit} 
              style={{position:"absolute", left:"50%",transform:"translate(-50%,-50%)"}} 
              id="submitbtn" onKeyDown={handleClick}>
                <span />
                <span />
                <span />
                <span />
                Sign in
              </a>
            </div>
      </form>
      </div>
  </div>
  );


  return (
    <div className="authentication-wrapper authentication-cover">
    <div className="authentication-inner row m-0">
      {renderForm}
    </div>
  </div>
  )
}
