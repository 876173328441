import React, { useEffect, useMemo, useState } from 'react';
import styles from './BillingTarifs.module.scss';
import secureLocalStorage from 'react-secure-storage';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import axios from 'axios';
import { blue } from '@mui/material/colors';
import { Tooltip } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useParams } from 'react-router-dom';

export default function BillingTarifs() {
    const {id,region} = useParams();
    const [data,setData] = useState([]);
    const [brand,setBrand] = useState(0);
    const [outbound,setOutbound] = useState(0);
    const [inbound,setInbound] = useState(0);
    const [operator,setOperator] = useState(0);
    const [price,setPrice] = useState(0);
    const [account,setAccount] = useState(0);
    const [regionTariff,setRegionTariff] = useState('ru');
    const [percent,setPercent] = useState(0);

    const setTariffInfo = (val) => {
        setAccount(val.id);
        setPrice(val.price);
    }

    const SwalDel = () =>{
        return Swal.fire({ // <-- return Promise
            title: 'Delete record?',
            text: 'This action cannot be undone.',
            icon: 'question',
            confirmButtonText: 'Delete',
            confirmButtonColor: '#DC004E',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            focusConfirm: false,
            focusCancel: false,
            scrollbarPadding: false
            }).then((result) => {
            return result.isConfirmed;
            });
    }
    const loadInfo=()=>{
        axios.get(region!==undefined?`https://www.pbxdiamondcall.com:8081/billing/manage/${id}/${region}`:`https://www.pbxdiamondcall.com:8081/billing/manage/${id}`)
        .then((res)=>{
            setData(res.data.data);
        })
        .catch((err)=>console.log(err))
        axios.get('https://www.pbxdiamondcall.com:8081/countries')
        .then((res)=>{
            var options2 = [];
            res.data.forEach((element, idx) => {
                options2.push({value:element.id,label:`${element.title}`});
            });
            secureLocalStorage.setItem('countries',options2);
        })
        .catch((err)=>console.log(err))        
    }
    const handleSubmit=()=>{
        axios.post('https://www.pbxdiamondcall.com:8081/billing/insert',{
            company:+id,
            region:regionTariff.value,
            outbound:outbound.value,
            inbound:inbound.value,
            operator:operator.value,
            price:+price,
            billingacc_id: account,
        })
        .then((res)=>{
            console.log(res);
            Swal.fire({
                icon:'success',
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            loadInfo();
        })
        .catch((err)=>{
            Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            console.log(err)
        });

    }
    const handleUpdate=()=>{
        axios.post('https://www.pbxdiamondcall.com:8081/billing/update',{
            id:account,
            price:price
        })
        .then((res)=>{
            Swal.fire({
                icon:'success',
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            loadInfo();
        })
        .catch((err)=>{
            Swal.fire({
            icon: 'warning',
            title: 'Result',
            text: err,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        console.log(err)
        });

        this.forceUpdate();
    }
    const handlePercentPlus=()=>{
        axios.post('https://www.pbxdiamondcall.com:8081/billing/updateprice/plus',{
            region:regionTariff,
            company_id:id,
            percent:percent
        }).then((res)=>{
            Swal.fire(res.data);
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    const handlePercentMinus=()=>{
        axios.post('https://www.pbxdiamondcall.com:8081/billing/updateprice/minus',{
            region:regionTariff,
            company_id:id,
            percent:percent
        }).then((res)=>{
            console.log(res.data);
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    const handleDelete = async (val) => {
        let isdel = await SwalDel();
        if(isdel){
        axios.post('https://www.pbxdiamondcall.com:8081/billing/delete',{id:val.id})
        .then((res)=>{
            console.log(res);
            Swal.fire({
                icon:'success',
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            loadInfo();
        })
        .catch((err)=>{
            Swal.fire({
            icon: 'warning',
            title: 'Result',
            text: err,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
            console.log(err)
        });
    }
    }
    useEffect(()=>{
        loadInfo()
    },[])
    useEffect(()=>{
        axios.post('https://www.pbxdiamondcall.com:8081/billing/account',{company_id:brand.value})
        .then((res)=>{
            setAccount(res.data[0].id);
        })
        .catch((err)=>console.log(err))
    },[brand])
    useEffect(()=>{
        axios.post('https://www.pbxdiamondcall.com:8081/operators', {country:inbound.value})
        .then((res)=>{
            var options2 = [];
            res.data.forEach((element, idx) => {
                options2.push({value:element.operator_id,label:`${element.num}|${element.title}|${element.country}`});
            });
            secureLocalStorage.setItem('operators',options2);
        })
        .catch((err)=>console.log(err))
    },[inbound])
    const columns = useMemo(
        () => [
            {
                accessorKey: 'Title',
                header: 'Company',
                size: 200, 
                Cell: ({ renderedCellValue, row }) => {
                    console.log()
                    return (
                        <span style={{ paddingLeft:"1vw"}}>{row.original.Title}</span>
                    )
                },
            },
            {
            accessorKey: 'region',
            header: 'Region',
            grow:false, 
            size:250,
            Cell: ({ renderedCellValue, row }) => {
                return (
                    <span>{row.original.inbound_country} [{row.original.region}]</span>
                )
            },
            },
            {
            accessorKey: 'operator',
            header: 'Operator',
            grow:false, 
            size:300,
            Cell: ({ renderedCellValue, row }) => {
                return (
                    <span>{row.original.operator}</span>
                )
            },
            },
            {
            accessorKey: 'price',
            header: 'Price',
            grow:false, 
            size:300,
            Cell: ({ renderedCellValue, row }) => {
                return (
                    <span>{row.original.price}</span>
                )
            },
            },
            {
            accessorKey: 'status',
            header: 'Status',
            grow:false, 
            size:200,
            Cell: ({ renderedCellValue, row }) => {
                return (
                    <span style={{width:"100%",display:"flex",justifyContent:"center"}} onClick={()=>{
                        if(row.original.status === 0)
                        axios.post('https://www.pbxdiamondcall.com:8081/billing/changestatus',{id:row.original.id, status:1})
                        .then((res)=>{
                            loadInfo();
                        })
                        .catch((err)=>console.log(err))
                        else axios.post('https://www.pbxdiamondcall.com:8081/billing/changestatus',{id:row.original.id, status:0})
                        .then((res)=>{
                            loadInfo();
                        })
                        .catch((err)=>console.log(err))
                    }}>
                        {row.original.status == 1
                        ?
                        <span style={{
                            width:"5vw",padding:"0.3vw 0.6vh",fontWeight:"600", fontFamily:"'Ubuntu',sans-serif",color:"#24D6A3",
                            border:"1px solid #24D6A3", borderRadius:"0.4vh", textAlign:"center",userSelect:"none", background:"#232329"
                        }}>
                            Active
                        </span>
                        :
                        <span style={{
                            width:"5vw",padding:"0.3vw 0.6vh",fontWeight:"600", fontFamily:"'Ubuntu',sans-serif",color:"#FF4861",
                            border:"1px solid #FF4861", borderRadius:"0.4vh", textAlign:"center",userSelect:"none", background:"#232329"
                        }}>
                            Disabled
                        </span>
                        }
                    </span>
                )
            },
            },
            {
            accessorKey: 'action',
            header: '',
            enableResizing:false,
            enableSorting:false,
            enableColumnDragging:false,
            enableColumnActions: false,
            enableColumnFilter:false,
            grow:false, 
            size:300,
            Cell: ({ renderedCellValue, row }) => {
                return (
                    <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center", gap:"0.2vw"}}>
                        <Tooltip title={"Update"}>
                            <span className={styles.button} 
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasEdit"
                            onClick={()=>{
                                setTariffInfo(row.original);
                            }}>
                                Update
                            </span>
                        </Tooltip>
                        <Tooltip title={"Delete"}>
                            <span className={styles.button_delete} 
                            onClick={()=>{
                                handleDelete(row.original)
                            }}
                            >
                                Delete
                            </span>
                        </Tooltip>
                    </div>
                )
            },
            },
            
        ],
        [],
    );
    const table = useMaterialReactTable({
        columns,
        data,
        enableStickyHeader:true,
        enableStickyFooter:true,
        enableColumnOrdering: true,
        muiDetailPanelProps: () => ({
        sx: (theme) => ({
            backgroundColor:'#2A2A31'
        }),
        }),
        renderTopToolbarCustomActions: ({ table }) => {
            return (
                <div className={styles.select}>
                    <span className={styles.button_create} 
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasAddUser"
                            onClick={()=>{

                            }}>
                                Create tariff
                    </span>
                </div>
            )
        },
        // muiExpandButtonProps: ({ row, table }) => ({
        //     onClick: () => {
        //         table.setExpanded({ [row.id]: !row.getIsExpanded() });
        //         setAudioLoading(true);
        //         axios.post('https://www.pbxdiamondcall.com:8081/getrecord', {filename:row.original.uuid},
        //         {responseType:'blob'})
        //         .then((res)=>{
        //             if(res.status===400){
        //                 console.log("audio not found");
        //                 // self.setState({audioNotFound:true});
        //             }
        //             else{
        //             setAudioFound(true);
        //             var binaryData = [];
        //             binaryData.push(res.data)
        //             const audioUrl = URL.createObjectURL(new Blob(binaryData, {type:"audio/mp3"}));
        //             console.log(audioUrl);
        //             setAudio(audioUrl);
        //             setAudioLoading(false);
        //             // if(document.getElementById("record")!=null)
        //             // document.getElementById("record").load();
        //             // self.setState({downloaded:true});
        //             }
        //         })
        //         .catch((err)=>{
        //             setAudioLoading(false);setAudioFound(false)});

        //     }, 
        //     children:  row.getIsExpanded() 
        //     ? 
        //         <MicOffIcon sx={{width:"3vw", marginLeft:"1.5vw",height:"3.5vh",border:"1px solid #b47fe9",background:"#232329", color:"#b47fe9", padding:"0.2vw 0.4vh", borderRadius:"0.4vh"}} />
        //     :
        //         <KeyboardVoiceIcon sx={{width:"3vw", marginLeft:"1.5vw",height:"3.5vh",border:"1px solid #b47fe9",background:"#232329", color:"#b47fe9", padding:"0.2vw 0.4vh", borderRadius:"0.4vh"}} />,
        //     sx: {
        //         color:"#fff",
        //         transition: 'transform 0.2s',
        //         // transform: row.getIsExpanded() ? 'rotate(90deg)' : 'rotate(0deg)',
        //         // transition: 'transform 0.2s',
        //     },
        // }),
        onColumnOrderChange: (order)=>console.log(order),
        // onColumnVisibilityChange: (visibility)=>console.log(visibility),
        initialState: { 
            density: 'compact',
            pagination: {pageSize:50},
        //   columnOrder:[ 'time', 'company', 'sip_user', 'dst', 'duration', 'conversation',
        //     'status', 'Record', 'mrt-row-spacer'
        //   ]
        },
        muiSelectCheckboxProps: { 
          color: 'primary', 
          sx:(theme)=>({
            color: blue[50],
              '&.Mui-checked': {
                color: blue[500],
              },
          })
        },
        positionToolbarAlertBanner: 'none', 
        getRowId: (row) => row.Id,
        defaultColumn: {
            maxSize: 900,
            minSize: 80,
            size: 160,
        }, 
        muiTableBodyProps: {
          sx: (theme) => ({
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
              {
                backgroundColor:'#2A2A31',
                borderBottom:'1px dashed rgb(46, 50, 54)',
    
              },
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
              {
                backgroundColor: '#2A2A31',
                borderBottom:'1px dashed rgb(46, 50, 54)',
    
              },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
              {
                backgroundColor: '#2A2A31',
                
                borderBottom:'1px dashed rgb(46, 50, 54)',
              },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
              {
                backgroundColor: '#2A2A31',
                borderBottom:'1px dashed rgb(46, 50, 54)',
              },
          }),
        },
        muiTableContainerProps: {
          sx: (theme) => ({
            height:"68vh",
            maxWidth:"90vw"
          })
        },
        mrtTheme: (theme) => ({
          baseBackgroundColor: '#2A2A31',
          draggingBorderColor: '#000',
        }),
        muiTableBodyCellProps: ({ column }) => ({
            sx: {
              backgroundColor:'#2A2A31',
              fontFamily:"'Open Sans', sans-serif",
              fontSize: '14px',
              color:'#fff',
              borderBottom:'1px dashed rgb(46, 50, 54)',
              '& .MuiTableCell-root':{
                borderBottom:'1px dashed rgb(46, 50, 54)'
              }
            },
        }),
        muiTableProps: ({table})=>({
        sx:{
            backgroundColor:'grey',
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '14px',
            border:"1px solid rgb(46, 50, 54)"
        }
        }),
        muiTopToolbarProps: ({table})=>({
        sx:{
            backgroundColor:"#2A2A31",
            borderTop:"1px solid rgb(46, 50, 54)",
            borderLeft:"1px solid rgb(46, 50, 54)",
            borderRight:"1px solid rgb(46, 50, 54)",
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '14px',
            borderTopLeftRadius:'5px',
            borderTopRightRadius:'5px',
            '& .MuiButtonBase-root':{
            color:'#fff'
            },
            '& .MuiSvgIcon-root':{
            color:'#fff'

            },
            '& .MuiInputBase-root':{
            borderColor:'#fff'

            },
            '& .MuiInputBase-input':{
            color:'#fff',
            fontFamily:"'Open Sans', sans-serif",
            }
        }
        }),
        muiColumnActionsButtonProps: ({row})=>({
        sx:{
            color:'#fff'
        }
        }),
        muiTableHeadCellProps: {
        sx: {
            fontWeight: 'bold',
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '14px',
            backgroundColor:'#2A2A31',
            color:'#fff',
            
            borderBottom:'1px dashed rgb(46, 50, 54)',
            '& .MuiSvgIcon-root':{
            fill:'#fff'
            },
            '& .MuiDivider-root':{
            borderColor:'lightgrey'
            },
            '& .MuiInputBase-input':{
            color:'#fff',
            fontFamily:"'Open Sans', sans-serif",
            }
        },
        },
        muiTableHeadProps:{
        sx:{
            zIndex:1,
            '& .MuiTableCell-root':{
            border:"1px solid rgb(46, 50, 54)",
            },
        }
        },
        muiPaginationProps: {
        showRowsPerPage: false,
        shape: 'rounded',
        sx:{
            '& .MuiButtonBase-root':{
                color:'#fff'
            },
            '& .Mui-selected':{
                backgroundColor:"rgba(62, 163, 252, 0.213) !important"
            }
        }
        },
        muiBottomToolbarProps:{
        sx:{
            backgroundColor:'#2A2A31',
            borderBottom:"1px solid rgb(46, 50, 54)",
            borderLeft:"1px solid rgb(46, 50, 54)",
            borderRight:"1px solid rgb(46, 50, 54)",
            color:'#fff'

        },
        },
        muiFilterTextFieldProps:{
        sx:{
            color:'#fff'
        }
        },
        paginationDisplayMode: 'pages',
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        localization:{
        //   actions: 'Действия',
        //   and: 'и',
        //   cancel: 'Отменить',
        //   changeFilterMode: 'Изменить режим фильтра',
        //   changeSearchMode: 'Изменить режим поиска',
        //   clearFilter: 'Очистить фильтр',
        //   clearSearch: 'Очистить поиск',
        //   clearSelection: 'Очистить выбор',
        //   clearSort: 'Очистить сортировку',
        //   clickToCopy: 'Нажмите, чтобы скопировать',
        //   copy: 'Копировать',
        //   columnActions: 'Действие колонки',
        //   copiedToClipboard: 'Скопировано в буфер обмена',
        //   collapse: 'Свернуть',
        //   collapseAll: 'Свернуть все',
        //   dropToGroupBy: 'Добавить в группу к {column}',
        //   edit: 'Редактировать',
          expand: 'Audio',
        //   expandAll: 'Раскрыть все',
        //   filterArrIncludes: 'Включить',
        //   filterArrIncludesAll: 'Влючить все',
        //   filterArrIncludesSome: 'Включить',
        //   filterBetween: 'Между',
        //   filterBetweenInclusive: 'Между включительно',
        //   filterByColumn: 'Отфильтровать по {column}',
        //   filterContains: 'Содержит',
        //   filterEmpty: 'Пустой',
        //   filterEndsWith: 'Оканчивается',
        //   filterEquals: 'Равны',
        //   filterEqualsString: 'Равны',
        //   filterFuzzy: 'Физзи',
        //   filterGreaterThan: 'Больше чем',
        //   filterGreaterThanOrEqualTo: 'Больше или равно',
        //   filterInNumberRange: 'Между',
        //   filterIncludesString: 'Содержит',
        //   filterIncludesStringSensitive: 'Содержит (регистрозависимый)',
        //   filterLessThan: 'Меньше чем',
        //   filterLessThanOrEqualTo: 'Меньше или равно',
        //   filterMode: 'Режим фильтра: {filterType}',
        //   filterNotEmpty: 'Не пустое',
        //   filterNotEquals: 'Не равны',
        //   filterStartsWith: 'Начинается с',
        //   filterWeakEquals: 'Равны',
        //   filteringByColumn: 'Фильтр по {column} - {filterType} {filterValue}',
        //   goToFirstPage: 'Перейти на первую страницу',
        //   goToLastPage: 'Перейти на последнюю страницу',
        //   goToNextPage: 'Перейти на следующую страницу',
        //   goToPreviousPage: 'Перейти на предыдущую страницу',
        //   grab: 'Grab',
        //   groupByColumn: 'Сгруппировать по {column}',
        //   groupedBy: 'Сгруппировать',
        //   hideAll: 'Скрыть всё',
        //   hideColumn: 'Скрыть {column} колонку',
        //   max: 'Макс',
        //   min: 'Мин',
        //   move: 'Двигать',
        //   noRecordsToDisplay: <span style={{color:'#fff'}}>Нет записей для показа</span>,
        //   noResultsFound: 'Результатов не найдено',
        //   of: 'из',
        //   or: 'или',
        //   pin: 'Прикрепить',
        //   pinToLeft: 'Прикрепить слева',
        //   pinToRight: 'Прикрепить справа',
        //   resetColumnSize: 'Сбросить размер колонок',
        //   resetOrder: 'Сбросить порядок',
        //   rowActions: 'Действия строки',
        //   rowNumber: '#',
        //   rowNumbers: 'Количество строк',
        //   rowsPerPage: 'Строк на странице',
        //   save: 'Сохранить',
        //   search: 'Найти',
        //   selectedCountOfRowCountRowsSelected: '{selectedCount} из {rowCount} строк выбрано',
        //   select: 'Выбрать',
        //   showAll: 'Показать все',
        //   showAllColumns: 'Показать все колонки',
        //   showHideColumns: 'Показать/скрыть колонки',
        //   showHideFilters: 'Показать/скрыть фильтры',
        //   showHideSearch: 'Показать/скрыть поиск',
        //   sortByColumnAsc: 'Сортировать {column} по возрастанию',
        //   sortByColumnDesc: 'Сортировать {column} по убыванию',
        //   sortedByColumnAsc: 'Отсортирована по {column} возрастанию',
        //   sortedByColumnDesc: 'Отсортирована по {column} убыванию',
        //   thenBy: ', затем ',
        //   toggleDensity: 'Изменить плотность',
        //   toggleFullScreen: 'Включить полноэкранный режим',
        //   toggleSelectAll: 'Выбрать всё',
        //   toggleSelectRow: 'Переключить выбор строки',
        //   toggleVisibility: 'Изменить видимость',
        //   ungroupByColumn: 'Разгруппировать по {column}',
        //   unpin: 'Открепить',
        //   unpinAll: 'Открепить всё',
        }
    },[data]);

    return (
        <div className={styles.page}>
            <div className={styles.history}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MaterialReactTable table={table} />
                </LocalizationProvider>
            </div>

            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel" style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
                    <div className="offcanvas-header">
                        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Tariff</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <label className="form-label">Brand</label>
                        <Select options={secureLocalStorage.getItem('brands').map((val)=>{return {value:val.Id,label:val.Title}})} 
                        onChange={(e)=>setBrand(e)} 
                        placeholder='Select company' id='companyLb'
                        
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2",
                                borderColor:"#232329"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#949fae",
                            }),
                        }}/>
                        <br />
                        <label className="form-label">Region</label>
                        <Select options={[
                            {value:'ru',label:'ru'},
                            {value:'eu',label:'eu'},
                            {value:'full',label:'full'},
                        ]} 
                        onChange={(e)=>setRegionTariff(e)}
                        placeholder='Select region' id='prefixLb' isDisabled={brand?false:true}
                        
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2",
                                borderColor:"#232329"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#949fae",
                            }),
                        }} />
                        <br />
                        <label className="form-label">Inbound Country</label>
                        <Select options={secureLocalStorage.getItem('countries')} placeholder='Select inbound country'
                        onChange={(e)=>setInbound(e)}
                        id='incountryLb' 
                        
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2",
                                borderColor:"#232329"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#949fae",
                            }),
                        }}/>
                        <br />
                        <label className="form-label">Outbound Country</label>
                        <Select options={secureLocalStorage.getItem('countries')} placeholder='Select outbound country'
                        onChange={(e)=>setOutbound(e)}
                        id='outcountryLb' 
                        
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2",
                                borderColor:"#232329"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#949fae",
                            }),
                        }}/>
                        <br />
                        <label className="form-label">Operator</label>
                        <Select options={secureLocalStorage.getItem('operators')} placeholder='Select operator'
                        onChange={(e)=>setOperator(e)}
                        id='operatorLb'
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2",
                                borderColor:"#232329"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#d3d3d2"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#232329",
                                color:"#949fae",
                            }),
                        }}/>
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname">Price</label>
                            <input type="number"
                                step="0.001"
                                min="0" 
                                className="form-control" 
                                id="add-billing-price" 
                                placeholder="0" 
                                name="billingPrice" 
                                style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                aria-label="Billing Price"
                                onChange={(e)=>setPrice(e.target.value)}
                                />   
                        </div>
                        <br />
                        <button 
                            id='submitbtn'
                            type="button" 
                            className="btn me-sm-3 me-1 data-submit"
                            style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent"}}
                            onClick={()=>handleSubmit()}
                            >Create</button>
                        <button 
                            type="button" 
                            className="btn" 
                            data-bs-dismiss="offcanvas"
                            style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>
                            Cancel</button>
                        </form>
                    </div>
                </div>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasEdit" aria-labelledby="offcanvasEditLabel" style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEditLabel" className="offcanvas-title">Update tariff</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname">Price</label>
                            <input type="number"
                                step="0.001"
                                min="0" 
                                className="form-control" 
                                id="add-billing-price" 
                                placeholder="0" 
                                name="billingPrice" 
                                aria-label="Billing Price"
                                style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                                value={price}
                                onChange={(e)=>setPrice(e.target.value)}
                                />   
                        </div>
                        <br />
                        <button 
                            id='updatebtn'
                            type="button" 
                            className="btn btn-warning me-sm-3 me-1 data-submit"
                            style={{border:"0.1vh solid #ffab00",backgroundColor:"transparent"}}
                            onClick={()=>handleUpdate()}
                            >Update</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary" 
                            data-bs-dismiss="offcanvas"
                            style={{border:"0.1vh solid #788393",backgroundColor:"transparent"}}>
                            Cancel</button>
                        </form>
                    </div>
                </div>
        </div>
    )
}