import axios from 'axios';
import flatpickr from 'react-flatpickr';
import React, { useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select'
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useTranslation } from 'react-i18next';

export default function Analytics() {
    const [daterange,SetDateRange] = useState({
        from:"",
        to:""
    });
    const { t, i18n} = useTranslation();
    const [companies,setCompanies] = useState([]);
    const [groups,setGroups] = useState([]);
    const [agents,setAgents] = useState([]);
    const [group,setGroup] = useState("");
    const [groupid,setGroupId] = useState(0);
    const [companyActive,setCompanyActive] = useState(false);
    const [groupActive,setGroupActive] = useState(false);
    const [agentActive,setAgentActive] = useState(false);
    const [agent,setAgent] = useState(null);
    const [loading,setLoading] = useState(false);
    const [tdata,setTData] = useState({
        sip_user:"",
        pending:0,
        conversation:0,
        count:0,
        answer:0,
        noanswer:0
    });
    const [tGroup, setTgroup] = useState([]);
    function addOneDay(date = new Date()) {
        date.setDate(date.getDate() + 1);
      
        return date;
    }
    function subOneDay(date = new Date()) {
        date.setDate(date.getDate() - 1);
      
        return date;
    }
    useEffect(()=>{
        var flatpickrRange = document.querySelector("#flatpickr-range");
            flatpickrRange.flatpickr({
                mode: "range",
                onChange: function (selectedDates, dateStr, instance){
                    console.log(selectedDates,dateStr);
                    if(dateStr.includes("to")){
                        SetDateRange({
                            from:dateStr.split(' ')[0],
                            to:dateStr.split(' ')[2]
                        })
                        setCompanyActive(true);
                        axios.get("https://www.pbxdiamondcall.com:8081/companies")
                        .then((res)=>{
                            var arr = [];
                            if(res.data.length>0){

                                if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
                                res.data.forEach((item) => {
                                    arr.push({
                                        value:item.Id,
                                        label:item.Title
                                    });
                                })
                                setCompanies(arr);
                                }
                                else{
                                    res.data.forEach((item) => {JSON.parse(secureLocalStorage.getItem("user")).company_id !== item.Id
                                        ?console.log(item)
                                        :arr.push({
                                            value:item.Id,
                                            label:item.Title
                                        })
                                    })
                                    setCompanies(arr);
                                }
                            }
                        })
                        .catch((err)=>console.log(err))
                    }
                    else if(!dateStr.includes("to")){
                        console.log(selectedDates,dateStr);
                            SetDateRange({
                                from:selectedDates[0],
                                to:addOneDay(selectedDates[1])
                            })
                            setCompanyActive(true);
                            axios.get("https://www.pbxdiamondcall.com:8081/companies")
                            .then((res)=>{
                                var arr = [];
                                if(res.data.length>0){
    
                                    if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
                                    res.data.forEach((item) => {
                                        arr.push({
                                            value:item.Id,
                                            label:item.Title
                                        });
                                    })
                                    setCompanies(arr);
                                    }
                                    else{
                                        res.data.forEach((item) => {JSON.parse(secureLocalStorage.getItem("user")).company_id !== item.Id
                                            ?console.log(item)
                                            :arr.push({
                                                value:item.Id,
                                                label:item.Title
                                            })
                                        })
                                        setCompanies(arr);
                                    }
                                }
                            })
                            .catch((err)=>console.log(err))
                        }
                    }
            });
        
            SetDateRange({
                from:"",
                to:""
            })
    },[])
    const handleCompanyChoose=(e)=>{
        console.log(daterange)
        setGroupActive(true);
        setAgentActive(false);
        setAgent(null);
        if(JSON.parse(secureLocalStorage.getItem('user')).role_id===1){
            axios.post(`https://www.pbxdiamondcall.com:8081/teams/company/`,{company_id:e.value})
            .then((res)=>{
                var arr = [];
                console.log(res);
                if(res.data.length>0){
                    res.data.forEach((item) => {
                        arr.push({
                            value:item.id,
                            label:item.title
                        });
                    })
                    setGroups(arr);
                }
            })
            .catch((err)=>console.log(err))
             }
            else{
            axios.post('https://www.pbxdiamondcall.com:8081/agents/access/', {
                user_info_id:JSON.parse(secureLocalStorage.getItem('user')).user_info_id,
                company_id:JSON.parse(secureLocalStorage.getItem('user')).company_id
           })
           .then((res)=>{
            var arr = [];
            res.data.forEach((el)=>{
                console.log(el);
                if(el.has_access===1){
                arr.push({
                            value:el.id,
                            label:el.title
                        })
                }
                
            })
            setGroups(arr);
            
            console.log(res.data);
           })
           .catch((err)=>console.log(err))
        }
    }
    const handleGroupChoose=(e)=>{
        setAgentActive(true);
        setAgent(null);
        setGroup(e.label);
        setGroupId(e.value);
        axios.post("https://www.pbxdiamondcall.com:8081/agents/getFromTeam", {team_id:e.value})
        .then((res)=>{
            var arr = [];
            console.log(res);
            if(res.data.length>0){
                res.data.forEach((item) => {
                    arr.push({
                        value:item.Id,
                        label:item.Login
                    });
                })
                setAgents(arr);
            }
        })
        .catch((err)=>console.log(err))
    }
    const handleAgentChoose=(e)=>{
        setAgent(e.label);
    }
    const handleSubmit= async ()=>{
        setLoading(true);
        console.log(daterange)
        await axios.post("https://www.pbxdiamondcall.com:8081/agent/analyze",{
            from:daterange.from,
            to:daterange.to,
            sip:agent
        })
        .then((res)=>{
            setTData(res.data[0]);    
            console.log(res);
            setLoading(false);
        })
        .catch((err)=>console.log(err));
    }
    const handleTeamSubmit= async ()=>{
        
        setLoading(true);
        console.log(daterange)
        await axios.post("https://www.pbxdiamondcall.com:8081/team/analyze",{
            from:daterange.from,
            to:daterange.to,
            team_id:groupid
        })
        .then((res)=>{
            setTgroup(res.data);    
            console.log(res);
            setLoading(false);
        })
        .catch((err)=>console.log(err));
    }

return (
    <div className="container-xxl flex-grow-1 container-p-y">
    <div className="card" style={{height:"100%"}}>
        <div className="card-header ">
            <h5 className="card-title" style={{fontSize:"25px", fontWeight:"bold"}}>Analytics</h5>
        </div>
        <div style={{display:'flex', flexDirection:"row"}}>
            <div className="mb-3" style={{width:400, margin:10}}>
            <label htmlFor="flatpickr-range" className="form-label">Select range</label>
            <input type="text" className="form-control" placeholder="YYYY-MM-DD to YYYY-MM-DD" id="flatpickr-range" style={{padding:10}}/>
        </div>
        <div className="mb-3" style={{width:250, margin:10}}>
            <label htmlFor="flatpickr-range" className="form-label">Select company</label>
            <Select placeholder='Select company'
                            onChange={(e)=>handleCompanyChoose(e)}
                            id='operatorLb' 
                            isDisabled={!companyActive}
                            options={companies}
                            styles={{
                                control:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd",
                                    borderColor:"#444663"
                                }),
                                option: (styles, { isFocused, isSelected }) => {
                                    return {
                                        ...styles,
                                        backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                    };
                                },
                                singleValue:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd"
                                }),
                                placeholder:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd"
                                }),
                                menuList:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd"
                                }),
                            }}/>
        </div>

        <div className="mb-3" style={{width:250, margin:10}}>
            <label htmlFor="flatpickr-range" className="form-label">Select group</label>
            <Select placeholder='Select group'
                            isDisabled={!groupActive}      
                            onChange={(e)=>handleGroupChoose(e)}
                            id='operatorLb' 
                            options={groups}
                            styles={{
                                control:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd",
                                    borderColor:"#444663"
                                }),
                                option: (styles, { isFocused, isSelected }) => {
                                    return {
                                        ...styles,
                                        backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                    };
                                },
                                singleValue:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd"
                                }),
                                placeholder:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd"
                                }),
                                menuList:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd",
                                }),
                            }}/>
        </div>
        <div className="mb-3" style={{width:250, margin:10}}>
            <label htmlFor="flatpickr-range" className="form-label">Select agent</label>
            <Select placeholder='Select agent'
                            onChange={(e)=>handleAgentChoose(e)}
                            id='operatorLb' 
                            isDisabled={!agentActive} 
                            options={agents} 
                            styles={{
                                control:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd",
                                    borderColor:"#444663"
                                }),
                                option: (styles, { isFocused, isSelected }) => {
                                    return {
                                        ...styles,
                                        backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                    };
                                },
                                singleValue:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd"
                                }),
                                placeholder:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd"
                                }),
                                menuList:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#444663",
                                    color:"#b8b8cd",
                                }),
                            }}/>
        </div>
        <button className='btn btn-primary' 
        style={{width:70, height:50, margin:10, alignSelf:"center", marginTop:25, fontWeight:"bold"}}
        disabled={!(companyActive && groupActive && agentActive && agent!==null)} onClick={()=>handleSubmit()}>Agent</button>
        <button className='btn btn-primary' 
        style={{width:70, height:50, margin:10, alignSelf:"center", marginTop:25, fontWeight:"bold"}}
        disabled={!(companyActive && groupActive && agentActive)} onClick={()=>handleTeamSubmit()}>Group</button>
        </div>
        <div className="card-body" style={{height:"100%"}}>
            {
                loading===true
                ?
                <div  style={{width:1200, height:600, justifyContent:'center',alignItems:'center', marginLeft:"70px",display:'flex',flexDirection:'column'}}>
                    <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>{t("loadingData")}...</h1>
                <ScaleLoader
                    color="#696cff"
                    height={270}
                    margin={10}
                    radius={2}
                    speedMultiplier={0.8}
                    width={120}
                    cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
                :
                <div className="card-datatable table-responsive">
                <table className="datatables-users table border-top">
                    <thead>
                    <tr>
                        <th>Agent</th>
                        <th>Group</th>
                        <th>Pending time</th>
                        <th>Conversation time</th>
                        <th>Different calls</th>
                        <th>Answer</th>
                        <th>No answer</th>
                    </tr>
                    </thead>
                    <tbody>
                    {agent!==null?tdata.sip_user !== ""?
                        <tr>
                            <td style={{fontWeight:"bold"}}>{tdata.sip_user}</td>
                            <td style={{fontWeight:"bold"}}>{group}</td>
                            <td style={{fontWeight:"bold"}}>{Number(tdata.pending/60).toFixed()} min.</td>
                            <td style={{fontWeight:"bold"}}>{Number(tdata.conversation/60).toFixed()} min.</td>
                            <td style={{fontWeight:"bold"}}>{tdata.count} calls</td>
                            <td style={{fontWeight:"bold"}}>{tdata.answer}</td>
                            <td style={{fontWeight:"bold"}}>{tdata.noanswer}</td>
                        </tr>
                        :
                        <tr>
                        <td style={{fontWeight:"bold"}}></td>
                        <td style={{fontWeight:"bold"}}></td>
                        <td style={{fontWeight:"bold"}}></td>
                        <td style={{fontWeight:"bold"}}>Information not found.</td>
                        <td style={{fontWeight:"bold"}}></td>
                        <td style={{fontWeight:"bold"}}></td>
                        <td style={{fontWeight:"bold"}}></td>
                    </tr>
                    :tGroup.length>0?tGroup.map((val,ind)=>(
                        <tr key={ind}>
                        <td style={{fontWeight:"bold"}}>{val.sip_user}</td>
                        <td style={{fontWeight:"bold"}}>{group}</td>
                        <td style={{fontWeight:"bold"}}>{Number(val.pending/60).toFixed()} min.</td>
                        <td style={{fontWeight:"bold"}}>{Number(val.conversation/60).toFixed()} min.</td>
                        <td style={{fontWeight:"bold"}}>{val.count} calls</td>
                        <td style={{fontWeight:"bold"}}>{val.answer}</td>
                        <td style={{fontWeight:"bold"}}>{val.noanswer}</td>
                    </tr>
                    ))
                    :null
                    }
                    </tbody>
                </table>
                </div>
            }
        </div>
    </div>
    </div>
    )
}
