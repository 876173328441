import { Box, Pagination, PaginationItem, Tooltip, styled } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarQuickFilter, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import secureLocalStorage from 'react-secure-storage';

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));
function CustomNoRowsOverlay() {
return (
    <StyledGridOverlay>
    <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
    >
        <g fill="none" fillRule="evenodd">
        <g transform="translate(24 31.67)">
            <ellipse
            className="ant-empty-img-5"
            cx="67.797"
            cy="106.89"
            rx="67.797"
            ry="12.668"
            />
            <path
            className="ant-empty-img-1"
            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
            className="ant-empty-img-2"
            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
            className="ant-empty-img-3"
            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
        </g>
        <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
        />
        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
        </g>
        </g>
    </svg>
    <Box sx={{ mt: 1 }}>Empty set</Box>
    </StyledGridOverlay>
);
}
function customCheckbox(theme) {
return {
    '& .MuiCheckbox-root svg': {
    width: 16,
    height: 16,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
        }`,
    borderRadius: 2,
    },
    '& .MuiCheckbox-root svg path': {
    display: 'none',
    },
    '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
    backgroundColor: '#1890ff',
    borderColor: '#1890ff',
    transition: 'background 3s !important',
    transition: 'color 3s !important',
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
    position: 'absolute',
    display: 'table',
    border: '2px solid #fff',
    borderTop: 0,
    borderLeft: 0,
    transform: 'rotate(45deg) translate(-50%,-50%)',
    opacity: 1,
    transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
    content: '""',
    top: '50%',
    left: '39%',
    width: 5.71428571,
    height: 9.14285714,
    },
    '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
    width: 8,
    height: 8,
    backgroundColor: '#1890ff',
    transform: 'none',
    transition: 'background 3s !important',
    transition: 'color 3s !important',
    top: '39%',
    border: 0,
    },
};
}
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
border: 0,
color:
    theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(','),
WebkitFontSmoothing: 'auto',
letterSpacing: 'normal',
'& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#121212',
    transition: 'background 3s !important',
    transition: 'color 3s !important',
},
'& .MuiDataGrid-rowsContainer': {
    minHeight:500
},
'& .MuiDataGrid-iconSeparator': {
    display: 'none',
},
'& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {

},
'& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    }`,
},
'& .MuiDataGrid-cell': {
    color:
    theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
},
'& .MuiPaginationItem-root': {
    borderRadius: 0,
},
...customCheckbox(theme),
}));
function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
    <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} sx={{color:"#d3d3d2",borderColor:"#d3d3d2",borderRadius:"2px"}} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
    );
}
const PAGE_SIZE = 14;
function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter 
        sx={{
            '& .MuiInputBase-root':
            {
                color:"#d3d3d2"
            },
            '& .MuiButtonBase-root':
            {
                color:"#d3d3d2"
            },
            color:"#d3d3d2",
            border:"0.1vh solid #999999",
            padding:"0.5vh",
            borderRadius:"0.4vh",
            '& .MuiInputBase-root::after':{
                border:"1px solid #232329"
            }
            }} />
      </Box>
    );
  }
export default function Transactions() {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [checkResult, setCheckResult] = useState("");
    const [checkSpam , setCheckSpam] = useState("");
    const [spamResult , setSpamResult] = useState("");
    const [transactions,setTransactions] = useState([]);
    const [timeoutSpam,setTimeoutSpam] = useState(false); 
    const onPhoneChange=(e)=>{
        console.log(e.target.value);
        setPhoneNumber(e.target.value);
        console.log(secureLocalStorage.getItem("user"))
    }
    const VerifyPhone=()=>{
        axios.post("https://www.pbxdiamondcall.com:8081/verifynumber", {
            phoneNumber,
            sip:JSON.parse(secureLocalStorage.getItem("user")).login
    }).then((res)=>{
        setCheckResult(res.data);
        })
    }
    useEffect(()=>{
        axios.post("https://www.pbxdiamondcall.com:8081/brand/transactions",{company_id:JSON.parse(secureLocalStorage.getItem("user")).company_id}).then((resp)=>{
            console.log(resp);
            setTransactions(resp.data);
        }).catch((err)=>console.log(err));
        setInterval(()=>{
            axios.post("https://www.pbxdiamondcall.com:8081/brand/transactions",{company_id:JSON.parse(secureLocalStorage.getItem("user")).company_id}).then((resp)=>{
            console.log(resp);
            setTransactions(resp.data);
        }).catch((err)=>console.log(err));
        },15000)
    },[])
    const SpamNumber =async ()=>{
        // const num = checkSpam
            setTimeoutSpam(true);
        const {data} =  await axios.post('https://www.pbxdiamondcall.com:8081/spamNumberCheck', {
            num:checkSpam,
            user_info_id: JSON.parse(secureLocalStorage.getItem("user")).user_info_id,
            billingaccount_id: JSON.parse(secureLocalStorage.getItem("user")).billingaccount_id,
            company_id: JSON.parse(secureLocalStorage.getItem("user")).company_id,
            description:`[${checkSpam}] was checked for spam.`,
            transactiontype:"SpamCheck",
            price:0.02
        })
        axios.post("https://www.pbxdiamondcall.com:8081/brand/transactions",{company_id:JSON.parse(secureLocalStorage.getItem("user")).company_id}).then((resp)=>{
            console.log(resp);
            setTransactions(resp.data);
            setTimeout(()=>{
                setTimeoutSpam(false);
            },5000)
        }).catch((err)=>console.log(err));
        setSpamResult(data)
        console.log(data);
    }
    
    const columns = [
        {
            field: 'TransactionDate',
            headerName: 'Action date',
            width:100,
            headerAlign: 'start',
            editable: false,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.TransactionDate}>
                        <span style={{color:"#d3d3d2", width:"100px",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden"}}>
                            {moment(params.row.TransactionDate).format("YYYY-MM-DD HH:mm:ss")}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'Login',
            headerName: 'User',
            width:110,
            headerAlign: 'start',
            editable: false,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.Login}>
                        <div style={{display:'flex',alignItems:"center",justifyContent:"start",gap:5, color:"#d3d3d2"}}>
                            <span style={{color:"#d3d3d2", width:"100px",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden"}}>
                                {params.row.Login}
                            </span>
                        </div>
                    </Tooltip>
                );    
            },
        },
        // {
        //     field: 'Price',
        //     headerName: 'Price',
        //     width:90,
        //     headerAlign: 'start',
        //     editable: false,
        //     renderCell: (params) => {
        //         return (
        //             <span style={{color:"#d3d3d2"}}>{params.row.Price} USD</span>
        //         );
        //     },
        // },
        {
            field: 'Description',
            headerName: 'Description',
            flex:1,
            headerAlign: 'start',
            editable: false,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.Description}>
                    <span style={{color:"#d3d3d2", width:"230px",whiteSpace:"normal",overflow:"hidden"}}>{params.row.Description}</span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'Result',
            headerName: 'Result',
            flex:1,
            headerAlign: 'start',
            editable: false,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.Result}>
                        <span style={{color:"#d3d3d2", width:"230px",whiteSpace:"normal",overflow:"hidden"}}>{params.row.Result}</span>
                    </Tooltip>
                );
            },
        },
    ];
    const [paginationModel, setPaginationModel] = useState({
        pageSize: PAGE_SIZE,
        page: 0,
    });
return (
    <div className='container-xxl flex-grow-1 container-p-y' style={{display:"flex", flexDirection:"row",justifyContent:"center"}}>
        <div className='card' style={{width:"18vw", height:"80vh", margin:"10px",backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
            <div className='card-header' style={{fontWeight:"bold", fontSize:"25px"}}>Check for spam</div>
            <div className='card-body'>
                <div class="mb-3">
                    <label for="defaultFormControlInput" className="form-label">Phone number</label>
                    <div style={{display:"flex", flexDirection:"row"}}>
                        <input type="text" className="form-control" id="defaultFormControlInput"
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                        placeholder="33312345678901" onChange={e=> setCheckSpam(e.target.value)} aria-describedby="defaultFormControlHelp"  />
                        <button className='btn' onClick={()=>SpamNumber()} disabled={timeoutSpam}
                        style={{marginLeft:"10px",border:timeoutSpam?"0.1vh solid #FF4861":"0.1vh solid #3EA3FC",backgroundColor:"transparent",color:"#d3d3d2"}}>{!timeoutSpam?'Check':'Processing..'}</button>
                    </div>
                </div>
                <textarea rows="15" value={spamResult?`[RU]\n${spamResult[0]?.TagRU.trim()}\n[EU]\n${spamResult[0]?.TagEU}`:""} className="form-control" 
                style={{width:"100%", height:"60vh",resize:"none",color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                readOnly/>
            </div>
        </div>
        <div className='card' style={{width:"45vw", height:"80vh", margin:"10px",backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
            <div className='card-header' style={{fontWeight:"bold", fontSize:"25px"}}>TransactionHistory</div>
            <div className='card-body'>
            <StyledDataGrid
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        disableRowSelectionOnClick
                        disableCellSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        autoHeight={true}

                        getRowHeight={(params)=>'auto'}
                        getRowId={(row)=>row.Id}
                        sx={{
                            border: '1px solid #999999',
                            transition: 'background color 3s !important',
                            width: "43vw",
                            backgroundColor:'#232329' ,
                            color:"#d3d3d2",
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            padding: "10px",
                            minHeight:"68vh",
                            maxHeight:"68vh",
                            minWidth:"43vw",
                            maxWidth:"43vw",
                            fontFamily: "'Public Sans' , sans-serif",
                            '--DataGrid-overlayHeight': '55.5vh',
                            // '& .MuiDataGrid-footerContainer':{
                            //     display:"none"
                            // }
                        }}
                        slots={{
                            pagination: CustomPagination,
                            noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: QuickSearchToolbar
                        }}
                        rows={transactions}
                        columns={columns}
                    />
            </div>

        </div>
    </div>
  )
}
