import React, {useState,useEffect, useRef} from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { Navigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import OnlineTable from './OnlineTable';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import { ReadyState } from 'react-use-websocket';
import axios from 'axios';
import ReportIcon from '@mui/icons-material/Report';
import { LinearProgress } from '@mui/material';
import styles from './OnlineCalls.module.scss';
export default function OnlineCallsRebrending() {
    const didUnmount = useRef(false);
    const {t,i18n} = useTranslation();
    const [socketUrl] = useState('wss://www.pbxdiamondcall.com:8081/echo');
    const [data,SetData] = useState([]);
    const [isPageActive,setActive] = useState(true);
    const [table,SetTable] = useState();
    const [spamResult , setSpamResult] = useState("");
    const [checkSpam , setCheckSpam] = useState("");
    const [endedCalls, setEndedCalls] = useState([]);
    const [timeoutSpam,setTimeoutSpam] = useState(false); 
    const [buffer, setBuffer] = useState([]);
    const {sendMessage, lastMessage, readyState} = useWebSocket(socketUrl, {
        onMessage: (event)=>{
            try {
                const parsedData = JSON.parse(event.data);
                SetData(parsedData);
                updateEndedCalls(JSON.parse(parsedData.tp), JSON.parse(parsedData.tp1));
            } catch (error) {
                console.log(error)
            }
        },
        shouldReconnect: (closeEvent) => true,
        onClose:(event)=>{
        },
        onReconnectStop:(event)=>{
            // sendMessage("Give me calls");
        },
        onError:(event)=>{
            sendMessage(`${JSON.parse(secureLocalStorage.getItem('user')).id},Give me calls`)
        },
        reconnectAttempts:10,
        reconnectInterval:10000,
    });
    const updateEndedCalls = (message, message1) => {
        const allCurrentCalls = [...message, ...message1];
        const currentCallIds = allCurrentCalls.map(call => call.uuid);

        // Find calls that are no longer in the current call list
        const endedCallsBuffer = buffer.filter(call => !currentCallIds.includes(call.uuid));

        // Add the ended calls to the endedCalls array with status "Call Ended"
        endedCallsBuffer.forEach(call => {
            call.status = 'Call Ended';
            setEndedCalls(prevEndedCalls => [...prevEndedCalls, call]);
            setTimeout(() => {
                setEndedCalls(prevEndedCalls => prevEndedCalls.filter(c => c.uuid !== call.uuid));
            }, 5000);
        });

        // Update the buffer with the current calls
        setBuffer(allCurrentCalls);
        SetData(allCurrentCalls);
        SetTable(renderData(JSON.stringify(allCurrentCalls), JSON.stringify(allCurrentCalls),endedCalls));
    };
    const renderData = (message, message1,ended) => {
        return (message.length === 0 && message1.length === 0)
        ?
        <div className='container-xxl flex-grow-1 container-p-y'>
                <div className="card" style={{width:1400, height:500, justifyContent:'center',alignContent:'center', display:'inline-flex', 
                backgroundColor:"#2A2A31",color:"#d3d3d2"}}>
                    <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>{t("onlineCallsNoCalls")}</h1>
                    <ScaleLoader
                        color="#696cff"
                        height={270}
                        margin={10}
                        radius={2}
                        speedMultiplier={0.8}
                        width={80}
                        cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
            </div>
        :
        <OnlineTable t={t} names={message} names1={message1} endedCalls={ended}/>
    }    
    const SpamNumber =async ()=>{
        // const num = checkSpam
        setTimeoutSpam(true);
        const {data} =  await axios.post('https://www.pbxdiamondcall.com:8081/spamNumberCheck', {
            num:checkSpam,
            user_info_id: JSON.parse(secureLocalStorage.getItem("user")).user_info_id,
            billingaccount_id: JSON.parse(secureLocalStorage.getItem("user")).billingaccount_id,
            company_id: JSON.parse(secureLocalStorage.getItem("user")).company_id,
            description:`[${checkSpam}] was checked for spam.`,
            transactiontype:"SpamCheck",
            price:0.02
        })
        setSpamResult(data)
        setTimeout(()=>{
            setTimeoutSpam(false);
        },5000)
        console.log(data);
    }
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];
    useEffect(()=>{
        sendMessage(`${JSON.parse(secureLocalStorage.getItem('user')).id},Give me calls`);
        window.addEventListener('focus', function () {
            console.log('focused')
            sendMessage(`${JSON.parse(secureLocalStorage.getItem('user')).id},Give me calls`)
            setActive(true);
        });
    },[])
    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(role === 6 ){
        return <Navigate to="/onlinecalls" replace={true}/>
    }
    else if(lastMessage === null) {
        return <div className='container-xxl flex-grow-1 container-p-y'>
                <div className="card" style={{width:"70vw", height:"70vh", justifyContent:'center',alignItems:'center', display:'flex',
            backgroundColor:"#2A2A31",color:"#d3d3d2", flexDirection:"column", gap:"0.5vh"}}>
                    <span style={{display:'block',fontSize:"2vh",fontFamily:"'Ubuntu',sans-serif",fontWeight:"500"}}>{t("loadingData")}...</span>
                    <LinearProgress 
                        sx={{color:"#d3d3d2",width:"40vw"}}
                    />
                </div>
            </div>
    }
    else if(lastMessage?.data!== undefined && JSON.parse(lastMessage?.data)?.tp.length === 0){
        return <div className='container-xxl flex-grow-1 container-p-y'>
        <div className="card" style={{width:"70vw", height:"70vh", justifyContent:'center',alignItems:'center', display:'flex',
    backgroundColor:"#2A2A31",color:"#d3d3d2", flexDirection:"column", gap:"0.5vh"}}>
            <span style={{display:'block',fontSize:"2vh",fontFamily:"'Ubuntu',sans-serif",fontWeight:"500"}}>{t("onlineCallsNoCalls")}...</span>
            
        </div>
    </div>
    }
    else{ 
    return (
    <div className='container-xxl flex-grow-1 container-p-y'>
        <div className="card" style={{width:"100%", height:"70vh", display:'flex',
    backgroundColor:"#2A2A31",color:"#d3d3d2", flexDirection:"column", gap:"0.5vh"}}>
            <div className="card-header" style={{width:"100%",}}>
                <div className="row" style={{width:"100%",display:'flex', flexDirection:'row', justifyContent:'space-between', position:"relative",marginBottom:"5vh"}}>
                    <h5 style={{
                        position:"absolute",top:"1vh",left:"1vw",color:"#d3d3d2",
                        fontSize:"2.5vh", fontWeight:"bold"
                    }}>{t("onlinecalls")}</h5>
                    <h5  className={styles.header_item}
                        style={{
                            position:'absolute',
                            top:'1vh',
                            right:'2vw',
                        }}
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasEnd" 
                        aria-controls="offcanvasEnd">
                            <ReportIcon sx={{width:"1.5vw"}}/>
                            Spam check</h5>
                </div>
            </div>
            {
                        table
            }  
        </div>
        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasEnd" aria-labelledby="offcanvasEndLabel" style={{width:450, backgroundColor:"#2A2A31",color:"#d3d3d2"}} >
                <div className="offcanvas-header">
                <h5 id="offcanvasStartLabel" className="offcanvas-title">Check for spam</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" style={{color:"#d3d3d2"}} aria-label="Close" />
                </div>
                <div className='card-body' style={{width:"400px", display:"flex",alignItems:"start",justifyContent:"start", flexDirection:"column", padding:"20px"}}>
                    <div class="mb-3">
                        <label for="defaultFormControlInput" className="form-label">Phone number</label>
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <input type="text" className="form-control" id="defaultFormControlInput"
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            placeholder="33312345678901" onChange={e=> setCheckSpam(e.target.value)} aria-describedby="defaultFormControlHelp"  />
                            <button className='btn' onClick={()=>SpamNumber()} disabled={timeoutSpam}
                        style={{marginLeft:"10px",border:timeoutSpam?"0.1vh solid #FF4861":"0.1vh solid #3EA3FC",backgroundColor:"transparent",color:"#d3d3d2"}}>{!timeoutSpam?'Check':'Processing..'}</button>
                        </div>
                    </div>
                    <textarea rows="15" value={spamResult?`[RU]\n${spamResult[0]?.TagRU.trim()}\n[EU]\n${spamResult[0]?.TagEU}`:""} className="form-control" 
                    style={{width:"350px", height:"40vh",resize:"none",color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                    readOnly/>
                </div>

                <button type="button" className="btn btn-label-secondary d-grid" data-bs-dismiss="offcanvas" 
                style={{width:250, alignSelf:'center', margin:10,border:"0.1vh solid #788393",backgroundColor:"transparent"}}>{t("cancelbtn")}</button>
        </div>
    </div>
)
        }

}
