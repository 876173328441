import axios from 'axios';
import React, { useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select'

export default function ItNumbersRebrending() {
  const [companies,SetCompanies] = useState([]);
  const [numbers,SetNumbers] = useState([]);
  const [brandId, SetBrandId] = useState(JSON.parse(secureLocalStorage.getItem('user')).company_id);
  useEffect(()=>{
    if(JSON.parse(secureLocalStorage.getItem('user')).role_id===1){
      axios.get('https://www.pbxdiamondcall.com:8081/companies')
      .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            arr.push({
                        value:el.Id,
                        label:el.Title
                    })
            
        })
        SetCompanies(arr);
        
        console.log(res.data);
      })
      .catch((err)=>console.log(err))
    }
    axios.post('https://www.pbxdiamondcall.com:8081/numbers/it',{
      brand:brandId
    }).then((res)=>{
      SetNumbers(res.data);
    }).catch((err)=>{
      console.log(err);
    })
  },[])
  const HandleCompanyChoose=(el)=>{
    SetBrandId(el.value);
    axios.post('https://www.pbxdiamondcall.com:8081/numbers/it',{
      brand:el.value
    }).then((res)=>{
      SetNumbers(res.data);
    }).catch((err)=>{
      console.log(err);
    })
  }
  return (
    <div className="container-xxl flex-grow-1 container-p-y"
    style={{ justifyContent: "start", display: "flex", flexDirection: "column", width:'100%' }}>
        <div className="card" style={{width:"70vw", height:"70vh", maxHeight:"70vh",overflowY:"scroll",  display:'flex',
    backgroundColor:"#2A2A31",color:"#d3d3d2", flexDirection:"column", gap:"0.5vh", fontFamily:"'Ubuntu',sans-serif"}}>
            <h5 className="card-header" style={{borderBottom:"0.1vh solid #d3d2d29d"}} >
                Numbers
                <br />
                <br />{
                  JSON.parse(secureLocalStorage.getItem('user')).role_id == 1?
                <Select placeholder='Select company'
                                onChange={(e)=>HandleCompanyChoose(e)}
                                id='operatorLb' 
                                isDisabled={false} 
                                    options={companies} 
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={{
                                        control:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#232329",
                                            color:"#d3d3d2",
                                            borderColor:"#232329"
                                        }),
                                        option: (styles, { isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                            };
                                        },
                                        singleValue:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#232329",
                                            color:"#d3d3d2"
                                        }),
                                        placeholder:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#232329",
                                            color:"#d3d3d2"
                                        }),
                                        menuList:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#232329",
                                            color:"#949fae",
                                        }),
                                    }}/>
                  :''}
            </h5>
            <div className="card-body" style={{width:"100%",padding:0}}>
              <div className="table-responsive">
                <table className="table">
                  <thead style={{backgroundColor:'#2A2A31',borderColor:'#d3d3d2',position:"sticky", top:'0vh'}}>
                    <tr style={{color:'#d3d3d2',fontSize:"1.8vh"}}>
                      <th>Prefix</th>
                      <th>Type</th>
                      <th>Numbers</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      numbers.map((val,ind)=>{
                        return  <tr style={{color:"#d3d3d2",fontSize:"1.4vh",borderColor:'#d3d2d29d'}}>
                                  <td>{val.prefix}</td>
                                  <td>{val.type}</td>
                                  <td>{val.type=='Provider'?'-':val.number}</td>
                                </tr>
                      })
                     
                    }
                  </tbody>
                </table>
              </div>
            </div>
        </div>
    </div>
  )
}
