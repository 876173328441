import React, { Component } from 'react'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import {ic_phone_missed} from 'react-icons-kit/md/ic_phone_missed'
import { withBaseIcon } from 'react-icons-kit';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
const SideIconContainer = 
    withBaseIcon({ size: 32, style: {color: '#a3a5ca'}})

export const PhoneMissedIcon = () => <SideIconContainer icon={ic_phone_missed}/>
class NumbersTable extends Component {
        company = '';
        options = [
            { value: 2, label: 'Company admin'},
            { value: 3, label: 'Office admin'},
            { value: 4, label: 'Department admin'},
            { value: 5, label: 'Team admin'},
            { value: 6, label: 'Agent'},
            { value: 7, label: 'BrandManager'},
            { value: 8, label: 'Coach'},
        ]
        options1 = [
            { value: 1, label: 'Russia'},
            { value: 2, label: 'Armenia'},
            { value: 3, label: 'Kazakhstan'},
            { value: 4, label: 'Azerbaijan'},
        ]
        options2 = []
        state = { company:1,prefix:1,inbound:"",outbound:"",operator:1,title:"",titleUpdate:"",prefixUpdate:0,status:0, spam:0,spamUpdate:0,
        trunk:"",trunkUpdate:"",domain:"",domainUpdate:"",prefixDisabled:true, titleDisabled:true,idUpdate:0, numbers : "",numbersResult:"",
        billingaccount_id:0,prefixInsert:"",numbersDisabled:true,prefixUpdated:0,spamUpdated:0,prefixUpdatedL:"",
        inputs:{
            prefix:false,
            title:false,
            spam:false,
            trunk:false,
            domain:false
        }, valid:false }
        SwalDel(){
            return Swal.fire({ // <-- return Promise
                title: 'Delete record?',
                text: 'This action cannot be undone.',
                icon: 'question',
                confirmButtonText: 'Delete',
                confirmButtonColor: '#DC004E',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                reverseButtons: true,
                focusConfirm: false,
                focusCancel: false,
                scrollbarPadding: false
                }).then((result) => {
                return result.isConfirmed;
                });
        }
        
        componentDidMount(){
            axios.get('https://www.pbxdiamondcall.com:8081/prefixes')
            .then((res)=>{
                console.log(res.data);
                this.options2 = [];
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.id,label:`${element.title|element.prefix}`});
                });
                secureLocalStorage.setItem('prefixes',this.options2);
                secureLocalStorage.setItem('prefixes_update',this.options2);
                document.getElementById('prefixupdLb').options = secureLocalStorage.getItem('prefixes_update');
                console.log(secureLocalStorage.getItem('prefixes'));
                this.setState({prefixDisabled : false, inputs:{...this.state.inputs,company:true}});
                this.checkValid();
            })
            .catch((err)=>console.log(err))
            const self = this;
            const { t } = this.props;
            if (!$.fn.DataTable.isDataTable("#myTable")) {
                    $(document).ready(function () {
                        setTimeout(function () {
                        $("#table").DataTable({
                            pagingType: "full_numbers",
                            pageLength: 20,
                            processing: true,
                            dom: "Bfrtip",
                            paging:true, 
                            select: {
                            style: "single",
                            },
                            language: {
                                "processing": t("loadingData")+'...'
                            },
                            ajax:{
                            url:'https://www.pbxdiamondcall.com:8081/numbers',
                            type:'GET',
                            dataType:"json",
                            dataSrc: 'data',
                            error: function (xhr, textStatus, errorThrown) {
                                console.log(xhr);
                            }},
                            columns: [
                                { data: null,name: '#'},
                                { data: 'number',name: 'Phone Number'},
                                { data: 'title_prefix',name: 'Prefix_Title'},
                                { data: 'prefix',name: 'Prefix'},
                                { data: 'addit',name: 'Additional Prefix'},
                                { data: 'useof',name: 'Use', render: function(data,type,row){
                                    //console.log(data);
                                    return data===1?`<button  class='btn btn-label-success' disabled>&nbsp${"+"}&nbsp</button>`:`<button  class='btn btn-label-danger' disabled>&nbsp${"-"}&nbsp&nbsp</button>`
                                }},
                                { data: 'IsSpam',className:'status',name: 'Spam', render: function(data,type,row){
                                    //console.log(data);
                                    return data===0?`<button  class='btn btn-label-success' disabled>&nbsp&nbsp${"No Spam"}&nbsp</button>`:`<button  class='btn btn-label-danger' disabled>&nbsp&nbsp&nbsp&nbsp${"Spam"}&nbsp&nbsp&nbsp&nbsp</button>`
                                }, orderable:false},
                                // { data: 'outbound_country',name: 'Outbound country'},
                                // { data: 'operator',name: 'Operator'},
                                // { data: 'price',name: 'Price'},
                                // { data: 'status',className:'status',name: 'Status', render: function(data,type,row){
                                //     //console.log(data);
                                //     return data===1?`<button  class='btn btn-label-success' disabled>&nbsp&nbsp${t("managementActive")}&nbsp</button>`:`<button  class='btn btn-label-danger' disabled>${t("managementInActive")}</button>`
                                // }, orderable:false},
                                { 
                                    data: null,name: 'action',className:'update', orderable:false,
                                    render: function(data,type,row){
                                        //console.log(data);
                                        return `<button  class='btn btn-label-warning' data-bs-toggle="offcanvas" 
                                        data-bs-target="#offcanvasEdit" >${t("updatebtn")}</button>`
                                    },
                                },
                                { data: null,name: 'action',className:'delete', render: function(data,type,row){
                                    //console.log(data);
                                    return `<button  class='btn btn-label-danger'>${t("deletebtn")}</button>`
                                }, orderable:false}
                              
                            ],
                            bDestroy:true,
                            buttons: [
                                {
                                    extend: "copy",
                                    text:t("onlineButtonsCopy"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "print",
                                    text:t("onlineButtonsPrint"),
                                    customize: function (win) {
                                    $(win.document.body).css("font-size", "10pt");
                                    $(win.document.body)
                                        .find("table")
                                        .addClass("compact")
                                        .css("font-size", "inherit");
                                    },
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: 'spacer',
                                    style: 'bar',
                                    text: 'Export files:'
                                },
                                {
                                    extend: "csv",
                                    text:t("onlineButtonsCSV"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "excel",
                                    text:"Excel",
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend:'pdf',
                                    text:"PDF",
                                    className: "btn btn-secondary bg-primary",
                                }
        
                                
                                ],
                
                            fnRowCallback: function (
                            nRow,
                            aData,
                            iDisplayIndex,
                            iDisplayIndexFull
                            ) {
                            var index = iDisplayIndexFull + 1;
                            $("td:first", nRow).html(index);
                            return nRow;
                            },
                
                            lengthMenu: [
                            [10, 20, 30, 50, -1],
                            [10, 20, 30, 50, "All"],
                            ],
                            columnDefs: [
                            {
                                targets: 0,
                                render: function (data, type, row, meta) {
                                return type === "export" ? meta.row + 1 : data;
                                },
                            }
                            ],
                        });
                        }, 1000);
                    });
                    $('#table').on('click', 'td.update', function(e, type, action){
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        console.log(row[0]);
                        axios.get('https://www.pbxdiamondcall.com:8081/prefixes')
                        .then((res)=>{
                            console.log(res.data);
                            this.options2 = [];
                            let i = 0;
                            res.data.forEach((element, idx) => {
                                this.options2.push({value:element.id,label:`${element.title|element.prefix}`});
                                if(element.prefix == row[0].prefix){
                                    self.setState({prefixUpdated:res.data[i].id,prefixUpdatedL:`${res.data[i].title|res.data[i].prefix}`})
                                }
                                i++;
                            });
                            secureLocalStorage.setItem('prefixes_update',this.options2);
                            console.log(secureLocalStorage.getItem('prefixes_update'));
                            document.getElementById('prefixupdLb').options = secureLocalStorage.getItem('prefixes_update');
                            
                            console.log(self.state.prefixUpdated);
                            console.log(self.prefixUpdatedL);
                            console.log(document.getElementById('prefixupdLb'));
                        })
                        .catch((err)=>console.log(err))
                        self.setState({
                            idUpdate:row[0].id,
                            titleUpdate:row[0].number,
                            spamUpdate:row[0].IsSpam
                        })
                    });
                    $('#table').on('click', 'td.delete', async function(e, type, action){
                        let isdel = await self.SwalDel();
                        console.log(isdel);
                        if(isdel){
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        axios.post('https://www.pbxdiamondcall.com:8081/number/delete',{id:row[0].id})
                        .then((res)=>{
                            console.log(res);
                            Swal.fire({
                                icon:'success',
                                title: 'Result',
                                text: res.statusText,
                                customClass: {
                                confirmButton: 'btn btn-primary'
                                },
                                buttonsStyling: false
                            })
                            $('#table').DataTable().ajax.reload();
                        })
                        .catch((err)=>{
                            Swal.fire({
                            icon: 'warning',
                            title: 'Result',
                            text: err,
                            customClass: {
                            confirmButton: 'btn btn-primary'
                            },
                            buttonsStyling: false
                        })
                            console.log(err)
                        });
                    }
                    });
                    $('#table').on('click', 'td.status', function(e, type, action){
                        e.preventDefault();
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        console.log(row[0]);
                        if(row[0].IsSpam === 0)
                        axios.post('https://www.pbxdiamondcall.com:8081/numbers/changestatus',{id:row[0].id, status:1})
                        .then((res)=>{
                            console.log(res.data);
                            row.ajax.reload();
                        })
                        .catch((err)=>console.log(err))
                        else axios.post('https://www.pbxdiamondcall.com:8081/numbers/changestatus',{id:row[0].id, status:0})
                        .then((res)=>{
                            console.log(res.data);
                            row.ajax.reload();
                        })
                        .catch((err)=>console.log(err))
                    });
            }
            console.log(secureLocalStorage.getItem('companies'));
            //console.log(JSON.parse(secureLocalStorage.getItem('user')).company_id);
            axios.get('https://www.pbxdiamondcall.com:8081/companies')
            .then((res)=>{
                console.log(res.data);
                this.options2 = [];
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.Id,label:`${element.Title}`,bid:element.bid});
                });
                secureLocalStorage.setItem('companies',this.options2);
            })
        }
        handleNumbersChange(e){
            this.setState({numbers:e.target.value});
            console.log(this.state.numbers.split('\n'));
        }
        handleNumbersInsert(e){
            axios.post("https://www.pbxdiamondcall.com:8081/number/insert",{
                numbers:this.state.numbers.split('\n'),
                prefix:this.state.prefixInsert
            })
            .then((res)=>{
                let arr = [];
                res.data.forEach(element => {
                    arr.push(`${element.number}:${element.status.split(' ')[0]}\n`)
                });
                this.setState({numbersResult:arr});
                $('#table').DataTable().ajax.reload();
                console.log(res);
            })
            .catch((err)=>console.log(err));
        }
        async handleNumbersDelete(e){
            let isdel = await this.SwalDel();
            if(isdel){
            axios.post("https://www.pbxdiamondcall.com:8081/number/delete/multiple",{
                numbers:this.state.numbers.split('\n'),
                prefix:this.state.prefixInsert
            })
            .then((res)=>{
                let arr = [];
                res.data.forEach(element => {
                    arr.push(`${element.number}:${element.status.split(' ')[0]}\n`)
                });
                this.setState({numbersResult:arr});
                $('#table').DataTable().ajax.reload();
                console.log(res);
            })
            .catch((err)=>console.log(err));
        }
        }
        handleCompanyChoose(e){
            axios.post('https://www.pbxdiamondcall.com:8081/prefixes',{id:e.value})
            .then((res)=>{
                console.log(res.data);
                this.options2 = [];
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.prefix_id,label:`${element.title|element.prefix}`});
                });
                secureLocalStorage.setItem('prefixes',this.options2);
                console.log(secureLocalStorage.getItem('prefixes'));
                this.setState({prefixDisabled : false, company : e.value, inputs:{...this.state.inputs,company:true}});
                this.checkValid();
            })
            .catch((err)=>console.log(err))
            axios.post('https://www.pbxdiamondcall.com:8081/billing/account',{company_id:e.value})
            .then((res)=>{
                console.log(res);
                this.setState({billingaccount_id:res.data[0].id})
                console.log(this.state.billingaccount_id)
            })
            .catch((err)=>console.log(err))
        }
        handlePrefixChoose(e){
            console.log(e);
                this.setState({titleDisabled:false,prefix: e.value, inputs:{...this.state.inputs,prefix:true}});
                this.checkValid();
        }
        handleNPrefixChoose(e){
            console.log(e);
                this.setState({numbersDisabled:false,prefixInsert: e.value});
                this.checkValid();
        }
        handleUpdatePrefixChoose(e){
                this.setState({titleDisabled:false,prefixUpdate: e.value});
        }
        handleInboundChoose(e){
            axios.post('https://www.pbxdiamondcall.com:8081/operators', {country:e.value})
            .then((res)=>{
                console.log(e.value);
                console.log(res.data);
                this.options2 = [];
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.operator_id,label:`${element.num}|${element.title}|${element.country}`});
                });
                secureLocalStorage.setItem('operators',this.options2);
                console.log(secureLocalStorage.getItem('operators'));
                this.setState({operatorDisabled : false, inbound:e.value, inputs:{...this.state.inputs,inbound:true}});
                this.checkValid();
            })
            .catch((err)=>console.log(err))
        }
        handleOutboundChoose(e){
                this.setState({outbound:e.value, inputs:{...this.state.inputs,outbound:true}});
                this.checkValid();
        }
        handleOperatorChoose(e){
                this.setState({priceDisabled:false,operator:e.value, inputs:{...this.state.inputs,operator:true}});
                this.checkValid();
        }
        handlePriceChange(e){
            this.setState({title:e.target.value, inputs:{...this.state.inputs,title:true}});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handleUpdatePriceChange(e){
            this.setState({titleUpdate:e.target.value});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handleTrunkChange(e){
            this.setState({trunk:e.target.value, inputs:{...this.state.inputs,trunk:true}});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handleUpdateTrunkChange(e){
            this.setState({trunkUpdate:e.target.value});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handleDomainChange(e){
            this.setState({domain:e.target.value, inputs:{...this.state.inputs,domain:true}});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handleUpdateDomainChange(e){
            this.setState({domainUpdate:e.target.value});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handleSpamChange(e){
            this.setState({spam:e.target.checked, inputs:{...this.state.inputs,spam:true}});
            this.checkValid();
            console.log(this.state);
        }
        handleUpdateSpamChange(e){
            this.setState({spamUpdate:e.target.checked});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        checkValid(){
            if(this.state.inputs.prefix
                && this.state.inputs.title && this.state.inputs.spam)
            this.setState({valid:true})
            else this.setState({valid:false})

        }
        handleSubmit(){
            console.log(this.state);
            axios.post('https://www.pbxdiamondcall.com:8081/number',{
                prefix:this.state.prefix,
                number:this.state.title,
                spam:this.state.spam,
            })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                    icon:'success',
                    title: 'Result',
                    text: res.statusText,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                $('#table').DataTable().ajax.reload();
            })
            .catch((err)=>{
              Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
              console.log(err)
            });

        }
        handleSubmitAll(){
            console.log(this.state);
            secureLocalStorage.getItem('companies').forEach((val)=>{
                if(val.value !== 1 && val.value !==12)
                axios.post('https://www.pbxdiamondcall.com:8081/billing/insert',{
                    company:val.value,
                    prefix:this.state.prefix,
                    outbound:this.state.outbound,
                    inbound:this.state.inbound,
                    operator:this.state.operator,
                    price:this.state.price,
                    billingacc_id: val.bid
                })
                .then((res)=>{
                    console.log(res);
                    Swal.fire({
                        icon:'success',
                        title: 'Result',
                        text: res.statusText,
                        customClass: {
                        confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                    })
                    $('#table').DataTable().ajax.reload();
                })
                .catch((err)=>{
                  Swal.fire({
                    icon: 'warning',
                    title: 'Result',
                    text: err,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                  console.log(err)
                });
    

            })
        }
        handleUpdate(){
            //console.log(this.state);
            axios.post('https://www.pbxdiamondcall.com:8081/number/update',{
                id:this.state.idUpdate,
                prefix:this.state.prefixUpdate,
                number:this.state.titleUpdate,
                spam: this.state.spamUpdate,
                trunk:this.state.trunkUpdate,
                domain: this.state.domainUpdate
            })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                    icon:'success',
                    title: 'Result',
                    text: res.statusText,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                $('#table').DataTable().ajax.reload();
            })
            .catch((err)=>{
                Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            console.log(err)
            });

            this.forceUpdate();
        }
        render(){
            const { t } = this.props;
            return(
            <div className="table-responsive text-nowrap">
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel">
                    <div className="offcanvas-header">
                        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Phone number</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0" style={{height:"100%"}}>
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <label className="form-label">{t("managementThPrefix")}</label>
                        <Select options={secureLocalStorage.getItem('prefixes')} 
                        onChange={(e)=>this.handlePrefixChoose(e)}
                        placeholder='Select prefix' id='prefixLb' isDisabled={this.state.prefixDisabled}
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }} />
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname">Number</label>
                            <input type="text"
                                className="form-control" 
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                disabled={this.state.titleDisabled}
                                value={this.state.title}
                                onChange={(e)=>this.handlePriceChange(e)}
                                />   
                        </div>
                        <br />
                        <div className="col mb-0">
                                    <label htmlFor="emailSlideTop" className="form-label">Spam:</label>
                                    <input type="checkbox" id="emailSlideTop" style={{marginLeft:"5px",marginTop:"10px"}}
                                    checked={this.state.spam} onChange={(e)=>this.handleSpamChange(e)}
                                    />
                        </div>
                        <br />
                        <button 
                            id='submitbtn'
                            type="button" 
                            className="btn btn-success me-sm-3 me-1 data-submit"
                            onClick={()=>this.handleSubmit()}
                            >{t("submitbtn")}</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary" 
                            data-bs-dismiss="offcanvas">
                            {t("cancelbtn")}</button>
                        </form>
                    </div>
                </div>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddNumbers" aria-labelledby="offcanvasAddUserLabel">
                    <div className="offcanvas-header">
                        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Phone number</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0" style={{height:"100%"}}>
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <div className="mb-3">
                        <label className="form-label">{t("managementThPrefix")}</label>
                        <Select options={secureLocalStorage.getItem('prefixes')} 
                        onChange={(e)=>this.handleNPrefixChoose(e)}
                        placeholder='Select prefix' id='prefixLb' 
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }} />
                        <br />
                            <label className="form-label" htmlFor="add-agent-fullname">Numbers</label>
                            <textarea
                                className="form-control" 
                                style={{width:"100%", height:300}}
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                value={this.state.numbers}
                                onChange={(e)=>this.handleNumbersChange(e)}
                                readOnly={this.state.numbersDisabled}
                                />   
                        </div>
                        <br />
                        <button 
                            id='submitbtn'
                            type="button" 
                            className="btn btn-success me-sm-3 me-1 data-submit"
                            onClick={()=>this.handleNumbersInsert()}
                            >{t("submitbtn")}</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary" 
                            data-bs-dismiss="offcanvas">
                            {t("cancelbtn")}</button>
                        </form>


                        
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname">Results:</label>
                            <textarea
                                className="form-control" 
                                style={{width:"100%", height:300}}
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                readOnly="true"
                                value={this.state.numbersResult}
                                />   
                        </div>
                    </div>
                </div>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasDelNumbers" aria-labelledby="offcanvasAddUserLabel">
                    <div className="offcanvas-header">
                        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Phone number</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0" style={{height:"100%"}}>
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <div className="mb-3">
                        <label className="form-label">{t("managementThPrefix")}</label>
                        <Select options={secureLocalStorage.getItem('prefixes')} 
                        onChange={(e)=>this.handleNPrefixChoose(e)}
                        placeholder='Select prefix' id='prefixLb' 
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }} />
                        <br />
                            <label className="form-label" htmlFor="add-agent-fullname">Numbers</label>
                            <textarea
                                className="form-control" 
                                style={{width:"100%", height:300}}
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                value={this.state.numbers}
                                onChange={(e)=>this.handleNumbersChange(e)}
                                readOnly={this.state.numbersDisabled}
                                />   
                        </div>
                        <br />
                        <button 
                            id='submitbtn'
                            type="button" 
                            className="btn btn-danger me-sm-3 me-1 data-submit"
                            onClick={()=>this.handleNumbersDelete()}
                            >{t("submitbtn")}</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary" 
                            data-bs-dismiss="offcanvas">
                            {t("cancelbtn")}</button>
                        </form>


                        
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname">Results:</label>
                            <textarea
                                className="form-control" 
                                style={{width:"100%", height:300}}
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                readOnly="true"
                                value={this.state.numbersResult}
                                />   
                        </div>
                    </div>
                </div>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasEdit" aria-labelledby="offcanvasEditLabel">
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEditLabel" className="offcanvas-title">Phone number update</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <label className="form-label">{t("managementThPrefix")}</label>
                        <Select options={secureLocalStorage.getItem('prefixes_update')} 
                        onChange={(e)=>this.handleUpdatePrefixChoose(e)}
                        placeholder='Select prefix' id='prefixupdLb'
                        value={{value:this.state.prefixUpdated,label:this.state.prefixUpdatedL}}
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }}
                        />
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname">Number</label>
                            <input type="text"
                                className="form-control" 
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                disabled={this.state.titleDisabled}
                                value={this.state.titleUpdate}
                                onChange={(e)=>this.handleUpdatePriceChange(e)}
                                />   
                        </div>
                        <br />
                        <div className="col mb-0">
                                    <label htmlFor="emailSlideTop" className="form-label">Spam:</label>
                                    <input type="checkbox" id="emailSlideTop" style={{marginLeft:"5px",marginTop:"10px"}}
                                    checked={this.state.spamUpdate} onChange={(e)=>this.handleUpdateSpamChange(e)}
                                    />
                        </div>
                        <br />
                        <button 
                            id='updatebtn'
                            type="button" 
                            className="btn btn-warning me-sm-3 me-1 data-submit"
                            onClick={()=>this.handleUpdate()}
                            >{t("updatebtn")}</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary" 
                            data-bs-dismiss="offcanvas">
                            {t("cancelbtn")}</button>
                        </form>
                    </div>
                </div>
                <button className='btn btn-primary' style={{marginBottom:5}} 
                data-bs-toggle="offcanvas" 
                data-bs-target="#offcanvasAddUser">
                    Add new number
                </button>
                <button className='btn btn-primary' style={{marginBottom:5, marginLeft:10}} 
                data-bs-toggle="offcanvas" 
                data-bs-target="#offcanvasAddNumbers">
                    Add some numbers
                </button>
                <button className='btn btn-danger' style={{marginBottom:5, marginLeft:10}} 
                data-bs-toggle="offcanvas" 
                data-bs-target="#offcanvasDelNumbers">
                    Delete some numbers
                </button>
                <table id="table" className="table table-stripped align-items-center justify-content-center mb-0 cell-border" style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <th style={{fontSize:"15px"}}></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-8 ps-2" style={{fontSize:"15px"}}>Number</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-8 ps-2" style={{fontSize:"15px"}}>Prefix_Title</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-8 ps-2" style={{fontSize:"15px"}}>Prefix</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-8 ps-2" style={{fontSize:"15px"}}>Add.Prefix</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-8 ps-2" style={{fontSize:"15px"}}>Use</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-8 ps-2" style={{fontSize:"15px"}}>Spam</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-8 ps-2" style={{fontSize:"15px"}}>{t("managementThUpdate")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-8 ps-2" style={{fontSize:"15px"}}>{t("managementThDelete")}</th>
                        </tr>
                    </thead>
        
                    <tbody>
                            {
                            //this.showTable()
                            }
                    </tbody>
                </table>
            </div>
        )
        }
    }
export default withTranslation()(NumbersTable);