import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import ReactDropdown from 'react-dropdown';
import { Link, Navigate } from 'react-router-dom'
import 'react-dropdown/style.css';
import secureLocalStorage from 'react-secure-storage';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import styles from './Users.module.scss';
import dayjs from 'dayjs';


const SearchBar = ({callback}) => {
    const [innerValue, setInnerValue] = useState("");
    const handleSubmit = e => {
        e.preventDefault()
        callback(innerValue)
    }
    return (
        <form className="searchBar" onSubmit={handleSubmit}>
        <input
            type="text"
            className="searchBarInput"
            value={innerValue}
            onChange={(e) => setInnerValue(e.target.value)}
        />
        </form>
    );
};
export default function UsersRebrending() {
    const {t,i18n} = useTranslation();
    const title = useRef(null);
    const company = useRef(null);
    const user_id = useRef(null);
    const role = useRef(null);
    const role_id = useRef(null);
    const [searchValue, setSearchValue] = useState("");
    const [Entities, SetEntities] = useState([]);
    const [allowed, setAllowed] = useState([]);
    const [filteredArticles , setFilteredArticles] = useState([])
    const [access,setAccess] = useState([])
    const [Teams, SetTeams] = useState([]);
    const MySwal = withReactContent(Swal);
    const [moveTeams,SetMoveTeams] = useState([]);
    const [moveAgentId,SetMoveAgentId] = useState(0);
    const [moveTeamId,SetMoveTeamId] = useState(0);
    const banTime = useRef(null);
    var options = [
        { value: 2, label: 'Company admin'},
        { value: 3, label: 'Office admin'},
        { value: 4, label: 'Department admin'},
        { value: 5, label: 'Team admin'},
        { value: 6, label: 'Agent'},
        { value: 7, label: 'BrandManager'},
        { value: 8, label: 'Coach'},
        { value: 9, label: 'It'},
    ]
    
    const handleMove=async ()=>{
        console.log(moveAgentId);
        console.log(moveTeamId);
        await axios.post('https://www.pbxdiamondcall.com:8081/agents/move',{
        id:user_id.current,
        team_id:moveTeamId
        })
        .then((res)=>{
        console.log(res);
        })
        .catch((err)=>console.log(err));
    }
    async function handleSubmit(){
        var arr = [];
        Teams.forEach(element => {
            arr.push({
            id:element.id,
            access:document.getElementById(element.id).checked
            });
        });
        console.log(role.current);
        await axios.post("https://www.pbxdiamondcall.com:8081/agents/updateaccess",
        {   
            id: user_id.current,
            teams_access: arr,
            role_id: role.current.state.selected.value,
            banTime:banTime.current.value!==null?dayjs().add(banTime.current.value,'minutes').format("YYYY-MM-DD HH:mm:ss"):null,
        }
        )
        .then((res)=>{
          console.log(res);
          Swal.fire({
            icon:'success',
            title: 'Result',
            text: res.statusText,
            timer:2000,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
      })
      .catch((err)=>{
        Swal.fire({
          icon: 'warning',
          title: 'Result',
          text: err.statusText,
          timer:2000,
          customClass: {
          confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
      })
        console.log(err)
      });
        
    }
    async function handleDelete () {
        let isdel = await SwalDel();
        console.log(isdel);
        if(isdel){
            await axios.delete("https://www.pbxdiamondcall.com:8081/agents/delete",{data:{id:user_id.current} })
            .then((res)=>{
            console.log(res);
            MySwal.fire({
                icon:'success',
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
        })
            .catch((err)=>{
            console.log(err);
            MySwal.fire({
                icon: 'error',
                title: 'Authentification error.',
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            })
        }
    }
    const SwalDel =()=>{
        return Swal.fire({ // <-- return Promise
            title: 'Delete record?',
            text: 'This action cannot be undone.',
            icon: 'question',
            confirmButtonText: 'Delete',
            confirmButtonColor: '#DC004E',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            focusConfirm: false,
            focusCancel: false,
            scrollbarPadding: false
            }).then((result) => {
            return result.isConfirmed;
            });
    }
    function renderSwitch(id) {
        switch (id) {
          case 1: return "God"; 
          case 2: return "BrandAdmin"; 
          case 3: return "OfficeAdmin"; 
          case 4: return "DepartmentAdmin"; 
          case 5: return "TeamLeader"; 
          case 6: return "Agent"; 
          case 7: return "BrandManager"; 
          case 8: return "Coach"; 
          case 9: return "IT"; 
          default: return "default";
        }
    }
    function onMove(){
        var arr = [];
        Teams.forEach((val,ind)=>{
          arr.push({value:val.id,label:val.title})
        })
        SetMoveTeams(arr);
    }
    async function onAccess(ind){
        user_id.current = filteredArticles[ind].Id;
        company.current = filteredArticles[ind].Title;
        role.current.state.selected.value = filteredArticles[ind].role_id;
        // eslint-disable-next-line eqeqeq
        // eslint-disable-next-line eqeqeq
        role.current.state.selected.label = options[options.findIndex((el)=>el.value==filteredArticles[ind].role_id)].label;
        role_id.current = filteredArticles[ind].role_id;
        title.current = filteredArticles[ind].Login;
        await axios.post("https://www.pbxdiamondcall.com:8081/teams/company",{
            company_id:filteredArticles[ind].company_id
        })
        .then(async (ress)=>{
            SetTeams(ress.data);
            
        await axios.post("https://www.pbxdiamondcall.com:8081/agents/access",
        {user_info_id:filteredArticles[ind].user_info_id,company_id:filteredArticles[ind].company_id})
        .then((res)=>{
            setAccess(res.data);
            ress.data.forEach(element => {
                document.getElementById(element.id).checked = false;
            });
            ress.data.forEach(element => {
            res.data.forEach(el=>{
                if(el.id === element.id && el.has_access===1){
                document.getElementById(element.id).checked = true;
                }
            })
            });
            var arr = [];
            ress.data.forEach((val,ind)=>{
              arr.push({value:val.id,label:val.title})
            })
            SetMoveTeams(arr);
        })
        .then((res)=>{
            SetTeams(ress.data);
            SetMoveTeams(Teams);
        })
        .catch((err)=>console.log(err));
        })
    } const handleClose=()=>{
    }
    useEffect(() => {
        if (Entities) {
          const filtered = Entities.filter(
            (el) => el.Login.toLowerCase().includes(searchValue.toLowerCase())
          );
          setFilteredArticles(filtered);
        }
    }, [searchValue]);

    useEffect(()=>{
        const FetchData = async ()=>{
            JSON.parse(secureLocalStorage.getItem('user')).role_id==1?
         await axios.get("https://www.pbxdiamondcall.com:8081/agents/")
            .then((res)=>{
                SetEntities(res.data);
                setFilteredArticles(res.data)
            })
            .catch((err)=>{
                console.log(err);
            })
            :
            axios.post('https://www.pbxdiamondcall.com:8081/agents/onlineaccess', {
                ui_id:JSON.parse(secureLocalStorage.getItem('user')).user_info_id,
                company_id:JSON.parse(secureLocalStorage.getItem('user')).company_id
            }).then(async (res)=>{
                SetEntities(res.data);
                setFilteredArticles(res.data)
            }).catch((err)=>console.log(err))
        }
        FetchData()
    },[])
    let roled = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(roled === 6 || role === 7){
        return <Navigate to="/onlinecalls" replace={true}/>
    }
    else
    return (
    <div className="container-xxl flex-grow-1 container-p-y">
    <div className="card" style={{width:"70vw", height:"70vh",  display:'flex',
    backgroundColor:"#2A2A31",color:"#d3d3d2", flexDirection:"column", gap:"0.5vh", fontFamily:"'Ubuntu',sans-serif"}}>
        <div className="card-header " style={{height:"7vh",borderBottom:"0.1vh solid #d3d2d29d"}}>
            <h5 className="card-title" style={{fontSize:"2.5vh",fontFamily:"'Ubuntu',sans-serif",color:"#d3d3d2", fontWeight:"500"}}>{t("accessUsers")}</h5>
        </div>
        <div className="card-datatable table-responsive">
        {
        JSON.parse(secureLocalStorage.getItem('user')).role_id==1?
        <div style={{margin:10, marginTop:0,position:"absolute",top:"1.5vh",right:"1.5vw",backgroundColor:"#2A2A31", border:"0.2vh solid #2A2A31"}}>
            {/* <label style={{fontSize:"1.4vh",fontFamily:"'Ubuntu',sans-serif",color:"#d3d3d2"}}>Find user..</label> */}
            {/* <SearchBar callback={(searchValue) => setSearchValue(searchValue)} /> */}
                <input value={searchValue} className={styles.search} placeholder="Find user" onChange={(e) => setSearchValue(e.target.value)}/>
        </div>
        :""
        }
        <table className="datatables-users table ">
            <thead style={{position:"sticky",top:"0vh",backgroundColor:"#2A2A31", border:"0px",borderBottom:"0.1vh solid #d3d2d29d"}}>
            <tr style={{borderColor:"#d3d2d29d"}}>
                <th style={{fontSize:"1.8vh",color:"#d3d3d2",}}>{t("accessThLogin")}</th>
                <th style={{fontSize:"1.8vh",color:"#d3d3d2",}}>{t("accessThCompany")}</th>
                <th style={{fontSize:"1.8vh",color:"#d3d3d2",}}>{t("accessThTeam")}</th>
                {JSON.parse(secureLocalStorage.getItem('user')).role_id==1?<th style={{fontSize:"1.8vh",color:"#d3d3d2",}}>{t("accessOffCanvasRole")}</th>:""}
                <th style={{fontSize:"1.8vh",color:"#d3d3d2",}}>Individual</th>
                {JSON.parse(secureLocalStorage.getItem('user')).role_id==1?<th style={{fontSize:"1.8vh",color:"#d3d3d2",}}>{t("accessThAccess")}</th>:""}
            </tr>
            </thead>
            <tbody>
            {filteredArticles.length !== 0 ? 
            filteredArticles.map((cmp,ind)=>{
                return JSON.parse(secureLocalStorage.getItem('user')).role_id == 1? 
                cmp.role_id !== 1 
                ? <tr key={ind} style={{color:"#d3d3d2", fontSize:"1.4vh", borderColor:"#d3d2d29d"}} >
                        <td><b>{cmp.Login}</b></td>
                        <td><b>{cmp.Title}</b></td>
                        <td><b>{cmp.Team}</b></td>
                        <td><b>{renderSwitch(cmp.role_id)}</b></td>
                        <td><Link to={`/company/individual/${filteredArticles[ind].company_id}/${filteredArticles[ind].Login}`} >
                        <button type="button" className={styles.individual_item} 
                        >Individual</button>
                        </Link></td>
                        <td><button type="button" className={styles.access_item} 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasAddUser"
                        onClick={()=>onAccess(ind)}
                        >{t("accessBtnManage")}</button></td>
                    </tr>
                : null
                : <tr key={ind} style={{color:"#d3d3d2", fontSize:"1.4vh", borderColor:"#d3d2d29d"}} >
                <td>{cmp.Login}</td>
                <td><b>{cmp.Title}</b></td>
                <td><b>{cmp.Team}</b></td>
                <td><Link to={`/company/individual/${filteredArticles[ind].company_id}/${filteredArticles[ind].Login}`} >
                <button type="button" className={styles.individual_item} 
                >Individual</button>
                </Link></td>
            </tr>
            })
            :null}
            </tbody>
        </table>
        </div>
      {/* Offcanvas to add new instance */}
        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasAddUserLabel" className="offcanvas-title">User</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body mx-0 flex-grow-0">
            <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
            <div className="mb-3">
                <label className="form-label" htmlFor="access-agent-login">{t("accessOffCanvasLogin")}</label>
                <input type="text" 
                className="form-control" 
                id="access-agent-login" 
                placeholder="Agent login..." 
                name="agentLogin" 
                aria-label="Agent login"
                value={title.current}
                disabled={true} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="access-agent-company">{t("accessOffCanvasCompany")}</label>
                <input type="text" 
                className="form-control" 
                id="access-agent-company" 
                placeholder="Agent company..." 
                name="agentCompany" 
                aria-label="Agent company"
                value={company.current}
                disabled={true} />
            </div>
            <br />
            <label className="form-label" htmlFor="access-agent-company">{t("accessOffCanvasRole")}</label>
            <ReactDropdown options={options} placeholder='Select role' ref={role}  id='rolesdd' />
            <br />
            <div className="mb-3">
                        <label className="form-label" htmlFor="add-agent-fullname">Ban</label>
                        <input type="text" 
                            style={{color:"#d3d3d2",backgroundColor:"transparent",border:"0.1vh solid #d3d3d2"}}
                            className="form-control" 
                            id="add-agent-fullname" 
                            placeholder={"Ban time"}
                            name="agentFullName" 
                            aria-label="Agent bantime"
                            ref={banTime} />   
                        </div>
            <br />
            <label className="form-label" htmlFor="teams-list">{t("accessOffCanvasTeams")}</label>
            <div className="list-group" style={{maxHeight:400, overflow:"scroll", WebkitOverflowScrolling:'touch'}}>
                {
                    Teams.map((val,ind)=>{
                        let a = false;
                        access.forEach(el=>{
                            if(el.id === val.id && el.has_access===1){
                                a = true;
                            }
                        })
                        return <label className="list-group-item" key={ind}>
                            <label class="switch">
                            <input type="checkbox" class="switch-input" id={val.id} />
                            <span class="switch-toggle-slider">
                                <span class="switch-on">
                                <i class="bx bx-check"></i>
                                </span>
                                <span class="switch-off">
                                <i class="bx bx-x"></i>
                                </span>
                            </span>
                            <span class="switch-label">{val.title}</span>
                            </label>
                        </label>
                    }) 
                }
                
            </div>
            <br />
            <button 
                id='submitbtn'
                type="button" 
                className="btn btn-success me-sm-3 me-1 data-submit"
                onClick={handleSubmit}
                >{t("accessOffCanvasSetAccess")}</button>
                
            <button type="button" className='btn btn-info me-sm-3 me-1' 
                        data-bs-toggle="modal" data-bs-target="#modalTop"
                        style={{marginRight:5}}
                        onClick={()=>onMove()}
                        >Move Agent</button>
                <button type="button" className='btn btn-label-danger me-sm-3 me-1 data-submit' onClick={()=>handleDelete()} disabled={false}>{t("accessBtnDelete")}</button>
            <button 
                type="button" 
                className="btn btn-label-secondary" 
                data-bs-dismiss="offcanvas">
                {t("cancelbtn")}</button>
            </form>
        </div>
        </div>
        <div className="modal fade" data-bs-backdrop="static" id="modalTop" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <form className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTopTitle">Move agent</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>handleClose()}/>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col mb-3">
                                <Select options={moveTeams}
                                    onChange={(e)=>SetMoveTeamId(e.value)}
                                    placeholder='Select team' id='prefixupdLb'
                                    styles={{
                                        control:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd",
                                            borderColor:"#444663"
                                        }),
                                        option: (styles, { isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                            };
                                        },
                                        singleValue:(baseStyles,state)=>({
                                            ...baseStyles,
                                            color:"#b8b8cd"
                                        }),
                                        placeholder:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd"
                                        }),
                                        menuList:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd",
                                        }),
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" onClick={()=>handleClose()}>Close</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>handleMove()}>Move</button>
                        </div>
                        </form>
                    </div>
        </div>
    </div>
    
    </div>
)
}
