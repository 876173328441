import axios from 'axios';
import flatpickr from 'react-flatpickr';
import React, { useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select'
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@mui/material';
import { DateRangePicker } from 'rsuite';
import { addDays } from 'date-fns';
import dayjs from 'dayjs';
import moment from 'moment';
import 'rsuite/DateRangePicker/styles/index.css';
import './dark-theme-overrides.css';
import styles from './Analytics.module.scss';

export default function AnalyticsRebrending() {
    const [value, setValue] = React.useState([
        dayjs(new Date(Date.now)).startOf("day"),
        dayjs(new Date(Date.now)).endOf("day"),
      ]);
    const [daterange,SetDateRange] = useState({
        from:"",
        to:""
    });
    const { t, i18n} = useTranslation();
    const [companies,setCompanies] = useState([]);
    const [groups,setGroups] = useState([]);
    const [agents,setAgents] = useState([]);
    const [group,setGroup] = useState("");
    const [groupid,setGroupId] = useState(0);
    const [companyActive,setCompanyActive] = useState(false);
    const [groupActive,setGroupActive] = useState(false);
    const [agentActive,setAgentActive] = useState(false);
    const [agent,setAgent] = useState(null);
    const [loading,setLoading] = useState(false);
    const [tdata,setTData] = useState({
        sip_user:"",
        pending:0,
        conversation:0,
        count:0,
        answer:0,
        noanswer:0
    });
    const [tGroup, setTgroup] = useState([]);
    function addOneDay(date = new Date()) {
        date.setDate(date.getDate() + 1);
      
        return date;
    }
    function subOneDay(date = new Date()) {
        date.setDate(date.getDate() - 1);
      
        return date;
    }
    // useEffect(()=>{
    //     var flatpickrRange = document.querySelector("#flatpickr-range");
    //         flatpickrRange.flatpickr({
    //             mode: "range",
    //             onChange: function (selectedDates, dateStr, instance){
    //                 console.log(selectedDates,dateStr);
    //                 if(dateStr.includes("to")){
    //                     SetDateRange({
    //                         from:dateStr.split(' ')[0],
    //                         to:dateStr.split(' ')[2]
    //                     })
    //                     setCompanyActive(true);
    //                     axios.get("https://www.pbxdiamondcall.com:8081/companies")
    //                     .then((res)=>{
    //                         var arr = [];
    //                         if(res.data.length>0){

    //                             if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
    //                             res.data.forEach((item) => {
    //                                 arr.push({
    //                                     value:item.Id,
    //                                     label:item.Title
    //                                 });
    //                             })
    //                             setCompanies(arr);
    //                             }
    //                             else{
    //                                 res.data.forEach((item) => {JSON.parse(secureLocalStorage.getItem("user")).company_id !== item.Id
    //                                     ?console.log(item)
    //                                     :arr.push({
    //                                         value:item.Id,
    //                                         label:item.Title
    //                                     })
    //                                 })
    //                                 setCompanies(arr);
    //                             }
    //                         }
    //                     })
    //                     .catch((err)=>console.log(err))
    //                 }
    //                 else if(!dateStr.includes("to")){
    //                     console.log(selectedDates,dateStr);
    //                         SetDateRange({
    //                             from:selectedDates[0],
    //                             to:addOneDay(selectedDates[1])
    //                         })
    //                         setCompanyActive(true);
    //                         axios.get("https://www.pbxdiamondcall.com:8081/companies")
    //                         .then((res)=>{
    //                             var arr = [];
    //                             if(res.data.length>0){
    
    //                                 if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
    //                                 res.data.forEach((item) => {
    //                                     arr.push({
    //                                         value:item.Id,
    //                                         label:item.Title
    //                                     });
    //                                 })
    //                                 setCompanies(arr);
    //                                 }
    //                                 else{
    //                                     res.data.forEach((item) => {JSON.parse(secureLocalStorage.getItem("user")).company_id !== item.Id
    //                                         ?console.log(item)
    //                                         :arr.push({
    //                                             value:item.Id,
    //                                             label:item.Title
    //                                         })
    //                                     })
    //                                     setCompanies(arr);
    //                                 }
    //                             }
    //                         })
    //                         .catch((err)=>console.log(err))
    //                     }
    //                 }
    //         });
        
    //         SetDateRange({
    //             from:"",
    //             to:""
    //         })
    // },[])
    useEffect(()=>{
        setCompanyActive(true);
        console.log(value);
        axios.get("https://www.pbxdiamondcall.com:8081/companies")
        .then((res)=>{
            var arr = [];
            if(res.data.length>0){

                if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
                res.data.forEach((item) => {
                    arr.push({
                        value:item.Id,
                        label:item.Title
                    });
                })
                setCompanies(arr);
                }
                else{
                    res.data.forEach((item) => {JSON.parse(secureLocalStorage.getItem("user")).company_id !== item.Id
                        ?console.log(item)
                        :arr.push({
                            value:item.Id,
                            label:item.Title
                        })
                    })
                    setCompanies(arr);
                }
            }
        })
        .catch((err)=>console.log(err))
    },[value])
    const handleCompanyChoose=(e)=>{
        console.log(daterange)
        setGroupActive(true);
        setAgentActive(false);
        setAgent(null);
        if(JSON.parse(secureLocalStorage.getItem('user')).role_id===1){
            axios.post(`https://www.pbxdiamondcall.com:8081/teams/company/`,{company_id:e.value})
            .then((res)=>{
                var arr = [];
                console.log(res);
                if(res.data.length>0){
                    res.data.forEach((item) => {
                        arr.push({
                            value:item.id,
                            label:item.title
                        });
                    })
                    setGroups(arr);
                }
            })
            .catch((err)=>console.log(err))
             }
            else{
            axios.post('https://www.pbxdiamondcall.com:8081/agents/access/', {
                user_info_id:JSON.parse(secureLocalStorage.getItem('user')).user_info_id,
                company_id:JSON.parse(secureLocalStorage.getItem('user')).company_id
           })
           .then((res)=>{
            var arr = [];
            res.data.forEach((el)=>{
                console.log(el);
                if(el.has_access===1){
                arr.push({
                            value:el.id,
                            label:el.title
                        })
                }
                
            })
            setGroups(arr);
            
            console.log(res.data);
           })
           .catch((err)=>console.log(err))
        }
    }
    const handleGroupChoose=(e)=>{
        setAgentActive(true);
        setAgent(null);
        setGroup(e.label);
        setGroupId(e.value);
        axios.post("https://www.pbxdiamondcall.com:8081/agents/getFromTeam", {team_id:e.value})
        .then((res)=>{
            var arr = [];
            console.log(res);
            if(res.data.length>0){
                res.data.forEach((item) => {
                    arr.push({
                        value:item.Id,
                        label:item.Login
                    });
                })
                setAgents(arr);
            }
        })
        .catch((err)=>console.log(err))
    }
    const handleAgentChoose=(e)=>{
        setAgent(e.label);
    }
    const handleSubmit= async ()=>{
        setLoading(true);
        console.log(daterange)
        await axios.post("https://www.pbxdiamondcall.com:8081/agent/analyze",{
            from:dayjs(value[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            to:dayjs(value[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            sip:agent
        })
        .then((res)=>{
            setTData(res.data[0]);    
            console.log(res);
            setLoading(false);
        })
        .catch((err)=>console.log(err));
    }
    const handleTeamSubmit= async ()=>{
        
        setLoading(true);
        console.log(daterange)
        await axios.post("https://www.pbxdiamondcall.com:8081/team/analyze",{
            from:dayjs(value[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            to:dayjs(value[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            team_id:groupid
        })
        .then((res)=>{
            const summary = res.data.reduce((acc, val) => {
                acc.pending += Number(val.pending);
                acc.conversation += Number(val.conversation);
                acc.count += val.count;
                acc.answer += val.answer;
                acc.noanswer += val.noanswer;
                return acc;
            }, { sip_user:"Summary:",pending: 0, conversation: 0, count: 0, answer: 0, noanswer: 0 });
            res.data.push(summary);
            setTgroup(res.data);
            setLoading(false);
        })
        .catch((err)=>console.log(err));
    }
    const predefinedRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'Last 7 Days',
            value: [addDays(new Date(), -7), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 Days',
            value: [addDays(new Date(), -30), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 2 Months',
            value: [addDays(new Date(), -60), new Date()],
            placement: 'left'
        }
    ];
return (
    <div className="container-xxl flex-grow-1 container-p-y" 
    style={{ justifyContent: "start", display: "flex", flexDirection: "column", width:'100%' }}>
    <div className="card" style={{width:"70vw", height:"70vh", maxHeight:"70vh",overflowY:"scroll",  display:'flex',
    backgroundColor:"#2A2A31",color:"#d3d3d2", flexDirection:"column", gap:"0.5vh", fontFamily:"'Ubuntu',sans-serif"}}>
        <div className="card-header ">
            <h5 className="card-title" style={{fontSize:"2.5vh",color:"#d3d3d2", fontWeight:"bold"}}>Analytics</h5>
        </div>
        <div style={{display:'flex', flexDirection:"row"}}>
            <div className="mb-3 rs-theme-dark" style={{width:"14.5vw", margin:10}}>
            <label style={{color:"#d3d3d2"}} htmlFor="flatpickr-range" className="form-label">Select range</label>
            <DateRangePicker showOneCalendar ranges={predefinedRanges} value={value} onChange={setValue} className='rs-theme-dark'/>
            </div>
            {/* <div className="mb-3" style={{width:400, margin:10}}>
            <label htmlFor="flatpickr-range" className="form-label">Select range</label>
            <input type="text" className="form-control" placeholder="YYYY-MM-DD to YYYY-MM-DD" id="flatpickr-range" style={{padding:10}}/> */}
            {/* </div> */}
        <div className="mb-3" style={{width:"14.5vw", margin:10}}>
            <label style={{color:"#d3d3d2"}} htmlFor="flatpickr-range" className="form-label">Select company</label>
            <Select placeholder='Select company'
                            onChange={(e)=>handleCompanyChoose(e)}
                            id='operatorLb' 
                            isDisabled={!companyActive}
                            options={companies}
                            styles={{
                                control:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#d3d3d2",
                                    borderColor:"#232329"
                                }),
                                option: (styles, { isFocused, isSelected }) => {
                                    return {
                                        ...styles,
                                        backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                    };
                                },
                                singleValue:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#d3d3d2"
                                }),
                                placeholder:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#d3d3d2"
                                }),
                                menuList:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#949fae",
                                }),
                            }}/>
        </div>
        <div className="mb-3" style={{width:"14.5vw", margin:10}}>
            <label style={{color:"#d3d3d2"}} htmlFor="flatpickr-range" className="form-label">Select group</label>
            <Select placeholder='Select group'
                            isDisabled={!groupActive}      
                            onChange={(e)=>handleGroupChoose(e)}
                            id='operatorLb' 
                            options={groups}
                            styles={{
                                control:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#d3d3d2",
                                    borderColor:"#232329"
                                }),
                                option: (styles, { isFocused, isSelected }) => {
                                    return {
                                        ...styles,
                                        backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                    };
                                },
                                singleValue:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#d3d3d2"
                                }),
                                placeholder:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#d3d3d2"
                                }),
                                menuList:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#949fae",
                                }),
                            }}/>
        </div>
        <div className="mb-3" style={{width:"14.5vw", margin:10}}>
            <label style={{color:"#d3d3d2"}} htmlFor="flatpickr-range" className="form-label">Select agent</label>
            <Select placeholder='Select agent'
                            onChange={(e)=>handleAgentChoose(e)}
                            id='operatorLb' 
                            isDisabled={!agentActive} 
                            options={agents} 
                            styles={{
                                control:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#d3d3d2",
                                    borderColor:"#232329"
                                }),
                                option: (styles, { isFocused, isSelected }) => {
                                    return {
                                        ...styles,
                                        backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                    };
                                },
                                singleValue:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#d3d3d2"
                                }),
                                placeholder:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#d3d3d2"
                                }),
                                menuList:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#232329",
                                    color:"#949fae",
                                }),
                            }}/>
        </div>
        <div style={{height:"7vh",paddingRight:"0.5vw",display:"flex",alignItems:"end",justifyContent:"end",gap:"0.5vw"}}>
            <button className={styles.header_item} 
            disabled={!(companyActive && groupActive && agentActive && agent!==null)} onClick={()=>handleSubmit()}>Agent</button>
            <button className={styles.header_item} 
            disabled={!(companyActive && groupActive && agentActive)} onClick={()=>handleTeamSubmit()}>Group</button>
        </div>
        </div>
        <div className="card-body" style={{height:"100%"}}>
            {
                loading===true
                ?
                <div className='container-xxl flex-grow-1 container-p-y'>
                <div className="card" style={{width:"70vw", height:"40vh", justifyContent:'center',alignItems:'center', display:'flex',
                backgroundColor:"#2A2A31",color:"#d3d3d2", flexDirection:"column", gap:"0.5vh"}}>
                        <span style={{display:'block',fontSize:"2vh",fontFamily:"'Ubuntu',sans-serif",fontWeight:"500"}}>{t("loadingData")}...</span>
                        <LinearProgress 
                            sx={{color:"#d3d3d2",width:"40vw"}}
                        />
                    </div>
                </div>
                :
                <div className="card-datatable table-responsive">
                <table className="datatables-users table" style={{borderColor:'#d3d3d2'}}>
                  <thead style={{backgroundColor:'#2A2A31',borderColor:'#d3d3d2',position:"sticky", top:'0vh'}}>
                    <tr style={{color:'#d3d3d2',fontSize:"1.8vh"}}>
                        <th style={{color:"#d3d3d2"}}>Agent</th>
                        <th style={{color:"#d3d3d2"}}>Group</th>
                        <th style={{color:"#d3d3d2"}}>Pending time</th>
                        <th style={{color:"#d3d3d2"}}>Conversation time</th>
                        <th style={{color:"#d3d3d2"}}>Different calls</th>
                        <th style={{color:"#d3d3d2"}}>Answer</th>
                        <th style={{color:"#d3d3d2"}}>No answer</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tGroup.length>0?tGroup.map((val,ind)=>(
                        <tr key={ind}>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{val.sip_user}</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{group}</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{Number(val.pending/60).toFixed()} min.</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{Number(val.conversation/60).toFixed()} min.</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{val.count} calls</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{val.answer}</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{val.noanswer}</td>
                        </tr>
                    ))
                    :agent!==null?tdata.sip_user !== ""?
                        <tr>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{tdata.sip_user}</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{group}</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{Number(tdata.pending/60).toFixed()} min.</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{Number(tdata.conversation/60).toFixed()} min.</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{tdata.count} calls</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{tdata.answer}</td>
                            <td style={{fontWeight:"bold",color:"#d3d3d2"}}>{tdata.noanswer}</td>
                        </tr>
                        :
                        <tr>
                        <td style={{fontWeight:"bold",color:"#d3d3d2"}}></td>
                        <td style={{fontWeight:"bold",color:"#d3d3d2"}}></td>
                        <td style={{fontWeight:"bold",color:"#d3d3d2"}}></td>
                        <td style={{fontWeight:"bold",color:"#d3d3d2"}}>Information not found.</td>
                        <td style={{fontWeight:"bold",color:"#d3d3d2"}}></td>
                        <td style={{fontWeight:"bold",color:"#d3d3d2"}}></td>
                        <td style={{fontWeight:"bold",color:"#d3d3d2"}}></td>
                    </tr>
                    :null
                    }
                    </tbody>
                </table>
                </div>
            }
        </div>
    </div>
    </div>
    )
}
