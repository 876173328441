import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';

export default function Companies() {
    const nav = useNavigate(); 
    const title = useRef(null);
    const {t,i18n } = useTranslation();
    const currentCompany = useRef();
    const [Entities, SetEntities] = useState([]);
    const [Prefixes, SetPrefixes] = useState([]);
    const [companyid, setcompanyid] = useState(0);
    
    const Connect =()=> {
      axios.get("https://www.pbxdiamondcall.com:8081/companies")
      .then((res)=>{
          //console.log(res);
          SetEntities(res.data);
      })
      .catch((err)=>{
          console.log(err);
      })
      axios.get("https://www.pbxdiamondcall.com:8081/prefixes")
      .then((res)=>{
        console.log(res.data);
          SetPrefixes(res.data);
      })
      .catch((err)=>{
          console.log(err);
      })
    }

    function handleSubmit(){
      var arr = [];
        Prefixes.forEach(element => {
          arr.push({
            id:element.id,
            access:document.getElementById(element.id).checked
          });
        });
        const element = title.current;
        if(element.value !== ""){
            axios.post("https://www.pbxdiamondcall.com:8081/companies/add",{title:element.value, prefixes: arr})
            .then((res)=>{
                console.log(res);
                Swal.fire({
                  title: 'Result',
                  text: res.statusText,
                  timer:2000,
                  customClass: {
                  confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
              })
              Connect();
            })
            .catch((err)=>{
              Swal.fire({
                title: 'Result',
                text: err.statusText,
                timer:2000,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            Connect();
              console.log(err)
            });
        }
        else alert("enter title");
    }
    function handleUpdate(){
        var arr = [];
        Prefixes.forEach(element => {
          arr.push({
            id:element.id,
            access:document.getElementById(element.id).checked
          });
        });
        console.log(arr);
        const element = title.current;
        let id = companyid;
        if(element.value !== ""){
          console.log(id);
            axios.post("https://www.pbxdiamondcall.com:8081/companies/update",{title:element.value, id, prefixes:arr})
            .then((res)=>{
                console.log(res);
                Swal.fire({
                  icon: 'success',
                  title: 'Result',
                  text: res.statusText,
                  timer:2000,
                  customClass: {
                  confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
              })
              Connect();
            })
            .catch((err)=>{
              Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err.statusText,
                timer:2000,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            Connect();
              console.log(err)
            });
        }
        else alert("enter title");
    }
    async function handleDelete () {
      let isdel = await SwalDel();
      console.log(isdel);
      if(isdel){
        axios.delete("https://www.pbxdiamondcall.com:8081/companies/delete",{data:{id:currentCompany.current} })
        .then((res)=>{
          console.log(res);
          Swal.fire({
            icon:'success',
            title: 'Result',
            text: res.statusText,
            timer:2000,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        Connect();
      })
        .catch((err)=>{
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Authentification error.',
            text: err.statusText,
            timer:2000,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
        Connect();
        })
      }
    }
    const SwalDel =()=>{
        return Swal.fire({ // <-- return Promise
          title: 'Delete record?',
          text: 'This action cannot be undone.',
          icon: 'question',
          confirmButtonText: 'Delete',
          confirmButtonColor: '#DC004E',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: false,
          scrollbarPadding: false
        }).then((result) => {
          return result.isConfirmed;
        });
    }
    function onUpdate(ind){
      currentCompany.current = Entities[ind].Id;
      axios.post("https://www.pbxdiamondcall.com:8081/prefixes/company",
        {id:Entities[ind].Id})
        .then((res)=>{
          console.log(res);
          Prefixes.forEach(element => {
                document.getElementById(element.id).checked = false;
          });
          Prefixes.forEach(element => {
            res.data.forEach(el=>{
              if(el.prefix_id === element.id){
                document.getElementById(element.id).checked = true;
              }
            })
          });
        })
        .catch((err)=>console.log(err));
        setcompanyid(Entities[ind].Id);
        const element = title.current;
        element.value = Entities[ind].Title;
        const el = document.getElementById('submitbtn');
        el.disabled = true;
        const el1 = document.getElementById('updatebtn');
        el1.disabled = false;
    }
    function onAdd(){
        const element = document.getElementById('updatebtn');
        element.disabled = true; 
        const el1 = document.getElementById('submitbtn');
        el1.disabled = false;
    }
    function handleCheck(ind){
        nav(`/old/companies/${Entities[ind].Id}/offices/`);
    }

    useEffect(()=>{
      Connect();
    },[])
    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(role !== 1){
        return <Navigate to="/old/onlinecalls" replace={true}/>
    }
    else
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
    <div className="card">
      <div className="card-header border-bottom">
        <h5 className="card-title" style={{fontSize:"25px", fontWeight:"bold"}}>{t("companies")}</h5>
        {/* Add new company button */}
        <div className="col-md-10">
            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-start flex-md-row flex-column mb-3 mb-md-0">
                <div className="dt-buttons btn-group flex-wrap"> 
                    <button className="btn btn-secondary add-new btn-primary"
                      tabIndex={0} aria-controls="DataTables_Table_0" 
                      type="button" data-bs-toggle="offcanvas" 
                      data-bs-target="#offcanvasAddUser"
                      onClick={onAdd}
                      >
                        <span><i className="bx bx-plus me-0 me-sm-1" />
                        <span className="d-none d-sm-inline-block">{t("companiesAdd")}</span>
                        </span>
                    </button> 
                </div>
            </div>
        </div>
      </div>
      <div className="card-datatable table-responsive">
        <table className="datatables-users table border-top">
          <thead>
            <tr>
              <th style={{fontSize:"18px"}}>{t("companiesThTitle")}</th>
              <th style={{fontSize:"18px"}}>{t("companiesThUpdate")}</th>
              <th style={{fontSize:"18px"}}>{t("companiesThOffices")}</th>
            </tr>
          </thead>
          <tbody>
            {Entities.length !== 0 ? 
            Entities.map((cmp,ind)=>{
                    if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1)
                    return <tr key={ind}  style={{fontWeight:"bold"}}>
                    <td><b>{cmp.Title}</b></td>
                    <td><button type="button" className='btn btn-warning' 
                    data-bs-toggle="offcanvas" 
                    data-bs-target="#offcanvasAddUser"
                    onClick={()=>onUpdate(ind)}
                    >{t("companiesThUpdate")}</button></td>
                    
                    <td><button type="button" className='btn btn-info' onClick={()=>handleCheck(ind)}>{t("companiesThOffices")}</button></td>
                </tr>
                    else 
                    return JSON.parse(secureLocalStorage.getItem("user")).company_id !== cmp.Id
                    ? null
                    : <tr key={ind}  style={{fontWeight:"bold"}}>
                    <td><b>{cmp.Title}</b></td>
                    <td><button type="button" className='btn btn-warning' 
                    data-bs-toggle="offcanvas" 
                    data-bs-target="#offcanvasAddUser"
                    onClick={()=>onUpdate(ind)}
                    >{t("companiesThUpdate")}</button></td>
                    
                    <td><button type="button" className='btn btn-info' onClick={()=>handleCheck(ind)}>{t("companiesThOffices")}</button></td>
                    </tr>
            })
            :null}
          </tbody>
        </table>
      </div>
      {/* Offcanvas to add new instance */}
      <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddUser"  style={{width:450}} aria-labelledby="offcanvasAddUserLabel">
        <div className="offcanvas-header">
          <h5 id="offcanvasAddUserLabel" className="offcanvas-title">{t("companies")}</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body mx-0 flex-grow-0">
          <form className="add-new-user pt-0" id="addNewCompany" onsubmit="return false">
            <div className="mb-3">
              <label className="form-label" htmlFor="add-company-title">{t("companiesThTitle")}</label>
              <input type="text" 
                className="form-control" 
                id="add-company-title" 
                placeholder={t("companiesPlaceholder")}
                name="companyTitle" 
                aria-label="Company title"
                ref={title} />
            </div>
            <br />
            <label className="form-label" htmlFor="teams-list">{t("companiesPrefixes")}</label>
            <div className="list-group" style={{maxHeight:400, overflow:"scroll", WebkitOverflowScrolling:'touch'}}>
                {
                    Prefixes.map((val,ind)=>{
                        return <label className="list-group-item" key={ind}>
                            <label class="switch">
                            <input type="checkbox" class="switch-input" id={val.id}  />
                            <span class="switch-toggle-slider">
                                <span class="switch-on">
                                <i class="bx bx-check"></i>
                                </span>
                                <span class="switch-off">
                                <i class="bx bx-x"></i>
                                </span>
                            </span>
                            <span class="switch-label">{val.prefix}_{val.title}</span>
                            </label>
                        </label>
                    })
                }
                
            </div>
            <br />
            <button 
                id='submitbtn'
                type="button" 
                className="btn btn-success me-sm-3 me-1 data-submit"
                onClick={handleSubmit}
                >{t("submitbtn")}</button>
            <button 
                id='updatebtn'
                type="button" 
                className="btn btn-warning me-sm-3 me-1 data-submit"
                onClick={handleUpdate}
                >{t("companiesThUpdate")}</button>
            <button type="button" className='btn btn-danger me-sm-3 me-1 ' onClick={()=>handleDelete()} disabled={false}>{t("companiesThDelete")}</button>
            <button 
                type="button" 
                className="btn btn-label-secondary me-sm-3 me-1 " 
                data-bs-dismiss="offcanvas">
                {t("cancelbtn")}</button>
          </form>
        </div>
      </div>
    </div>
    
    </div>
  )
}
